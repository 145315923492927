const initialState = {
  enabled: false,
  basicAuthCredentials: null,
  instanceName: "",
  authType: "",
  oauthConfiguration: null,
  defaultTicketTemplateId: null,
  integrationTypeActivated: null,
}

export default function connection(state = initialState, action) {
  switch (action.type) {
    case "SET_SERVICE_NOW_CONNECTION":
      return { ...state, ...action.payload }
    case "RESET_SERVICE_NOW_CONNECTION":
      return initialState
    default:
      return state
  }
}
