import { useMemo } from "react"
import { localized } from "js/includes/common/utils"
import { ControlLabel } from "react-bootstrap"
import { StyledCol, StyledRow } from "js/includes/components/Styled/Form"
import Rule from "./Rule"

const RuleGroup = ({ combinator, rules, translations, schema }) => {
  const { controls, createRule, onRuleAdd } = schema

  const labelMapper = useMemo(
    () => ({
      all: localized("If the conditions meet ”ALL” of these conditions"),
      any: localized("If the conditions meet ”ANY” of these conditions"),
      actions: localized("Perform the following actions"),
    }),
    [],
  )

  const addRule = event => {
    event.preventDefault()
    event.stopPropagation()

    const newRule = createRule()
    onRuleAdd(newRule)
  }

  return (
    <StyledRow id={`row-rule-group-${combinator}`} marginBottom={2} flexDirection="column">
      <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
        {labelMapper[combinator]}
      </StyledCol>
      <StyledCol xs={12} marginTop={2} flexDirection="column">
        {rules.map(
          ({ id, field, value, operator, validation, touched, metaData }, index) =>
            field !== "deleted" && (
              <Rule
                {...{
                  rowIndex: index,
                  key: id,
                  id,
                  field,
                  value,
                  operator,
                  schema,
                  translations,
                  validation,
                  touched,
                  combinator,
                  metaData,
                }}
              />
            ),
        )}
      </StyledCol>
      <StyledCol id={`column-add-${combinator}-container`} xs={12}>
        <controls.addRuleAction label={localized(translations.addRule.labelToken)} onClick={addRule} rules={rules} />
      </StyledCol>
    </StyledRow>
  )
}

RuleGroup.defaultProps = {
  rules: [],
  combinator: "all",
  schema: {},
}

export default RuleGroup
