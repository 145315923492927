import { combineReducers } from "redux"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import sortBy from "js/state/reducers/common/tableSorting/sortBy"
import sortDirection from "js/state/reducers/common/tableSorting/sortDirection"
import boolean from "js/state/reducers/common/boolean"
import devices from "./devices"
import criteria from "./criteria"
import filters from "./filters"
import groups from "./groups"
import groupsFilter from "./groupsFilter"
import activeSearch from "./activeSearch"
import selectedCriterion from "./selectedCriterion"
import noop from "../noop"
import searchForEdit from "./searchForEdit"
import deviceSearchGroupKey from "./deviceSearchGroupKey"
import deletingSearch from "./deletingSearch"
import changeDevicePolicy from "./changeDevicePolicy"
import deleteDevice from "./deleteDevice"
import wakeDevice from "./wakeDevice"
import loading from "js/state/reducers/common/loading"
import activityStatuses from "./activityStatuses"
import patches from "./patches"
import selectedListItems from "js/state/reducers/common/selectedListItems"
import patchInventoryNameFilter from "./patchInventoryNameFilter"
import activityStatusesNameFilter from "./activityStatusesNameFilter"
import activityStatusesTypeFilter from "./activityStatusesTypeFilter"
import performingDeviceAction from "./performingDeviceAction"
import devicesCount from "./devicesCount"
import actionRunnerParams from "./actionRunnerParams"

export default combineReducers({
  devices,
  devicesCount,
  criteria,
  filters,

  groups,
  groupEditable: createNamedWrapperReducer(boolean, "groupEditable"),
  groupsFilter,
  groupsListSort: combineReducers({
    sortBy: createNamedWrapperReducer(sortBy, "groupsListSort", "name"),
    sortDirection: createNamedWrapperReducer(sortDirection, "groupsListSort"),
  }),

  searchForEdit,
  deviceSearchGroupKey,
  selectedCriterion,
  deletingSearch,
  activeSearch,

  patches,
  patchInventoryNameFilter,
  patchInventorySorted: combineReducers({
    sortBy: createNamedWrapperReducer(sortBy, "patchInventory", "name"),
    sortDirection: createNamedWrapperReducer(sortDirection, "patchInventory"),
  }),

  activityStatuses,
  activityStatusesNameFilter,
  activityStatusesTypeFilter,
  activityStatusesSorted: combineReducers({
    sortBy: createNamedWrapperReducer(sortBy, "activityStatuses", "name"),
    sortDirection: createNamedWrapperReducer(sortDirection, "activityStatuses"),
  }),

  selectedDevices: createNamedWrapperReducer(selectedListItems, "devices"),
  selectAllModeUnselectedDevices: createNamedWrapperReducer(selectedListItems, "selectAllModeUnselectedDevices"),
  actionRunnerParams,
  deviceListSort: combineReducers({
    sortBy: createNamedWrapperReducer(sortBy, "deviceListSort", "name"),
    sortDirection: createNamedWrapperReducer(sortDirection, "deviceListSort", "ASC"),
  }),

  criteriaDefinitions: noop([]),

  changeDevicePolicy: combineReducers({
    modal: changeDevicePolicy,
    loading: createNamedWrapperReducer(loading, "changeDevicePolicyLoading", false),
  }),
  deleteDevice: combineReducers({
    modal: deleteDevice,
    loading: createNamedWrapperReducer(loading, "deleteDeviceLoading", false),
  }),
  wakeDevice: combineReducers({
    modal: wakeDevice,
    loading: createNamedWrapperReducer(loading, "wakeDeviceLoading", false),
  }),

  performingDeviceAction,
  runningSearch: createNamedWrapperReducer(loading, "runningSearch", false),
  savingSearch: createNamedWrapperReducer(loading, "savingSearch", false),
})
