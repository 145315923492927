import { memo, useMemo } from "react"
import { find, map, propEq } from "ramda"
import { StyledFormGroup, StyledCol, StyledRow } from "js/includes/components/Styled/Form"
import { localized } from "js/includes/common/utils"
import { flattenOptions } from "js/includes/components/RuleEditor/utils"

const Rule = memo(
  ({
    rowIndex,
    id,
    field,
    operator,
    value,
    translations,
    validation,
    touched,
    combinator,
    metaData,
    schema: {
      controls,
      fields,
      getInputType,
      getOperators,
      getValueEditorType,
      getValues,
      onPropChange,
      onRuleRemove,
      excludeOptions,
    },
  }) => {
    const operatorOptions = useMemo(
      () => (operator ? map(op => ({ ...op, label: localized(op.labelToken) }), getOperators(field) || []) : []),
      [operator, field, getOperators],
    )

    const onElementChanged = (property, value) => {
      onPropChange(property, value, id)
    }

    const onFieldChanged = value => {
      onElementChanged("field", value)
    }

    const onOperatorChanged = value => {
      onElementChanged("operator", value)
    }

    const onValueChanged = value => {
      onElementChanged("value", value)
    }

    const removeRule = event => {
      event.preventDefault()
      event.stopPropagation()

      onRuleRemove(id)
    }

    const fieldData = useMemo(() => find(propEq("value", field), flattenOptions(fields)) || { label: field }, [
      fields,
      field,
    ])

    return (
      <>
        <StyledRow id={`row-rule-${combinator}-${rowIndex}`} display="flex" flexDirection="row" marginBottom={2}>
          <StyledCol xs={11} flex={1} flexDirection="column">
            <StyledFormGroup flexDirection="row">
              <StyledCol id={`column-rule-${combinator}-1`} xs={4} display="block" flex="none">
                <controls.fieldSelector
                  ariaLabel={localized("Field selector for row {{rowIndex}}", { rowIndex })}
                  options={fields}
                  excludeOptions={excludeOptions}
                  value={field}
                  operator={operator}
                  handleOnChange={onFieldChanged}
                />
              </StyledCol>
              {!!operator && (
                <StyledCol id={`column-rule-${combinator}-2`} xs={4} display="block" flex="none">
                  <controls.operatorSelector
                    ariaLabel={localized("{{field}} operator", { field: fieldData.label })}
                    field={field}
                    options={operatorOptions}
                    value={operator}
                    handleOnChange={onOperatorChanged}
                  />
                </StyledCol>
              )}

              <StyledCol id={`column-rule-${combinator}-3`} xs={!!operator ? 4 : 8} display="block" flex="none">
                <controls.valueEditor
                  ariaLabel={localized("Field editor for row {{rowIndex}}", { rowIndex })}
                  field={field}
                  operator={operator}
                  value={value}
                  metaData={metaData}
                  type={getValueEditorType(field, operator)}
                  inputType={getInputType(field, operator)}
                  values={getValues(field, operator)}
                  handleOnChange={onValueChanged}
                />
              </StyledCol>
            </StyledFormGroup>
          </StyledCol>

          <StyledCol id={`column-rule-${combinator}-4`} xs={1} flex={0} alignItems="center" justifyContent="center">
            <StyledFormGroup>
              <StyledCol xs={12}>
                <controls.removeRuleAction onClick={removeRule} type="button" />
              </StyledCol>
            </StyledFormGroup>
          </StyledCol>
        </StyledRow>

        {touched && validation?.success === false && (
          <StyledRow marginBottom={2}>
            <StyledCol xs={12} display="inline-block" flex="none">
              <em className="invalid">{validation.message}</em>
            </StyledCol>
          </StyledRow>
        )}
      </>
    )
  },
)

Rule.defaultProps = {
  id: null,
  field: null,
  operator: null,
  value: null,
  schema: null,
}

export default Rule
