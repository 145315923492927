import React from "react"
import Select from "react-select"
import { propEq } from "ramda"
import { Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { localizationKey, localizedF } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"

const enabledOptions = [
  { value: true, label: localizedF("Turn On") },
  { value: false, label: localizedF("Turn Off") },
]
export default function SystemRestore({ onChange, values: { enabled } }) {
  return (
    <>
      <Box margin={sizer(2, 0, 1)}>
        <Text bold size="sm" token={localizationKey("Action")} />
      </Box>
      <Select
        {...{
          getOptionLabel: ({ label }) => label(),
          id: "systemRestoreOptions",
          options: enabledOptions,
          value: enabledOptions.find(propEq("value", enabled)),
          onChange: selection => onChange("enabled", selection.value),
        }}
      />
    </>
  )
}
