//@flow
import type { FilterAction } from "js/state/actions/types"

const softwarePatchesFilter = (state: string = "", action: FilterAction) => {
  switch (action.type) {
    case "UPDATE_SOFTWARE_PATCHES_FILTER":
      return action.filter
    default:
      return state
  }
}

export default softwarePatchesFilter
