import { combineReducers } from "redux"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import visibility from "js/state/reducers/common/visibility"
import loading from "js/state/reducers/common/loading"
import sortBy from "js/state/reducers/common/tableSorting/sortBy"
import sortDirection from "js/state/reducers/common/tableSorting/sortDirection"
import division from "./division"
import mapping from "./mapping"

export default combineReducers({
  division,
  loadingWebrootConfiguration: createNamedWrapperReducer(loading, "loadingWebrootConfiguration", false),
  webrootConfigurationModalVisible: createNamedWrapperReducer(visibility, "webrootConfigurationModalVisible", false),
  webrootActionsModalVisible: createNamedWrapperReducer(visibility, "webrootActionModalVisible", false),
  mapping: combineReducers({
    data: mapping,
    sortBy: createNamedWrapperReducer(sortBy, "webrootMappingSort", "organizationName"),
    sortDirection: createNamedWrapperReducer(sortDirection, "webrootMappingSort"),
  }),
})
