const getInitialState = () => ({
  path: [],
  documentTemplates: [],
  documentTemplateInstance: null,
  documentTemplateInstances: [],
  documentTemplateInstanceValues: {},
  documentTemplateToEdit: {},
})

const setPath = (state, { path }) => ({
  ...state,
  path,
})

const setDocumentTemplates = (state, { documentTemplates }) => ({
  ...state,
  documentTemplates,
})

const setDocumentTemplateInstance = (state, { documentTemplateInstance }) => ({
  ...state,
  documentTemplateInstance,
})

const setDocumentTemplateInstances = (state, { documentTemplateInstances }) => ({
  ...state,
  documentTemplateInstances,
})

const setDocumentTemplateInstanceValues = (state, { documentTemplateInstanceValues }) => ({
  ...state,
  documentTemplateInstanceValues,
})

const updateDocumentTemplateInstanceValues = (state, { updatedDocumentTemplateInstanceValues }) => ({
  ...state,
  documentTemplateInstanceValues: {
    ...state.documentTemplateInstanceValues,
    ...updatedDocumentTemplateInstanceValues,
  },
})

const setDocumentTemplateToEdit = (state, { documentTemplateToEdit }) => ({
  ...state,
  documentTemplateToEdit,
})

export default function appsAndServices(state = getInitialState(), action) {
  switch (action.type) {
    case "SET_DOCUMENT_PATH":
      return setPath(state, action)
    case "SET_DOCUMENT_TEMPLATES":
      return setDocumentTemplates(state, action)
    case "SET_DOCUMENT_TEMPLATE_INSTANCE":
      return setDocumentTemplateInstance(state, action)
    case "SET_DOCUMENT_TEMPLATE_INSTANCES":
      return setDocumentTemplateInstances(state, action)
    case "SET_DOCUMENT_TEMPLATE_INSTANCE_VALUES":
      return setDocumentTemplateInstanceValues(state, action)
    case "UPDATE_DOCUMENT_TEMPLATE_INSTANCE_VALUES":
      return updateDocumentTemplateInstanceValues(state, action)
    case "SET_DOCUMENT_TEMPLATE_TO_EDIT":
      return setDocumentTemplateToEdit(state, action)
    default:
      return state
  }
}
