export const onChangeTab = (state, { activeTab }) => ({
  ...state,
  activeTab,
})

export const onChangeSelected = (state, { selected, tabName }) => {
  return {
    ...state,
    [tabName]: {
      ...state[tabName],
      selected,
    },
  }
}

export const onChangeFilterText = (state, { filterText, tabName }) => {
  return {
    ...state,
    [tabName]: {
      ...state[tabName],
      filterText,
    },
  }
}
