import { combineReducers } from "redux"
import connection from "./connection"
import settings from "./settings"
import cwHelpDeskSettings from "./helpdesk"
import initialEditorState from "./initialEditorState"
import mappings from "./mappings/rootReducer"
import nodeSyncLogs from "./sync/nodeSyncLogs"
import agreementSyncLogs from "./sync/agreementSyncLogs"
import agreementSync from "./sync/agreementSync"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import loading from "js/state/reducers/common/loading"

export default combineReducers({
  connection,
  initialEditorState,
  settings,
  cwHelpDeskSettings,
  mappings,
  nodeSyncLogs,
  agreementSyncLogs,
  agreementSync,
  loadingAgreementSync: createNamedWrapperReducer(loading, "CWAgreementSync", true),
  loadingAgreementSyncLogs: createNamedWrapperReducer(loading, "CWAgreementSyncLog", false),
  loadingNodeSyncLogs: createNamedWrapperReducer(loading, "CWNodeSyncLog", false),
})
