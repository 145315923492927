import NewFolderModal from "js/includes/deviceDashboard/tools/FileExplorer/NewFolderModal"

type WrappedNewFolderModalProps = {
  unmount?: () => void
  save: () => string | undefined
  nodeClass: string
}

export const WrappedNewFolderModal = ({ unmount, ...restProps }: WrappedNewFolderModalProps) => (
  <NewFolderModal {...{ ...restProps, close: unmount }} />
)
