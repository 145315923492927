import React from "react"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

const Indicator = ({
  noText = false,
  loadingToken = localizationKey("Loading"),
  className = "display-flex flex-full align-items-center justify-content-center",
  loadingTokenStyleOverrides,
}) => (
  <div {...{ className }}>
    <div className="sk-spinner sk-spinner-fading-circle" style={{ margin: 0 }}>
      <div className="sk-circle1 sk-circle" />
      <div className="sk-circle2 sk-circle" />
      <div className="sk-circle3 sk-circle" />
      <div className="sk-circle4 sk-circle" />
      <div className="sk-circle5 sk-circle" />
      <div className="sk-circle6 sk-circle" />
      <div className="sk-circle7 sk-circle" />
      <div className="sk-circle8 sk-circle" />
      <div className="sk-circle9 sk-circle" />
      <div className="sk-circle10 sk-circle" />
      <div className="sk-circle11 sk-circle" />
      <div className="sk-circle12 sk-circle" />
    </div>
    <div
      style={{
        display: noText ? "none" : "block",
        color: "#949597",
        fontSize: "1.4em",
        marginLeft: "13px",
        ...loadingTokenStyleOverrides,
      }}
    >
      {localized(loadingToken)}
      ...
    </div>
  </div>
)

export default Indicator
