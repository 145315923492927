export const textBaseClassName = "data-table-text"

export const getTextClassName = ({ textWrap, textWrapLineLimit }) =>
  `text ${!textWrap ? "no-text-wrap" : ""} ${textWrap && textWrapLineLimit ? "clamp-lines" : ""}`

export const getTextStyle = ({ textWrap, textWrapLineLimit }) => ({
  ...(textWrap && textWrapLineLimit && { WebkitLineClamp: textWrapLineLimit }),
})

const Text = ({ textWrap, textWrapLineLimit, extraClassName, value }) => (
  <div
    {...{
      className: `${textBaseClassName} ${getTextClassName({ textWrap, textWrapLineLimit })} ${
        extraClassName ? extraClassName : ""
      }`,
      style: getTextStyle({ textWrap, textWrapLineLimit }),
    }}
  >
    {value}
  </div>
)

export default Text
