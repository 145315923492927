import {
  compose,
  defaultTo,
  reject,
  map,
  uniqBy,
  prop,
  without,
  adjust,
  assoc,
  findIndex,
  propEq,
  omit,
  differenceWith,
  indexBy,
  filter,
  reduce,
  pluck,
} from "ramda"
import fastDeepEqual from "fast-deep-equal"
import { isCriteriaEqual } from "js/state/selectors/deviceSearch/criteria"

function findCriterion(actionCriteria, stateCriterion) {
  const newCriterion = stateCriterion.search === -1 || stateCriterion.action === "CREATE"
  return actionCriteria.find(
    ({ type, search }) => type === stateCriterion.type && (newCriterion || stateCriterion.search === search),
  )
}

export default function criteria(state = [], action) {
  switch (action.type) {
    case "SET_CRITERIA": {
      // criteriaWithChanges compares the current group criteria stored in the redux store with the incoming criteria from the server
      // and returns the ids of the records that need to be updated in the redux store
      const criteriaWithChanges = compose(
        filter(id => id > 0),
        pluck("id"),
        differenceWith((obj1, obj2) => obj1.id === obj2.id && fastDeepEqual(obj1.selectedValues, obj2.selectedValues)),
      )(state, action.criteria)

      if (criteriaWithChanges.length > 0) {
        const criteriaWithChangesSet = new Set(criteriaWithChanges)

        // incomingCriteria has the incoming group criteria from the server indexed by id
        const incomingCriteria = indexBy(prop("id"), action.criteria)

        // newCriteriaState loops trough the current criteria in the redux store and checks if a record (c) needs to be updated
        // that's accomplished by checking if the id of a record (c) is included in criteriaWithChanges
        const newCriteriaState = reduce(
          (acc, c) => {
            if (criteriaWithChangesSet.has(c.id)) {
              // if incomingCriteria has an object with the record id, the record still exists but was updated and needs to be replaced
              // if incomingCriteria does not have an object with the record id, the record was deleted and needs to be removed
              return incomingCriteria[c.id] && c.action !== "DELETE" ? [...acc, incomingCriteria[c.id]] : acc
            }
            return [...acc, c]
          },
          [],
          state,
        )

        return newCriteriaState
      }

      return uniqBy(prop("id"), [...state.filter(c => c.action !== "DELETE"), ...action.criteria])
    }
    case "SET_FULL_CRITERIA":
      return action.criteria
    case "CREATE_CRITERION":
      return [...state, action.criterion]
    case "UPDATE_CRITERION":
      return state.map(c => (isCriteriaEqual(c, action.criterion) ? action.criterion : c))
    case "UPDATE_CRITERIA":
      return state.map(s => action.criteria.find(c => c.id === s.id) || s)
    case "UPDATE_NEW_CRITERIA": {
      return compose(
        reject(propEq("action", "DELETE")),
        map(stateCriterion => defaultTo(stateCriterion, findCriterion(action.criteria, stateCriterion))),
      )(state)
    }
    case "REVERT_CRITERIA": {
      return compose(
        map(c => (c.search === action.searchId ? omit(["action"], { ...c, ...c.initialValues }) : c)),
        reject(c => c.search === action.searchId && c.id <= 0),
      )(state)
    }
    case "REMOVE_CRITERIA_BY_SEARCH": {
      return state.filter(c => c.search !== action.searchId)
    }
    case "DELETE_CRITERION":
      const idx = findIndex(propEq("id", action.criterion.id), state)
      return adjust(idx, assoc("action", "DELETE"), state)
    case "REMOVE_CRITERIA":
      return without(action.criteria, state)
    case "CLEAR_UNSAVED_SEARCH_CRITERIA":
      return state.filter(c => c.id > 0 || !!c.search)
    default:
      return state
  }
}
