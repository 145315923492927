//@flow
import type { SoftwarePatch } from "js/state/types"
import type { SoftwarePatchesAction } from "js/state/actions/softwareTab/softwarePatches"

const softwarePatches = (state: Array<SoftwarePatch> = [], action: SoftwarePatchesAction): Array<SoftwarePatch> => {
  switch (action.type) {
    case "SET_SOFTWARE_PATCHES":
      return action.softwarePatches
    case "CLEAR_SOFTWARE_PATCHES":
      return []
    default:
      return state
  }
}

export default softwarePatches
