import { $isAtNodeEnd } from "@lexical/selection"

export function getSelectedNode(selection) {
  const anchor = selection.anchor
  const focus = selection.focus
  const anchorNode = selection.anchor.getNode()
  const focusNode = selection.focus.getNode()

  if (anchorNode === focusNode) return anchorNode

  const isBackward = selection.isBackward()
  if (isBackward) return $isAtNodeEnd(focus) ? anchorNode : focusNode

  return $isAtNodeEnd(anchor) ? anchorNode : focusNode
}

export function getDOMRangeRect(nativeSelection, rootElement) {
  const domRange = nativeSelection.getRangeAt(0)

  let rect

  if (nativeSelection.anchorNode === rootElement) {
    let inner = rootElement
    while (inner.firstElementChild != null) {
      inner = inner.firstElementChild
    }
    rect = inner.getBoundingClientRect()
  } else {
    rect = domRange.getBoundingClientRect()
  }

  return rect
}
