import { combineReducers } from "redux"
import configurations from "js/state/reducers/quickConnect/configurations"
import connect from "js/state/reducers/quickConnect/connect"
import tables from "js/state/reducers/quickConnect/tables"

export default combineReducers({
  configurations,
  connect,
  tables,
})
