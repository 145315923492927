import { combineReducers } from "redux"
import loading from "js/state/reducers/common/loading"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import deviceGlobalCustomFields from "./deviceGlobalCustomFields"
import deviceRoleCustomFields from "./deviceRoleCustomFields"

export default combineReducers({
  deviceRoleCustomFields,
  deviceGlobalCustomFields,
  deviceCustomFieldsLoading: createNamedWrapperReducer(loading, "deviceCustomFieldsLoading", false),
})
