import { useMemo } from "react"
import { ParagraphNode } from "lexical"

import { CodeHighlightNode, CodeNode } from "@lexical/code"
import { HashtagNode } from "@lexical/hashtag"
import { AutoLinkNode, LinkNode } from "@lexical/link"
import { ListItemNode, ListNode } from "@lexical/list"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary"
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { HeadingNode, QuoteNode } from "@lexical/rich-text"
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table"

import {
  CodeHighlightPlugin,
  ExtendedTextNode,
  HTMLParserPlugin,
  MentionNode,
  AutoLinkPlugin,
  MentionPlugin,
  Placeholder,
  //Styled Components
  StyledEditorContainer,
  theme,
} from "@ninjaone/components/src/WYSIWYG"

import { ContentEditableReadOnly } from "./components"
import { imageImportForReadOnly, spanImport } from "./importUtils"
import { TicketingImageNode, TicketingMentionNode, TicketingParagraphNode } from "./nodes"
import { StyledEditorReadOnly } from "./styled"

const nodes = [
  HeadingNode,
  QuoteNode,
  HashtagNode,
  LinkNode,
  CodeNode,
  CodeHighlightNode,
  HorizontalRuleNode,
  ListItemNode,
  ListNode,
  TableCellNode,
  TableNode,
  TableRowNode,
  AutoLinkNode,
  TicketingMentionNode,
  ExtendedTextNode,
  {
    replace: MentionNode,
    with: node => {
      return new TicketingMentionNode(node.getId(), node.getLabel(), node.getMetadata())
    },
  },
  TicketingImageNode,
  TicketingParagraphNode,
  {
    replace: ParagraphNode,
    with: node => new TicketingParagraphNode(),
  },
]

export const WYSIWYGEditorReadOnly = ({
  isPublicResponse,
  attachmentProps = {},
  htmlParserPluginProps = {},
  mentionPluginProps = {},
  autoLinkProps = {},
}) => {
  const { attachments } = attachmentProps
  const { matchers } = autoLinkProps
  const { initialData: initialMentionData } = mentionPluginProps
  const { initialHTML, updateOnChange } = htmlParserPluginProps

  const initialConfig = useMemo(() => {
    return {
      onError: error => {
        // TODO: Report error to Sentry
        throw error
      },
      theme,
      nodes,
      html: {
        import: {
          ...(attachments && { img: imageImportForReadOnly({ attachments }) }),
          ...(initialMentionData && { span: spanImport({ initialMentionData }) }),
        },
      },
    }
  }, [attachments, initialMentionData])

  return (
    <LexicalComposer
      initialConfig={{
        ...initialConfig,
        editable: false,
      }}
    >
      <StyledEditorReadOnly>
        <StyledEditorContainer>
          <RichTextPlugin
            contentEditable={<ContentEditableReadOnly placeholder={Placeholder} isPublicResponse={isPublicResponse} />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <CodeHighlightPlugin />
          <MentionPlugin idKey="id" labelKey="displayName" />
          <HTMLParserPlugin initialHTML={initialHTML} updateOnChange={updateOnChange} />
          {!!matchers?.length && <AutoLinkPlugin matchers={matchers} />}
        </StyledEditorContainer>
      </StyledEditorReadOnly>
    </LexicalComposer>
  )
}
