import {
  DefragOption,
  RemovableMedia,
  SystemRestore,
  WindowServices,
} from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"

const windowsNativeScripts = {
  "a8c2658d-add7-49fa-92f1-caa7e0daf656": {
    parameterComponent: DefragOption,
    scriptName: (name, { volumeName, checkList }) => {
      let option = ""

      if (volumeName === "BOOT_VOLUME") {
        option = checkList === "INCLUDE" ? localized("Boot Volume") : localized("Excluding Boot Volume")
      } else if (volumeName === "BOOT_AND_RECOVERY_VOLUMES") {
        option = localized("Excluding Boot & Recovery Volumes")
      } else {
        option = localized("All Volumes")
      }

      return `${name} ${option}`
    },
    parameters: { volumeName: "ALL_VOLUMES", checkList: "INCLUDE" },
  },
  "258836d1-5aa1-424e-900a-20cf22b228a3": {
    parameterComponent: RemovableMedia,
    scriptName: (name, { enabled }) => `${enabled ? localized("Enable") : localized("Disable")} ${name}`,
    parameters: { enabled: false },
  },
  "7dd142b2-ce16-44ff-a1d6-de2c1baa1749": {
    parameterComponent: WindowServices,
    scriptName: (name, { start, serviceName }) =>
      `${start ? localized("Start") : localized("Stop")} ${name} '${serviceName}'`,
    parameters: { start: true },
    customName: "Start or Stop - Windows Service",
    generateNameWhenSelected: ({ start }) =>
      start ? localized("Start - Windows Service") : localized("Stop - Windows Service"),
    generateDescriptionWhenSelected: ({ displayName }) => `- ${displayName} `,
  },
  "2fed9333-c4fd-4e6b-9913-375a8d181376": {
    parameterComponent: SystemRestore,
    scriptName: (name, { enabled }) => `${enabled ? localized("Turn On") : localized("Turn Off")} ${name}`,
    parameters: { enabled: true },
  },
}

export default windowsNativeScripts
