import { assoc, assocPath, propEq, update, compose, find } from "ramda"

const initialState = {
  mapping: {},
  availableSites: {},
  companySites: {},
  locationSiteModal: {
    location: {},
  },
  possibleLocationMappings: [],
}

export default function accountOrganizationModal(state = initialState, action) {
  switch (action.type) {
    case "SET_CW_ACCOUNT_ORGANIZATION_MODAL_MAPPING":
      return assoc("mapping", action.data, state)
    case "SET_CW_ACCOUNT_ORGANIZATION_MODAL_AVAILABLE_SITES":
      return assoc("availableSites", action.data, state)
    case "EDIT_CW_ACCOUNT_ORGANIZATION_MODAL_MAPPING_LOCATION_SITE_MAPPING":
      const { locationId } = action.data

      const { locationSiteMappings } = state.mapping

      const mapping = compose(
        location => ({ ...location, ...action.data }),
        find(propEq("locationId", locationId)),
      )(locationSiteMappings)

      const updatedLocationSites = update(
        locationSiteMappings.findIndex(propEq("locationId", locationId)),
        mapping,
        locationSiteMappings,
      )
      return assocPath(["mapping", "locationSiteMappings"], updatedLocationSites, state)
    case "SET_CW_ACCOUNT_ORGANIZATION_MODAL_COMPANY_SITES":
      const { companyId, sites } = action.data
      return compose(
        companySites => assoc("companySites", companySites, state),
        assoc(companyId, sites),
      )(state.companySites)
    case "SET_CW_ACCOUNT_ORGANIZATION_MODAL_LOCATION_SITE_MODAL":
      return assocPath(["locationSiteModal", "location"], action.data, state)
    case "SET_CW_ACCOUNT_ORGANIZATION_MODAL_LOCATION_SITE_MODAL_SITE":
      const location = compose(
        assoc("psaSiteName", action.data.psaSiteName),
        assoc("psaSiteId", action.data.psaSiteId),
        assoc("mapped", true),
      )(state.locationSiteModal.location)
      return assocPath(["locationSiteModal", "location"], location, state)
    case "SET_CW_ACCOUNT_ORGANIZATION_MODAL_LOCATION_PRODUCT_MAPPING":
      const { productMappingName, productMappingId, productMappingType } = action.data

      const locationUpdateProductMapping = {
        ...state.locationSiteModal.location,
        productMappingName,
        productMappingId,
        productMappingType,
        mapped: true,
      }

      return assocPath(["locationSiteModal", "location"], locationUpdateProductMapping, state)
    case "SET_CW_ACCOUNT_ORGANIZATION_MODAL_LOCATION_AGREEMENT":
      const { agreementId, agreementName } = action.data

      const locationUpdateAgreement = {
        ...state.locationSiteModal.location,
        agreementId,
        agreementName,
        mapped: true,
      }

      return assocPath(["locationSiteModal", "location"], locationUpdateAgreement, state)
    case "SET_CW_ACCOUNT_ORGANIZATION_MODAL_LOCATION_CONFIGURATION_TYPE_MAPPING":
      const { configurationTypeMappingName, configurationTypeMappingId, configurationTypeMappingType } = action.data

      const locationUpdateConfigurationTypeMapping = {
        ...state.locationSiteModal.location,
        configurationTypeMappingName,
        configurationTypeMappingId,
        configurationTypeMappingType,
        mapped: true,
      }

      return assocPath(["locationSiteModal", "location"], locationUpdateConfigurationTypeMapping, state)
    case "SET_CW_ACCOUNT_POSSIBLE_LOCATION_MAPPINGS":
      const { possibleLocationMappings } = action
      return {
        ...state,
        possibleLocationMappings,
      }
    case "CLEAR_CW_ACCOUNT_POSSIBLE_LOCATION_MAPPINGS":
      return {
        ...state,
        possibleLocationMappings: [],
      }
    case "SET_CW_ACCOUNT_LOCATION_MAPPINGS":
      const { newMappedLocations } = action
      return {
        ...state,
        mapping: {
          ...state.mapping,
          locationSiteMappings: newMappedLocations,
        },
      }
    case "CLEAR_CW_ACCOUNT_ORGANIZATION_MODAL_COMPANY_SITES":
      return {
        ...state,
        companySites: {},
      }
    default:
      return state
  }
}
