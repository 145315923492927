import { always, propEq } from "ramda"
import { Col, ControlLabel, FormGroup } from "react-bootstrap"
import { Checkbox, Tooltip } from "@ninjaone/components"

import { createJobOption, getRestoreBatchJob, submitJob } from "js/includes/common/backup"
import { useMountedState } from "js/includes/common/hooks"
import showModal from "js/includes/common/services/showModal"
import {
  getPathSeparator,
  isFeatureEnabled,
  localizationKey,
  localized,
  showSuccessMessage,
} from "js/includes/common/utils"
import { getJobsByFolderEndpoint } from "js/includes/components/Backup/BackupManager/utils"
import { replaceSeparatorAndRemoveTrailingSlash } from "js/includes/components/Browsers"
import LockhartJobDetailsModal from "js/includes/components/LockhartJobDetailsModal"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import RestoreModal, { getOriginalLocationPath } from "./RestoreModal"
import { RegularInfoCircleIcon } from "@ninjaone/icons"
import { StyledSpan } from "js/includes/components/Styled"

export const RestoreFolderOrBatchModal = ({ unmount: close, nodes, sourceNode }) => {
  const [restoreDeletedFiles, setRestoreDeletedFiles] = useMountedState(false)
  const [firstNode] = nodes
  const { planId } = firstNode
  const sourceNodeName = sourceNode.friendlyName || sourceNode.name || sourceNode.systemName
  const [job, setJob] = useMountedState(null)
  const restore = async ({
    destinationNode,
    collisionHandling,
    restorePermissions,
    destinationPath,
    isOriginalLocation,
  }) => {
    const {
      nodes: [node],
    } = await window.deviceList.fetchById(isOriginalLocation ? sourceNode.id : destinationNode.id)
    const nodeId = node.id
    const nodeName = node.name || node.systemName
    const separator = getPathSeparator(sourceNode)
    const sourcePath = replaceSeparatorAndRemoveTrailingSlash(
      nodes.length > 1 ? firstNode.parentPath : firstNode.path,
      separator,
    )
    const getRestoreDestinationPath = () => {
      if (!isOriginalLocation) return destinationPath
      if (nodes.length > 1) return sourcePath
      return getOriginalLocationPath(sourcePath, separator)
    }

    const restoreJob = getRestoreBatchJob({
      nodeId,
      nodeName,
      sourcePath,
      sourceNodeName,
      destinationPath: getRestoreDestinationPath(),
      batch: nodes,
      backupJobId: job.value,
      sourceNodeId: sourceNode.id,
      sourcePlanId: firstNode.planId,
      sourcePlanName: firstNode.planName,
      collisionHandling: collisionHandling.value,
      originalLocation: isOriginalLocation,
      ...(isFeatureEnabled("backup_ntfs_permissions") && { restorePermissions }),
      restoreDeletedFiles,
    })

    const response = await submitJob([restoreJob])

    if (response.resultCode !== "SUCCESS") throw new Error("Error creating restore job")

    showSuccessMessage(localized("Restore job created successfully"))
    close()
    showModal(<LockhartJobDetailsModal {...{ job: restoreJob.jobs[0], node }} />)
  }

  return (
    <RestoreModal
      {...{
        RevisionSelector: (
          <>
            {nodes.some(propEq("type", "folder")) && (
              <FormGroup className="text-left" controlId="backup-restore-manager-download-deleted-files-revision">
                <Col componentClass={ControlLabel} xs={12}>
                  <StyledSpan marginRight="3px">{localized("Deleted Files")}</StyledSpan>
                  <Tooltip
                    label={localized(
                      "Will restore latest revision of all files backed up that were deleted from machine",
                    )}
                    ariaLabel={localized("More Information")}
                  >
                    <RegularInfoCircleIcon size="sm" />
                  </Tooltip>
                </Col>
                <Col componentClass={ControlLabel} xs={12}>
                  <Checkbox
                    fontWeight={400}
                    onChange={({ isChecked }) => setRestoreDeletedFiles(isChecked)}
                    label={localized("Include Latest Revision of Deleted Files")}
                  />
                </Col>
              </FormGroup>
            )}
            <FormGroup className="text-left" controlId="backup-restore-manager-download-select">
              <Col componentClass={ControlLabel} xs={12}>
                {localized("Date")}
              </Col>
              <Col xs={12}>
                <SearchableDropDown
                  {...{
                    openOnFocus: true,
                    pageSize: 100,
                    endpoint: getJobsByFolderEndpoint(sourceNode, planId),
                    width: "100%",
                    rowHeight: 40,
                    noRowsRendererToken: localizationKey("No options available"),
                    onSelect: setJob,
                    dataFormatter: obj => ({ ...createJobOption(obj), completed: obj.completed }),
                    lastItemKey: "completed",
                    isSearchable: false,
                    searchParams: always({ upsyncedData: false }),
                  }}
                />
              </Col>
            </FormGroup>
          </>
        ),
        restoreJob: restore,
        modalTitle: nodes.length > 1 ? localized("Restore Batch") : localized("Restore Folder"),
        disableRestore: job === null,
        sourceNode,
        nodes,
        restorePath: nodes.length > 1 ? firstNode.parentPath : firstNode.path,
        close,
      }}
    />
  )
}
