import {
  faFile,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileImage,
  faFileVideo,
  faFileAudio,
  faFilePdf,
  faFileArchive,
  faFileCode,
  faFileCsv,
  faFileAlt,
} from "@fortawesome/pro-solid-svg-icons"
import { getFileExtension } from "js/includes/common/utils"

export const wordExtensions = new Set(["doc", "dot", "wbk", "docx", "docm", "dotx", "dotm", "docb"])

export const excelExtensions = new Set([
  "xls",
  "xlt",
  "xlm",
  "xlsx",
  "xlsm",
  "xltx",
  "xltm",
  "xlsb",
  "xla",
  "xlam",
  "xll",
  "xlw",
])

export const powerpointExtensions = new Set([
  "ppt",
  "pot",
  "pps",
  "pptx",
  "pptm",
  "potx",
  "potm",
  "ppam",
  "ppsx",
  "ppsm",
  "sldx",
  "sldm",
])

export const audioExtensions = new Set([
  "3gp",
  "aa",
  "aac",
  "aax",
  "act",
  "aiff",
  "amr",
  "ape",
  "au",
  "awb",
  "dct",
  "dss",
  "dvf",
  "flac",
  "gsm",
  "iklax",
  "ivs",
  "m4a",
  "m4b",
  "m4p",
  "mmf",
  "mp3",
  "mpc",
  "msv",
  "nmf",
  "nsf",
  "oga",
  "mogg",
  "opus",
  "ra",
  "rm",
  "raw",
  "sln",
  "tta",
  "vox",
  "wav",
  "wma",
  "wv",
  "webm",
  "8svx",
])

export const videoExtensions = new Set([
  "mp4",
  "m4a",
  "m4v",
  "f4v",
  "f4a",
  "m4b",
  "m4r",
  "f4b",
  "mov",
  "3gp",
  "3gp2",
  "3g2",
  "3gpp",
  "3gpp2",
  "ogg",
  "oga",
  "ogv",
  "ogx",
  "wmv",
  "wma",
  "webm",
  "flv",
])

export const pdfExtensions = new Set(["pdf"])

export const csvExtensions = new Set(["csv"])

export const imageExtensions = new Set([
  "ase",
  "art",
  "bmp",
  "blp",
  "cd5",
  "cit",
  "cpt",
  "cr2",
  "cut",
  "dds",
  "dib",
  "djvu",
  "egt",
  "exif",
  "gif",
  "gpl",
  "grf",
  "icns",
  "ico",
  "iff",
  "jng",
  "jpeg",
  "jpg",
  "jfif",
  "jp2",
  "jps",
  "lbm",
  "max",
  "miff",
  "mng",
  "msp",
  "nitf",
  "ota",
  "pbm",
  "pc1",
  "pc2",
  "pc3",
  "pcf",
  "pcx",
  "pdn",
  "pgm",
  "PI1",
  "PI2",
  "PI3",
  "pict",
  "pct",
  "pnm",
  "pns",
  "ppm",
  "psb",
  "psd",
  "pdd",
  "psp",
  "px",
  "pxm",
  "pxr",
  "qfx",
  "raw",
  "rle",
  "sct",
  "sgi",
  "rgb",
  "int",
  "bw",
  "tga",
  "tiff",
  "tif",
  "vtf",
  "xbm",
  "xcf",
  "xpm",
  "3dv",
  "amf",
  "ai",
  "awg",
  "cgm",
  "cdr",
  "cmx",
  "dxf",
  "e2d",
  "eps",
  "fs",
  "gbr",
  "odg",
  "stl",
  "vrml",
  "x3d",
  "sxd",
  "v2d",
  "vnd",
  "wmf",
  "emf",
  "xar",
  "png",
  "webp",
  "jxr",
  "hdp",
  "wdp",
  "cur",
  "ecw",
  "liff",
  "nrrd",
  "pam",
  "pgf",
  "rgba",
  "inta",
  "sid",
  "ras",
  "sun",
])

export const archiveExtensions = new Set(["zip", "rar", "7z", "bz2", "lha", "lzh", "cab"])

export const codeExtensions = new Set([
  "asp",
  "aspx",
  "axd",
  "asx",
  "asmx",
  "ashx",
  "css",
  "cfm",
  "yaws",
  "swf",
  "html",
  "htm",
  "xhtml",
  "jhtml",
  "jsp",
  "jspx",
  "wss",
  "do",
  "action",
  "js",
  "pl",
  "php",
  "php4",
  "php3",
  "phtml",
  "py",
  "rb",
  "rhtml",
  "shtml",
  "xml",
  "rss",
  "svg",
  "cgi",
  "dll",
])

export const installExtensions = new Set(["msi", "msm", "msp", "mst", "idt", "cub", "pcp"])

export const extensionTypes = [
  {
    type: "word",
    extensions: wordExtensions,
  },
  {
    type: "excel",
    extensions: excelExtensions,
  },
  {
    type: "powerpoint",
    extensions: powerpointExtensions,
  },
  {
    type: "audio",
    extensions: audioExtensions,
  },
  {
    type: "video",
    extensions: videoExtensions,
  },
  {
    type: "pdf",
    extensions: pdfExtensions,
  },
  {
    type: "csv",
    extensions: csvExtensions,
  },
  {
    type: "image",
    extensions: imageExtensions,
  },
  {
    type: "archive",
    extensions: archiveExtensions,
  },
  {
    type: "code",
    extensions: codeExtensions,
  },
  {
    type: "install",
    extensions: installExtensions,
  },
]

const extensionTypeIconMapper = {
  word: faFileWord,
  excel: faFileExcel,
  powerpoint: faFilePowerpoint,
  audio: faFileAudio,
  video: faFileVideo,
  pdf: faFilePdf,
  csv: faFileCsv,
  image: faFileImage,
  archive: faFileArchive,
  code: faFileCode,
  install: faFileAlt,
}

export const getExtensionType = ext => extensionTypes.find(({ extensions }) => extensions.has(ext))?.type

export const getExtensionIcon = path => {
  const fileExtension = getFileExtension(path)
  const type = getExtensionType(fileExtension)

  return extensionTypeIconMapper[type] || faFile
}

const extensionTypeIconNameMapper = {
  word: "WordIconSolid",
  excel: "ExcelIconSolid",
  powerpoint: "PowerPointIconSolid",
  audio: "AudioIconSolid",
  video: "VideoIconSolid",
  pdf: "PDFIconSolid",
  csv: "CsvIconSolid",
  image: "ImageIconSolid",
  archive: "ArchiveIconSolid",
  code: "CodeIconSolid",
  install: "InstallIconSolid",
}

export const getExtensionIconName = path => {
  const fileExtension = getFileExtension(path)
  const type = getExtensionType(fileExtension)

  return extensionTypeIconNameMapper[type] || "FileIconSolid"
}
