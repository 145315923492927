import { reject, propEq } from "ramda"

const usersList = (state = [], action) => {
  switch (action.type) {
    case "SET_USERS_LIST":
      return action.usersList
    case "DELETE_USER":
      return reject(propEq("id", action.userId), state)
    default:
      return state
  }
}

export default usersList
