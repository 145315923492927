import { memo, useMemo } from "react"
import { Provider as GraphQLProvider, createClient } from "urql"
import { getTicketingUrl } from "js/includes/common/utils"

export const WithGraphql = (Component, options = {}) =>
  memo(props => {
    const { client } = options

    const ticketingClient = useMemo(
      () =>
        createClient({
          url: client || getTicketingUrl(),
        }),
      [client],
    )

    return (
      <GraphQLProvider value={ticketingClient}>
        <Component {...props} />
      </GraphQLProvider>
    )
  })
