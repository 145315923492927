import React from "react"
import { sizer } from "@ninjaone/utils"
import { useTheme } from "@emotion/react"
import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"
import Text from "./Text"

const Badge = ({ count, color, bold, ...rest }) => {
  const theme = useTheme()
  return (
    <Box borderRadius={10.5} padding={sizer(1, 0)} width="40px" textAlign="center" {...rest}>
      <Text lineHeight={1} color={color || theme.color.black["100"]} size="xs" bold={bold}>
        {count}
      </Text>
    </Box>
  )
}

export default Badge
