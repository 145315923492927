const initialState = {}

export default function healthStatus(state = initialState, action) {
  switch (action.type) {
    case "SET_AT_HEALTH_STATUS":
      return { ...state, ...action.payload }
    default:
      return state
  }
}
