const initialState = window.localStorage.getItem("ninja:dashboard.sortSystemItemsBy") || "status"

export default function sortSystemItemsBy(state = initialState, action) {
  switch (action.type) {
    case "SET_SORT_SYSTEM_ITEMS_BY":
      return action.sortItemsBy
    default:
      return state
  }
}
