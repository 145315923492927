export default function connectionTestResult(state = "", action) {
  switch (action.type) {
    case "SET_TEST_CONNECTION_RESULT":
      return action.result
    case "RESET_TEST_CONNECTION_RESULT":
      return ""
    default:
      return state
  }
}
