import { omit, mapObjIndexed, evolve, reject, propEq } from "ramda"

const initialState = {
  newTickets: {},
  openTickets: {},
}

function setTicketOpenValues(state, action) {
  return {
    ...state.openTickets,
    [action.ticket.id]: {
      ticket: action.ticket,
      original: action.ticket,
      editorData: {
        body: action.editorData.body,
        htmlBody: action.editorData.htmlBody,
        uploads: action.editorData.uploads,
        isPublic: action.editorData.isPublic,
        techniciansTagged: action.editorData.techniciansTagged,
      },
      timeEntry: {
        remote: action.editorData.timeEntry?.remote,
        startDate: action.editorData.timeEntry?.startDate,
        agreementId: action.editorData.timeEntry?.agreementId,
        billingType: action.editorData.timeEntry?.billingType,
        agreementOriginType: action.editorData.timeEntry?.agreementOriginType,
      },
    },
  }
}

function updateOpenTicket(state, action) {
  const { shouldUpdateOriginal, ticketId } = action

  const newTicketValues = {
    ...state.openTickets[ticketId]?.ticket,
    ...action.payload,
  }

  return {
    ...state.openTickets,
    [ticketId]: {
      ...state.openTickets[ticketId],
      ticket: newTicketValues,
      ...(shouldUpdateOriginal && {
        original: newTicketValues,
      }),
    },
  }
}

function updateEditorData(state, action) {
  const newEditorData = {
    ...state.openTickets[action.ticketId]?.editorData,
    ...action.payload,
  }

  return {
    ...state.openTickets,
    [action.ticketId]: {
      ...state.openTickets[action.ticketId],
      editorData: newEditorData,
    },
  }
}

function saveNewTicket(state, action) {
  const { ticket, editorData } = action
  return {
    ...state.newTickets,
    [ticket.id]: {
      ticket,
      editorData: {
        body: editorData.body,
        htmlBody: editorData.htmlBody,
        isPublic: editorData.isPublic,
        uploads: editorData.uploads,
        techniciansTagged: editorData.techniciansTagged,
      },
    },
  }
}

function updateNewTicket(state, action) {
  const { tempTicketId, payload } = action
  return {
    ...state.newTickets,
    [tempTicketId]: {
      ...state.newTickets[tempTicketId],
      ticket: {
        ...state.newTickets[tempTicketId].ticket,
        ...payload,
      },
    },
  }
}

function updateNewTicketEditorData(state, action) {
  const { tempTicketId, payload } = action
  return {
    ...state.newTickets,
    [tempTicketId]: {
      ...state.newTickets[tempTicketId],
      editorData: {
        ...state.newTickets[tempTicketId].editorData,
        ...payload,
      },
    },
  }
}

function removeUserFromOpenTickets(state, action) {
  const {
    payload: { id: userId, uid },
  } = action

  return mapObjIndexed(openTicket => {
    const isUserAssigned =
      openTicket.original.assignedAppUserId === userId || openTicket.ticket.assignedAppUserId === userId
    const isUserRequester = openTicket.original.requesterUid === uid || openTicket.ticket.requesterUid === uid

    const evolution = {
      ...((isUserAssigned || isUserRequester) && {
        original: evolve({
          ...(isUserAssigned && { assignedAppUserId: () => null }),
          ...(isUserRequester && { requesterUid: () => null }),
        }),
        ticket: evolve({
          ...(isUserAssigned && { assignedAppUserId: () => null }),
          ...(isUserRequester && { requesterUid: () => null }),
        }),
      }),
    }

    return evolve(evolution, openTicket)
  }, state.openTickets)
}

export default function editor(state = initialState, action) {
  switch (action.type) {
    case "SAVE_NEW_TICKET":
      return { ...state, newTickets: saveNewTicket(state, action) }
    case "UPDATE_NEW_TICKET":
      return { ...state, newTickets: updateNewTicket(state, action) }
    case "UPDATE_NEW_TICKET_EDITOR_DATA":
      return { ...state, newTickets: updateNewTicketEditorData(state, action) }
    case "ADD_NEW_TICKET_EDITOR_DATA_UPLOAD":
      return {
        ...state,
        newTickets: {
          ...state.newTickets,
          [action.tempTicketId]: {
            ...state.newTickets[action.tempTicketId],
            editorData: {
              ...state.newTickets[action.tempTicketId].editorData,
              uploads: [...state.newTickets[action.tempTicketId].editorData.uploads, action.payload.upload],
            },
          },
        },
      }
    case "REMOVE_NEW_TICKET_EDITOR_DATA_UPLOAD":
      return {
        ...state,
        newTickets: {
          ...state.newTickets,
          [action.tempTicketId]: {
            ...state.newTickets[action.tempTicketId],
            editorData: {
              ...state.newTickets[action.tempTicketId].editorData,
              uploads: reject(
                propEq("resourceId", action.resourceId),
                state.newTickets[action.tempTicketId].editorData.uploads,
              ),
            },
          },
        },
      }
    case "REMOVE_NEW_TICKET":
      return {
        ...state,
        newTickets: omit([action.tempTicketId], state.newTickets),
      }
    case "SET_REQUEST_UID":
      return { ...state, requestUid: action.requestUid }
    case "ADD_TO_OPEN_TICKETS":
      return {
        ...state,
        openTickets: setTicketOpenValues(state, action),
      }
    case "UPDATE_OPEN_TICKET":
      return {
        ...state,
        openTickets: updateOpenTicket(state, action),
      }
    case "REMOVE_OPEN_TICKET":
      return {
        ...state,
        openTickets: omit([action.ticketId], state.openTickets),
      }
    case "UPDATE_EDITOR_DATA":
      return {
        ...state,
        openTickets: updateEditorData(state, action),
      }
    case "ADD_EDITOR_DATA_UPLOAD":
      return {
        ...state,
        openTickets: {
          ...state.openTickets,
          [action.ticketId]: {
            ...state.openTickets[action.ticketId],
            editorData: {
              ...state.openTickets[action.ticketId].editorData,
              uploads: [...state.openTickets[action.ticketId].editorData.uploads, action.payload.upload],
            },
          },
        },
      }
    case "REMOVE_EDITOR_DATA_UPLOAD":
      return {
        ...state,
        openTickets: {
          ...state.openTickets,
          [action.ticketId]: {
            ...state.openTickets[action.ticketId],
            editorData: {
              ...state.openTickets[action.ticketId].editorData,
              uploads: reject(
                propEq("resourceId", action.resourceId),
                state.openTickets[action.ticketId].editorData.uploads,
              ),
            },
          },
        },
      }
    case "DELETE_USER_FROM_OPEN_TICKETS":
      return {
        ...state,
        openTickets: removeUserFromOpenTickets(state, action),
      }
    default:
      return state
  }
}
