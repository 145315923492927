// @flow
import * as React from "react"
import type { Node as ReactNode, Element as ReactElement } from "react"
import PropTypes from "prop-types"

import Resizable from "./Resizable"
import { resizableProps } from "./propTypes"
import type { ResizeCallbackData, ResizableBoxState } from "./propTypes"
import { Box } from "../Styled"

// ElementConfig gives us an object type where all items present in `defaultProps` are made optional.
// <ResizableBox> does not have defaultProps, so we can use this type to tell Flow that we don't
// care about that and will handle it in <Resizable> instead.
// A <ResizableBox> can also have a `style` property.
type ResizableBoxProps = {| ...React.ElementConfig<typeof Resizable>, style?: Object, children?: ReactElement<any> |}

export default class ResizableBox extends React.Component<ResizableBoxProps, ResizableBoxState> {
  // PropTypes are identical to <Resizable>, except that children are not strictly required to be present.
  static propTypes = {
    ...resizableProps,
    width: PropTypes.number,
    height: PropTypes.number,
    children: PropTypes.element,
  }

  containerRef = React.createRef()

  state: ResizableBoxState = {
    width: this.props.width,
    height: this.props.height,
  }

  handleResize = (width, height) => {
    const containerWidth = this.containerRef.current?.offsetWidth
    const containerHeight = this.containerRef.current?.offsetHeight
    const isContainerWidthOrGreater = width >= containerWidth
    const isContainerHeightOrGreater = height >= containerHeight

    return {
      width: isContainerWidthOrGreater ? containerWidth : width,
      height: isContainerHeightOrGreater ? containerHeight : height,
    }
  }

  componentDidMount() {
    const { width, height, onResizeStop } = this.props
    const resized = this.handleResize(width, height)
    this.setState(resized, () => onResizeStop(resized))
  }

  onResize: (e: SyntheticEvent<>, data: ResizeCallbackData) => void = (e, data) => {
    const {
      size: { width, height },
    } = data
    const resized = this.handleResize(width, height)
    this.setState(resized)
  }

  onResizeStop: (e: SyntheticEvent<>, data: ResizeCallbackData) => void = (e, data) => {
    const {
      size: { width, height },
    } = data
    const resized = this.handleResize(width, height)

    if (this.props.onResizeStop) {
      e.persist?.()
      this.props.onResizeStop(resized)
    }
  }

  render(): ReactNode {
    const { width, height } = this.state

    const {
      axis,
      handle,
      handleSize,
      draggableOpts,
      minConstraints,
      maxConstraints,
      lockAspectRatio,
      resizeHandles,
      transformScale,
      ChildrenRenderer,
    } = this.props

    const containerWidth = this.containerRef.current?.offsetWidth
    const isContainerWidthOrGreater = width >= containerWidth

    return (
      <Box width="100%" height="100%" ref={this.containerRef} className="the-world">
        <Resizable
          axis={axis}
          draggableOpts={draggableOpts}
          handle={handle}
          handleSize={handleSize}
          height={height}
          lockAspectRatio={lockAspectRatio}
          minConstraints={minConstraints}
          onResize={this.onResize}
          onResizeStop={this.onResizeStop}
          resizeHandles={resizeHandles}
          transformScale={transformScale}
          width={width}
          maxConstraints={maxConstraints || [this.containerRef.current?.offsetWidth ?? Infinity, Infinity]}
        >
          <ChildrenRenderer {...{ width, height, isContainerWidthOrGreater }} />
        </Resizable>
      </Box>
    )
  }
}
