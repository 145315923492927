import { ParagraphNode } from "lexical"

function patchStyleConversion(originalDOMConverter) {
  return element => {
    const original = originalDOMConverter?.(element)
    if (!original) return null

    const originalOutput = original.conversion(element)
    if (!originalOutput) return originalOutput

    if (element.style) {
      const indent = parseInt(element.style.paddingLeft, 10) / 20
      if (indent > 0) {
        originalOutput.node.setIndent(indent)
      }
    }
    return originalOutput
  }
}

export class TicketingParagraphNode extends ParagraphNode {
  static getType() {
    return "ticketing-paragraph"
  }

  static clone(node) {
    return new TicketingParagraphNode(node.__key)
  }

  static importDOM() {
    const importers = ParagraphNode.importDOM()
    return {
      ...importers,
      p: () => ({
        conversion: patchStyleConversion(importers?.p),
        priority: 1,
      }),
    }
  }

  static importJSON(serializedNode) {
    return ParagraphNode.importJSON(serializedNode)
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: TicketingParagraphNode.getType(),
      version: 1,
    }
  }
}
