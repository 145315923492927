import { Link as NinjaLink } from "@ninjaone/components"
import { NINJAONE_ZENDESK_BASE_URL } from "js/includes/common/utils"
import ZendeskSsoForm from "./Zendesk/ZendeskSsoForm"

const Link = ({ href, children, increaseContrast, increaseSpacing }) => {
  const isZendeskLink = href?.startsWith(NINJAONE_ZENDESK_BASE_URL)

  return isZendeskLink ? (
    <ZendeskSsoForm link={href} {...{ increaseContrast, increaseSpacing }}>
      {children}
    </ZendeskSsoForm>
  ) : (
    <NinjaLink href={href} target="_blank" rel="nonopener noreferrer" {...{ increaseContrast }}>
      {children}
    </NinjaLink>
  )
}

export default Link
