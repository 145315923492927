const getInitialState = () => ({
  path: [],
})

const setPath = (state, { path }) => ({
  path,
})

export default function knowledgeBase(state = getInitialState(), action) {
  switch (action.type) {
    case "SET_KB_PATH":
      return setPath(state, action)
    default:
      return state
  }
}
