import { useMemo } from "react"
import { useStore } from "react-redux"
import styled from "@emotion/styled"

import { UserSlashIcon, UserXMarkIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import { Tooltip } from "@ninjaone/components"
import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"

import { fullNameOrDisplayName, generateLabel, getUserNotFoundLabel } from "./mentionNodeUtils"

const StyledMention = styled.span`
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme, isCurrentUser }) => (isCurrentUser ? theme.color.yellow : theme.color.lightGray)};
  padding: ${sizer(0.5, 1)};
  border-radius: ${sizer(1)};
  text-decoration: none;

  &:hover {
    background-color: ${({ theme, isCurrentUser }) => (isCurrentUser ? theme.color.yellow : theme.color.gray)};
  }
`

export const TicketingMentionElement = ({ mention }) => {
  const store = useStore()

  const currentUser = useMemo(() => store.getState().session.user, [store])
  const isCurrentUser = useMemo(() => currentUser?.id === mention?.id, [currentUser, mention])

  return (
    <span data-cy={`mention-${mention?.id || "not-found"}`} contentEditable={false}>
      <Tooltip label={generateLabel(mention)}>
        <StyledMention {...{ isCurrentUser }}>
          @{mention ? fullNameOrDisplayName(mention) : getUserNotFoundLabel()}
          {mention?.deleted && (
            <Box as="span" paddingLeft={sizer(1)}>
              <UserXMarkIcon size="xs" />
            </Box>
          )}
          {mention?.permitted === false && (
            <Box as="span" paddingLeft={sizer(1)}>
              <UserSlashIcon size="xs" />
            </Box>
          )}
        </StyledMention>
      </Tooltip>
    </span>
  )
}
