import { memo } from "react"
import { localized, localizationKey, localizedWith, noop, getIcon } from "js/includes/common/utils"
import { cancelJob } from "js/includes/common/_jobs"
import { clearFailedBackupJob, getPlanIcon, jobStatus, useLiveBackupJobProcedure } from "./common"
import RunningJobModal from "./RunningJobModal"
import { getReadableBytes } from "js/includes/common/_conversions"
import { replaceSeparatorAndRemoveTrailingSlash } from "js/includes/components/Browsers"
import { VARIANTS } from "@ninjaone/components/src/Button"
import showModal from "js/includes/common/services/showModal"
import { Body, ConfirmationModal, DeviceIcon } from "@ninjaone/components"
import { Flex } from "js/includes/components/Styled"
import tokens from "@ninjaone/tokens"

export default memo(function LockhartJobDetailsModal({
  node,
  job: _job,
  unmount,
  refreshCallback = noop,
  loadingNodeData,
}) {
  const { jobFromServer, updatingJob, error, jobData } = useLiveBackupJobProcedure({
    node,
    job: _job,
  })

  const { actionType, type, sourcePath, file, destinationPath } = jobFromServer?.content ?? {}
  const { jobUID, jobFilesProcessed, jobTotalFiles, jobProcessedBytes, jobTotalBytes, status, percentage } = jobData
  const PlanIcon = getPlanIcon(type)
  const jobCancelled = status === jobStatus.CANCELED
  const jobFailed = status === jobStatus.FAILED
  const jobCompleted = status === jobStatus.COMPLETED
  const jobCompletedWithWarnings = jobTotalFiles > 1 && jobCompleted && jobFilesProcessed !== jobTotalFiles
  const isPreparing =
    (!jobTotalFiles || status === jobStatus.PROCESSING) && !jobFailed && !jobCompleted && !jobCancelled

  const planUID = _job.content.sourcePlanId
  const planName = _job.content.sourcePlanName

  const separator = node.nodeClass === "MAC" ? "/" : "\\"
  const destinationDir = destinationPath ? replaceSeparatorAndRemoveTrailingSlash(destinationPath, separator) : ""

  const restoreToNodeName = node.displayName || node.friendlyName || node.name || node.systemName || ""

  const cancelButton = {
    labelToken: localizationKey("Cancel Job"),
    variant: VARIANTS.DANGER,
    onClick: () => {
      showModal(
        <ConfirmationModal
          type="danger"
          titleText={localized("Cancel restore job?")}
          actionToken={localizationKey("Cancel restore")}
          onCancel={({ unmount }) => {
            unmount()
          }}
          onConfirm={({ unmount: unmountConfirmation }) => {
            cancelJob(jobFromServer, node.id)
            refreshCallback()
            unmountConfirmation()
            unmount()
          }}
        >
          <Body textWrap>
            {localizedWith(
              "Canceling the restore job for <%bold>planName<%> will result in an incomplete restore. Are you sure you want to proceed?",
              {
                bold: () => <strong>{planName}</strong>,
              },
            )}
          </Body>
        </ConfirmationModal>,
      )
    },
  }

  const clearButton = {
    labelToken: localizationKey("Clear restore"),
    variant: VARIANTS.DANGER,
    onClick: () =>
      showModal(
        <ConfirmationModal
          type="danger"
          titleText={localized("Clear restore job?")}
          actionToken={localizationKey("Clear restore")}
          onCancel={({ unmount }) => {
            unmount()
          }}
          onConfirm={({ unmount: unmountConfirmation }) => {
            clearFailedBackupJob({
              nodeId: node.id,
              planId: planUID,
              jobId: jobUID,
              unmount,
              refreshCallback,
            })
            unmountConfirmation()
            unmount()
          }}
        >
          <Body textWrap>
            {localizedWith(
              "Clear the restore job for <%bold>planName<%> will result in tagging the job to be ignored. Are you sure you want to proceed?",
              {
                bold: () => <strong>{planName}</strong>,
              },
            )}
          </Body>
        </ConfirmationModal>,
      ),
  }

  return (
    <RunningJobModal
      {...{
        node,
        planUID,
        planName,
        actionType,
        createTime: jobFromServer.createTime,
        detailsSections: [
          {
            title: localized("Device"),
            icon: <DeviceIcon icon={getIcon(node.nodeClass)} />,
            text: node.displayName || node.name || node.systemName,
          },
          {
            title: localized("Plan Name"),
            icon: <PlanIcon />,
            text: planName,
          },
          {
            title: localized("Path"),
            text: sourcePath || file,
          },
          {
            title: localized("Restore To"),
            text: () => (
              <Flex flexDirection="column" gap={tokens.spacing[2]}>
                <Body>{restoreToNodeName}</Body>
                <Body>{destinationDir}</Body>
              </Flex>
            ),
          },
        ],
        percentage,
        jobTotalFiles,
        loading: updatingJob || loadingNodeData,
        isPreparing,
        jobCompleted,
        jobCompletedWithWarnings,
        jobFailed,
        jobCancelled,
        error,
        modalTitle: localized("Restore"),
        preparingJobText: localized("Preparing to restore..."),
        processedDataText: localized("{{sentBytes}} of {{totalBytes}}", {
          sentBytes: getReadableBytes(jobProcessedBytes),
          totalBytes: getReadableBytes(jobTotalBytes),
        }),
        filesScannedText: localized("{{num}} of {{total}} files", {
          num: jobFilesProcessed,
          total: jobTotalFiles,
        }),
        jobCompletedText: localized("The job was completed automatically because no files were found to restore."),
        jobCompletedWithWarningsText: localized("Restored {{jobFilesProcessed}} of {{jobTotalFiles}} successfully", {
          jobFilesProcessed,
          jobTotalFiles,
        }),
        cancelButton,
        clearButton,
        refreshCallback,
        unmount,
      }}
    />
  )
})
