import { DAYS, NINETY_ONE_DAYS_THRESHOLD, isOldAccount } from "js/includes/GetStartedPage/utils/accountCreation"

const initialState = {
  currentStepId: "stepOne",
  stepsCompleted: {
    stepOne: false,
    stepTwo: false,
    stepThree: false,
    stepFour: false,
    stepFive: false,
    stepSix: false,
    finishTraining: false,
  },
}

export default function gettingStartedReducer(state = initialState, action) {
  switch (action.type) {
    case "COMPLETE_GETTING_STARTED_STEP":
      return {
        ...state,
        stepsCompleted: { ...state.stepsCompleted, [action.stepId]: true },
      }
    case "SET_GETTING_STARTED_STEP":
      return {
        stepsCompleted: state.stepsCompleted,
        currentStepId: action.stepId,
      }
    case "SETUP_GETTING_STARTED":
      const { gettingStartedSteps: userGettingStartedSteps } = action
      if (!userGettingStartedSteps) return state
      const { currentStep, ...gettingStartedSteps } = userGettingStartedSteps

      const { isTrialLicense } = window.application.get("session").attributes
      const { userInvitationAcceptedTime } = window.application.get("session").attributes.user
      const isOldUserAccount = isOldAccount(userInvitationAcceptedTime, DAYS, NINETY_ONE_DAYS_THRESHOLD)
      const shouldOverrideTrainingToCompletion =
        !isTrialLicense && !gettingStartedSteps.finishTraining && isOldUserAccount

      const stepsCompleted = shouldOverrideTrainingToCompletion
        ? Object.keys(gettingStartedSteps).reduce((acc, key) => ({ ...acc, [key]: true }), {})
        : gettingStartedSteps

      const userCurrentStep = currentStep ? currentStep : "stepOne"
      const currentStepId = gettingStartedSteps.finishTraining ? "finishTraining" : userCurrentStep

      return {
        currentStepId,
        stepsCompleted,
      }
    default:
      return state
  }
}
