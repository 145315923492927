const policy = (state = null, action) => {
  switch (action.type) {
    case "CLEAR_POLICY":
      return null
    case "SET_POLICY_ORIGINAL":
      return action.policy
    case "UPDATE_POLICY_NAME":
      return { ...state, name: action.name, description: action.description, enabled: action.enabled }
    default:
      return state
  }
}

export default policy
