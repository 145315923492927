import { reject, propEq } from "ramda"

export default function organizations(state = [], action) {
  switch (action.type) {
    case "SET_ORGANIZATION_LIST":
      return action.organizations
    case "REMOVE_ORGANIZATION":
      return reject(propEq("id", action.payload), state)
    default:
      return state
  }
}
