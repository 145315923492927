const getInitialState = () => ({
  fields: [],
  values: {},
})

const setDeviceRoleCustomFields = (state, action) => ({
  ...state,
  fields: action.fields,
})

const setDeviceRoleCustomFieldValues = (state, action) => ({
  ...state,
  values: action.values,
})

export default function deviceRoleCustomFields(state = getInitialState(), action) {
  switch (action.type) {
    case "SET_DEVICE_ROLE_CUSTOM_FIELDS":
      return setDeviceRoleCustomFields(state, action)
    case "SET_DEVICE_ROLE_CUSTOM_FIELD_VALUES":
      return setDeviceRoleCustomFieldValues(state, action)
    case "RESET_DEVICE_ROLE_CUSTOM_FIELDS":
      return getInitialState()
    default:
      return state
  }
}
