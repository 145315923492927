import styled from "@emotion/styled"
import { colors } from "js/includes/common/theme"

export const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  border-radius: 4px;
  border: solid 1px ${colors.reactSelectGrey};
  cursor: pointer;
  flex-direction: column;
  background-color: ${colors.white};
  padding: 2px 8px;
  min-height: 38px;
  max-height: 120px;
  overflow-y: scroll;
  transition: 100ms;
  &:hover {
    border-color: ${colors.reactSelectMediumGrey};
  }
`

export const StyledBody = styled.span`
  margin: 4px 0 8px 0;
`

export const StyledEditorWrapper = styled.div`
  background-color: ${({ isPublicResponse, theme }) =>
    isPublicResponse === false ? theme.colorAlertWarningWeakest : theme.colorBackground};
  border: 1px solid ${colors.ninjaLight};
  border-radius: 4px;
  padding: 10px;
  max-height: 120px;
  overflow-y: scroll;
  ${({ onClick }) =>
    onClick &&
    `
    &:hover {
      border-color: ${colors.reactSelectMediumGrey};
    }
  `}
`
