import { Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { capitalizeFirstLetter, localized } from "js/includes/common/utils"
import { Box, StyledSpan } from "js/includes/components/Styled"
import {
  isClosedStatus,
  getStatusBackgroundFromId,
} from "js/includes/configuration/integrations/ticketing/ticketCustomStatus/common"

export const StatusRowRenderer = ({
  value,
  valueLabel,
  hideChildColorIndicator,
  isValueRenderer,
  maxWidth,
  ...rest
}) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" {...rest}>
      <Box
        width={sizer(2)}
        height={sizer(2)}
        minWidth={sizer(2)}
        minHeight={sizer(2)}
        borderRadius={sizer(1)}
        marginRight={sizer(2)}
        {...{
          ...(!hideChildColorIndicator &&
            isClosedStatus(value) && {
              border: "2px solid",
              borderColor: "ninjaLight",
            }),
          ...(!hideChildColorIndicator && { backgroundColor: getStatusBackgroundFromId(value) }),
        }}
      />
      <StyledSpan maxWidth={maxWidth}>
        <Text size="sm" key={valueLabel}>
          {isValueRenderer && `${localized("Status")}: `}
          {capitalizeFirstLetter(valueLabel ?? "")}
        </Text>
      </StyledSpan>
    </Box>
  )
}

export const statusRowRenderer = ({ value, labelText }) => <StatusRowRenderer value={value} valueLabel={labelText} />
