import { combineReducers } from "redux"
import nodeSyncLogs from "./syncLogs"
import connection from "./connection"
import settings from "./settings"
import healthStatus from "./healthStatus"
import loading from "js/state/reducers/common/loading"
import helpdeskSettings from "./helpdesk"

export default combineReducers({
  helpdeskSettings,
  nodeSyncLogs,
  connection,
  settings,
  loading,
  healthStatus,
})
