import qs from "qs"
import { ArrowLeftIcon } from "@ninjaone/icons"
import { Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { fetchJson, localizationKey } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import ArticleList from "js/includes/components/HelpDropdown/Views/ArticlesList"
import HelpDefault from "js/includes/components/HelpDropdown/Views/HelpDefault"
import ZendeskTicketWidget from "js/includes/components/Zendesk/ZendeskTicketWidget"

export const DROPDOWN_VIEWS_KEY = {
  default: "default",
  articleList: "articleList",
  submitHelpTicket: "submitHelpTicket",
}

export const getHelpLinkAction = url => window.open(url, "_blank", "noopener noreferrer")

export const fetchHelpArticles = query => {
  const _query = qs.stringify({ ...query })
  return fetchJson(`/zendesk/articles/?${_query}`)
}

export const DROPDOWN_VIEWS = {
  default: {
    renderer: props => <HelpDefault {...props} />,
  },
  articleList: {
    renderer: props => <ArticleList {...props} />,
  },
  submitHelpTicket: {
    titleToken: localizationKey("Submit Help ticket"),
    getFooterButtons: ({ handleGoBack }) => [
      {
        Icon: ArrowLeftIcon,
        labelToken: localizationKey("Back"),
        onClick: () => handleGoBack(),
        type: "cancel",
      },
      {
        labelToken: localizationKey("Submit"),
        type: "apply",
        submit: true,
        form: "zendesk-widget",
      },
    ],
    renderer: props => (
      <>
        <Box marginBottom={sizer(6)}>
          <Text
            size="sm"
            color="colorTextWeak"
            token={localizationKey(
              "Let us know what we can help with. A Support Team member will get back to you shortly.",
            )}
            textWrap
          />
        </Box>
        <ZendeskTicketWidget {...props} handleComplete={props.handleClose} />
      </>
    ),
  },
}
