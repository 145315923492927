const initialState = {
  list: [],
  loading: false,
}

export default function forms(state = initialState, action) {
  switch (action.type) {
    case "TICKETING_SET_TICKET_FORMS_LIST":
      return {
        ...state,
        list: action.list,
      }
    case "TICKETING_SET_TICKET_FORMS_LOADING":
      return {
        ...state,
        loading: action.loading,
      }
    default:
      return state
  }
}
