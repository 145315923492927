import { useEffect, useMemo, useRef, useState } from "react"
import styled from "@emotion/styled"

import tokens from "@ninjaone/tokens"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import { TextInput } from "./TextInput"
import { Dropdown } from "./Dropdown"

const StyledWrapper = styled.div`
  box-sizing: unset;
  padding: ${tokens.spacing[4]};
`

const StyledBasicColor = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${tokens.spacing[2]};
  margin: 0;
  padding: 0;
`

const StyledBasicColorButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  border-radius: ${tokens.borderRadius[1]};
  height: ${tokens.spacing[4]};
  width: ${tokens.spacing[4]};
  cursor: pointer;
  list-style-type: none;

  ${({ active, theme }) => active && theme.elevationWeak};
`

const StyledSaturation = styled.div`
  width: 100%;
  position: relative;
  margin-top: ${tokens.spacing[4]};
  height: 150px;
  background-image: linear-gradient(transparent, black), linear-gradient(to right, white, transparent);
  user-select: none;
`

const StyledSaturationCursor = styled.div`
  position: absolute;
  width: ${tokens.spacing[5]};
  height: ${tokens.spacing[5]};
  border: 2px solid ${({ theme }) => theme.colorBorderWeakest};
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.elevationWeak};
  box-sizing: border-box;
  transform: translate(-10px, -10px);
`

const StyledHue = styled.div`
  width: 100%;
  position: relative;
  margin-top: ${tokens.spacing[4]};
  height: ${tokens.spacing[3]};
  background-image: linear-gradient(
    to right,
    rgb(255, 0, 0),
    rgb(255, 255, 0),
    rgb(0, 255, 0),
    rgb(0, 255, 255),
    rgb(0, 0, 255),
    rgb(255, 0, 255),
    rgb(255, 0, 0)
  );
  user-select: none;
  border-radius: ${tokens.borderRadius[3]};
`

const StyledHueCursor = styled.div`
  position: absolute;
  width: ${tokens.spacing[5]};
  height: ${tokens.spacing[5]};
  border: 2px solid ${({ theme }) => theme.colorBorderWeak};
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.elevationWeak};
  box-sizing: border-box;
  transform: translate(-10px, -4px);
`

const StyledColor = styled.div`
  border: 1px solid ${({ theme }) => theme.colorBorderWeak};
  margin-top: ${tokens.spacing[4]};
  width: 100%;
  height: ${tokens.spacing[5]};
`

const basicColors = [
  "#d0021b",
  "#f5a623",
  "#f8e71c",
  "#8b572a",
  "#7ed321",
  "#417505",
  "#bd10e0",
  "#9013fe",
  "#4a90e2",
  "#50e3c2",
  "#b8e986",
  "#000000",
  "#4a4a4a",
  "#9b9b9b",
  "#ffffff",
]

const WIDTH = 214
const HEIGHT = 150

let skipAddingToHistoryStack = false

const MoveWrapper = ({ Component, onChange, style, children, ...rest }) => {
  const ref = useRef(null)
  const draggedRef = useRef(false)

  const move = e => {
    if (ref.current) {
      const { current: div } = ref
      const { width, height, left, top } = div.getBoundingClientRect()

      const x = clamp(e.clientX - left, width, 0)
      const y = clamp(e.clientY - top, height, 0)

      onChange({ x, y })
    }
  }

  const onMouseDown = e => {
    if (e.button !== 0) return

    move(e)

    const onMouseMove = e => {
      draggedRef.current = true
      skipAddingToHistoryStack = true
      move(e)
    }

    const onMouseUp = e => {
      if (draggedRef.current) {
        skipAddingToHistoryStack = false
      }

      document.removeEventListener("mousemove", onMouseMove, false)
      document.removeEventListener("mouseup", onMouseUp, false)

      move(e)
      draggedRef.current = false
    }

    document.addEventListener("mousemove", onMouseMove, false)
    document.addEventListener("mouseup", onMouseUp, false)
  }

  return <Component {...{ ref, style, onMouseDown, ...rest }}>{children}</Component>
}

function clamp(value, max, min) {
  return value > max ? max : value < min ? min : value
}

export function toHex(value) {
  if (!value.startsWith("#")) {
    const ctx = document.createElement("canvas").getContext("2d")

    if (!ctx) throw new Error("2d context not supported or canvas already initialized")

    ctx.fillStyle = value

    return ctx.fillStyle
  } else if (value.length === 4 || value.length === 5) {
    value = value
      .split("")
      .map((v, i) => (i ? v + v : "#"))
      .join("")

    return value
  } else if (value.length === 7 || value.length === 9) {
    return value
  }

  return "#000000"
}

function hex2rgb(hex) {
  const rbgArr = (
    hex
      .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => "#" + r + r + g + g + b + b)
      .substring(1)
      .match(/.{2}/g) || []
  ).map(x => parseInt(x, 16))

  return {
    b: rbgArr[2],
    g: rbgArr[1],
    r: rbgArr[0],
  }
}

function rgb2hsv({ r, g, b }) {
  r /= 255
  g /= 255
  b /= 255

  const max = Math.max(r, g, b)
  const d = max - Math.min(r, g, b)

  const h = d ? (max === r ? (g - b) / d + (g < b ? 6 : 0) : max === g ? 2 + (b - r) / d : 4 + (r - g) / d) * 60 : 0
  const s = max ? (d / max) * 100 : 0
  const v = max * 100

  return { h, s, v }
}

function hsv2rgb({ h, s, v }) {
  s /= 100
  v /= 100

  const i = ~~(h / 60)
  const f = h / 60 - i
  const p = v * (1 - s)
  const q = v * (1 - s * f)
  const t = v * (1 - s * (1 - f))
  const index = i % 6

  const r = Math.round([v, q, p, p, t, v][index] * 255)
  const g = Math.round([t, v, v, q, p, p][index] * 255)
  const b = Math.round([p, p, t, v, v, q][index] * 255)

  return { b, g, r }
}

function rgb2hex({ b, g, r }) {
  return "#" + [r, g, b].map(x => x.toString(16).padStart(2, "0")).join("")
}

function transformColor(format, color) {
  let hex = toHex("#121212")
  let rgb = hex2rgb(hex)
  let hsv = rgb2hsv(rgb)

  if (format === "hex") {
    const value = color

    hex = toHex(value)
    rgb = hex2rgb(hex)
    hsv = rgb2hsv(rgb)
  } else if (format === "rgb") {
    const value = color

    rgb = value
    hex = rgb2hex(rgb)
    hsv = rgb2hsv(rgb)
  } else if (format === "hsv") {
    const value = color

    hsv = value
    rgb = hsv2rgb(hsv)
    hex = rgb2hex(rgb)
  }

  return { hex, hsv, rgb }
}

export const ColorPicker = ({ color, onChange }) => {
  const [selfColor, setSelfColor] = useState(transformColor("hex", color))
  const [inputColor, setInputColor] = useState(color)
  const innerDivRef = useRef(null)

  const saturationPosition = useMemo(
    () => ({
      x: (selfColor.hsv.s / 100) * WIDTH,
      y: ((100 - selfColor.hsv.v) / 100) * HEIGHT,
    }),
    [selfColor.hsv.s, selfColor.hsv.v],
  )

  const huePosition = useMemo(
    () => ({
      x: (selfColor.hsv.h / 360) * WIDTH,
    }),
    [selfColor.hsv],
  )

  const onSetHex = hex => {
    setInputColor(hex)
    if (/^#[0-9A-Fa-f]{6}$/i.test(hex)) {
      const newColor = transformColor("hex", hex)
      setSelfColor(newColor)
    }
  }

  const onMoveSaturation = ({ x, y }) => {
    const newHsv = {
      ...selfColor.hsv,
      s: (x / WIDTH) * 100,
      v: 100 - (y / HEIGHT) * 100,
    }
    const newColor = transformColor("hsv", newHsv)
    setSelfColor(newColor)
    setInputColor(newColor.hex)
  }

  const onMoveHue = ({ x }) => {
    const newHsv = { ...selfColor.hsv, h: (x / WIDTH) * 360 }
    const newColor = transformColor("hsv", newHsv)

    setSelfColor(newColor)
    setInputColor(newColor.hex)
  }

  useEffect(() => {
    const isDropdownNative = innerDivRef.current !== null && onChange
    if (isDropdownNative) {
      onChange(selfColor.hex, skipAddingToHistoryStack)
      setInputColor(selfColor.hex)
    }
  }, [selfColor, onChange])

  useEffect(() => {
    if (color === undefined) return
    const newColor = transformColor("hex", color)
    setSelfColor(newColor)
    setInputColor(newColor.hex)
  }, [color])

  return (
    <StyledWrapper ref={innerDivRef} style={{ width: WIDTH }}>
      <TextInput label={localized("Hex")} onChange={e => onSetHex(e.target.value)} value={inputColor} />
      <StyledBasicColor>
        {basicColors.map(basicColor => (
          <StyledBasicColorButton
            key={basicColor}
            active={basicColor === selfColor.hex}
            style={{ backgroundColor: basicColor }}
            onClick={() => {
              setInputColor(basicColor)
              setSelfColor(transformColor("hex", basicColor))
            }}
          />
        ))}
      </StyledBasicColor>
      <MoveWrapper
        style={{ backgroundColor: `hsl(${selfColor.hsv.h}, 100%, 50%)` }}
        onChange={onMoveSaturation}
        Component={StyledSaturation}
      >
        <StyledSaturationCursor
          style={{
            backgroundColor: selfColor.hex,
            left: saturationPosition.x,
            top: saturationPosition.y,
          }}
        />
      </MoveWrapper>
      <MoveWrapper onChange={onMoveHue} Component={StyledHue}>
        <StyledHueCursor
          style={{
            backgroundColor: `hsl(${selfColor.hsv.h}, 100%, 50%)`,
            left: huePosition.x,
          }}
        />
      </MoveWrapper>
      <StyledColor style={{ backgroundColor: selfColor.hex }} />
    </StyledWrapper>
  )
}

export const DropdownColorPicker = ({
  disabled = false,
  stopCloseOnClickSelf = true,
  withMaxHeight = false,
  color,
  onChange,
  ...rest
}) => {
  return (
    <Dropdown {...{ ...rest, disabled, stopCloseOnClickSelf, withMaxHeight }}>
      <ColorPicker {...{ color, onChange }} />
    </Dropdown>
  )
}
