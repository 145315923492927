export const extractOnChangeValues = onChangeArgs => {
  const firstArgument = onChangeArgs[0]
  const secondArgument = onChangeArgs[1]

  switch (typeof firstArgument) {
    case "string":
      return { [firstArgument]: secondArgument }
    case "object":
      return firstArgument
    default:
      return
  }
}
