const initialState = {
  addDeviceMenu: false,
  helpMenu: false,
}

const guideTooltips = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_TOOLTIP":
      return { ...state, [action.name]: true }
    case "CLOSE_TOOLTIP":
      return { ...state, [action.name]: false }
    default:
      return state
  }
}

export default guideTooltips
