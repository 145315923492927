import { compose, join, pluck } from "ramda"

import { localized } from "js/includes/common/utils"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { getSearchableDropdownTagRenderer } from "js/includes/ticketing/TriggerEditor/components/utils"
import { getSelectedValue, getValuesMap } from "js/includes/ticketing/TriggerEditor/utils"

const OPTION_ID_KEY = "value"
const OPTION_LABEL_KEY = "label"
const getSelectedArrayValues = compose(join(","), pluck(OPTION_ID_KEY))

const metaDataMapper = ({ name, id, active }) => ({ label: name, value: id, active })

const getInactiveLabel = ({ valuesMap, option }) => {
  return valuesMap[option?.[OPTION_ID_KEY]]?.active === false ? ` (${localized("Inactive")})` : ""
}

export const FormEditor = ({ isMulti = true, value, handleOnChange, metaData, formOptions }) => {
  const valuesMap = getValuesMap({ metaData, options: formOptions, metaDataMapper, idKey: OPTION_ID_KEY })
  const selected = getSelectedValue({ value, isMulti, valuesMap, idKey: OPTION_ID_KEY, labelKey: OPTION_LABEL_KEY })

  const handleOnSelect = selected => {
    handleOnChange(Array.isArray(selected) ? getSelectedArrayValues(selected) : selected?.value || null)
  }

  const getOptionLabel = ({ option }) => {
    return `${option?.[OPTION_LABEL_KEY] || ""}${getInactiveLabel({ valuesMap, option })}`
  }

  return (
    <SearchableDropDown
      isMulti={isMulti}
      keepDropdownInView
      options={formOptions}
      value={selected}
      getOptionLabel={() => getOptionLabel({ option: selected })}
      tagRenderer={getSearchableDropdownTagRenderer({ getOptionLabel, optionIdKey: OPTION_ID_KEY })}
      onSelect={handleOnSelect}
    />
  )
}
