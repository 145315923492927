import { assoc } from "ramda"

export const initialState = {
  hostname: "",
  enabled: false,
}

export default function settings(state = initialState, action) {
  switch (action.type) {
    case "SET_ACCELO_HOSTNAME":
      return assoc("hostname", action.hostname, state)
    case "SET_ACCELO_ENABLED":
      return assoc("enabled", action.enabled, state)
    default:
      return state
  }
}
