const faFileVisio = {
  prefix: "fac",
  iconName: "fileVicio",
  icon: [
    384,
    512,
    [],
    "0030",
    `M0.741,20.287L0.292,491.395C1.795,502.087 10.15,509.667 21.136,511.881L362.6,511.769C374.591,509.68 381.903,503.064 383.89,491.299L383.857,159.925L245.265,159.842C232.885,157.141 226.037,149.422 223.987,137.348L224.027,-0.159L22.46,-0.14C10.743,1.949 3.472,8.726 0.741,20.287ZM215.158,439.827C212.406,447.06 176.997,446.522 172.354,439.304L72.043,265.561C68.337,259.141 70.54,250.921 76.959,247.215L100.22,233.785C106.639,230.079 114.86,232.281 118.566,238.701L193.907,369.195L268.946,239.223C272.652,232.804 280.873,230.601 287.292,234.307L310.553,247.737C316.973,251.444 319.175,259.664 315.469,266.083L215.158,439.827ZM256.276,-0.102L256.292,128.246L383.335,127.565C384.449,119.666 382.748,112.839 378.798,106.87L278.01,5.716C272.261,1.707 265.129,-0.389 256.276,-0.102Z`,
  ],
}

export default faFileVisio
