import { combineReducers } from "redux"

function patches(initialState) {
  return patchType => (state = initialState, action) => {
    switch (action.type) {
      case `SET_PATCHES_${patchType}`:
        return action.patches
      default:
        return state
    }
  }
}

const patchInventory = patches([])("patch-inventory")
const patchSoftwareInventory = patches([])("patch-software-inventory")

export default combineReducers({
  patchInventory,
  patchSoftwareInventory,
})
