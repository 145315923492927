export default function previouslyUsedFilterMap(state = null, action) {
  switch (action.type) {
    case "SET_CW_PREVIOUSLY_FETCHED_COMPANY_FILTER_MAP":
      return action.companyFilterMap
    case "RESET_CW_PREVIOUSLY_FETCHED_COMPANY_FILTER_MAP":
      return null
    default:
      return state
  }
}
