const initialState = {
  enabled: false,
  companyId: "",
  publicApiKey: "",
  urlBase: "",
}

export default function connection(state = initialState, action) {
  switch (action.type) {
    case "SET_CW_CONNECTION":
      return { ...state, ...action.payload }
    default:
      return state
  }
}
