import { combineReducers } from "redux"

function patchesName(initialState) {
  return patchType => (state = initialState, action) => {
    switch (action.type) {
      case `SET_PATCH_INVENTORY_NAME_FILTER_${patchType}`:
        return action.filter
      default:
        return state
    }
  }
}

const patchInventory = patchesName("")("patch-inventory")
const patchSoftwareInventory = patchesName("")("patch-software-inventory")

export default combineReducers({
  patchInventory,
  patchSoftwareInventory,
})
