import { map, propEq, when } from "ramda"

export const setNodeRolePolicyMap = (state, action) => {
  const { nodeRolePolicyMap } = action
  return { ...state, nodeRolePolicyMap }
}

export const updateNodeRolePolicyMap = (state, action) => {
  const { nodeRoleId, policyId, policyName } = action
  const { nodeRolePolicyMap } = state

  const list = map(
    when(propEq("nodeRoleId", nodeRoleId), item => ({ ...item, policyId, policyName })),
    nodeRolePolicyMap,
  )

  return { ...state, nodeRolePolicyMap: list }
}
