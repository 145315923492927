import { $applyNodeReplacement } from "lexical"

import { TicketingMentionElement } from "./TicketingMentionElement"
import { MentionNode } from "@ninjaone/components/src/WYSIWYG"

function convertMentionElement(domNode) {
  const textContext = domNode.textContent
  if (textContext !== null) {
    const node = $createTicketingMentionsNode(null, textContext)
    return { node }
  }

  return null
}

export class TicketingMentionNode extends MentionNode {
  __id
  __label
  __metadata

  static getType() {
    return "ticketing-mention"
  }

  static clone(node) {
    return new TicketingMentionNode(node.__id, node.__label, node.__metadata, node.__key)
  }

  constructor(id, label, metadata, key) {
    super(id, label, metadata, key)
    this.__id = id
    this.__label = label
    this.__metadata = metadata
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      id: this.__id,
      label: this.__label,
      metadata: this.__metadata,
      type: "ticketing-mention",
      version: 1,
    }
  }

  static importJSON(serializedNode) {
    return $createTicketingMentionsNode(serializedNode.id, serializedNode.label, serializedNode.metadata)
  }

  createDOM(config) {
    const span = document.createElement("span")
    span.className = "mention"
    return span
  }

  exportDOM() {
    const element = document.createElement("span")
    element.setAttribute("data-lexical-mention", "true")
    element.textContent = `@user:${this.__id}`
    element.className = "technician-tagged"
    return { element }
  }

  updateDOM() {
    return false
  }

  static importDOM() {
    return {
      span: domNode => {
        if (!domNode.hasAttribute("data-lexical-mention") && !domNode.classList.contains("technician-tagged")) {
          return null
        }
        return {
          conversion: convertMentionElement,
          priority: 1,
        }
      },
    }
  }

  getTextContent() {
    return this.__label
  }

  decorate() {
    const mention = this.__id
      ? {
          id: this.__id,
          displayName: this.__label,
          email: this.__metadata?.email,
          deleted: this.__metadata?.deleted,
          permitted: this.__metadata?.permitted,
        }
      : null
    return <TicketingMentionElement mention={mention} />
  }
}

export function $createTicketingMentionsNode(id, label, metadata) {
  const mentionNode = new TicketingMentionNode(id, label, metadata)
  return $applyNodeReplacement(mentionNode)
}

export function $isTicketingMentionNode(node) {
  return node instanceof TicketingMentionNode
}
