import { useRef } from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { IconButton, Text, Tooltip } from "@ninjaone/components"
import { CircleExclamationIcon, PlusIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import { Box, Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { localized, showErrorMessage } from "@ninjaone/webapp/src/js/includes/common/utils"

const StyledIconButtonWrapper = styled.div`
  button {
    background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest};

    &:focus-visible {
      outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    }

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeak};
    }
  }
`

const StyledFlex = styled(Flex)`
  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest};
`

const FileAttachmentUpload = ({
  onSelect,
  accept,
  buttonLabelToken,
  titleToken,
  labelTokenDescription,
  maxFiles = 5,
  errorMessageToken,
  multiple = false,
  currentFileCount = 0,
  fileSizeExceeded,
}) => {
  const inputRef = useRef(null)

  const handleFileSelect = e => {
    const files = e.target.files
    const totalFiles = files?.length + currentFileCount
    if (files?.length > 0 && totalFiles <= maxFiles) {
      onSelect(files)
    } else {
      showErrorMessage(localized(errorMessageToken, { maxFiles }))
    }
  }

  const openFileBrowser = input => {
    if (inputRef?.current) {
      inputRef.current.value = null
      inputRef.current.click()
    }
  }

  const disabledInput = currentFileCount >= maxFiles

  return (
    <Box width="100%">
      <StyledFlex
        minHeight={sizer(10)}
        justifyContent="space-between"
        alignItems="center"
        borderRadius={sizer(1)}
        paddingLeft={sizer(3)}
        flexWrap="wrap"
      >
        <Text bold token={titleToken} tokenArgs={{ currentFileCount }} color="colorTextStrong" />
        <input
          type="file"
          style={{ display: "none" }}
          ref={inputRef}
          onChange={handleFileSelect}
          accept={accept}
          multiple={multiple}
          disabled={disabledInput}
        />
        <StyledIconButtonWrapper>
          <IconButton
            {...{
              onClick: () => openFileBrowser(inputRef),
              disabled: disabledInput,
              tooltip: buttonLabelToken,
              size: "lg",
            }}
          >
            <PlusIcon color="colorTextStrong" />
          </IconButton>
        </StyledIconButtonWrapper>
      </StyledFlex>
      <Flex
        paddingLeft={sizer(3)}
        paddingRight={sizer(3)}
        marginTop={sizer(3)}
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap
      >
        {fileSizeExceeded && (
          <Box margin={sizer(1, 2, 0, 0)}>
            <Tooltip label={localized("File is larger than 100 MB")}>
              <CircleExclamationIcon color="colorTextDanger" size="sm" />
            </Tooltip>
          </Box>
        )}
        <Text
          textWrap
          size="sm"
          token={labelTokenDescription}
          color={fileSizeExceeded ? "colorTextDanger" : "colorTextWeakest"}
        />
      </Flex>
    </Box>
  )
}

FileAttachmentUpload.propTypes = {
  maxFiles: PropTypes.number,
  multiple: PropTypes.bool,
  currentFileCount: PropTypes.number,
}

export default FileAttachmentUpload
