import { memo, useEffect, useRef, useState } from "react"
import { isNotNilOrEmpty } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"
import { getTextClassName, getTextStyle, textBaseClassName } from "./Text"
import Tooltip from "../../Tooltip"

const TextWithTooltip = ({
  textWrap: textWrapProp,
  textWrapLineLimit,
  value,
  mouseEnteredRow,
  tooltip,
  extraClassName,
}) => {
  const textContainerEl = useRef()
  const textNodeEl = useRef()

  const hasSpaces = typeof value === "string" && value.includes(" ")
  const textWrap = hasSpaces && textWrapProp

  const [textEllipsis, setTextEllipsis] = useState(false)

  useEffect(() => {
    if (tooltip) {
      return
    }

    const containerEl = textContainerEl.current
    const textEl = textNodeEl.current

    const containerSize = (textWrap ? containerEl?.offsetHeight : containerEl?.offsetWidth) || 0
    // Subtracting 1 to account for javascript rounding offset decimals
    const textSize = ((textWrap ? textEl?.offsetHeight : textEl?.offsetWidth) || 0) - 1
    const setEllipsis = containerSize < textSize

    if (setEllipsis) {
      setTextEllipsis(containerSize < textSize)
    }
  }, [textWrap, mouseEnteredRow, tooltip, value])

  const enableTooltip = mouseEnteredRow && isNotNilOrEmpty(value) && (tooltip || textEllipsis)

  const textNode = (
    <div
      {...{
        className: `${textBaseClassName} ${getTextClassName({ textWrap, textWrapLineLimit })} ${
          enableTooltip ? "enable-tooltip" : ""
        } ${!textWrap || textWrapLineLimit ? "show-ellipsis" : ""} ${extraClassName ? extraClassName : ""}`,
        style: getTextStyle({ textWrap, textWrapLineLimit }),
        ref: textContainerEl,
      }}
    >
      <span ref={textNodeEl}>{value}</span>
    </div>
  )

  return enableTooltip ? <Tooltip label={tooltip ?? value}>{textNode}</Tooltip> : textNode
}

export default memo(TextWithTooltip)
