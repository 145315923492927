import styled from "@emotion/styled"

import { StyledDropdown, StyledDropdownItem } from "../../Components"

export const StyledTypeheadPopover = styled(StyledDropdown)`
  width: 250px;
`

export const StyledTypeheadPopoverUl = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  overflow-y: scroll;
  max-height: 200px;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledTypeheadPopoverLi = styled(StyledDropdownItem)`
  min-width: 180px;

  &.selected {
    background-color: ${({ theme }) => theme.colorForegroundSelected};
  }
`
