import { last } from "ramda"
import { Node as SlateNode, Text } from "slate"
import { jsx } from "slate-hyperscript"
import { arrayToMapWithKey, isNotNil, sanitizeHTML } from "js/includes/common/utils"
import { getEmptyBlock } from "./utils"

export const richTextEditorTagTypeMapper = {
  BLOCKQUOTE: "block-quote",
  UL: "bulleted-list",
  OL: "numbered-list",
  LI: "list-item",
  PRE: "block-code",
  H1: "heading-one",
  H2: "heading-two",
  IMG: "image",
  TABLE: "table",
  TBODY: "table-body",
  TR: "table-row",
  TH: "table-header",
  TD: "table-cell",
  A: "link",
  BUTTON: "button",
  P: "paragraph",
}

export const serializeToPlainText = nodes => {
  return nodes.map(node => SlateNode.string(node)).join("\n")
}

export const serializeToHTML = (node, customSerializeToHtml = {}) => {
  if (Text.isText(node)) {
    let string = sanitizeHTML(node.text)

    if (node.bold) {
      string = `<strong>${string}</strong>`
    }

    if (node.code) {
      string = `<code style="background-color: #FAFBFD;border-radius: 4px;color: #D53948;font-size: 90%;padding: 0px 2px;white-space: nowrap;">${string}</code>`
    }

    if (node.italic) {
      string = `<em>${string}</em>`
    }

    if (node.underline) {
      string = `<u>${string}</u>`
    }

    if (node.strikethrough) {
      string = `<del>${string}</del>`
    }

    return string
  }

  const children = node.children.map(n => serializeToHTML(n, customSerializeToHtml)).join("")

  const align = node.align ? `text-align:${node.align};` : ""
  const defaultStyles = `word-break: break-word;box-sizing: border-box;${align}`
  const style = `style="${defaultStyles}"`

  if (node.type in customSerializeToHtml) return customSerializeToHtml[node.type](node)

  switch (node.type) {
    case "block-quote":
      return `<blockquote>${children}</blockquote>`
    case "bulleted-list":
      return `<ul>${children}</ul>`
    case "numbered-list":
      return `<ol>${children}</ol>`
    case "list-item":
      return `<li ${style}>${children}</li>`
    case "block-code":
      return `<pre style="border-style: solid;border-width: 1px;border-radius: 4px;border-color: #E1E2E4;padding: 8px;background-color: #FAFBFD;white-space: pre-wrap;overflow-wrap: break-word;font-family: monospace;width: 100%;display: block;${defaultStyles}">${children}</pre>`
    case "heading-one":
      return `<h1 ${style}>${children}</h1>`
    case "heading-two":
      return `<h2 ${style}>${children}</h2>`
    case "image":
      return `<img src="${sanitizeHTML(
        node.uri ? "cid:" + node.attachment?.resourceId : sanitizeHTML(node.url),
      )}" width="${node.width ? node.width : "100%"}" height="${node.height ? node.height : "100%"}" ${
        node.name ? `alt="${node.name}"` : ""
      }>${children}</img>`
    case "table":
      return `<table width="100%" style="border-collapse: collapse;${defaultStyles}">${children}</table>`
    case "table-body":
      return `<tbody>${children}</tbody>`
    case "table-row":
      return `<tr>${children}</tr>`
    case "table-header":
      return `<th style="padding: 5px;border-width: 1px;border-style: solid;border-color: #D1D0DA;word-break: break-word;box-sizing: border-box;${align}">${children}</th>`
    case "table-cell":
      return `<td style="padding: 5px;border-width: 1px;border-style: solid;border-color: #D1D0DA;word-break: break-word;box-sizing: border-box;${align}">${children}</td>`
    case "link":
      return `<a href="${sanitizeHTML(node.url)}" ${style}>${children}</a>`
    case "button":
      return `<button type="button" style="margin: 0 0.1em;background-color: #efefef;padding: 2px 6px;border-width: 1px;border-style: solid;border-color: #767676;border-radius: 2px;font-size: 0.9em;">${children}</button>`
    case "paragraph":
      return `<p style="margin: 0;${defaultStyles}">${children}</p>`
    default:
      return children
  }
}

export const deserialize = ({
  element,
  markAttributes = {},
  attachments,
  customDeserializeToHtml = {},
  ...additionalConfigs
}) => {
  if (element.nodeType === Node.TEXT_NODE && isNotNil(element.textContent)) {
    return jsx("text", markAttributes, element.textContent)
  } else if (element.nodeType !== Node.ELEMENT_NODE) {
    return null
  }

  const nodeAttributes = { ...markAttributes }

  // define attributes for text nodes
  switch (element.nodeName) {
    case "STRONG":
    case "B":
      nodeAttributes.bold = true
      break
    case "CODE":
      nodeAttributes.code = true
      break
    case "EM":
      nodeAttributes.italic = true
      break
    case "U":
      nodeAttributes.underline = true
      break
    case "DEL":
      nodeAttributes.strikethrough = true
      break
    default:
      break
  }

  const childNodes = element.childNodes.length ? element.childNodes : [document.createTextNode("")]

  const children = Array.from(childNodes)
    .map(node =>
      deserialize({
        element: node,
        markAttributes: nodeAttributes,
        attachments,
        customDeserializeToHtml,
        ...additionalConfigs,
      }),
    )
    .flat()

  const align = element.style.textAlign

  if (element.nodeName in customDeserializeToHtml)
    return customDeserializeToHtml[element.nodeName](element, children, additionalConfigs)

  switch (element.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children)
    case "BR":
      return "\n"
    case "BLOCKQUOTE":
      return jsx("element", { type: richTextEditorTagTypeMapper.BLOCKQUOTE, align }, children)
    case "UL":
      return jsx("element", { type: richTextEditorTagTypeMapper.UL, align }, children)
    case "OL":
      return jsx("element", { type: richTextEditorTagTypeMapper.OL, align }, children)
    case "LI":
      return jsx("element", { type: richTextEditorTagTypeMapper.LI, align }, children)
    case "PRE":
      return jsx("element", { type: richTextEditorTagTypeMapper.PRE }, children)
    case "H1":
      return jsx("element", { type: richTextEditorTagTypeMapper.H1, align }, children)
    case "H2":
      return jsx("element", { type: richTextEditorTagTypeMapper.H2, align }, children)
    case "IMG":
      const url = element.src
      const width = element.width
      const height = element.height
      const [, resourceId] = url.startsWith("cid:") ? url.split(":") : []
      const attachment = attachments?.[resourceId] ?? {}

      return attachment
        ? jsx("element", { type: richTextEditorTagTypeMapper.IMG, url, width, height, attachment }, children)
        : children
    case "TABLE":
      return jsx("element", { type: richTextEditorTagTypeMapper.TABLE }, children)
    case "TBODY":
      return jsx("element", { type: richTextEditorTagTypeMapper.TBODY }, children)
    case "TR":
      return jsx("element", { type: richTextEditorTagTypeMapper.TR }, children)
    case "TH":
      return jsx("element", { type: richTextEditorTagTypeMapper.TH, align }, children)
    case "TD":
      return jsx("element", { type: richTextEditorTagTypeMapper.TD, align }, children)
    case "A":
      return jsx("element", { type: richTextEditorTagTypeMapper.A, url: element.getAttribute("href") }, children)
    case "BUTTON":
      return jsx("element", { type: richTextEditorTagTypeMapper.BUTTON }, children)
    case "P":
      return jsx("element", { type: richTextEditorTagTypeMapper.P, align }, children)
    default:
      return children
  }
}

export const deserializeToSlate = ({
  html,
  attachments,
  attachmentSerializerKey = "contentId",
  customDeserializeToHtml,
  ...additionalConfigs
}) => {
  const document = new DOMParser().parseFromString(html, "text/html")
  const result = deserialize({
    element: document.body,
    attachments: attachments ? arrayToMapWithKey(attachmentSerializerKey, attachments) : [],
    customDeserializeToHtml,
    ...additionalConfigs,
  })

  const lastBlock = last(result)

  if (lastBlock.type !== "paragraph") {
    result.push(getEmptyBlock())
  }

  return result
}
