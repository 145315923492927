import { combineReducers } from "redux"
import accounts from "./accounts/rootReducer"
import products from "./products/rootReducer"
import configurations from "./configurations/rootReducer"
import nodeRoleList from "./nodeRoleList"

export default combineReducers({
  accounts,
  products,
  configurations,
  nodeRoleList,
})
