import { useMemo } from "react"
import { ControlLabel } from "react-bootstrap"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { append, pluck, reduce, reject, without } from "ramda"

import { arrayToMapWithKey, localizationKey, localized, sortByFieldNameCaseInsensitive } from "js/includes/common/utils"
import DragableList from "js/includes/components/DragableList"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import {
  StyledDraggableItem,
  StyledDraggableList,
  StyledDraggableListHeader,
} from "js/includes/components/Styled/Draggable"
import { StyledCol, StyledFormGroup, StyledRow } from "js/includes/components/Styled/Form"

import { getColumnOptions } from "./utils"

const getDraggableElements = (systemOptionsMap, attributeOptionsMap, columns) =>
  reduce(
    (draggableElements, columnId) => {
      const option = systemOptionsMap[columnId] || attributeOptionsMap[columnId]
      if (option) {
        draggableElements.push(option)
      }
      return draggableElements
    },
    [],
    columns,
  )

export const Columns = ({ columns, ticketAttributes = [], sortBy, onChange }) => {
  const { systemOptions, systemOptionsMap, sortDirectionOptions } = useMemo(() => {
    const systemOptions = getColumnOptions()
    return {
      systemOptions,
      systemOptionsMap: arrayToMapWithKey("value", systemOptions),
      sortDirectionOptions: [
        {
          value: "ASC",
          label: localized("Ascending"),
        },
        {
          value: "DESC",
          label: localized("Descending"),
        },
      ],
    }
  }, [])

  const { activeAttributeOptions, allAttributeOptionsMap } = useMemo(() => {
    const data = reduce(
      (acc, elem) => {
        const option = {
          label: elem.name,
          value: `attribute:${elem.id}`,
        }
        if (elem.active) {
          acc.activeOptions.push(option)
        }
        acc.allOptionsMap[option.value] = option
        return acc
      },
      {
        activeOptions: [],
        allOptionsMap: {},
      },
      ticketAttributes,
    )
    return {
      activeAttributeOptions: sortByFieldNameCaseInsensitive("label", "ASC")(data.activeOptions),
      allAttributeOptionsMap: data.allOptionsMap,
    }
  }, [ticketAttributes])

  return (
    <StyledRow>
      <StyledCol xs={6} flexDirection="column">
        <StyledFormGroup>
          <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
            {localized("Sort By")}
          </StyledCol>
          <StyledCol xs={12} flexDirection="column" marginTop={2}>
            <SearchableDropDown
              width="100%"
              options={systemOptions}
              openOnFocus
              keepDropdownInView
              value={systemOptions.find(o => o.value === sortBy[0].field)}
              onSelect={({ value }) => onChange("sortBy", [{ ...sortBy[0], field: value }])}
            />
          </StyledCol>
        </StyledFormGroup>
        <StyledFormGroup marginTop={2}>
          <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
            {localized("Sort Direction")}
          </StyledCol>
          <StyledCol xs={12} flexDirection="column" marginTop={2}>
            <SearchableDropDown
              width="100%"
              options={sortDirectionOptions}
              openOnFocus
              keepDropdownInView
              value={sortDirectionOptions.find(o => o.value === sortBy[0].direction)}
              onSelect={({ value }) => onChange("sortBy", [{ ...sortBy[0], direction: value }])}
            />
          </StyledCol>
        </StyledFormGroup>
      </StyledCol>
      <StyledCol xs={6} display="flex" flexDirection="column">
        <StyledFormGroup>
          <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
            {localized("Columns")}
          </StyledCol>
          <StyledCol xs={12} flexDirection="column" marginTop={2}>
            <SearchableDropDown
              width="100%"
              options={[
                {
                  value: "system",
                  label: localized("System"),
                  options: reject(o => columns.includes(o.value), systemOptions),
                },
                {
                  value: "fields",
                  label: localized("Fields"),
                  options: reject(o => columns.includes(o.value), activeAttributeOptions),
                },
              ]}
              openOnFocus
              keepDropdownInView
              searchPlaceholderToken={localizationKey("Add column to board")}
              onSelect={({ value }) => onChange("columns", append(value, columns))}
              valueRenderer={() => localized("Add column to board")}
            />
            {!!columns.length && (
              <StyledDraggableList>
                <StyledDraggableListHeader>{localized("Drag columns to arrange layout")}</StyledDraggableListHeader>
                <DragableList
                  keyProp="value"
                  elements={getDraggableElements(systemOptionsMap, allAttributeOptionsMap, columns)}
                  onChange={values => onChange("columns", values ? pluck("value", values) : [])}
                  onRemoveElement={value => onChange("columns", without([value], columns))}
                  renderElement={(section, removeSection, isDragging) => {
                    return (
                      <StyledDraggableItem className={isDragging ? "dragging" : ""}>
                        <span>{section.label}</span>
                        <span
                          onClick={e => {
                            removeSection(e, section.value)
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </span>
                      </StyledDraggableItem>
                    )
                  }}
                />
              </StyledDraggableList>
            )}
          </StyledCol>
        </StyledFormGroup>
      </StyledCol>
    </StyledRow>
  )
}
