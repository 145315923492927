import { assoc, mergeDeepLeft } from "ramda"
import moment from "moment"
import { serverScheduleDateFormat } from "js/includes/common/utils"
import { defaultSchedule } from "js/includes/components/Schedule/serverBased/scheduleDataDefaults"

const initialState = {
  enabled: false,
  runCount: 0,
  schedule: mergeDeepLeft(
    {
      dayOfMonth: 0,
      repeats: "MONTHLY",
      runAtHour: 9,
      runAtMinute: 0,
      startDate: moment().format(serverScheduleDateFormat),
      end: {
        type: "NEVER",
        endDate: null,
      },
    },
    defaultSchedule,
  ),
}

export default function sync(state = initialState, action) {
  switch (action.type) {
    case "SET_PRODUCT_AGREEMENT_SYNC":
      return action.payload
    case "SET_PRODUCT_AGREEMENT_SYNC_ENABLED":
      return assoc("enabled", action.enabled, state)
    case "SET_PRODUCT_AGREEMENT_SYNC_SCHEDULE":
      return assoc("schedule", action.schedule, state)
    case "":
    case "INIT_PRODUCT_AGREEMENT_SYNC":
      return initialState
    default:
      return state
  }
}
