import { localized } from "js/includes/common/utils"
import { either, prop } from "ramda"

export const fullNameOrDisplayName = either(prop("name"), prop("displayName"))
export const getUserNotFoundLabel = () => localized("User not Found")

export function generateLabel(mention) {
  const label = mention ? `${fullNameOrDisplayName(mention)} - ${mention.email}` : getUserNotFoundLabel()
  if (mention?.deleted) return `${label} (${localized("Technician deleted")})`
  if (mention?.permitted === false) return `${label} (${localized("Technician not allowed to view ticket")})`
  return label
}
