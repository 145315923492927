import { assoc, assocPath, defaultTo } from "ramda"

const defaultToEmptyList = defaultTo([])

const availableDeviceDataSyncAttributes = [
  "cpuSpeed",
  "defaultGateway",
  "macAddress",
  "model",
  "lastLoginName",
  "localHardDrives",
  "osInfo",
  "productKey",
  "privateIpAddress",
  "ram",
  "serialNumber",
]

export const initialState = () => ({
  syncNodes: false,
  accountList: [],
  productList: [],
  accountCriteria: [],
  productCategories: [],
  deviceDataSyncAttributes: availableDeviceDataSyncAttributes,
  availableDeviceDataSyncAttributes,
  accountOrganizationMappings: [],
  nodeRoleMappings: [],
  locationMappings: [],
  companyFilterMap: {
    types: {},
  },
})

const setSettings = (state, action) => ({ ...state, ...action.payload })
const setCompanyFilterMapTypes = (state, action) => assocPath(["companyFilterMap", "types"], action.types, state)

export default function settings(state = initialState(), action) {
  switch (action.type) {
    case "SET_AT_ACCOUNT_CRITERIA":
      return assoc("accountCriteria", defaultToEmptyList(action.payload), state)
    case "SET_AT_SYNC_CONFIGURATIONS":
      return assoc("syncNodes", action.bool, state)
    case "SET_AT_PRODUCT_TYPE":
      return assoc("productCategories", action.productCategories, state)
    case "SET_AT_NODE_ROLE_MAPPINGS":
      return assoc("nodeRoleMappings", action.payload, state)
    case "SET_AT_LOCATION_MAPPINGS":
      return assoc("locationMappings", action.payload, state)
    case "SET_AT_ACCOUNT_ORGANIZATION_MAPPINGS":
      return assoc("accountOrganizationMappings", action.payload, state)
    case "SET_AT_ACCOUNT_FILTER_TYPES":
      return setCompanyFilterMapTypes(state, action)
    case "SET_AT_PRODUCT_LIST":
      return assoc("productList", action.payload, state)
    case "SET_AT_ACCOUNT_LIST":
      return assoc("accountList", action.payload, state)
    case "SET_AT_DEVICE_DATA_SYNC_ATTRIBUTES":
      return assoc("deviceDataSyncAttributes", action.attributes, state)
    case "RESET_AT_SETTINGS":
      return initialState()
    case "SET_AT_SETTINGS":
      return setSettings(state, action)
    default:
      return state
  }
}
