import { SortDirection } from "react-virtualized"
import { combineReducers } from "redux"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import sortBy from "js/state/reducers/common/tableSorting/sortBy"
import sortDirection from "js/state/reducers/common/tableSorting/sortDirection"
import usersFilter from "./usersFilter"
import usersList from "./usersList"

export default combineReducers({
  usersFilter,
  usersList,
  usersListSort: combineReducers({
    sortBy: createNamedWrapperReducer(sortBy, "usersListSort", "name"),
    sortDirection: createNamedWrapperReducer(sortDirection, "usersListSort", SortDirection.ASC),
  }),
})
