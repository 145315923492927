import { useMemo } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { sizer, isRequiredIf, useMountedState } from "@ninjaone/utils"
import { FileIconLight, CircleXMarkLight, EllipsisHIcon, CircleExclamationIcon, LoaderIconLight } from "@ninjaone/icons"
import { formatFileSize } from "@ninjaone/utils"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/localization/autocomplete"
import { iconCommonProps, iconMappings, msPowerpointExtendedExtension, msWordExtendedExtension } from "./constants"
import Body from "../Typography/Body"
import IconButton from "../IconButton"
import Dropdown from "../Dropdown"
import ProgressBar from "../ProgressBar"
import Tooltip, { VARIANTS as TooltipVariants } from "../Tooltip"

const iconContainerWidth = 52
const buttonContainerWidth = 40

function getFileInfoContainerWidth({ hasActionIcons, hasLoadingActions }) {
  if (hasActionIcons || hasLoadingActions) {
    return `calc(100% - ${buttonContainerWidth}px)`
  }

  return `100%`
}

const StyledCard = styled.div`
  height: 52px;
  width: 100%;

  padding: ${sizer(1)};

  ${({ onClick }) => onClick && `cursor: pointer;`}

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${({ theme, backgroundColor }) => theme[backgroundColor] || theme.colorBackground};

  border-radius: 2px;
  border: 1px solid ${({ theme, borderColor }) => theme[borderColor] || theme.colorBorderWeak};

  ${({ matchWidth }) => !matchWidth && `max-width: 300px`};

  &:focus-visible {
    outline-offset: 0;
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }

  &:not(:focus-visible):not(:focus-within):not(:hover) {
    .action-icons {
      visibility: hidden;
    }
  }

  [data-ninja-dropdown-trigger] {
    &[aria-expanded="true"] {
      background-color: ${({ theme }) => theme.colorForegroundHover};
    }
  }
`

const StyledIconContainer = styled.div`
  width: 44px;
  min-width: 44px;

  height: 44px;

  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest};
  margin-right: ${sizer(2)};

  display: flex;
  align-items: center;
  justify-content: center;
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - ${iconContainerWidth}px);
`

const StyledFileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ isLoading }) => (isLoading ? sizer(2) : sizer(1))};
  width: ${({ hasActionIcons, hasLoadingActions }) => getFileInfoContainerWidth({ hasActionIcons, hasLoadingActions })};
`

const StyledIconButton = styled(IconButton)`
  width: 32px;
  height: 32px;

  &:focus-visible {
    outline-offset: 0;
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
`

function FileAttachment({ file, actions, onClick, onCancelUploadClick, progress, matchWidth, variant, iconTooltip }) {
  const [actionsOpen, setActionsOpen] = useMountedState(false)

  const isLoading = typeof progress === "number" && progress < 100
  const hasActions = actions?.length > 0
  const hasLoadingActions = isLoading && onCancelUploadClick

  const {
    component: IconComponent,
    label,
    iconColor,
    tooltipVariant,
    iconSize,
    cardBorderColor,
    cardBackgroundColor,
    textColor,
  } = useMemo(() => {
    if (variant === "PROCESSING") {
      return {
        component: LoaderIconLight,
        label: "Loading", // localizationKey("Loading")
        cardBackgroundColor: "colorBackgroundAccentNeutralWeakest",
        textColor: "colorTextDisabled",
      }
    }
    if (variant === "ERROR") {
      return {
        component: CircleExclamationIcon,
        label: "Error", // localizationKey("Error")
        tooltipVariant: TooltipVariants.DANGER,
        iconColor: "colorAlertError",
        cardBorderColor: "colorAlertError",
        iconSize: "md",
      }
    }

    const iconKey = Object.keys(iconMappings).find(key => file.type?.toLowerCase().includes(key))

    return (
      iconMappings[iconKey] || {
        component: FileIconLight,
        label: "File", // localizationKey("File")
      }
    )
  }, [file.type, variant])

  const fileType = useMemo(() => {
    const innerFileType = file.type || ""

    if (innerFileType === msPowerpointExtendedExtension) {
      return "pptx"
    }

    if (innerFileType === msWordExtendedExtension) {
      return "docx"
    }

    return innerFileType?.split("/")?.[1]?.toUpperCase() || innerFileType.toUpperCase() || localized("Document")
  }, [file])

  const renderIcon = () => (
    <IconComponent
      {...{
        label: localized(label),
        ...iconCommonProps,
        ...(iconColor && { color: iconColor }),
        ...(iconSize && { size: iconSize }),
      }}
    />
  )

  return (
    <StyledCard
      {...{
        onClick,
        tabIndex: 0,
        "data-testid": "file-attachment-card",
        ...(cardBorderColor && { borderColor: cardBorderColor }),
        ...(cardBackgroundColor && { backgroundColor: cardBackgroundColor }),
      }}
    >
      <StyledIconContainer aria-hidden="true">
        {iconTooltip ? (
          <Tooltip {...{ label: iconTooltip, ...(tooltipVariant && { variant: tooltipVariant }) }}>
            {renderIcon()}
          </Tooltip>
        ) : (
          renderIcon()
        )}
      </StyledIconContainer>

      <ContentContainer {...{ matchWidth }}>
        <StyledFileInfoContainer {...{ hasActionIcons: hasActions, hasLoadingActions, isLoading }}>
          <Body {...{ as: "p", size: "bodyXs", color: textColor || "colorTextStrong", fontWeight: 500 }}>
            {file.name}
          </Body>

          {isLoading ? (
            <ProgressBar {...{ progress, ariaLabel: `${progress}%` }} />
          ) : (
            <Body {...{ as: "p", color: textColor || "colorTextWeakest", type: "bodyXs" }}>
              {`${fileType}${file?.size ? ` - ${formatFileSize(file.size)}` : ""}`}
            </Body>
          )}
        </StyledFileInfoContainer>

        <StyledButtonContainer>
          {!isLoading && hasActions && (
            <div className={!actionsOpen ? "action-icons" : undefined}>
              <Dropdown
                {...{
                  options: actions,
                  alignRight: true,
                  variant: "compact",
                  childIsButton: true,
                  onOpenChange: setActionsOpen,
                }}
              >
                <StyledIconButton>
                  <EllipsisHIcon {...{ size: "md", color: "colorTextStrong" }} />
                </StyledIconButton>
              </Dropdown>
            </div>
          )}

          {hasLoadingActions && (
            <StyledIconButton {...{ handleClick: onCancelUploadClick, tooltip: localized("Cancel upload") }}>
              <CircleXMarkLight {...{ size: "md", color: "colorTextStrong" }} />
            </StyledIconButton>
          )}
        </StyledButtonContainer>
      </ContentContainer>
    </StyledCard>
  )
}

export default FileAttachment

FileAttachment.propTypes = {
  /**
   * The file object containing file details.
   */
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    // We use this reference to consider the type as a valid type
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    type: PropTypes.string.isRequired,
    size: PropTypes.number,
  }).isRequired,
  /**
   * Callback function triggered when the cancel upload button is clicked.
   */
  onCancelUploadClick: PropTypes.func,
  /**
   * Callback function triggered when the card is clicked.
   */
  onClick: PropTypes.func,
  /**
   * The progress value to indicate the completion status.
   */
  progress: PropTypes.number,
  /**
   * Prop to the component to match the container width
   */
  matchWidth: PropTypes.number,
  /**
   * An array of action objects.
   * Each action object should have the following properties:
   * - `titleToken`: The title of the action.
   * - `labelToken`: The label for the action, required if `titleToken` is not provided.
   * - `action`: A function to be executed when the action is triggered.
   * - `splitAfter`: A boolean indicating whether to split the actions after this one.
   * - `isRed`: A boolean indicating whether the action should be displayed in a red color.
   */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      titleToken: PropTypes.string,
      labelToken: isRequiredIf(
        PropTypes.string,
        props => !props.hasOwnProperty("titleToken"),
        "`labelToken` is required if not passing a value for `titleToken",
      ),
      action: PropTypes.func,
      splitAfter: PropTypes.bool,
      isRed: PropTypes.bool,
    }),
  ),
  /**
   * An optional variant to apply a different style.
   */
  variant: PropTypes.oneOf(["ERROR", "PROCESSING"]),
  /**
   * An optional localized text to show when hovering over the icon.
   */
  iconTooltip: PropTypes.string,
}
