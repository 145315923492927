const softwareCurrentRules = (state = {}, action) => {
  switch (action.type) {
    case "COPY_SOFTWARE_CURRENT_RULES":
      return action.products
    default:
      return state
  }
}

export default softwareCurrentRules
