//TODO: move to tab HOR
const selectedTab = (state = "General", action) => {
  switch (action.type) {
    case "SET_SELECTED_TAB":
      return action.tab
    default:
      return state
  }
}

export default selectedTab
