import { combineReducers } from "redux"
import nodeApprovalContent from "./nodeApprovalContent"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import loading from "js/state/reducers/common/loading"

export default combineReducers({
  content: nodeApprovalContent,
  loading: createNamedWrapperReducer(loading, "loadingNodeApprovals"),
  loadingStatusTabs: createNamedWrapperReducer(loading, "loadingNodeApprovalsStatusTabs"),
})
