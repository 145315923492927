import { forwardRef, useEffect, useImperativeHandle } from "react"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { Button, SearchBar, Tooltip } from "@ninjaone/components"
import { RegularInfoCircleIconLight } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import { isEnterKey, localized } from "js/includes/common/utils"
import { useMountedState } from "js/includes/common/hooks"
import { Flex } from "js/includes/components/Styled"

const StyledTooltip = styled.span`
  padding: 0 ${sizer(2)};

  svg {
    height: 100%;
  }
`

const isSearchEnabled = value => value?.length > 2

const BackupManagerSearch = ({ searchValue, onSearchValueChange }, ref) => {
  const [inputValue, setInputValue] = useMountedState(searchValue)
  const theme = useTheme()

  const handleSearch = value => {
    isSearchEnabled(value) && onSearchValueChange(value)
  }

  useImperativeHandle(ref, () => ({
    clearSearch: () => onSearchValueChange(""),
  }))

  useEffect(() => {
    setInputValue(searchValue)
  }, [searchValue, setInputValue])

  return (
    <Flex gap={sizer(2)} marginBottom={sizer(2)}>
      <SearchBar
        className="data-table-search"
        width={sizer(100)}
        placeholderText={localized("Search files or folders in restore...")}
        value={inputValue || ""}
        onChange={e => setInputValue(e.target.value)}
        onKeyDown={e => isEnterKey(e) && handleSearch(e.target.value)}
        clearInput={() => {
          setInputValue("")
          onSearchValueChange("")
        }}
        rightIcon={
          <StyledTooltip>
            <Tooltip
              label={
                <span>
                  {localized("Search by: File name, folder name, file extension.")}
                  <br />
                  {localized("Use wildcard * for explicit rule matching (*.txt, Accounting*)")}
                </span>
              }
              ariaLabel={localized("More Information")}
            >
              <RegularInfoCircleIconLight size="sm" color={theme.colorTextWeakest} />
            </Tooltip>
          </StyledTooltip>
        }
      />
      <Button
        maxHeight={sizer(10)}
        type="save"
        disabled={!isSearchEnabled(inputValue)}
        onClick={() => handleSearch(inputValue)}
      >
        {localized("Search")}
      </Button>
    </Flex>
  )
}

export default forwardRef(BackupManagerSearch)
