import { clone } from "ramda"

export default function initialEditorState(state = {}, action) {
  switch (action.type) {
    case "SET_SCHEDULED_TASK_INITIAL_EDITOR_STATE":
      return clone(action.payload)
    default:
      return state
  }
}
