export default function helpdesk(
  state = {
    helpdeskSettings: false,
    embedHelpRequestImagesInTickets: false,
  },
  action,
) {
  switch (action.type) {
    case "SET_HELPDESK_OPTIONS":
      return {
        ...state,
        ...action.helpdeskSettings,
      }
    default:
      return state
  }
}
