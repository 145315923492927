export default function helpdesk(state = {}, action) {
  switch (action.type) {
    case "SET_CW_HELPDESK_OPTIONS":
      return {
        ...state,
        ...action.cwHelpDeskSettings,
      }
    case "SET_CW_HELPDESK_CREATE_CONTACT_OPTION":
      return {
        ...state,
        createContact: action.createContact,
      }
    default:
      return state
  }
}
