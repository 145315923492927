import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Root, List, Trigger, Content } from "@radix-ui/react-tabs"
import { Root as VisuallyHidden } from "@radix-ui/react-visually-hidden"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/localization"
import { isDownKey, isUpKey } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/charsets"
import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { InputErrorIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import Text from "../Text"
import {
  StyledRoot,
  StyledTabsContainer,
  StyledList,
  StyledListItem,
  StyledError,
  StyledContent,
} from "./VerticalTabStyles"

const StyledTabsRoot = StyledRoot.withComponent(Root)
const StyledTabsList = StyledList.withComponent(List)
const StyledTab = StyledListItem.withComponent(Trigger)
const StyledErrorIcon = StyledError.withComponent(InputErrorIcon)
const StyledPanel = StyledContent.withComponent(Content)

const removeWhitespace = string => {
  return string.replace(/\s+/g, "")
}

const getErrorTabs = tabs => {
  return tabs.reduce((acc, tab, index) => (tab.hasError ? [...acc, index] : acc), [])
}

const VerticalTabs = ({
  animateTabErrors,
  ariaLabel,
  defaultTab,
  bottomContentRenderer,
  setAnimateTabErrors,
  tabs,
  getActiveTabIndex,
}) => {
  const defaultValue = removeWhitespace(defaultTab || tabs[0].label)
  const [errorTabs, setErrorTabs] = useState([])
  const [activeIndex, setActiveIndex] = useState(defaultTab ? tabs.findIndex(o => o.label === defaultTab) : 0)

  const handleTabNavigation = index => {
    tabs[activeIndex].onChange?.()
    setErrorTabs(getErrorTabs(tabs))
    setActiveIndex(index)
    getActiveTabIndex?.(index)
  }

  useEffect(() => {
    setErrorTabs(getErrorTabs(tabs))
  }, [tabs])

  useEffect(() => {
    let timeoutId

    if (animateTabErrors) {
      const errors = getErrorTabs(tabs)

      if (errors.length) {
        setErrorTabs(errors)
        timeoutId = setTimeout(() => setAnimateTabErrors(false), 5000)
      }
    }

    return () => clearTimeout(timeoutId)
  }, [animateTabErrors, setAnimateTabErrors, tabs])

  return (
    <StyledTabsRoot defaultValue={removeWhitespace(defaultValue)} orientation="vertical">
      <StyledTabsContainer>
        <StyledTabsList aria-label={ariaLabel}>
          {tabs.map(({ icon: Icon, label, required, valid }, index) => {
            const error = errorTabs.includes(index)
            return (
              <StyledTab
                key={`${label}-${index}`}
                value={removeWhitespace(label)}
                onClick={() => index !== activeIndex && handleTabNavigation(index)}
                onKeyUp={e => (isUpKey(e) || isDownKey(e)) && handleTabNavigation(index)}
                data-error={error}
                data-animate={required && animateTabErrors}
              >
                {Icon && (
                  <Box marginRight={sizer(2)}>
                    <Icon color="colorTextStrong" />
                  </Box>
                )}

                <Text
                  children={label}
                  size="sm"
                  bold={index === activeIndex}
                  lineHeight={1.21}
                  textWrap
                  textWrapLineLimit={2}
                  color="colorTextStrong"
                />
                {required && (
                  <>
                    <span aria-hidden="true">&nbsp;*</span>
                    <VisuallyHidden>{localized("Tab contains required content")}</VisuallyHidden>
                  </>
                )}
                {error && (
                  <>
                    <VisuallyHidden>{localized("Tab content contains error")}</VisuallyHidden>
                    <StyledErrorIcon />
                  </>
                )}
              </StyledTab>
            )
          })}
        </StyledTabsList>

        {bottomContentRenderer?.()}
      </StyledTabsContainer>

      {tabs.map(({ label, renderer }, index) => (
        <StyledPanel key={`${label}-${index}`} value={removeWhitespace(label)}>
          {renderer()}
        </StyledPanel>
      ))}
    </StyledTabsRoot>
  )
}

VerticalTabs.propTypes = {
  /**
   * Determines if the tabs should animate errors. Works in conjunction with
   * `setAnimationTabErrors`.
   */
  animateTabErrors: PropTypes.bool,
  /**
   * Describes the purpose of the tabs list.
   */
  ariaLabel: PropTypes.string.isRequired,
  /**
   * The renderer function to show content below the tabs list.
   */
  bottomContentRenderer: PropTypes.func,
  /**
   * The selected tab on mount. Defaults to the first tab.
   */
  defaultTab: PropTypes.string,
  /**
   * Event handler to stop the error animation. Works in conjunction with
   * `animateTabErrors`.
   */
  setAnimateTabErrors: PropTypes.func,
  /**
   * The array of tabs and their content.
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      hasError: PropTypes.bool,
      icon: PropTypes.func,
      label: PropTypes.string.isRequired,
      onChange: PropTypes.func,
      renderer: PropTypes.func.isRequired,
      required: PropTypes.bool,
    }),
  ).isRequired,
  /**
   * Function to act as a callback when the active tab changes returning the active tab index
   */
  getActiveTabIndex: PropTypes.func,
}

export default VerticalTabs
