import { SortDirection } from "react-virtualized"

const sortDirection = (state = SortDirection.DESC, action) => {
  switch (action.type) {
    case "SORT":
      return action.sortDirection
    default:
      return state
  }
}

export default sortDirection
