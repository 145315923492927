import { Tooltip } from "@ninjaone/components"

export const TOOLTIP_Z_INDEX = 100000

export const getSearchableDropdownTagRenderer = ({ getOptionLabel, optionIdKey = "id" }) => {
  return ({ option, renderTagOptionName, renderTagDeleteAction, tagLabelValue, TagWrapper }) => {
    const label = getOptionLabel({ option })
    return (
      <TagWrapper key={option[optionIdKey]} data-testid="input-tag">
        {renderTagOptionName(
          <Tooltip label={label} contentZIndex={TOOLTIP_Z_INDEX}>
            {label}
          </Tooltip>,
        )}
        {renderTagDeleteAction()}
      </TagWrapper>
    )
  }
}
