import { assoc, append, remove, insert } from "ramda"

export const initialState = {
  id: 0,
  name: "",
  nodeRoleConfigurationTypeMappings: [],
}

export default function configurationTypeMapping(state = initialState, action) {
  switch (action.type) {
    case "RESET_CW_CONFIGURATION_TYPE_MAPPING":
      return initialState
    case "SET_CW_CONFIGURATION_TYPE_MAPPING":
      return action.configurationTypeMapping
    case "SET_CW_ONFIGURATION_TYPE_MAPPING_ID":
      return assoc("id", action.id, state)
    case "SET_CW_CONFIGURATION_TYPE_MAPPING_NAME":
      return assoc("name", action.name, state)
    case "ADD_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING":
      const nodeRoleConfigurationTypeMappings = append(action.mapping, state.nodeRoleConfigurationTypeMappings)
      return assoc("nodeRoleConfigurationTypeMappings", nodeRoleConfigurationTypeMappings, state)
    case "REMOVE_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING":
      const deleteIndex = state.nodeRoleConfigurationTypeMappings.findIndex(rm => rm.id === action.id)
      return assoc(
        "nodeRoleConfigurationTypeMappings",
        remove(deleteIndex, 1, state.nodeRoleConfigurationTypeMappings),
        state,
      )
    case "EDIT_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING":
      const editIndex = state.nodeRoleConfigurationTypeMappings.findIndex(rm => rm.id === action.mapping.id)
      const tempNodeRoleConfigurationTypeMappings = remove(editIndex, 1, state.nodeRoleConfigurationTypeMappings)
      return assoc(
        "nodeRoleConfigurationTypeMappings",
        insert(editIndex, action.mapping, tempNodeRoleConfigurationTypeMappings),
        state,
      )
    default:
      return state
  }
}
