import { last } from "ramda"
import { Body, Heading, Text } from "@ninjaone/components"
import { PaperPlaneIconLight, SearchIcon, ExclamationCircle } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"
import { DOJO_ARTICLES_PREFIX, DOJO_COMMUNITY_PREFIX, isRmmServiceLocation, localized } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import HelpDropdownAnchor from "../HelpDropdownAnchor"
import { ArticleCard, HelpDropdownSkeletons, Markdown, allowedElements } from "../common"
import RowItem from "../RowItem"
import SubmitTicketRowItem from "../SubmitTicketRowItem"

const getArticleHref = url => {
  const parseUrl = last(url.split("/"))
  return DOJO_ARTICLES_PREFIX + "/" + parseUrl
}

const ArticleList = ({ articles, loading, error, handleChangeView, handleClose, showHelpSubmitTicket }) => {
  const helpOptions = (
    <>
      <RowItem
        {...{
          id: "visit-community",
          zendeskUrl: DOJO_COMMUNITY_PREFIX,
          topic: localized("Visit the community"),
          icon: isRmmServiceLocation() ? null : <PaperPlaneIconLight />,
          isExternalLink: true,
        }}
      />
      {showHelpSubmitTicket && <SubmitTicketRowItem {...{ handleChangeView }} />}
    </>
  )

  if (error) {
    return (
      <>
        <Flex flexDirection="column" alignItems="center" justifyContent="center" gap={tokens.spacing[2]} height="100%">
          <ExclamationCircle size="lg" color="colorTextWeakest" />
          <Box textAlign="center">
            <Box marginBottom={tokens.spacing[1]}>
              <Heading color="colorTextWeakest" level={2} type="headingS">
                {localized("Something went wrong on our end.")}
              </Heading>
            </Box>
            <Body color="colorTextWeakest">{localized("Please try again.")}</Body>
          </Box>
        </Flex>
        {helpOptions}
      </>
    )
  }

  if (loading) {
    return (
      <>
        <Flex flex="1" flexDirection="column" marginBottom={tokens.spacing[6]}>
          <HelpDropdownSkeletons />
        </Flex>
        {helpOptions}
      </>
    )
  }

  return (
    <>
      {articles.length ? (
        <Flex flexDirection="column" flex="1" height="auto" minHeight="250px" marginBottom={tokens.spacing[6]}>
          <Box marginBottom={tokens.spacing[3]}>
            <Text size="sm" color="colorTextWeakest">
              {localized("{{count}} articles", { count: articles?.length })}
            </Text>
          </Box>
          <Flex flexDirection="column" gap={tokens.spacing[2]} flex={1} overflowY="scroll">
            {articles?.map(({ name, snippet, id, html_url }) => (
              <ArticleCard key={id}>
                <HelpDropdownAnchor bold href={getArticleHref(html_url)}>
                  {name}
                </HelpDropdownAnchor>
                <Markdown
                  {...{
                    skipHtml: true,
                    allowedElements,
                    linkTarget: "_blank",
                  }}
                >
                  {snippet}
                </Markdown>
              </ArticleCard>
            ))}
          </Flex>
        </Flex>
      ) : (
        <Flex flexDirection="column" alignItems="center" justifyContent="center" gap={tokens.spacing[2]} height="100%">
          <SearchIcon size="lg" color="colorTextWeakest" />
          <Heading color="colorTextWeakest" level={2} type="headingS">
            {localized("No articles found")}
          </Heading>
        </Flex>
      )}
      {helpOptions}
    </>
  )
}

export default ArticleList
