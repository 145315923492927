const getInitialState = () => ({
  fields: {
    all: [],
    organization: [],
    location: [],
    node: [],
  },
  values: {
    organization: {},
    location: {},
    locations: {},
    node: {},
  },
})

const setDeviceGlobalCustomFields = (state, { definitionScope, fields }) => ({
  ...state,
  fields: {
    ...state.fields,
    [definitionScope?.toLowerCase() || "all"]: fields,
  },
})

const setDeviceGlobalCustomFieldValues = (state, { definitionScope, values }) => ({
  ...state,
  values: {
    ...state.values,
    [definitionScope?.toLowerCase() || "node"]: values,
  },
})

export default function deviceGlobalCustomFields(state = getInitialState(), action) {
  switch (action.type) {
    case "SET_DEVICE_GLOBAL_CUSTOM_FIELDS":
      return setDeviceGlobalCustomFields(state, action)
    case "SET_DEVICE_GLOBAL_CUSTOM_FIELD_VALUES":
      return setDeviceGlobalCustomFieldValues(state, action)
    case "RESET_DEVICE_GLOBAL_CUSTOM_FIELDS":
      return getInitialState()
    default:
      return state
  }
}
