const globalSearch = (state = {}, action) => {
  const {
    pageExactResults,
    pageSuggestedResults,
    persistedExactResults,
    persistedSuggestedResults,
    pageValue,
    pageFilter,
    persistedValue,
    persistedFilter,
  } = action
  switch (action.type) {
    case "SET_GLOBAL_SEARCH_PAGE_RESULTS":
      return {
        ...state,
        pageExactResults,
        pageSuggestedResults,
      }
    case "RESET_GLOBAL_SEARCH_PAGE_RESULTS":
      return {
        ...state,
        pageExactResults: null,
        pageSuggestedResults: null,
      }
    case "SET_GLOBAL_SEARCH_PAGE_QUERY":
      return {
        ...state,
        pageValue,
        pageFilter,
      }
    case "SET_GLOBAL_SEARCH_PERSISTED_RESULTS":
      return {
        ...state,
        persistedExactResults,
        persistedSuggestedResults,
      }
    case "SET_GLOBAL_SEARCH_PERSISTED_QUERY":
      return {
        ...state,
        persistedValue,
        persistedFilter,
      }
    default:
      return state
  }
}

export default globalSearch
