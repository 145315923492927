import {
  cpuDataParser,
  memoryDataParser,
  datastoreDataParser,
  healthSensorsDataParser,
  networkDataParser,
  licenceDataParser,
} from "js/state/reducers/virtualization/parsers"

export const initDashboard = (state, action) => {
  const { datasets, agentVersion } = action
  const cpu = cpuDataParser(datasets["vm-host-cpu"])
  const memory = memoryDataParser(datasets["vm-host-memory"], datasets["vm-host-system"])
  const datastore = datastoreDataParser(datasets["vm-host-datastore"])
  const healthSensors = healthSensorsDataParser(datasets["vm-host-sensor"])
  const network = networkDataParser(datasets["vm-host-nic"])
  const licenses = licenceDataParser(datasets["vm-host-license"])
  const hostSystem = datasets["vm-host-system"] ? datasets["vm-host-system"].datapoints[0].data : {}

  return {
    ...state,
    cpu,
    memory,
    datastore,
    hostSystem,
    healthSensors,
    network,
    licenses,
    agentVersion,
  }
}

export const updateDeviceFriendlyName = (state, action) => {
  return {
    ...state,
    node: {
      ...state.node,
      friendlyName: action.name,
    },
  }
}

export const setNodeStatus = (state, action) => {
  const { triggeredConditions, activeJobs, maintenanceMode } = action
  return {
    ...state,
    triggeredConditions,
    activeJobs,
    maintenanceMode,
  }
}

export const updateWarrantyDates = (state, action) => {
  const { warrantyStartDate, warrantyEndDate } = action?.payload ?? {}
  return {
    ...state,
    node: { ...state.node, warrantyStartDate, warrantyEndDate },
  }
}
