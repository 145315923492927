const getInitialState = () => ({
  path: [],
  nodeVulnerabilitySeverities: {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
  },
})

const setPath = (state, { path }) => ({
  ...state,
  path,
})

const setNodeVulnerabilitySeverities = (state, nodeVulnerabilitySeverities) => ({
  ...state,
  nodeVulnerabilitySeverities,
})

export default function systemVulnerabilities(state = getInitialState(), action) {
  switch (action.type) {
    case "SET_VULNERABILITY_PATH":
      return setPath(state, action)
    case "SET_NODE_VULNERABILITY_SEVERITIES":
      return setNodeVulnerabilitySeverities(state, action.payload)
    default:
      return state
  }
}
