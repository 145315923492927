import styled from "@emotion/styled"
import { sizer } from "@ninjaone/utils"
import { Button as DefaultButton, Text } from "@ninjaone/components"
import { Box, Flex } from "js/includes/components/Styled"
import showModal from "js/includes/common/services/showModal"
import RequestModal from "js/includes/configuration/integrations/backup/lockhart/RequestModal"
import { localizationKey } from "js/includes/common/utils"
import { backupSetup } from "js/includes/GetStartedPage/media/GetStartedMedia"

const PlayerBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  margin-bottom: ${sizer(6)};
`

const PlayerWrapper = styled(Box)`
  width: 590px;
  height: 358px;
  margin: ${sizer(5, 0, 6, 0)};
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    border: 1px solid ${({ theme }) => theme.color.border};
  }
`

const BackupFeaturesContainer = styled(Flex)`
  margin-top: ${sizer(5)};
  margin-bottom: ${sizer(20)};
  align-items: center;
  gap: 60px;

  img {
    object-fit: scale-down;
  }

  ul {
    margin-top: ${sizer(4)};
    list-style: disc;

    li {
      padding-bottom: ${sizer(2)};
    }
  }
`

const Button = styled(DefaultButton)`
  margin-top: ${sizer(4)};
`

const backupFeatures = [
  localizationKey("Full image backup"),
  localizationKey("Document, file, and folder backup"),
  localizationKey("Cloud-only, local-only and hybrid storage options"),
  localizationKey("End-user self-service file restore"),
  localizationKey("Fast and easy file restore"),
  localizationKey("Bare Metal restore"),
]

export default function GetStarted({ allowScroll = true, showTitle = true }) {
  const requestDemo = () => showModal(<RequestModal />, { withProvider: true })

  return (
    <Flex
      height="100%"
      width="100%"
      flexDirection="column"
      overflow={allowScroll ? "auto" : "unset"}
      marginBottom={sizer(6)}
    >
      <PlayerBox>
        {showTitle && (
          <Text size="lg" token={localizationKey("Get started in a few easy steps")} color="inputText" bold />
        )}
        <PlayerWrapper>{backupSetup}</PlayerWrapper>
      </PlayerBox>

      <Box maxWidth={850}>
        <Text color="inputText" token={localizationKey("Backup and data recovery for any scenario")} bold />
        <Text
          size="sm"
          token={localizationKey(
            "We give you the flexibility to balance data retention against storage utilization, access against RTO, and peace of mind against cost. Ninja gives you the flexibility to deploy data protection workflows to all your endpoints.",
          )}
          textWrap
        />
        <Button labelToken={localizationKey("Request Demo")} onClick={requestDemo} />
      </Box>

      <BackupFeaturesContainer>
        <Box>
          <Text color="inputText" token={localizationKey("Flexible backup and recovery")} bold />
          <ul>
            {backupFeatures.map(feature => (
              <li key={feature}>
                <Text token={feature} textWrap />
              </li>
            ))}
          </ul>
        </Box>
        <img src="/img/backupDashboard.png" alt="backup_dashboard" width={426} height={220} />
      </BackupFeaturesContainer>
    </Flex>
  )
}
