import { useEffect, useMemo, useRef } from "react"
import { append, evolve, map, pick, pluck } from "ramda"
import PropTypes from "prop-types"
import fastDeepEqual from "fast-deep-equal"
import { RegularInfoCircleIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import { localizationKey, localized, validations } from "@ninjaone/webapp/src/js/includes/common/utils"
import { useForm } from "@ninjaone/webapp/src/js/includes/common/hooks"
import { Box, Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import StyledDraggableList from "@ninjaone/webapp/src/js/includes/components/Styled/DraggableList/StyledDraggableList"
import SearchableDropDown from "@ninjaone/webapp/src/js/includes/components/SearchableDropDown"

import { additionalTableSettingsTypes } from "./utils"
import { DefaultButtonGroup } from "../ButtonGroup"
import Modal from "../Modal"
import Text from "../Text"
import Switch from "../Switch"
import Tooltip from "../Tooltip"
import Tags from "../Tags"
import Button from "../Button"
import AlertMessage from "../AlertMessage"

const pickColumnIds = evolve({
  columns: map(pick(["id"])),
})

const DataTableSettingsSwitch = ({ name, checked, onChange, labelToken, tooltipToken }) => {
  return (
    <Flex key={name} alignItems="center" marginTop={sizer(2)}>
      <Box marginRight={sizer(2)}>
        <Switch key={name} checked={checked} onChange={value => onChange(name, value)} labelToken={labelToken} />
      </Box>
      {tooltipToken && (
        <Tooltip label={localized(tooltipToken)}>
          <RegularInfoCircleIcon size="sm" color="colorTextWeak" />
        </Tooltip>
      )}
    </Flex>
  )
}

const DataTableSettingsModal = ({
  unmount,
  onSubmit,
  canAutoRefresh,
  canResetDefaults,
  autoRefreshEnabledTooltipToken,
  initialAutoRefreshEnabled,
  defaultAutoRefreshEnabled,
  initialSortBy = [],
  defaultSortBy = [],
  initialColumns = [],
  visibleColumns = [],
  defaultVisibleColumns = [],
  additionalColumnSettings,
  maxColumnCount,
  saveButtonToken,
  displayedSettingsFields = {
    sort: true,
    columns: true,
  },
  onMount,
  onUnmount = () => {},
}) => {
  const ascendingOption = { value: "ASC", name: localized("Ascending") }
  const descendingOption = { value: "DESC", name: localized("Descending") }

  const getSortByColumn = sortBy => (sortBy[0]?.id ? initialColumns.find(col => col.id === sortBy[0]?.id) : null)
  const getSortByDirection = sortBy => (sortBy[0]?.desc ? descendingOption : ascendingOption)
  const getAdditionalColumnSettings = valuePropertyName =>
    additionalColumnSettings?.reduce(
      (acc, setting) => ({
        ...acc,
        [setting.name]: setting[valuePropertyName],
      }),
      {},
    )

  const initialFields = {
    sortByColumn: getSortByColumn(initialSortBy),
    sortByDirection: getSortByDirection(initialSortBy),
    columns: visibleColumns,
    autoRefreshEnabled: initialAutoRefreshEnabled || defaultAutoRefreshEnabled,
    ...getAdditionalColumnSettings("initialValue"),
  }

  // TECH DEBT:
  // - We intentionally disabled ESLint here since @ninjaone/components is now integrated into our build pipeline & linting warnings/errors will trigger a failure.
  // - We decided not to fix the warning (wrap the initialization 'defaultFields' in its own useMemo) for now as that could lead to unexpected bugs.
  // - If adding any new code here do it with caution.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultFields = {
    sortByColumn: getSortByColumn(defaultSortBy),
    sortByDirection: getSortByDirection(defaultSortBy),
    columns: defaultVisibleColumns,
    autoRefreshEnabled: defaultAutoRefreshEnabled,
    ...getAdditionalColumnSettings("defaultValue"),
  }

  const { onChange, values } = useForm({
    fields: initialFields,
    validate: {
      columns: validations.required,
    },
  })
  const { columns, sortByColumn, sortByDirection, autoRefreshEnabled } = values
  const selectedColumnIds = pluck("id", columns)
  const availableColumns = initialColumns.filter(
    col => !col.hideFromTableSettingsAvailableColumns && (!selectedColumnIds.includes(col.id) || col.generateColumns),
  )

  const groupedAvailableColumns = availableColumns.reduce((acc, option) => {
    const { isRemovable = true } = option

    if (isRemovable) {
      const optionWithLabel = {
        ...option,
        label: typeof option.Header === "function" ? option.Header({ columns }) : option.Header,
      }
      if (option.tableSettingsColumnsGroup) {
        const groupIndex = acc.findIndex(item => item.label === option.tableSettingsColumnsGroup)
        if (groupIndex !== -1) {
          acc[groupIndex].options.push(optionWithLabel)
        } else {
          acc.push({
            id: option.tableSettingsColumnsGroup,
            label: option.tableSettingsColumnsGroup,
            options: [optionWithLabel],
          })
        }
      } else {
        acc.push(optionWithLabel)
      }
    }
    return acc
  }, [])

  const listBottomRef = useRef(null)

  const isResetToDefaultsDisabled = useMemo(() => fastDeepEqual(pickColumnIds(defaultFields), pickColumnIds(values)), [
    defaultFields,
    values,
  ])

  useEffect(() => {
    onMount?.()
    return onUnmount
  }, [onMount, onUnmount])

  return (
    <Modal
      cancelable
      size="sm"
      heading={localized("Table settings")}
      buttonRenderer={() => (
        <Flex justifyContent="space-between" width="100%">
          {canResetDefaults && (
            <Button
              variant="tertiary"
              onClick={() => {
                onChange(defaultFields)
              }}
              labelToken={localizationKey("Reset to defaults")}
              disabled={isResetToDefaultsDisabled}
            />
          )}
          <DefaultButtonGroup
            {...{
              actionToken: saveButtonToken,
              onConfirm: () => {
                onSubmit({
                  autoRefreshEnabled,
                  columns,
                  sortBy: sortByColumn?.id
                    ? [
                        {
                          id: sortByColumn?.id,
                          desc: sortByDirection?.value === "DESC",
                        },
                      ]
                    : [],
                  ...(additionalColumnSettings && pick(pluck("name", additionalColumnSettings), values)),
                })
                unmount()
              },
              disabled: !columns.length,
              unmount,
            }}
          />
        </Flex>
      )}
      unmount={unmount}
    >
      <Box>
        {displayedSettingsFields.sort && (
          <>
            <Box marginBottom={sizer(2)}>
              <Box marginBottom={sizer(1)}>
                <Text size="sm" token={localizationKey("Sort By")} color="colorTextWeak" />
              </Box>
              <SearchableDropDown
                options={columns.filter(col => !col.disableSortBy)}
                width="100%"
                value={sortByColumn}
                onSelect={column => onChange({ sortByColumn: column })}
                valueSelectorKey="id"
                searchableKey="Header"
                rowRenderer={option => <div className="text-ellipsis">{option.Header}</div>}
                searchPlaceholderToken={localizationKey("Select")}
              />
            </Box>
            <Box marginBottom={sizer(2)}>
              <Box marginBottom={sizer(1)}>
                <Text size="sm" token={localizationKey("Sort Direction")} color="colorTextWeak" />
              </Box>
              <SearchableDropDown
                options={[ascendingOption, descendingOption]}
                width="100%"
                value={sortByDirection}
                onSelect={option => onChange({ sortByDirection: option })}
                valueSelectorKey="value"
                searchableKey="name"
                searchPlaceholderToken={localizationKey("Select")}
              />
            </Box>
          </>
        )}
        {displayedSettingsFields.columns && (
          <>
            <Box>
              <Flex marginBottom={sizer(1)} justifyContent="space-between" alignItems="center">
                <Text size="sm" token={localizationKey("Columns")} color="colorTextWeak" />
                <Box marginLeft={sizer(1)}>
                  <Text size="xs" color="colorTextWeakest">
                    {localized("{{count}} column(s) selected out of {{maxCount}}", {
                      count: columns.length,
                      maxCount: Math.min(maxColumnCount, initialColumns.length),
                    })}
                  </Text>
                </Box>
              </Flex>
              <SearchableDropDown
                options={groupedAvailableColumns}
                key={columns.toString()}
                width="100%"
                shouldSelectOption={async column => {
                  if (column.generateColumns) {
                    const newColumns = await column.generateColumns({ columns })
                    const filteredNewColumns = newColumns.filter(newCol => !selectedColumnIds.includes(newCol.id))
                    if (filteredNewColumns.length) {
                      onChange({ columns: [...columns, ...filteredNewColumns] })
                      setTimeout(() => listBottomRef.current?.scrollIntoView({ behavior: "smooth" }), 100)
                    }
                  } else {
                    onChange({ columns: append(column, columns) })
                    setTimeout(() => listBottomRef.current?.scrollIntoView({ behavior: "smooth" }), 100)
                  }
                  return false
                }}
                valueSelectorKey="id"
                disabled={columns.length >= maxColumnCount}
                rowRenderer={option => <div className="text-ellipsis">{option.label}</div>}
                searchPlaceholderToken={localizationKey("Add column to board")}
              />
            </Box>
            {!!columns?.length && (
              <Box marginTop={sizer(3)}>
                <StyledDraggableList
                  dragLabel={localized("Drag columns to reorder")}
                  keyProp="id"
                  elements={columns}
                  onChange={values => {
                    onChange({
                      columns: values || [],
                    })
                  }}
                  onRemoveElement={columnId => {
                    onChange({
                      columns: columns.filter(col => col.id !== columnId),
                      ...(columnId === sortByColumn?.id && { sortByColumn: null }),
                    })
                  }}
                  renderElement={column => (
                    <Flex alignItems="center" justifyContent="space-between">
                      <span className="text-ellipsis">
                        {column.tableSettingsHeader ? column.tableSettingsHeader : column.Header}
                      </span>
                      {column.tableSettingsTag && (
                        <Box margin={[0, sizer(1)]}>
                          <Tags labels={[{ id: column.tableSettingsTag, label: column.tableSettingsTag }]} />
                        </Box>
                      )}
                    </Flex>
                  )}
                  height="300px"
                  listBottomRef={listBottomRef}
                  fadeAtBottom
                />
              </Box>
            )}
            {columns.length >= maxColumnCount && (
              <AlertMessage
                variant="warning"
                titleToken={localizationKey("Warning")}
                labelToken={localizationKey("This table has reached maximum amount of columns.")}
              />
            )}
          </>
        )}
        {additionalColumnSettings?.map(setting => {
          if (setting.type === additionalTableSettingsTypes.SWITCH) {
            return (
              <DataTableSettingsSwitch
                key={setting.name}
                name={setting.name}
                checked={values[setting.name]}
                onChange={onChange}
                labelToken={setting.labelToken}
                tooltipToken={setting.tooltipToken}
              />
            )
          }
          return null
        })}
        {canAutoRefresh && (
          <DataTableSettingsSwitch
            name="autoRefreshEnabled"
            checked={autoRefreshEnabled}
            onChange={onChange}
            labelToken={localizationKey("Auto-Refresh Table Data")}
            tooltipToken={autoRefreshEnabledTooltipToken}
          />
        )}
      </Box>
    </Modal>
  )
}

DataTableSettingsModal.propTypes = {
  unmount: PropTypes.func,
  onSubmit: PropTypes.func,
  canAutoRefresh: PropTypes.bool,
  canResetDefaults: PropTypes.bool,
  autoRefreshEnabledTooltipLabelToken: PropTypes.string,
  initialSortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      desc: PropTypes.bool,
    }),
  ),
  initialColumns: PropTypes.array,
  visibleColumns: PropTypes.array,
  additionalColumnSettings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      initialValue: PropTypes.any.isRequired,
      labelToken: PropTypes.string,
      tooltipToken: PropTypes.string,
    }),
  ),
  maxColumnCount: PropTypes.number,
  saveButtonToken: PropTypes.string.isRequired,
  displayedSettingsFields: PropTypes.shape({
    sort: PropTypes.bool,
    columns: PropTypes.bool,
  }),
  onMount: PropTypes.func,
  onUnmount: PropTypes.func,
}

export default DataTableSettingsModal
