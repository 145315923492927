import { reject, propEq } from "ramda"

export default function healthStatus(state = [], action) {
  switch (action.type) {
    case "SET_HEALTH_STATUS": {
      const { healthStatus } = action
      const newState = reject(propEq("name", healthStatus.name), state)
      return [...newState, healthStatus]
    }
    case "SET_HEALTH_STATUSES":
      return action.healthStatuses
    case "REVERT_TO_DEFAULT_HEALTH_STATUS":
      return []
    default:
      return state
  }
}
