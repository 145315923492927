import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { localized, getAppLogoFromBranding } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import HelpDropdown from "js/includes/components/HelpDropdown/HelpDropdown"

export default class EditorHeader extends PureComponent {
  closeModal = e => {
    const { isSaving, close } = this.props
    if (!isSaving && typeof close === "function") {
      close()
    }
  }

  saveChanges = e => {
    const { isSaving, save } = this.props
    if (!isSaving && typeof save === "function") {
      save()
    }
  }

  render() {
    const { isSaving, close, save, infoRenderer, customActionTitle, disableSave, headerId, headerRef } = this.props
    const appLogo = getAppLogoFromBranding()

    return (
      <>
        <div className="theme-bg-color ninja-editor-header" id={headerId} ref={headerRef}>
          {appLogo && (
            <a href="/#/systemDashboard/overview">
              <img className="ninja-header-logo" src={appLogo} alt="logo" />
            </a>
          )}
          <div>{infoRenderer?.()}</div>
          <Box marginLeft="auto">
            <HelpDropdown portalContainerId="help-dropdown-alt-editor-container" isEditor usesSmallTrigger />
          </Box>
          <div className="buttons-container white-space-nowrap">
            {save && (
              <button
                onClick={this.saveChanges}
                type="submit"
                disabled={isSaving || disableSave}
                className="btn btn-success"
              >
                {customActionTitle ?? localized("Save")}
              </button>
            )}
            {close && (
              <button onClick={this.closeModal} disabled={isSaving} type="button" className="btn btn-white">
                {localized("Close")}
              </button>
            )}
          </div>
        </div>
        <div id="help-dropdown-alt-editor-container" />
      </>
    )
  }
}

EditorHeader.propTypes = {
  infoRenderer: PropTypes.func,
}
