export const initialState = {
  gsmKey: null,
  requestStatus: null,
  registrationEmail: null,
  polling: false,
  alternateSubmit: false,
  deleteInProgress: false,
  registrationStatus: "NOT_CONFIGURED",
  firstName: null,
  lastName: null,
  companyName: null,
  country: null,
  username: null,
  password: null,
}

const division = (state = initialState, action) => {
  switch (action.type) {
    case "SET_REQUEST_STATUS":
      return { ...state, requestStatus: action.requestStatus }
    case "SET_ALTERNATE_SUBMIT":
      return { ...state, alternateSubmit: action.alternateSubmit }
    case "SET_REGISTRATION_STATUS":
      return { ...state, registrationStatus: action.registrationStatus }
    case "SET_DIVISION_MODAL":
      return { ...state, ...action.division }
    case "START_POLLING":
      return { ...state, polling: true }
    case "STOP_POLLING":
      return { ...state, polling: false }
    case "SET_REQUEST_TIME":
      return { ...state, requestTime: Date.now() }
    default:
      return state
  }
}

export default division
