const getInitialState = () => ({
  nodes: [],
})

const setDocumentationDelegates = (state, { nodes }) => {
  return {
    nodes,
  }
}

export default function deviceRoleCustomFields(state = getInitialState(), action) {
  switch (action.type) {
    case "SET_DOCUMENTATION_DELEGATE_NODES":
      return setDocumentationDelegates(state, action)
    default:
      return state
  }
}
