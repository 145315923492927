export const initialState = {
  syncLogs: [],
  syncInProgress: false,
}

export default function agreementSyncLogs(state = initialState, action) {
  switch (action.type) {
    case "SET_CW_AGREEMENT_SYNC_LOGS_DATA":
      return action.payload
    case "SET_CW_AGREEMENT_SYNC_LOGS_LOADING_STATUS":
      return { ...state, syncInProgress: action.syncInProgress }
    default:
      return state
  }
}
