import { combineReducers } from "redux"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import rowExpansion from "js/state/reducers/common/rowExpansion"
import visibility from "js/state/reducers/common/visibility"
import loading from "js/state/reducers/common/loading"
import selectedTab from "./selectedTab"
import selectedProducts from "./selectedProducts"
import selectedAddProducts from "./selectedAddProducts"
import products from "./products"
import timestamp from "js/state/reducers/common/timestamp"
import patchDetailsModal from "./patchDetailsModal"
import productSearch from "./productSearch"
import addProductSearch from "./addProductSearch"
import softwareCurrentRules from "./softwareCurrentRules"

export default combineReducers({
  selectedTab,
  selectedProducts,
  visibilityAddProductsModal: createNamedWrapperReducer(visibility, "addProductsModal"),
  selectedAddProducts,
  selectedAddProductsRowExpansion: createNamedWrapperReducer(rowExpansion, "selectedAddProducts"),
  addedProductsRowExpansion: createNamedWrapperReducer(rowExpansion, "addedProducts"),
  products,
  timestamp: createNamedWrapperReducer(timestamp, "softwarePatchManagement"),
  loadingSoftwareProducts: createNamedWrapperReducer(loading, "loadingSoftwareProducts"),
  patchDetailsModal,
  productSearch,
  addProductSearch,
  softwareCurrentRules,
})
