const initialState = []

export default function preselectedTargets(state = initialState, action) {
  switch (action.type) {
    case "INIT_PRESELECTED_TARGETS":
      return action.targets
    case "RESET_PRESELECTED_TARGETS":
      return initialState
    default:
      return state
  }
}
