import { useMemo } from "react"

import { sortByFieldNameCaseInsensitive } from "js/includes/common/utils"

type SortOptions = {
  sortBy?: string,
  sortDirection?: "ASC" | "DESC",
}

export const useSorted = (data = [], { sortBy = "name", sortDirection = "ASC" }: SortOptions = {}) =>
  useMemo(() => sortByFieldNameCaseInsensitive(sortBy, sortDirection, data), [data, sortBy, sortDirection])
