import React, { memo, useState } from "react"
import { FormControl } from "react-bootstrap"
import {
  localized,
  localizationKey,
  windowsFileFolderNameValidation,
  macFileFolderNameValidation,
  isMacDevice,
} from "js/includes/common/utils"
import { useForm } from "js/includes/common/hooks"
import Modal from "js/includes/components/Modal"

export default memo(({ save, close, nodeClass }) => {
  const [loading, setLoading] = useState(false)
  const [wampError, setWampError] = useState(false)

  const {
    values: { folder },
    validateForm,
    validation,
    onChange,
  } = useForm({
    validateOnChange: true,
    fields: {
      folder: "",
    },
    validate: {
      folder: validateFolder,
    },
  })

  function handleInput(event) {
    onChange("folder", event.target.value)
  }

  async function handleSave(e) {
    e.preventDefault()
    setLoading(true)
    const error = await save(folder.trim())
    setLoading(false)
    if (error) {
      setWampError(error)
    } else {
      close()
    }
  }

  function validateFolder(value) {
    setWampError(false)
    const required = !value.trim()
    const invalidName = isMacDevice(nodeClass)
      ? !macFileFolderNameValidation.test(value) || value.trim().charAt(0) === "."
      : !windowsFileFolderNameValidation.test(value)

    return {
      success: !required && !invalidName,
      message: required ? localized("Required") : invalidName ? localized("Invalid") : "",
    }
  }

  return (
    <form onSubmit={handleSave}>
      <Modal
        submittable
        title={localizationKey("New Folder")}
        saveText={localizationKey("Create")}
        disabledSaveBtn={!validation.success.folder}
        {...{ close, loading }}
      >
        <div className="position-relative text-align-left">
          <FormControl
            autoFocus
            value={folder}
            onChange={handleInput}
            onBlur={() => validateForm(["folder"])}
            className={`${validation.message.folder || wampError ? " has-error" : ""}`}
          />
          <em className="invalid position-absolute no-margin text-left">{validation.message.folder || wampError}</em>
        </div>
      </Modal>
    </form>
  )
})
