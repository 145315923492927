import { useEffect, useMemo, useRef } from "react"
import { adjust, assoc, compose, filter, flatten, map, omit, reduce, values, when } from "ramda"
import { Dropdown, IconButton } from "@ninjaone/components"
import { EllipsisHIconSvg } from "@ninjaone/icons"
import { useMountedState } from "@ninjaone/webapp/src/js/includes/common/hooks"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"
import { useMounted } from "@ninjaone/utils"

const ActionCell = ({
  cell,
  cellId,
  row,
  actions,
  lastCellFocused,
  multipleRowsSelected,
  isLoading,
  resetData,
  mouseEnteredRow,
  isShiftPressed,
  hideActions,
  actionButtons,
  onOpenChange,
}) => {
  const actionsRef = useRef(null)
  const { current } = useMounted()

  const [focusedElementIndex, setFocusedElementIndex] = useMountedState(0)

  const hasActions = actions?.primary.length || actions?.secondary.length
  // this is a bypass when the user is navigating using keyboard (using tab).
  const ruleBypass = lastCellFocused.id === row.cells.length - 1 && lastCellFocused.rowId === row.id
  const shouldShowActionButtons = actionButtons?.length > 0 && actionButtons?.length <= 3

  // this is to verify when it's only one action button and when it's not.
  const buttons = useMemo(() => {
    if (!current) return []

    return actionsRef?.current?.children || actionsRef?.current
  }, [current])

  // This is for the first focus on the action cell.
  useEffect(() => {
    if (ruleBypass && !multipleRowsSelected) {
      const currentButtonIndex = isShiftPressed ? buttons.length - 1 : 0

      const button = buttons[currentButtonIndex]

      if (button) {
        const buttonToFocus = button.querySelector("button")

        setFocusedElementIndex(currentButtonIndex)
        buttonToFocus.focus()
      }
    }
  }, [ruleBypass, multipleRowsSelected, buttons, isShiftPressed, setFocusedElementIndex])

  if (!hasActions || isLoading || hideActions) return null

  const options = compose(
    when(() => !!actions.secondary?.length, adjust(actions.primary.length - 1, assoc("splitAfter", true))),
    map(compose(assoc("resetData", resetData), assoc("selected", [row.original]))),
    filter(({ hideRowAction }) => !hideRowAction?.(row.original)),
    flatten,
    values,
    omit(["row"]),
  )(actions)

  return (
    <td
      data-no-pad
      key={cellId}
      {...cell.getCellProps()}
      className="data-table-cell-actions"
      data-test-column-id={cell.column.id}
      {...(ruleBypass && {
        onKeyDown: e => {
          if (e.key === "Tab") {
            const isTabNavigationEnd = e.shiftKey ? !focusedElementIndex : focusedElementIndex === buttons.length - 1
            if (isTabNavigationEnd) return

            let nextFocusedElement = e.shiftKey ? focusedElementIndex - 1 : focusedElementIndex + 1
            if (nextFocusedElement < 0) nextFocusedElement = 0

            if (!multipleRowsSelected) {
              const currentButton = buttons[nextFocusedElement]?.querySelector("button")

              currentButton.focus()
              e.preventDefault()

              setFocusedElementIndex(nextFocusedElement)
              e.stopPropagation()
            }
          }

          // This is to not select or trigger the row action when the dropdown/button action is focused.
          if (e.code === "Space" || e.code === "Enter") {
            e.stopPropagation()
          }
        },
      })}
    >
      <div
        className="data-table-inline-action-wrap"
        style={{ ...(multipleRowsSelected && { display: "none" }) }}
        {...(!ruleBypass && { "data-visible-on-row-hover": true })}
      >
        <div className="data-table-inline-action" ref={actionsRef}>
          {shouldShowActionButtons &&
            actionButtons.map(actionButton => {
              const {
                Icon,
                action,
                options,
                getLabel,
                labelToken,
                getDisabled,
                variant = "blue",
                showTooltipWhenDisabled = false,
              } = actionButton

              const label = getLabel?.({ ...row.original }) || localized(labelToken)
              const disabled = getDisabled?.({ ...row.original }) ?? false

              const parsedOptions = reduce(
                (acc, option) => {
                  if (option.show?.(row.original) ?? true) {
                    acc.push({
                      ...option,
                      action: () => option.action({ ...row.original, isSelected: row.isSelected }),
                    })
                  }
                  return acc
                },
                [],
                options || [],
              )

              if (options && !disabled)
                return (
                  <Dropdown
                    {...{
                      key: label,
                      modal: true,
                      onOpenChange,
                      alignRight: true,
                      variant: "compact",
                      childIsButton: true,
                      options: parsedOptions,
                    }}
                  >
                    <IconButton
                      size="sm"
                      tabIndex={0}
                      {...{ variant }}
                      tooltip={label}
                      handleClick={() => action({ ...row.original, isSelected: row.isSelected })}
                    >
                      <Icon size="sm" />
                    </IconButton>
                  </Dropdown>
                )

              return (
                <IconButton
                  size="sm"
                  tabIndex={0}
                  key={label}
                  tooltip={label}
                  {...{ variant, disabled, showTooltipWhenDisabled }}
                  handleClick={() => action({ ...row.original, isSelected: row.isSelected })}
                >
                  <Icon size="sm" />
                </IconButton>
              )
            })}

          <div className="actions-dropdown-button">
            {options?.length > 0 && (
              <>
                {mouseEnteredRow || ruleBypass ? (
                  <Dropdown
                    {...{
                      options,
                      modal: true,
                      onOpenChange,
                      alignRight: true,
                      childIsButton: true,
                      variant: "compact",
                    }}
                  >
                    <IconButton tooltipAlignRight size="sm" tooltip={localized("Actions")}>
                      <EllipsisHIconSvg />
                    </IconButton>
                  </Dropdown>
                ) : (
                  <button>
                    <EllipsisHIconSvg />
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </td>
  )
}

export default ActionCell
