import { reject, propEq, groupBy } from "ramda"
import windowsNativeScripts from "./windowsNativeScripts"

const nativeScripts = {
  ...windowsNativeScripts,
}

const buildNativeScripts = scriptsFromServer =>
  scriptsFromServer.map(s => ({
    ...s,
    ...nativeScripts[s.uid],
    deviceType: s.operatingSystems[0],
  }))

export const buildNonNativeScripts = scripts =>
  scripts.map(s => ({
    ...s,
    deviceType: s.operatingSystems[0],
    parameters: {
      runAs: "system",
      scriptParam: "",
    },
  }))

export default function scripts(state = [], action) {
  switch (action.type) {
    case "SET_SCRIPTS":
      const { scripts } = action

      const { nativeScripts = [], nonNativeScripts = [] } = groupBy(({ language }) =>
        language === "native" ? "nativeScripts" : "nonNativeScripts",
      )(scripts || [])

      return [...buildNativeScripts(nativeScripts), ...buildNonNativeScripts(nonNativeScripts)]
    case "CREATE_SCRIPT":
      return [buildNonNativeScripts([action.script])[0], ...state]
    case "UPDATE_SCRIPT": {
      const { script } = action
      return state.map(s => (s.id === script.id ? buildNonNativeScripts([script])[0] : s))
    }
    case "DELETE_SCRIPT":
      return reject(propEq("id", action.script.id), state)
    default:
      return state
  }
}
