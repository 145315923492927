import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"
import { Text } from "@ninjaone/components"
import CopyToClipboard from "@ninjaone/components/src/DataTable/Components/CopyToClipboard"
import { Flex } from "js/includes/components/Styled"
import { localizationKey, localized } from "js/includes/common/utils"

const StyledPopoverContent = styled.div`
  padding: ${tokens.spacing[4]};

  .data-table-copy-to-clipboard {
    border: 0;
    background: none;
    display: inherit;

    svg {
      color: ${({ theme }) => theme.colorTextAction};
    }
  }
`

const StyledSection = styled.section`
  padding: ${tokens.spacing[3]} 0;
  border-bottom: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  word-wrap: break-word;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  & > div:last-child {
    margin-top: ${tokens.spacing[1]};
  }
`

export default function VolumePopoverContent({ id, name, location, fileSystem, partitionStyle }) {
  const guidMatched = id?.match?.(/{(.*?)}/)?.[1]
  const guid = guidMatched ?? localized("Unknown")

  return (
    <StyledPopoverContent>
      <Text color="colorTextStrong" token={localizationKey("Details")} />
      <StyledSection>
        <Text size="sm" color="colorTextWeakest">
          {localized("GUID")}
        </Text>
        <Flex>
          <Text size="sm" color="colorTextStrong">
            {guid}
          </Text>
          <CopyToClipboard value={guid} />
        </Flex>
      </StyledSection>
      <StyledSection>
        <Text size="sm" color="colorTextWeakest">
          {localized("Location")}
        </Text>
        <Text size="sm" color="colorTextStrong" textWrap>
          {location}
        </Text>
      </StyledSection>
      <StyledSection>
        <Text size="sm" color="colorTextWeakest">
          {localized("File system")}
        </Text>
        <Text size="sm" color="colorTextStrong" textWrap>
          {fileSystem}
        </Text>
      </StyledSection>
      <StyledSection>
        <Text size="sm" color="colorTextWeakest">
          {localized("Disk layout")}
        </Text>
        <Text size="sm" color="colorTextStrong" textWrap>
          {partitionStyle}
        </Text>
      </StyledSection>
    </StyledPopoverContent>
  )
}
