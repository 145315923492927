import { useCallback, useMemo } from "react"
import { ControlLabel } from "react-bootstrap"
import { $generateHtmlFromNodes } from "@lexical/html"
import { $getRoot } from "lexical"
import styled from "@emotion/styled"

import { useForm } from "js/includes/common/hooks"
import showModal from "js/includes/common/services/showModal"
import { isFeatureEnabled, localizationKey, localized, validations } from "js/includes/common/utils"
import Modal from "js/includes/components/Modal"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { StyledCol, StyledForm, StyledFormGroup } from "js/includes/components/Styled/Form"
import { WYSIWYGEditor as WYSIWYGEditorLegacy } from "js/includes/ticketing/editor/shared/components"
import { useEditor } from "js/includes/components/RichTextEditor/hooks/useEditor"
import { WYSIWYGEditor, WYSIWYGEditorReadOnly } from "js/includes/ticketing/editor/shared/components/WYSIWYGEditor"

import { Placeholders } from "../Placeholders"
import { StyledEditorWrapper } from "../Styled"

const StyledContainer = styled.div`
  .inmodal {
    .modal-body {
      text-align: initial;
    }
  }
`

const getResponseTypeOptions = () => [
  {
    value: true,
    label: localized("Public"),
  },
  {
    value: false,
    label: localized("Private"),
  },
]

const EditorModal = ({
  value,
  handleOnChange,
  editorInsertHtml,
  withCurrentUserPlaceholders,
  isWYSIWYGV3FeatureEnabled,
  unmount,
}) => {
  const { values, validation, validateForm, onChange } = useForm({
    fields: {
      body: value?.body ?? null,
      public: value?.public ?? true,
    },
    validate: {
      body: validations.required,
    },
  })

  function handleSave() {
    if (validateForm()) {
      editorInsertHtml(values.body)
      handleOnChange(values)
      unmount()
    }
  }

  const onChangeEvent = useCallback(
    (editorState, editor) => {
      editorState.read(() => {
        const root = $getRoot()
        const text = root.getTextContent()
        onChange("body", text.trim().length ? $generateHtmlFromNodes(editor) : null)
      })
    },
    [onChange],
  )

  const options = getResponseTypeOptions()

  return (
    <StyledContainer>
      <StyledForm horizontal marginTop="0">
        <Modal
          bsSize="large"
          title={localizationKey("Edit")}
          close={unmount}
          saveText={localizationKey("Apply")}
          save={handleSave}
        >
          <StyledFormGroup validationState={validation.success.body === false ? "error" : null} marginBottom={2}>
            <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
              {localized("Body")} <sup>*</sup>
            </StyledCol>
            <StyledCol xs={12} flexDirection="column" marginTop={2}>
              {isWYSIWYGV3FeatureEnabled ? (
                <WYSIWYGEditor
                  isPublicResponse={values.public}
                  htmlParserPluginProps={{ initialHTML: values.body }}
                  onChangePluginProps={{
                    onChange: onChangeEvent,
                  }}
                  validationProps={{
                    errorMessage: validation.message.body,
                  }}
                  imagePluginProps={{ excluded: true }}
                  attachmentPluginProps={{ excluded: true }}
                />
              ) : (
                <WYSIWYGEditorLegacy
                  isPublicResponse={values.public}
                  editorData={{
                    htmlBody: values.body,
                  }}
                  allowAttachments={false}
                  allowInlineImages={false}
                  hasError={validation.success.body === false}
                  onChange={({ html, text }) => onChange("body", text.trim()?.length ? html : null)}
                />
              )}
            </StyledCol>
          </StyledFormGroup>
          <StyledFormGroup marginBottom={2}>
            <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
              {localized("Response")} <sup>*</sup>
            </StyledCol>
            <StyledCol xs={12} flexDirection="column" marginTop={2}>
              <SearchableDropDown
                width="100%"
                value={options.find(option => option.value === values.public)}
                onSelect={selected => onChange("public", selected.value)}
                options={options}
                openOnFocus
              />
            </StyledCol>
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
              {localized("Placeholders")}
            </StyledCol>
            <StyledCol xs={12} flexDirection="column" marginTop={2}>
              <Placeholders {...{ withCurrentUserPlaceholders }} />
            </StyledCol>
          </StyledFormGroup>
        </Modal>
      </StyledForm>
    </StyledContainer>
  )
}

export const CommentEditor = props => {
  const [editor, { editorInsertHtml }] = useEditor()

  const htmlBody = useMemo(() => props?.value?.body ?? localized("Body"), [props?.value?.body])
  const isPublicResponse = useMemo(() => props?.value?.public, [props?.value?.public])
  const isWYSIWYGV3FeatureEnabled = isFeatureEnabled("wysiwyg_v3")

  return (
    <StyledEditorWrapper
      isPublicResponse={isPublicResponse}
      onClick={() => showModal(<EditorModal {...{ editorInsertHtml, isWYSIWYGV3FeatureEnabled, ...props }} />)}
    >
      {isWYSIWYGV3FeatureEnabled ? (
        <WYSIWYGEditorReadOnly
          isPublicResponse={isPublicResponse}
          htmlParserPluginProps={{
            initialHTML: htmlBody,
            updateOnChange: true,
          }}
        />
      ) : (
        <WYSIWYGEditorLegacy isPublicResponse={isPublicResponse} readOnly editor={editor} editorData={{ htmlBody }} />
      )}
    </StyledEditorWrapper>
  )
}
