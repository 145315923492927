import { initialState } from "./initialState"
import {
  onAddBackupPlan,
  onUpdateBackupPlan,
  onDeleteBackupPlan,
  onDisableBackupPlan,
  onEnableBackupPlan,
  onRevertOverride,
  onChangeBackupErrorsVisibility,
} from "./backupPlans"
import { onSetDefaults } from "./serverDefaults"

export default function backups(state = initialState(), action) {
  switch (action.type) {
    case "POLICY_EDITOR_INITIALIZE_BACKUPS":
      return initialState(state, action.backups)
    case "POLICY_EDITOR_ADD_BACKUP_PLAN":
      return onAddBackupPlan(state, action)
    case "POLICY_EDITOR_UPDATE_BACKUP_PLAN":
      return onUpdateBackupPlan(state, action)
    case "POLICY_EDITOR_DELETE_BACKUP_PLAN":
      return onDeleteBackupPlan(state, action)
    case "POLICY_EDITOR_ENABLE_BACKUP_PLAN":
      return onEnableBackupPlan(state, action)
    case "POLICY_EDITOR_DISABLE_BACKUP_PLAN":
      return onDisableBackupPlan(state, action)
    case "POLICY_EDITOR_REVERT_OVERRIDE_IN_BACKUP_PLAN":
      return onRevertOverride(state, action)
    case "POLICY_EDITOR_SHOW_BACKUP_ERRORS":
      return onChangeBackupErrorsVisibility(state, action)
    case "SET_BACKUP_SERVER_DEFAULTS":
      return onSetDefaults(state, action)
    default:
      return state
  }
}
