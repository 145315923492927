const discoveryJobsFilter = (state = "", action) => {
  switch (action.type) {
    case "SET_DISCOVERY_JOBS_FILTER":
      return action.discoveryJobsFilter
    default:
      return state
  }
}

export default discoveryJobsFilter
