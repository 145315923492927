import styled from "@emotion/styled"
import { colors } from "js/includes/common/theme"

export const StyledDraggableListHeader = styled.div`
  font-style: italic;
  color: ${colors.ninjaMedium};
`
export const StyledDraggableItem = styled.div`
  font-size: 14px;
  padding: 9px 12px 9px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 9px;
  border: 1px solid ${colors.ninjaMedium};
  border-radius: 3px;
  background-color: #ffffff;

  svg:hover {
    cursor: pointer;
    color: ${colors.ninjaBlack};
  }
`
export const StyledDraggableItemType = styled.div`
  font-style: italic;
  font-size: 11px;
  color: ${colors.ninjaMedium};
`

export const StyledDraggableList = styled.div`
  cursor: move;
  background-color: ${colors.ninjaWhite};
  border: 1px solid ${colors.ninjaLight};
  padding: 10px;
  border-radius: 4px;
  margin-top: 5px;
`
