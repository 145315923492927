import { useEffect, useMemo, useCallback } from "react"
import Select from "react-select"
import { FormControl, Col, FormGroup } from "react-bootstrap"
import { map, applySpec, identity } from "ramda"
import { Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { isNilOrEmpty, isEKey } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"

const HOUR_INDEX = 0
const MINUTE_INDEX = 1

const DEFAULT_VALUE = "1:0"

const minuteOptions = map(
  applySpec({
    value: identity,
    label: identity,
  }),
)(["0", "15", "30", "45"])

export const TimeSinceEditor = ({ value, handleOnChange }) => {
  const parsedValue = useMemo(() => value?.split(":") ?? DEFAULT_VALUE, [value])

  useEffect(() => {
    if (isNilOrEmpty(value)) {
      handleOnChange(DEFAULT_VALUE)
    }
  }, [value, handleOnChange])

  const handleChange = useCallback(
    (updatedValue, type) => {
      switch (type) {
        case "hour":
          handleOnChange([updatedValue, parsedValue[MINUTE_INDEX]].join(":"))
          break
        case "minute":
          handleOnChange([parsedValue[HOUR_INDEX], updatedValue].join(":"))
          break
        default:
          throw new Error(`Invalid type: "${type}"`)
      }
    },
    [parsedValue, handleOnChange],
  )

  return (
    <FormGroup>
      <Col xs={6}>
        <FormControl
          type="number"
          min="0"
          value={parsedValue[HOUR_INDEX]}
          onChange={e => handleChange(e.target.value, "hour")}
          onKeyDown={event => {
            if (isEKey(event)) {
              event.preventDefault()
            }
          }}
        />
        <Box marginTop={sizer(1)} marginLeft={sizer(2)}>
          <Text size="xs" bold token="general.hours" />
        </Box>
      </Col>
      <Col xs={6}>
        <Select
          value={minuteOptions.find(minuteOption => minuteOption.value === parsedValue[MINUTE_INDEX])}
          onChange={selected => handleChange(selected.value, "minute")}
          options={minuteOptions}
          isSearchable={false}
        />
        <Box marginTop={sizer(1)} marginLeft={sizer(2)}>
          <Text size="xs" bold token="general.minutes" />
        </Box>
      </Col>
    </FormGroup>
  )
}
