import { combineReducers } from "redux"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import value from "js/state/reducers/common/value"
import sortSystemItemsBy from "./sortSystemItemsBy"
import sortOrgItemsBy from "./sortOrgItemsBy"
import customerOverview from "./customerOverview"
import deviceSettings from "./deviceSettings"
import deviceTools from "./deviceTools"

export default combineReducers({
  mode: createNamedWrapperReducer(value, "dashboard.mode"),
  entityId: createNamedWrapperReducer(value, "dashboard.entityId"),
  entityListItems: createNamedWrapperReducer(value, "dashboard.entityListItems"),
  isDocumentationEnabled: createNamedWrapperReducer(value, "dashboard.isDocumentationEnabled"),
  teamViewerCustomerConfig: createNamedWrapperReducer(value, "dashboard.teamViewerCustomerConfig"),
  sortSystemItemsBy,
  sortOrgItemsBy,
  customerOverview,
  deviceSettings,
  deviceTools,
  currentTab: createNamedWrapperReducer(value, "dashboard.currentTab"),
})
