import { initialGuestDashboardState } from "js/state/reducers/virtualization/initialStates"
import { initDashboard, setNodeStatus } from "./actionHandlers"
import { updateOwner } from "js/state/reducers/virtualization/commons/actionHandlers"

export default function vmGuestDashboard(state = initialGuestDashboardState(), action) {
  switch (action.type) {
    case "INIT_GUEST_DASHBOARD_DATASETS":
      return initDashboard(state, action)
    case "INIT_GUEST_NODE_DATA":
      return { ...state, node: action.node, organization: action.organization, location: action.location }
    case "CLEAR_NODE_DATA":
      return { ...state, node: null }
    case "SET_GUEST_NODE_STATUS":
      return setNodeStatus(state, action)
    case "CLEAR_STATUS":
      return { ...state, triggeredConditions: [], activeJobs: [] }
    case "UPDATE_GUEST_NODE_OWNER":
      return updateOwner(state, action)
    case "SET_VM_GUEST_ITAM_TAGS":
      return { ...state, node: { ...state.node, tags: action.tags } }
    default:
      return state
  }
}
