import { localizationKey } from "js/includes/common/utils"

export const priorityMap = [
  { value: "LOW", labelToken: localizationKey("Low") },
  { value: "NORMAL", labelToken: localizationKey("Normal") },
  { value: "HIGH", labelToken: localizationKey("High") },
  { value: "URGENT", labelToken: localizationKey("Urgent") },
]

export const categoryMapper = [
  { value: "category_activities", labelToken: localizationKey("Activities") },
  { value: "category_active_directory", labelToken: localizationKey("Active Directory") },
  { value: "category_antivirus", labelToken: localizationKey("Antivirus") },
  { value: "category_api", labelToken: "API" },
  { value: "category_automation", labelToken: localizationKey("Automation") },
  { value: "category_backup", labelToken: localizationKey("Backup") },
  { value: "category_branding", labelToken: localizationKey("Branding") },
  { value: "category_billing", labelToken: localizationKey("Billing") },
  { value: "category_custom_fields", labelToken: localizationKey("Custom Fields") },
  { value: "category_devices", labelToken: localizationKey("Devices") },
  { value: "category_groups", labelToken: localizationKey("Groups") },
  { value: "category_integrations", labelToken: localizationKey("Integrations") },
  { value: "category_mdm", labelToken: "MDM" },
  { value: "category_networking", labelToken: localizationKey("Networking") },
  { value: "category_ninja_mobile", labelToken: localizationKey("Ninja Mobile") },
  { value: "category_notification_channels", labelToken: localizationKey("Notification Channels") },
  { value: "category_organizations", labelToken: localizationKey("Organizations") },
  { value: "category_other", labelToken: localizationKey("Other") },
  { value: "category_patch_management", labelToken: localizationKey("Patch Management") },
  { value: "category_policies", labelToken: localizationKey("Policies") },
  { value: "category_remote_tools", labelToken: localizationKey("Remote Tools") },
  { value: "category_reporting", labelToken: localizationKey("Reporting") },
  { value: "category_ticketing", labelToken: localizationKey("Ticketing") },
  { value: "category_user_accounts", labelToken: localizationKey("User Accounts") },
  { value: "category_virtualization", labelToken: localizationKey("Virtualization") },
]
