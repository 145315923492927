import { combineReducers } from "redux"
import application from "./application/rootReducer"
import session from "./session/rootReducer"
import gettingStarted from "./session/gettingStarted"
import general from "./general/rootReducer"
import user from "./user/rootReducer"
import deviceSearch from "./deviceSearch/rootReducer"
import osPatches from "./osPatches/rootReducer"
import softwareTab from "./softwareTab/rootReducer"
import softwarePatchManagement from "./softwarePatchManagement/rootReducer"
import integrations from "./integrations/rootReducer"
import dashboard from "./dashboard/rootReducer"
import dashboardTab from "./dashboardTab/rootReducer"
import usersListTab from "./usersListTab/rootReducer"
import endUsersListTab from "./endUsersListTab/rootReducer"
import activityList from "./activities/rootReducer"
import psa from "./psa/rootReducer"
import websiteBranding from "./websiteBranding/rootReducer"
import organizations from "./organizations"
import organizationLocations from "./organizationLocations"
import scripting from "./scripting/rootReducer"
import policyEditor from "./policyEditor/rootReducer"
import configuration from "./configuration/rootReducer"
import locations from "./locations/rootReducer"
import scheduledTasks from "./scheduledTasks/rootReducer"
import credentials from "./credentials/rootReducer"
import nodeApprovals from "./nodeApprovals"
import activeDevices from "./activeDevices/rootReducer"
import virtualization from "./virtualization"
import customerEditor from "./customerEditor"
import backups from "./backups"
import remoteSupport from "./remoteSupport"
import alerts from "./alerts/alertMessages"
import contactListTab from "./contactListTab/rootReducer"
import ticketing from "./ticketing/rootReducer"
import customFields from "./customFields/rootReducer"
import customSnmp from "./customSnmp/rootReducer"
import ninjaPSA from "./ninjaPSA/rootReducer"
import documentation from "./documentation/rootReducer"
import marketingPromo from "./marketingPromo/rootReducer"
import globalSearch from "./globalSearch/rootReducer"
import quickConnect from "./quickConnect/rootReducer"
import identityProvider from "./identityProvider"
import mdmConfiguration from "./mdm/rootReducer"
import vulnerabilities from "./vulnerabilities/rootReducer"

export default combineReducers({
  application,
  session,
  general,
  gettingStarted,
  user,
  deviceSearch,
  osPatches,
  softwareTab,
  integrations,
  psa,
  dashboard,
  dashboardTab,
  softwarePatchManagement,
  usersListTab,
  endUsersListTab,
  websiteBranding,
  activityList,
  credentials,
  organizations,
  organizationLocations,
  scripting,
  policyEditor,
  configuration,
  locations,
  scheduledTasks,
  nodeApprovals,
  activeDevices,
  virtualization,
  customerEditor,
  backups,
  remoteSupport,
  alerts,
  ticketing,
  contactListTab,
  customFields,
  customSnmp,
  ninjaPSA,
  documentation,
  marketingPromo,
  globalSearch,
  quickConnect,
  identityProvider,
  mdmConfiguration,
  vulnerabilities,
})
