import { combineReducers } from "redux"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import value from "js/state/reducers/common/value"
import sortBy from "js/state/reducers/common/tableSorting/sortBy"
import sortDirection from "js/state/reducers/common/tableSorting/sortDirection"

export default combineReducers({
  deviceType: createNamedWrapperReducer(value, "scripting-scriptsSelectorList-deviceType", null),
  selectedScriptCategory: createNamedWrapperReducer(
    value,
    "scripting-scriptsSelectorList-selectedScriptCategory",
    null,
  ),
  nameFilter: createNamedWrapperReducer(value, "scripting-scriptsSelectorList-nameFilter"),
  sort: combineReducers({
    sortBy: createNamedWrapperReducer(sortBy, "scripting-scriptsSelectorList-sort", "name"),
    sortDirection: createNamedWrapperReducer(sortDirection, "scripting-scriptsSelectorList-sort", "ASC"),
  }),
})
