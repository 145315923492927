import { reject, propEq } from "ramda"

export default function scriptCategories(state = [], action) {
  switch (action.type) {
    case "CREATE_SCRIPT_CATEGORY":
      return [...state, action.scriptCategory]
    case "SET_SCRIPT_CATEGORIES":
      return action.scriptCategories
    case "UPDATE_SCRIPT_CATEGORY":
      const { scriptCategory } = action
      return state.map(c => (c.id === scriptCategory.id ? scriptCategory : c))
    case "DELETE_SCRIPT_CATEGORY":
      return reject(propEq("id", action.scriptCategory.id), state)
    default:
      return state
  }
}
