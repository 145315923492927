export default function patchDetailsModal(state = null, action) {
  switch (action.type) {
    case "LOADING_PATCH_DETAILS":
      return "loading"
    case "SET_PATCH_DETAILS":
      return action.patch
    case "CLOSE_PATCH_DETAILS_MODAL":
      return null
    default:
      return state
  }
}
