import { memo, useCallback, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { Col, ControlLabel, FormGroup } from "react-bootstrap"
import Select from "react-select"
import { find, propEq } from "ramda"
import styled from "@emotion/styled"
import { Checkbox } from "@ninjaone/components"
import {
  collisionHandlingOptionValues,
  createCollisionHandlingOption,
  isLockhartEnabled,
} from "js/includes/common/backup"
import { fetchDeviceById, getOrganization } from "js/includes/common/client"
import { useMountedState } from "js/includes/common/hooks"
import {
  getPathSeparator,
  isFeatureEnabled,
  localizationKey,
  localized,
  ninjaReportError,
  showErrorMessage,
} from "js/includes/common/utils"
import PlanFileFolderExplorer from "js/includes/components/Backup/BackupManager/PlanFileFolderExplorer"
import { Radio, RadioGroup } from "js/includes/components/NinjaReactICheck"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import Tooltip from "js/includes/components/Tooltip"
import { replaceSeparatorAndRemoveTrailingSlash } from "js/includes/components/Browsers"

const StyledLabel = styled.span`
  color: ${({ theme, disabled }) => (disabled ? theme.palette.primary.light : "inherit")};
  margin-left: ${({ theme }) => theme.spacing(2)};
`

const StyledCheckboxCol = styled(Col)`
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;
  }
`

const ORIGINAL_RESTORE_LOCATION = "original"
const OTHER_RESTORE_LOCATION = "other"

const getIsOriginalDeviceAvailable = async sourceNodeId => {
  if (!sourceNodeId) return false

  try {
    const [device, isBackupEnabled] = await Promise.all([
      fetchDeviceById(sourceNodeId),
      isLockhartEnabled({ nodeId: sourceNodeId }),
    ])

    return device.node.isUp && isBackupEnabled
  } catch (error) {
    ninjaReportError(error)
    return false
  }
}

export const getOriginalLocationPath = (path = "", separator = "/") => path.substring(0, path.lastIndexOf(separator))

const RestoreModal = ({
  RevisionSelector,
  restoreJob,
  modalTitle,
  sourceNode,
  nodes,
  disableRestore,
  close,
  restorePath,
}) => {
  const [restoring, setRestoring] = useMountedState(false)
  const [organizationDevices, setOrganizationDevices] = useMountedState()
  const [defaultCollisionHandlingOptionValue] = collisionHandlingOptionValues
  const defaultCollisionHandlingOption = createCollisionHandlingOption(defaultCollisionHandlingOptionValue)
  const [collisionHandling, setCollisionHandling] = useMountedState(defaultCollisionHandlingOption)
  const [destinationPath, setDestinationPath] = useMountedState("")
  const [destinationNode, setDestinationNode] = useMountedState({})
  const [restorePermissions, setRestorePermissions] = useMountedState(false)
  const [firstNode] = nodes
  const canRestoreToOriginalLocation = !(firstNode.root && nodes.length > 1)
  const sourceNodeName = sourceNode.friendlyName || sourceNode.name || sourceNode.systemName
  const [isOriginalDeviceAvailable, setIsOriginalDeviceAvailable] = useMountedState(false)
  const [isOriginalLocation, setIsOriginalLocation] = useMountedState(
    isFeatureEnabled("restore_original_location") && canRestoreToOriginalLocation,
  )

  useEffect(() => {
    if (isFeatureEnabled("restore_original_location") && canRestoreToOriginalLocation) {
      const checkOriginalDeviceAvailability = async () => {
        const _isOriginalDeviceAvailable = await getIsOriginalDeviceAvailable(sourceNode.id)
        setIsOriginalDeviceAvailable(_isOriginalDeviceAvailable)
        if (!_isOriginalDeviceAvailable) {
          setIsOriginalLocation(false)
        }
      }
      checkOriginalDeviceAvailability()
    }
  }, [canRestoreToOriginalLocation, setIsOriginalDeviceAvailable, setIsOriginalLocation, sourceNode.id])

  const setOnlineDevices = useCallback(async () => {
    try {
      const customer = await getOrganization(sourceNode.clientId)
      const { mode, nodeIds = [] } = customer.content.backup.lockhart
      setOrganizationDevices(mode === "MANUAL" ? nodeIds : [])
    } catch (error) {
      ninjaReportError(error)
      setOrganizationDevices([])
    }
  }, [sourceNode, setOrganizationDevices])

  useEffect(() => {
    setOnlineDevices()
  }, [setOnlineDevices])

  const restore = async () => {
    try {
      setRestoring(true)

      await restoreJob({
        destinationNode,
        collisionHandling,
        restorePermissions,
        destinationPath,
        isOriginalLocation,
      })
    } catch (error) {
      showErrorMessage(localized("Error creating/updating jobs"))
      ninjaReportError(error)
    } finally {
      setRestoring(false)
    }
  }

  const isDestinationAvailable =
    (isOriginalLocation && isOriginalDeviceAvailable) || (destinationNode.id && destinationPath)

  const isDisableRestoreBtn = !isDestinationAvailable || restoring || disableRestore

  return (
    <Modal.Dialog bsClass="inmodal modal" className="overflow-y-auto" dialogClassName="backup-manager-restore-modal">
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body bsClass="modal-body overflow-visible-important display-flex flex-direction-column">
        <FormGroup className="text-left" controlId="backup-restore-manager-download-info">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Device")}
          </Col>
          <Col xs={12}>
            <div className="break-word">{sourceNodeName}</div>
          </Col>
        </FormGroup>

        <FormGroup className="text-left" controlId="backup-restore-manager-download-info">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Plan Name")}
          </Col>
          <Col xs={12}>
            <div className="break-word">{firstNode.planName}</div>
          </Col>
        </FormGroup>

        <FormGroup className="text-left" controlId="backup-restore-manager-download-info">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Path")}
          </Col>
          <Col xs={12}>
            <div className="break-all">
              {replaceSeparatorAndRemoveTrailingSlash(restorePath, getPathSeparator(sourceNode))}
            </div>
          </Col>
        </FormGroup>

        {RevisionSelector}

        <FormGroup className="text-left" controlId="backup-restore-manager-overwrite">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("If File Exists")}
          </Col>
          <Col xs={12}>
            <Select
              value={collisionHandling}
              placeholder={localized("Select")}
              onChange={selected => setCollisionHandling(selected)}
              options={collisionHandlingOptionValues.map(createCollisionHandlingOption)}
            />
          </Col>
        </FormGroup>

        {isFeatureEnabled("restore_original_location") && (
          <FormGroup>
            <Col xs={12}>
              <RadioGroup
                className="display-flex flex-column align-items-start"
                name="isOriginalLocation"
                value={isOriginalLocation ? ORIGINAL_RESTORE_LOCATION : OTHER_RESTORE_LOCATION}
                onChange={e => {
                  setIsOriginalLocation(e.target.value === ORIGINAL_RESTORE_LOCATION)
                }}
              >
                <Radio
                  label={
                    <Tooltip
                      token={localizationKey("Not available when restoring multiple root folders.")}
                      placement="right"
                      hideTooltip={canRestoreToOriginalLocation}
                    >
                      <StyledLabel disabled={!canRestoreToOriginalLocation}>
                        {localized("Original Location")}
                      </StyledLabel>
                    </Tooltip>
                  }
                  value={ORIGINAL_RESTORE_LOCATION}
                  radioClass="iradio_square-blue"
                  disabled={!isOriginalDeviceAvailable}
                />
                <Radio
                  label={<StyledLabel>{localized("Other Location")}</StyledLabel>}
                  value={OTHER_RESTORE_LOCATION}
                  radioClass="iradio_square-blue"
                />
              </RadioGroup>
            </Col>
          </FormGroup>
        )}

        {organizationDevices && !(isFeatureEnabled("restore_original_location") && isOriginalLocation) && (
          <FormGroup className="text-left" controlId="backup-restore-manager-file-folder-tree">
            <Col componentClass={ControlLabel} xs={12}>
              {localized("Restore To")}
            </Col>
            <Col xs={12}>
              <SearchableDropDown
                {...{
                  openOnFocus: true,
                  pageSize: 50,
                  endpoint: "/query/devices",
                  width: "100%",
                  rowHeight: 40,
                  searchableKey: "name",
                  valueSelectorKey: "id",
                  noRowsRendererToken: localizationKey("No online devices available"),
                  onSelect: node => node && setDestinationNode(node),
                  getDefaultSelected: items => find(propEq("id", sourceNode.id), items) ?? items[0],
                  searchParams: ({ query }) => ({
                    ...(query && { name: query }),
                    ...(sourceNode.clientId && { org: [sourceNode.clientId] }),
                    ...(organizationDevices.length && { nodeId: organizationDevices }),
                    nodeClass: [
                      "WINDOWS_SERVER",
                      "WINDOWS_WORKSTATION",
                      ...(isFeatureEnabled("macff") ? ["MAC", "MAC_SERVER"] : []),
                    ],
                    online: true,
                    lockhartEnabled: true,
                  }),
                }}
              />
            </Col>
            <Col className="m-t-md" xs={12}>
              {destinationNode.id && (
                <PlanFileFolderExplorer {...{ setDestinationPath, destinationNode, height: 300 }} />
              )}
            </Col>
          </FormGroup>
        )}
        {isFeatureEnabled("backup_ntfs_permissions") && firstNode.backupPermissions && (
          <FormGroup className="text-left m-b-xs" controlId="backup-restore-manager-ntfs-permissions">
            <StyledCheckboxCol xs={12}>
              <Checkbox
                checked={restorePermissions}
                fontWeight={400}
                onChange={({ isChecked }) => setRestorePermissions(isChecked)}
                label={localized("Restore NTFS Permissions")}
              />
              <Tooltip
                token={localizationKey(
                  "This option will restore the NTFS permissions for the selected files or folders from the most recent backup job.",
                )}
              />
            </StyledCheckboxCol>
          </FormGroup>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="display-flex justify-content-between">
          <div className="display-flex flex-full justify-content-end">
            <button type="button" className="btn btn-success" onClick={restore} disabled={isDisableRestoreBtn}>
              {localized("Restore")}
            </button>

            <button type="button" className="btn btn-white" onClick={close}>
              {localized("Close")}
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal.Dialog>
  )
}

export default memo(RestoreModal)
