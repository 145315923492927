export default function timers(state = {}, action) {
  switch (action.type) {
    case "INIT_TIMERS":
      return {
        ...state,
        ...action.payload,
      }
    case "SET_TIMER":
      return {
        ...state,
        [action.ticketId]: {
          isRunning: action.isRunning,
          timerSets: action.timerSets,
        },
      }
    default:
      return state
  }
}
