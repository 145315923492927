export default function changeDevicePolicy(state = {}, action) {
  switch (action.type) {
    case "OPEN_CHANGE_DEVICE_POLICY_MODAL":
      return {
        devices: action.devices,
        deviceType: action.deviceType,
      }
    case "SET_CHANGE_DEVICE_POLICY":
      return action.changeDevicePolicy
    case "SET_POLICY_FOR_CHANGE_DEVICE_POLICY_MODAL":
      return { ...state, policy: action.policy }
    case "CLOSE_CHANGE_DEVICE_POLICY_MODAL":
      return {}
    default:
      return state
  }
}
