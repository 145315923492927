import { assoc, dissocPath, init, map, propEq, when } from "ramda"

export default function rootReducer(state = {}, action) {
  switch (action.type) {
    case "UPDATE_SESSION":
      const newSession = { ...state, ...action.updatedProperties }
      if (window.application?.attributes?.session) {
        window.application.attributes.session.attributes = newSession
      }
      return newSession
    case "UPDATE_DIVISIONCONFIG":
      return {
        ...state,
        divisionConfig: map(when(propEq("name", action.key), assoc("enabled", action.value)), state.divisionConfig),
      }
    case "SET_SESSION":
      const { session } = action
      return {
        ...session,
        //using location.origin allows local testing
        origin:
          window.location.hostname === "localhost" || !session["application.origin"]
            ? window.location.origin
            : init(action.session["application.origin"]),
      }
    case "UPDATE_PSA_TYPE": {
      window.psaConnect.set("psaType", action.psaType)
      return { ...state, psaType: action.psaType }
    }
    case "DELETE_SESSION_AUTHUSER":
      return dissocPath(["connect", "authUser"], state)
    default:
      return state
  }
}
