export default function rootReducer(state = {}, action) {
  switch (action.type) {
    case "SET_USER":
      return action.user
    case "UPDATE_USER_EMAIL":
      return { ...state, email: action.email }
    case "UPDATE_USER_PHONE":
      return { ...state, phone: action.phone }
    default:
      return state
  }
}
