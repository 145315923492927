import { compose, concat, filter, isEmpty, map, mergeRight, pluck } from "ramda"

export default function devices(state = [], action) {
  switch (action.type) {
    case "SET_DEVICES":
      return action.devices
    case "ADD_DEVICES":
      return isEmpty(state) ? action.devices : concat(state, action.devices)
    case "CLEAR_ACTIVE_SEARCH":
      return []
    case "REFRESH_DEVICES":
      return compose(
        filter(device => action.devices.some(d => d.id === device.id)),
        map(device => ({ ...device, ...action.devices.find(d => d.id === device.id) })),
      )(state)
    case "UPDATE_DEVICE_DETAILS":
      const deviceIds = pluck("id", action.devices)
      return state.map(device =>
        deviceIds.includes(device.id)
          ? mergeRight(
              device,
              action.devices.find(d => d.id === device.id),
            )
          : device,
      )
    case "DELETE_DEVICES_FROM_SEARCH_RESULTS":
      return state.filter(device => !action.deletedDevices.includes(device.id))
    default:
      return state
  }
}
