import { mergeRight } from "ramda"

export const initialState = {
  nodeRoles: [],
}

export default function deviceRoles(state = initialState, action) {
  switch (action.type) {
    case "SET_DEVICE_ROLES_STANDARD_AND_CUSTOM":
      return mergeRight(state, { nodeRoles: action.nodeRoles })
    default:
      return state
  }
}
