import { useEffect } from "react"
import { compose, map, propEq } from "ramda"
import { Text } from "@ninjaone/components"
import styled from "@emotion/styled"
import { useTheme } from "@emotion/react"
import { sizer } from "@ninjaone/utils"
import {
  localized,
  localizedF,
  showErrorMessage,
  fetchJson,
  sortByFieldNameCaseInsensitive,
  localizationKey,
} from "js/includes/common/utils"
import { NinjaResponseError } from "js/includes/common/types"
import Select from "react-select"
import { useMountedState } from "js/includes/common/hooks"
import { StyledSpan } from "js/includes/components/Styled"

const actionOptions = [
  { value: true, label: localizedF("Start") },
  { value: false, label: localizedF("Stop") },
]

const StyledFormLabelContainer = styled.div`
  margin: ${sizer(2, 0, 1)};
`

export default function WindowServices({ onChange, validateForm, validation, values: { start, serviceName } }) {
  const [windowsServiceOptionsItems, setWindowsServiceOptionsItems] = useMountedState([])
  const [isLoading, setIsLoading] = useMountedState(false)

  const theme = useTheme()

  useEffect(() => {
    const getWindowsServices = async () => {
      try {
        setIsLoading(true)

        const response = await fetchJson("/webapp/winservice")
        if (response.resultCode !== "SUCCESS") {
          throw new NinjaResponseError(response)
        }
        const windowsServiceItems = compose(
          sortByFieldNameCaseInsensitive("label", "ASC"),
          map(service => ({ value: service.serviceName, label: `${service.displayName} (${service.serviceName})` })),
        )(response.windowsServices)

        setWindowsServiceOptionsItems(windowsServiceItems)
      } catch (error) {
        showErrorMessage(localized("Windows Services could not load"))
        throw error
      } finally {
        setIsLoading(false)
      }
    }
    getWindowsServices()
  }, [setIsLoading, setWindowsServiceOptionsItems])

  const selectedOption = windowsServiceOptionsItems.find(propEq("value", serviceName))

  return (
    <>
      <StyledFormLabelContainer>
        <Text
          bold
          {...{
            size: "sm",
            token: localizationKey("Service"),
            ...(validation.success.serviceName === false && { color: theme.color.red }),
          }}
        />
      </StyledFormLabelContainer>
      <Select
        hideSelectedOptions
        isClearable
        isSearchable
        {...{
          onBlur: () => {
            validateForm(["serviceName"])
          },
          onChange: selection =>
            onChange({
              serviceName: selection?.value,
              displayName: selection?.label,
            }),
          getOptionLabel: ({ value, label }) => {
            if (value === selectedOption?.value) {
              return value
            }

            return label
          },
          options: windowsServiceOptionsItems,
          value: selectedOption || "",
          placeholder: isLoading ? localized("Loading") : "",
        }}
      />
      <StyledSpan color={theme.color.red}>
        {validation.success.serviceName === false && validation.message.serviceName}
      </StyledSpan>
      <StyledFormLabelContainer>
        <Text bold size="sm" token={localizationKey("Action")} />
      </StyledFormLabelContainer>
      <Select
        {...{
          getOptionLabel: ({ label }) => label(),
          options: actionOptions,
          id: "windowsServiceStartOptions",
          value: actionOptions.find(propEq("value", start)),
          onChange: selection => onChange("start", selection.value),
        }}
      />
    </>
  )
}
