import React, { memo } from "react"
import { Modal, Text } from "@ninjaone/components"
import showModal from "js/includes/common/services/showModal"
import { useForm } from "js/includes/common/hooks"
import { localized, localizationKey, validations } from "js/includes/common/utils"
import { ControlLabel } from "react-bootstrap"
import { StyledForm } from "js/includes/components/Styled/Form"

import { Input } from "@ninjaone/components"

import { Box } from "js/includes/components/Styled"
import { useTheme } from "@emotion/react"
import { DefaultButtonGroup } from "@ninjaone/components/src/ButtonGroup"
import { sizer } from "@ninjaone/utils"

export const getSecureUrl = () =>
  new Promise(resolve => {
    showModal(<EnterSecureUrlModal {...{ resolve }} />)
  })

const EnterSecureUrlModal = memo(({ unmount, resolve }) => {
  const theme = useTheme()
  const {
    values: { secureUrl },
    onChange,
    validateForm,
    validation,
  } = useForm({
    fields: {
      secureUrl: "",
    },
    validate: {
      secureUrl: validations.urlOrIpAddress,
    },
  })

  const handleSave = e => {
    e.preventDefault()
    if (validateForm()) {
      resolve(secureUrl)
      unmount()
    }
  }

  const handleClose = () => {
    resolve(null)
    unmount()
  }

  return (
    <StyledForm onSubmit={handleSave}>
      <Modal
        titleGroup={{
          titleToken: localizationKey("Enter Secure URL"),
          color: theme.color.black["100"],
        }}
        buttonRenderer={() => (
          <Box width="100%" paddingTop={sizer(4)}>
            <DefaultButtonGroup
              submit
              actionToken={localizationKey("Save")}
              unmount={handleClose}
              disabled={validation.success.secureUrl === false}
            />
          </Box>
        )}
        unmount={handleClose}
      >
        <Text
          textWrap
          display="block"
          color={validation.success.secureUrl === false ? theme.color.error["100"] : "inherit"}
          componentClass={ControlLabel}
        >
          <span>{localized("URL")}</span>
        </Text>

        <Input
          type="text"
          value={secureUrl}
          placeholder="https://example.com"
          errorMessage={validation.message.secureUrl}
          onChange={e => onChange({ secureUrl: e.target.value })}
        />
      </Modal>
    </StyledForm>
  )
})

export default EnterSecureUrlModal
