import {
  cpuDataParser,
  memoryDataParser,
  networkDataParser,
  snapshotDataParser,
} from "js/state/reducers/virtualization/parsers"

export const initDashboard = (state, action) => {
  const { datasets } = action
  const cpu = cpuDataParser(datasets["vm-guest-cpu"])
  const memory = memoryDataParser(datasets["vm-guest-memory"], datasets["vm-guest-system"])
  const network = networkDataParser(datasets["vm-guest-nic"])
  const snapshot = snapshotDataParser(datasets["vm-guest-snapshot"])
  const guestSystem = datasets["vm-guest-system"] ? datasets["vm-guest-system"].datapoints[0].data : {}

  return {
    ...state,
    cpu,
    memory,
    guestSystem,
    network,
    snapshot,
  }
}

export const setNodeStatus = (state, action) => {
  const { triggeredConditions, activeJobs, maintenanceMode } = action
  return {
    ...state,
    triggeredConditions,
    activeJobs,
    maintenanceMode,
  }
}
