import { setNodesUids } from "js/includes/editors/Customer/Sections/Security/common"
import { initialState } from "./initialState"

export const onChangeMode = (state, { mode }) => ({
  ...state,
  general: {
    mode,
  },
})

export const initializeNodeApprovals = (state, { nodes, general, clientId }) => {
  if (!clientId) return initialState()

  const pending = {
    ...state.pending,
    nodes: nodes.pending,
  }

  const approved = {
    ...state.approved,
    nodes: nodes.approved,
  }

  const rejected = {
    ...state.rejected,
    nodes: setNodesUids(nodes.rejected),
  }

  return {
    ...state,
    clientId,
    general,
    pending,
    approved,
    rejected,
  }
}

const isCandidateNode = node => node.nodeApprovalType === "CANDIDATE_NODE"

export const onApproveNodes = (state, { payload: { nodes = [], candidateNodes = [] } }) => {
  const approvedArr = [...state.approved.nodes]
  const pendingArr = state.pending.nodes.filter(node => {
    const idsList = isCandidateNode(node) ? candidateNodes : nodes
    const isNodeApproved = idsList.includes(node.id)
    if (isNodeApproved) {
      node.approvalStatus = "APPROVED"
      if (isCandidateNode(node)) {
        node.installationStatus = "WAITING"
        node.approvalStatusByAppUserName = window.application.get("user").getName()
        node.approvalStatusByAppUserId = window.application.get("user").id
      }
      approvedArr.push(node)
    }
    return !isNodeApproved
  })

  const approved = {
    ...state.approved,
    nodes: approvedArr,
  }

  const pending = {
    ...state.pending,
    nodes: pendingArr,
  }

  return {
    ...state,
    pending,
    approved,
  }
}

export const onRejectNodes = (state, { payload: { tabName, nodes = [], candidateNodes = [] } }) => {
  const rejectedArr = [...state.rejected.nodes]
  const updatedArr = state[tabName].nodes.filter(node => {
    const idsList = isCandidateNode(node) ? candidateNodes : nodes
    const isNodeRejected = idsList.includes(node.id)
    if (isNodeRejected) {
      node.approvalStatus = "REJECTED"
      node.approvalStatusByAppUserName = window.application.get("user").getName()
      node.approvalStatusByAppUserId = window.application.get("user").id
      rejectedArr.push(node)
    }
    return !isNodeRejected
  })

  const rejected = {
    ...state.rejected,
    nodes: rejectedArr,
  }

  const tabContent = {
    ...state[tabName],
    nodes: updatedArr,
  }

  return {
    ...state,
    [tabName]: tabContent,
    rejected,
  }
}

export const onClearNodes = (state, { payload: { tabName, nodes = [], candidateNodes = [] } }) => {
  const updatedArr = state[tabName].nodes.filter(node => {
    const idsList = isCandidateNode(node) ? candidateNodes : nodes
    const isNodeCleared = idsList.includes(node.id)
    return !isNodeCleared
  })

  const tabContent = {
    ...state[tabName],
    nodes: updatedArr,
  }

  return {
    ...state,
    [tabName]: tabContent,
  }
}

export const refreshNodeApprovalsTabs = (state, { nodes }) => {
  const pending = {
    ...state.pending,
    nodes: nodes.pending,
  }

  const approved = {
    ...state.approved,
    nodes: nodes.approved,
  }

  const rejected = {
    ...state.rejected,
    nodes: setNodesUids(nodes.rejected),
  }

  return {
    ...state,
    pending,
    approved,
    rejected,
  }
}
