import { Editor, Transforms, Path, Element } from "slate"

const isAncestor = node => Editor.isEditor(node) || Element.isElement(node)

const getLastChild = (node, level) => {
  if (!(level + 1) || !isAncestor(node)) return node

  const { children } = node

  const lastNode = children[children.length - 1]

  return getLastChild(lastNode, level - 1)
}

export const getLastNode = (editor, level) => {
  const { children } = editor

  const lastNode = children[children.length - 1]

  if (!lastNode) return

  const [, lastPath] = Editor.last(editor, [])

  return [getLastChild(lastNode, level - 1), lastPath.slice(0, level + 1)]
}

export const withTrailingBlock = (editor, { type = "paragraph", options: { level = 0, ...query } = {} } = {}) => {
  const { normalizeNode } = editor

  editor.normalizeNode = ([node, path]) => {
    if (path.length) {
      const lastNode = getLastNode(editor, level)
      const lastChildNode = lastNode?.[0]

      if (!lastChildNode || lastChildNode.type !== type) {
        const at = lastNode ? Path.next(lastNode[1]) : [0]

        Transforms.insertNodes(
          editor,
          {
            type: type,
            children: [{ text: "" }],
          },
          { at },
        )

        return
      }
    }

    normalizeNode([node, path])
  }

  return editor
}
