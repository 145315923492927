import { FormEvent } from "react"
import { ControlLabel } from "react-bootstrap"
import styled from "@emotion/styled"
import { useForm } from "js/includes/common/hooks"
import showModal from "js/includes/common/services/showModal"
import { localized, localizationKey, validations } from "js/includes/common/utils"
import Modal from "js/includes/components/Modal"
import { StyledCol, StyledForm, StyledFormControl, StyledFormGroup } from "js/includes/components/Styled/Form"
import { Placeholders } from "js/includes/ticketing/TriggerEditor/components/Placeholders"
import { StyledContainer } from "js/includes/ticketing/TriggerEditor/components/Styled"

const StyledSubject = styled.span`
  margin: 7px 0;
`

type TicketSubjectEditorProps = {
  value?: string,
  handleOnChange(values: Record<string, unknown>): void,
}

type EditorModalProps = TicketSubjectEditorProps & {
  unmount(): void,
}

const EditorModal = ({ value, handleOnChange, unmount }: EditorModalProps) => {
  const { values, validation, validateForm, onChange } = useForm({
    fields: {
      subject: value ?? "",
    },
    validate: {
      subject: validations.required,
    },
  })

  function handleSave(e: FormEvent) {
    e.preventDefault()

    if (validateForm()) {
      handleOnChange(values.subject)
      unmount()
    }
  }

  return (
    <StyledForm horizontal onSubmit={handleSave} marginTop="0">
      <Modal overflow title={localizationKey("Edit")} close={unmount} saveText={localizationKey("Apply")} submittable>
        <StyledFormGroup validationState={validation.success.subject === false ? "error" : null} marginBottom={2}>
          <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
            {localized("Subject")} <sup>*</sup>
          </StyledCol>
          <StyledCol xs={12} marginTop={2}>
            <StyledFormControl
              maxLength="200"
              value={values.subject}
              onChange={e => onChange("subject", e.target.value)}
            />
          </StyledCol>
        </StyledFormGroup>
        <Placeholders />
      </Modal>
    </StyledForm>
  )
}

export const TicketSubjectEditor = (props: TicketSubjectEditorProps) => {
  const subject = props.value ?? <>&nbsp;</>

  return (
    <StyledContainer onClick={() => showModal(<EditorModal {...props} />)}>
      <StyledSubject className="break-word">{subject}</StyledSubject>
    </StyledContainer>
  )
}
