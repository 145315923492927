import { combineReducers } from "redux"
import scripts from "./scripts"
import noop from "js/state/reducers/noop"
import scriptCategories from "./scriptCategories"
import scriptsSelectorList from "./scriptsSelectorList"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import loading from "js/state/reducers/common/loading"

export default combineReducers({
  architectures: noop([]),
  languages: noop([]),
  operatingSystems: noop([]),
  automationTypes: noop([]),
  scriptCategories,
  scripts,
  scriptsSelectorList,
  scriptsLoading: createNamedWrapperReducer(loading, "scriptsLoading", false),
})
