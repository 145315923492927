import qs from "qs"
import pathParse from "path-parse"
import { fetchJson, isNotNilOrEmpty, localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"
import { getFileFoldersActions } from "./actions"
import { getFileFoldersColumns } from "./columns"
import { replaceSeparatorAndRemoveTrailingSlash } from "js/includes/components/Browsers"

const getFolderContent = async ({
  currentDir: {
    planName,
    planId,
    planDeleted,
    removed,
    path: parentPath,
    id: folderId,
    common,
    content,
    backupPermissions,
    destination,
    planType,
  },
  fetchingParams: { pageSize, pageIndex },
  separator,
  node,
}) => {
  if (!folderId)
    return {
      data: [],
    }

  if (common)
    return {
      data: content,
      totalCount: content.length,
    }

  const { results, totalElements, totalPages } = await fetchJson(
    `/backup/lockhart/v2/nodes/${node.id}/plans/${planId}/folders/${folderId}/content?${qs.stringify({
      page: pageIndex,
      size: pageSize,
    })}`,
  )

  const folderContent = results.map(
    ({ id, name, path, type, deleted, backupSize, size, latestRevisionCreatedAt, status }) => {
      const isFolder = type === "FOLDER"

      return {
        id,
        node,
        planId,
        deleted,
        planName,
        parentPath,
        folder: true,
        missing: deleted,
        planDeleted,
        removed: removed || status === "DELETED",
        actualSize: backupSize,
        originalSize: size,
        latestRevisionCreatedAt,
        type: isFolder ? "folder" : "file",
        name: isFolder ? pathParse(path).base : name,
        path: isFolder ? path : parentPath + separator + name,
        backupPermissions,
        destination,
        planType,
      }
    },
  )

  return {
    data: folderContent,
    pageCount: totalPages,
    totalCount: totalElements,
  }
}

export const getSearchResults = async ({
  currentDir: {
    planName,
    planId,
    planDeleted,
    path: parentPath,
    id: folderId,
    common,
    content,
    backupPermissions,
    destination,
    previousDir,
    planType,
  },
  fetchingParams: { pageSize, pageIndex, globalFilter },
  separator,
  node,
}: fetchDataProps) => {
  const { results = [], totalElements, totalPages } =
    (await fetchJson(
      `/backup/lockhart/nodes/${node.id}/plans/${planId}/search?${qs.stringify({
        searchTerm: globalFilter,
        folderId,
        page: pageIndex,
        size: pageSize,
      })}`,
    )) ?? {}

  const searchResults = results.map(({ id, name, path, type, deleted, backupSize, size }) => {
    const isFolder = type === "FOLDER"
    const searchPath = replaceSeparatorAndRemoveTrailingSlash(path.replace(`${parentPath}/`, ""), separator)

    return {
      id,
      node,
      planId,
      deleted,
      planName,
      parentPath,
      folder: true,
      planDeleted,
      actualSize: backupSize,
      originalSize: size,
      type: isFolder ? "folder" : "file",
      name: isFolder ? pathParse(path).base : name,
      path,
      searchPath: `${searchPath}${isFolder ? "" : separator + name}`,
      backupPermissions,
      destination,
      planType,
    }
  })

  return { data: searchResults, pageCount: totalPages, totalCount: totalElements }
}

const fetchData = fetchProps => {
  const {
    fetchingParams: { globalFilter },
  } = fetchProps

  const fetchFunction = isNotNilOrEmpty(globalFilter) ? getSearchResults : getFolderContent
  return fetchFunction(fetchProps)
}

const onError = (error, { name }) =>
  reportErrorAndShowMessage(error, localizationKey("There was an error fetching content for folder {{folderName}}"), {
    folderName: name,
  })

export const getFolderNavigationHandler = ({
  isFileFolderBackupAllowed,
  fetchingParams: { globalFilter } = {},
  separator,
}) => ({
  columns: getFileFoldersColumns(globalFilter, separator),
  actions: getFileFoldersActions({ isFileFolderBackupAllowed }),
  fetchData,
  onError,
  searchable: true,
})
