import { reject, propEq } from "ramda"

const endUsers = (state = { list: [], textFilter: "" }, action) => {
  switch (action.type) {
    case "SET_END_USERS_LIST":
      return { ...state, list: action.list }
    case "SET_END_USERS_FILTER":
      return { ...state, textFilter: action.textFilter }
    case "DELETE_END_USER":
      return { ...state, list: reject(propEq("id", action.userId), state.list) }
    default:
      return state
  }
}

export default endUsers
