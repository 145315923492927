import { useState } from "react"
import { useAsyncDebounce } from "react-table"
import { sizer } from "@ninjaone/utils"
import SearchBar from "../SearchBar"

export default function GlobalFilter({
  clearButtonId = "search-bar-global-filter-clear-button",
  disabled,
  globalFilter,
  globalFilterCharLimit,
  gotoPage,
  isManualPaginationEnabled,
  searchPlaceholderText,
  setGlobalFilter,
  shouldTrimGlobalFilter,
  searchBarFullWidth,
  searchBarAutoFocus,
}) {
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 400)

  return (
    <SearchBar
      buttonId={clearButtonId}
      className="data-table-search"
      width={searchBarFullWidth ? "100%" : sizer(75)}
      autofocus={searchBarAutoFocus}
      placeholderText={searchPlaceholderText}
      value={value || ""}
      onChange={e => {
        setValue(e.target.value)
        onChange(shouldTrimGlobalFilter ? e.target.value.trim() : e.target.value)
      }}
      clearInput={() => {
        setValue("")
        onChange("")
        if (isManualPaginationEnabled) {
          gotoPage(0)
        }
      }}
      maxLength={globalFilterCharLimit}
      disabled={disabled}
    />
  )
}
