import { assoc } from "ramda"

export const initialState = {
  id: 0,
  nodeRoleId: 0,
  productId: 0,
  productName: "",
  agreementTypeId: 0,
  agreementTypeName: "",
  groups: [],
  groupsLogicOperator: "AND",
  ranking: 0,
}

export default function nodeRoleProductMapping(state = initialState, action) {
  switch (action.type) {
    case "RESET_CW_NODE_ROLE_PRODUCT_MAPPING":
      return initialState
    case "SET_CW_NODE_ROLE_PRODUCT_MAPPING":
      return action.mapping
    case "SET_CW_NODE_ROLE_PRODUCT_MAPPING_ID":
      return assoc("id", action.id, state)
    case "SET_CW_NODE_ROLE_PRODUCT_MAPPING_NODE_ROLE":
      return assoc("nodeRoleId", action.nodeRole.id, state)
    case "SET_CW_NODE_ROLE_PRODUCT_MAPPING_PRODUCT":
      state = assoc("productId", action.product.id, state)
      return assoc("productName", action.product.name, state)
    case "SET_CW_NODE_ROLE_PRODUCT_MAPPING_AGREEMENT_TYPE":
      state = assoc("agreementTypeId", action.agreementType.id, state)
      return assoc("agreementTypeName", action.agreementType.name, state)
    case "SET_CW_NODE_ROLE_PRODUCT_MAPPING_GROUPS":
      return assoc("groups", action.groups, state)
    case "SET_CW_NODE_ROLE_PRODUCT_MAPPING_LOGICAL_OPERATOR":
      return assoc("groupsLogicOperator", action.logicalOperator, state)
    default:
      return state
  }
}
