import { append, map, mergeLeft, propEq, reject, when } from "ramda"

const defaultState = {
  channelList: [],
}

export default function notificationChannels(state = defaultState, action) {
  const { channelList } = state
  switch (action.type) {
    case "NOTIFICATION_CHANNELS_SET_LIST":
      return { ...state, channelList: action.list }
    case "NOTIFICATION_CHANNELS_ADD_CHANNEL":
      return { ...state, channelList: append(action.channel, channelList) }
    case "NOTIFICATION_CHANNELS_UPDATE_CHANNEL":
      return {
        ...state,
        channelList: map(when(propEq("id", action.channel.id), mergeLeft(action.channel)), channelList),
      }
    case "NOTIFICATION_CHANNELS_DELETE_CHANNEL":
      return { ...state, channelList: reject(propEq("id", action.id), channelList) }
    default:
      return state
  }
}
