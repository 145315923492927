import { defaultRemoteSupportConfig } from "js/state/actions/remoteSupport"

export default function remoteSupport(state = defaultRemoteSupportConfig, action) {
  switch (action.type) {
    case "REMOTE_SUPPORT_SET_SUPPORT_FOLDERS":
      return { ...state, supportFolders: action.payload }
    case "REMOTE_SUPPORT_SET_SETTINGS":
      const { enabled, expirationTTL, connections, installMessage } = action.payload
      return {
        ...state,
        enabled,
        expirationTTL,
        connections,
        installMessage,
      }
    default:
      return state
  }
}
