import { createLinkMatcherWithRegExp } from "@lexical/react/LexicalAutoLinkPlugin"

const URL_REGEX = /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)(?<![-.+():%])/

const EMAIL_REGEX = /(?<!\S)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}(?!\S)/

export const URL_MATCHER = createLinkMatcherWithRegExp(URL_REGEX, text => {
  return text.startsWith("http") ? text : `https://${text}`
})

export const EMAIL_MATCHER = createLinkMatcherWithRegExp(EMAIL_REGEX, text => {
  return `mailto:${text}`
})
