export default function deletingSearch(state = null, action) {
  switch (action.type) {
    case "BEGIN_DELETE_SEARCH":
      return action.search.id
    case "DELETE_GROUP":
      return null
    default:
      return state
  }
}
