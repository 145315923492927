import { assoc, append, remove, insert, propEq, findIndex, pluck, max, reduce, compose, map, reject } from "ramda"

const moveRanking = (state, action, up = true) => {
  const _nodeRoleProductMappings = [...state.nodeRoleProductMappings]
  const mappingIndex = findIndex(propEq("id", action.mappingId), _nodeRoleProductMappings)
  const { ranking } = _nodeRoleProductMappings[mappingIndex]

  if (ranking === 0) return state

  const newRanking = up ? ranking - 1 : ranking + 1

  const shouldUpdate = up ? newRanking > 0 && ranking !== 1 : newRanking <= _nodeRoleProductMappings.length

  if (!shouldUpdate) return state

  const mappingToBeSwappedIndex = findIndex(propEq("ranking", newRanking), _nodeRoleProductMappings)
  _nodeRoleProductMappings[mappingIndex].ranking = newRanking
  _nodeRoleProductMappings[mappingToBeSwappedIndex].ranking = ranking

  return { ...state, nodeRoleProductMappings: _nodeRoleProductMappings }
}

export const initialState = {
  id: 0,
  name: "",
  nodeRoleProductMappings: [],
  productMappingType: "SINGLE_HIT",
}

export default function productMapping(state = initialState, action) {
  switch (action.type) {
    case "RESET_CW_PRODUCT_MAPPING":
      return initialState
    case "SET_CW_PRODUCT_MAPPING":
      return action.productMapping
    case "SET_CW_PRODUCT_MAPPING_ID":
      return assoc("id", action.id, state)
    case "SET_CW_PRODUCT_MAPPING_NAME":
      return assoc("name", action.name, state)
    case "ADD_CW_PRODUCT_MAPPING_NODE_ROLE_PRODUCT_MAPPING":
      const lastRank = reduce(max, 0, pluck("ranking", state.nodeRoleProductMappings))
      const nodeRoleProductMappings = append(
        {
          ...action.mapping,
          ranking: lastRank + 1,
        },
        state.nodeRoleProductMappings,
      )
      return assoc("nodeRoleProductMappings", nodeRoleProductMappings, state)
    case "REMOVE_CW_PRODUCT_MAPPING_NODE_ROLE_PRODUCT_MAPPING":
      const deleteIndex = state.nodeRoleProductMappings.findIndex(npm => npm.id === action.id)
      const currentRanking = state.nodeRoleProductMappings[deleteIndex].ranking

      const mappings = compose(
        reject(propEq("id", action.id)),
        map(m => (m.ranking > currentRanking ? { ...m, ranking: m.ranking - 1 } : m)),
      )(state.nodeRoleProductMappings)

      return assoc("nodeRoleProductMappings", mappings, state)
    case "EDIT_CW_PRODUCT_MAPPING_NODE_ROLE_PRODUCT_MAPPING":
      const editIndex = state.nodeRoleProductMappings.findIndex(npm => npm.id === action.mapping.id)
      const tempNodeRoleProductMapping = remove(editIndex, 1, state.nodeRoleProductMappings)
      return assoc("nodeRoleProductMappings", insert(editIndex, action.mapping, tempNodeRoleProductMapping), state)
    case "CHANGE_CW_CURRENT_PRODUCT_MAPPING":
      return { ...state, ...action.newMapping }
    case "MOVE_CW_MAPPING_RANK_UP":
      return moveRanking(state, action)
    case "MOVE_CW_MAPPING_RANK_DOWN":
      return moveRanking(state, action, false)
    default:
      return state
  }
}
