import { append, remove, insert } from "ramda"

export default function configurationTypeMappings(state = [], action) {
  switch (action.type) {
    case "SET_CW_CONFIGURATION_TYPE_MAPPINGS":
      return action.payload
    case "ADD_CW_CONFIGURATION_TYPE_MAPPING":
      return append(action.newConfigurationTypeMapping, state)
    case "REMOVE_CW_CONFIGURATION_TYPE_MAPPING":
      const deleteIndex = state.findIndex(ctm => ctm.id === action.id)
      return remove(deleteIndex, 1, state)
    case "EDIT_CW_CONFIGURATION_TYPE_MAPPING":
      const editIndex = state.findIndex(ctm => ctm.id === action.configurationTypeMapping.id)
      const tempConfigurationTypeMappings = remove(editIndex, 1, state)
      return insert(editIndex, action.configurationTypeMapping, tempConfigurationTypeMappings)
    default:
      return state
  }
}
