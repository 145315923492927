import React, { memo } from "react"
import ActionItem from "./ActionItem"

export default memo(
  ({
    onFilterContent,
    onGetActions,
    noActionsRenderer,
    dispatchCallback,
    checked,
    history,
    content,
    nodeId,
    nodeClass,
    onAction,
  }) => {
    const actions = onGetActions({ checked, history, content, nodeClass })
    return (
      <div className="file-folder-actions">
        <div style={{ height: "54px" }}>{/* Placeholder for search bar */}</div>

        {actions.map(({ id, token, action, LinkComponent }) => {
          return (
            <ActionItem
              key={id}
              {...{
                nodeClass,
                checked,
                history,
                content,
                id,
                token,
                action,
                nodeId,
                LinkComponent,
                dispatchCallback,
                onAction,
              }}
            />
          )
        })}

        {!actions.length && noActionsRenderer()}
      </div>
    )
  },
)
