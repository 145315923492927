import { sortBy, prop } from "ramda"

const initialState = {
  list: [],
}

const sortStatusListById = sortBy(prop("id"))

export default function status(state = initialState, action) {
  switch (action.type) {
    case "TICKETING_SET_TICKET_STATUSES":
      return { ...state, list: sortStatusListById(action.payload) }
    default:
      return state
  }
}
