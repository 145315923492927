const faTeamviewer = {
  prefix: "fac",
  iconName: "teamviewer",
  icon: [
    256,
    256,
    [],
    "0001",
    `M113.083,0.269c-17.048,2.572 -30,6.572 -43.905,13.715c-34.571,17.619 -58.952,49.904 -67.333,89.047c-2.476,11.333 -2.476,38 0,49.333c11.047,51.715 49.333,90 101.047,101.048c11.334,2.476 38,2.476 49.334,0c51.714,-11.048 89.999,-49.333 101.047,-101.048c2.476,-11.333 2.476,-38 0,-49.333c-11.048,-51.333 -49.047,-89.619 -100.19,-100.857c-6.857,-1.524 -33.905,-2.762 -40,-1.905Zm-18.286,88.095c0.952,2 0.667,3.239 -1.048,6.858c-2.761,5.714 -2.761,8 0,9.904c1.905,1.334 6.858,1.524 33.81,1.524c26.952,0 31.905,-0.19 33.809,-1.524c2.762,-1.904 2.762,-4.381 0.096,-9.619c-1.334,-2.476 -1.81,-4.952 -1.429,-6.476c0.667,-2.667 4.095,-4.476 6.572,-3.428c5.428,2.095 65.428,38 66.666,39.809c1.333,2 1.333,2.572 0,4.572c-1.238,1.809 -61.238,37.714 -66.666,39.809c-2.477,1.048 -5.905,-0.762 -6.572,-3.429c-0.381,-1.523 0.095,-4 1.429,-6.476c2.666,-5.238 2.666,-7.714 -0.096,-9.619c-3.333,-2.381 -64.285,-2.381 -67.619,0c-2.761,1.905 -2.761,4.381 -0.095,9.619c1.334,2.476 1.81,4.953 1.429,6.476c-0.667,2.667 -4.095,4.477 -6.572,3.429c-5.428,-2.095 -65.428,-38 -66.666,-39.809c-1.334,-2 -1.334,-2.572 0,-4.572c1.238,-1.809 61.428,-37.809 66.666,-39.809c2.477,-0.953 5.143,0.19 6.286,2.761Z`,
  ],
}

export default faTeamviewer
