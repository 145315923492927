const initialState = {
  apps: false,
  add: false,
  user: false,
}

const quickMenus = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_MENU":
      return { ...state, [action.name]: true }
    case "CLOSE_MENU":
      return { ...state, [action.name]: false }
    default:
      return state
  }
}

export default quickMenus
