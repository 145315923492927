import { noop } from "@ninjaone/utils"
import showModal from "js/includes/common/services/showModal"
import { getTicketingUrl } from "js/includes/common/utils"
import TriggerEditorModal from "js/includes/ticketing/TriggerEditor/Modal"
import TicketRulesetModal from "js/includes/configuration/integrations/ticketing/ticketAutomation/ticketRuleset/TicketRulesetModal"

const triggerTypes = new Set(["EVENT_BASED", "RESPONSE_TEMPLATE", "TIME_BASED"])

export const AutomationLogEntryElement = ({ attributes, children, element: { automation } }) => {
  const onClick = () => {
    if (triggerTypes.has(automation.type)) {
      showModal(<TriggerEditorModal {...{ type: automation.type, id: automation.id }} />, {
        withProvider: true,
        withGraphQLProvider: true,
        graphQLUrl: getTicketingUrl(),
      })
      return
    }

    showModal(<TicketRulesetModal {...{ fetchRulesetList: noop, id: automation.id, type: automation.type }} />, {
      withProvider: true,
      withGraphQLProvider: true,
      graphQLUrl: getTicketingUrl(),
    })
  }

  return (
    <span
      {...{
        ...attributes,
        role: "button",
        tabIndex: 0,
        className: "btn-link",
        onClick,
      }}
    >
      {children}
    </span>
  )
}
