export const initialStatusTabState = tabName => ({
  tabName,
  nodes: [],
  sortBy: "displayName",
  sortDirection: "ASC",
  filterText: "",
  selected: {
    rows: {},
    length: 0,
  },
})

export const initialState = () => ({
  clientId: null,
  activeTab: "security",
  general: {
    mode: "AUTOMATIC",
  },
  pending: initialStatusTabState("pending"),
  approved: initialStatusTabState("approved"),
  rejected: initialStatusTabState("rejected"),
})
