import { compose, concat, either, map, reject, take, whereEq } from "ramda"

const MAXIMUM_NUMBER_OF_RECENT_TABS = 10

const tabs = (state = [], action) => {
  switch (action.type) {
    case "ADD_RECENT_TAB": {
      const {
        tab: {
          type,
          item: { id, name, nodeClass, ...rest },
        },
      } = action

      return compose(
        take(MAXIMUM_NUMBER_OF_RECENT_TABS),
        concat([
          {
            id,
            type,
            name,
            nodeClass,
            ...rest,
          },
        ]),
        reject(whereEq({ type, id })),
      )(state)
    }
    case "SET_RECENT_TABS":
      return action.tabs
    case "REMOVE_RECENT_TAB": {
      const { type, key = null, id } = action.tab
      return reject(
        either(whereEq({ type, id }), item => type === item.type && item[key] === id),
        state,
      )
    }
    case "REMOVE_RECENT_TAB_BY_KEY": {
      const { type, key, value } = action.tab
      return reject(item => type === item.type && item[key] === value, state)
    }
    case "UPDATE_RECENT_TAB": {
      const { id, type, payload } = action.tab
      return map(tab => {
        if (tab.id === id && tab.type === type) {
          return { ...tab, ...payload }
        }
        return tab
      }, state)
    }
    case "REMOVE_ALL_RECENT_TABS":
      return []
    default:
      return state
  }
}

export default tabs
