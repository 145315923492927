import styled from "@emotion/styled"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { TreeView } from "@lexical/react/LexicalTreeView"

import tokens from "@ninjaone/tokens"

const StyledTreeViewContainer = styled.div`
  pre {
    line-height: 1.1;
    background-color: ${({ theme }) => theme.colorBackgroundWidget};
    color: ${({ theme }) => theme.colorTextStrong};
    margin: 0;
    padding: ${tokens.spacing[2]};
    font-size: ${tokens.typography.fontSize.bodyXs};
    overflow: auto;
    max-height: 400px;
  }

  .tree-view-output {
    display: block;
    background-color: ${({ theme }) => theme.colorBackgroundWidget};
    color: ${({ theme }) => theme.colorTextStrong};
    padding: 0;
    font-size: ${tokens.typography.fontSize.bodyXs};
    margin: ${tokens.spacing[2]} auto;
    position: relative;
    overflow: hidden;
    border-radius: ${tokens.borderRadius[1]};
  }

  .debug-treetype-button {
    border: 0;
    padding: 0;
    font-size: ${tokens.typography.fontSize.bodyXs};
    top: ${tokens.spacing[2]};
    right: 85px;
    position: absolute;
    background: none;
    color: ${({ theme }) => theme.colorTextStrong};
  }

  .debug-treetype-button:hover {
    text-decoration: underline;
  }

  .debug-timetravel-panel {
    overflow: hidden;
    padding: 0 0 ${tokens.spacing[2]} 0;
    margin: auto;
    display: flex;
  }

  .debug-timetravel-button {
    border: 0;
    padding: 0;
    font-size: ${tokens.typography.fontSize.bodyXs};
    top: ${tokens.spacing[2]};
    right: ${tokens.spacing[4]};
    position: absolute;
    background: none;
    color: ${({ theme }) => theme.colorTextStrong};
  }

  .debug-timetravel-button:hover {
    text-decoration: underline;
  }

  .debug-timetravel-panel-slider {
    padding: 0;
    flex: 8;
  }

  .debug-timetravel-panel-button {
    padding: 0;
    border: 0;
    background: none;
    flex: 1;
    color: ${({ theme }) => theme.colorTextStrong};
    font-size: ${tokens.typography.fontSize.bodyXs};
  }

  .debug-timetravel-panel-button:hover {
    text-decoration: underline;
  }
`

export const TreeViewPlugin = () => {
  const [editor] = useLexicalComposerContext()
  return (
    <StyledTreeViewContainer>
      <TreeView
        viewClassName="tree-view-output"
        treeTypeButtonClassName="debug-treetype-button"
        timeTravelPanelClassName="debug-timetravel-panel"
        timeTravelButtonClassName="debug-timetravel-button"
        timeTravelPanelSliderClassName="debug-timetravel-panel-slider"
        timeTravelPanelButtonClassName="debug-timetravel-panel-button"
        editor={editor}
      />
    </StyledTreeViewContainer>
  )
}
