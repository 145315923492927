export default function selectedCriterion(state = null, action) {
  switch (action.type) {
    case "CREATE_CRITERION":
      return action.criterion.id
    case "SET_SELECTED_CRITERION":
      return action.selectedCriterion.id
    case "CLEAR_SELECTED_CRITERION":
      return null
    default:
      return state
  }
}
