import { Modal, Text } from "@ninjaone/components"
import { localizationKey } from "js/includes/common/utils"
import GetStarted from "js/includes/configuration/integrations/backup/lockhart/GetStarted"

export default function GetStartedModal({ unmount }) {
  return (
    <Modal
      titleGroup={{
        titleToken: localizationKey("Backup"),
        DescriptionComponent: () => (
          <Text token={localizationKey("Get started in a few easy steps")} color="inputText" bold />
        ),
      }}
      size="lg"
      unmount={unmount}
      cancelable={false}
      scrollable
    >
      <GetStarted allowScroll={false} showTitle={false} />
    </Modal>
  )
}
