export default function ticketTemplateEditorValues(state = null, action) {
  switch (action.type) {
    case "SET_TICKET_TEMPLATE_EDITOR_VALUES":
      return action.editorValues
    case "CLEAR_TICKET_TEMPLATE_EDITOR_VALUES":
      return null
    default:
      return state
  }
}
