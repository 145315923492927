import { combineReducers } from "redux"
import subTab from "js/state/reducers/common/subTab"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import osPatches from "./osPatches"
import loading from "js/state/reducers/common/loading"
import selectedListItems from "js/state/reducers/common/selectedListItems"
import confirmModalOptions from "./confirmModalOptions"
import processingPolicy from "./processingPolicy"
import osPatchesFilter from "./osPatchesFilter"

export default combineReducers({
  osPatches,
  selectedPatchType: createNamedWrapperReducer(subTab, "osPatches", "pending"),
  loadingOsPatches: createNamedWrapperReducer(loading, "loadingOsPatches"),
  selectedOsPatches: createNamedWrapperReducer(selectedListItems, "osPatches"),
  confirmModalOptions,
  processingDevices: createNamedWrapperReducer(loading, "osPatches"),
  processingPolicy,
  osPatchesFilter,
})
