const sortBy = (state = "", action) => {
  switch (action.type) {
    case "SORT":
      return action.sortBy
    default:
      return state
  }
}

export default sortBy
