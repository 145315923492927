import { useCallback, useEffect } from "react"

export function useKeydown(keyDownFilter, keyDownHandler) {
  const handleKeydown = useCallback(
    e => {
      if (keyDownFilter(e)) {
        keyDownHandler(e)
      }
    },
    [keyDownFilter, keyDownHandler],
  )

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown)
    return () => document.removeEventListener("keydown", handleKeydown)
  }, [handleKeydown])
}
