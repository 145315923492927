import { ninjaReportError, fetchJson } from "js/includes/common/utils"

export const getSignedUrlFromContentId = async contentId => {
  try {
    return await fetchJson(`/attachments/attachment/content-id/${contentId}/signed-url`)
  } catch (error) {
    ninjaReportError(error)
    return {}
  }
}

export const getSignedUrlFromAttachmentId = attachmentId =>
  fetchJson(`/attachments/attachment/${attachmentId}/signed-url`)
