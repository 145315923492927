import React from "react"
import Select from "react-select"
import { propEq } from "ramda"
import { Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { localizationKey, localizedF } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"

const enabledOptions = [
  { value: false, label: localizedF("Disable") },
  { value: true, label: localizedF("Enable") },
]
export default function RemovableMedia({ onChange, values: { enabled } }) {
  return (
    <>
      <Box margin={sizer(2, 0, 1)}>
        <Text bold size="sm" token={localizationKey("State")} />
      </Box>
      <Select
        getOptionLabel={({ label }) => label()}
        options={enabledOptions}
        id="mediaOptions"
        value={enabledOptions.find(propEq("value", enabled))}
        onChange={selection => onChange("enabled", selection.value)}
      />
    </>
  )
}
