import { curry, filter, includes } from "ramda"
import { localizationKey } from "js/includes/common/utils"
import { ValueEditor, ValueSelector, RuleGroup } from "./components"
import ButtonLink from "../ButtonLink"
import CloseButton from "../CloseButton"

export const defaultTranslations = {
  removeRule: {
    labelToken: localizationKey("Remove"),
  },
  addRule: {
    labelToken: localizationKey("Add"),
  },
}

export const filterOperators = curry(values => filter(o => includes(o.value, values), defaultOperators))

export const defaultOperators = [
  { value: "contains", labelToken: localizationKey("contains") },
  { value: "does_not_contain", labelToken: localizationKey("does not contain") },
  { value: "contains_any", labelToken: localizationKey("contains any") },
  { value: "contains_none", labelToken: localizationKey("contains none") },
  { value: "equals", labelToken: localizationKey("equals") },
  { value: "not_equals", labelToken: localizationKey("does not equal") },
  { value: "parent_equals", labelToken: localizationKey("parent equals") },
  { value: "parent_not_equals", labelToken: localizationKey("parent does not equal") },
  { value: "present", labelToken: localizationKey("present") },
  { value: "not_present", labelToken: localizationKey("not present") },
  { value: "hours_since:greater_or_equal_than", labelToken: localizationKey("greater than") },
  { value: "hours_since:less_or_equal_than", labelToken: localizationKey("less than") },
  { value: "hours_since:is", labelToken: localizationKey("is") },
  { value: "add", labelToken: localizationKey("Add") },
  { value: "remove", labelToken: localizationKey("Remove") },
  { value: "current_user", labelToken: localizationKey("Current User") },
  { value: "requester", labelToken: localizationKey("Requester") },
  { value: "assignee", labelToken: localizationKey("Assignee") },
  { value: "changed", labelToken: localizationKey("changed") },
  { value: "changed_to", labelToken: localizationKey("changed to") },
  { value: "in", labelToken: localizationKey("Within") },
  { value: "not_in", labelToken: localizationKey("Outside") },
  { value: "greater_than", labelToken: localizationKey("greater than") },
  { value: "less_than", labelToken: localizationKey("less than") },
  { value: "is", labelToken: localizationKey("is") },
  { value: "greater_or_equal_than", labelToken: localizationKey("greater than or equal to") },
  { value: "less_or_equal_than", labelToken: localizationKey("less than or equal to") },
]

export const defaultControlElements = {
  addRuleAction: ButtonLink,
  removeRuleAction: CloseButton,
  fieldSelector: ValueSelector,
  operatorSelector: ValueSelector,
  valueEditor: ValueEditor,
  ruleGroup: RuleGroup,
}
