import { combineReducers } from "redux"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import sortBy from "js/state/reducers/common/tableSorting/sortBy"
import sortDirection from "js/state/reducers/common/tableSorting/sortDirection"
import contacts from "./contacts"

export default combineReducers({
  contacts,
  contactListSort: combineReducers({
    sortBy: createNamedWrapperReducer(sortBy, "contactListSort", "name"),
    sortDirection: createNamedWrapperReducer(sortDirection, "contactListSort"),
  }),
})
