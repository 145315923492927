import { combineReducers } from "redux"
import scripting from "./scripting/rootReducer"
import backups from "./backups"
import editor from "./editor"
import patchOverrides from "./patchOverrides/rootReducer"

export default combineReducers({
  ...editor,
  scripting,
  backups,
  patchOverrides,
})
