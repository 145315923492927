import { combineReducers } from "redux"
import configurations from "./configurations"
import editor from "./editor"
import timers from "./timers"
import boards from "./boards"
import forms from "./forms"
import smtpHealth from "./smtpHealth"
import status from "./status"

export default combineReducers({
  configurations,
  editor,
  timers,
  boards,
  forms,
  smtpHealth,
  status,
})
