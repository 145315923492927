import { combineReducers } from "redux"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import visibility from "js/state/reducers/common/visibility"
import sortBy from "js/state/reducers/common/tableSorting/sortBy"
import sortDirection from "js/state/reducers/common/tableSorting/sortDirection"
import configurationTypeMappings from "../configurations/configurationTypeMappings"
import currentConfigurationTypeMapping from "../configurations/configurationTypeMapping"
import configurationTypeModalEditorMode from "../configurations/configurationTypeModalEditorMode"
import nodeRoleConfigurationTypeMappingModalEditorTitle from "../configurations/nodeRoleConfigurationTypeMappingModalEditorTitle"
import nodeRoleConfigurationTypeMappingModalEditorMode from "../configurations/nodeRoleConfigurationTypeMappingModalEditorMode"
import currentNodeRoleConfigurationTypeMapping from "../configurations/nodeRoleConfigurationTypeMapping"
import configurationTypeList from "../configurations/configurationTypeList"
import configurationStatusList from "../configurations/configurationStatusList"

export default combineReducers({
  configurationTypeList,
  configurationStatusList,
  configurationTypeMappings,
  configurationTypeMappingSort: combineReducers({
    sortBy: createNamedWrapperReducer(sortBy, "CWConfigurationTypeMappingSort", "name"),
    sortDirection: createNamedWrapperReducer(sortDirection, "CWConfigurationTypeMappingSort"),
  }),
  configurationTypeMappingModalVisibility: createNamedWrapperReducer(
    visibility,
    "CWConfigurationTypeMappingsModal",
    false,
  ),
  configurationTypeModalEditorMode,
  currentConfigurationTypeMapping,
  nodeRoleConfigurationTypeMappingsSort: combineReducers({
    sortBy: createNamedWrapperReducer(sortBy, "CWNodeRoleConfigurationTypeMappingsSort", "nodeRoleName"),
    sortDirection: createNamedWrapperReducer(sortDirection, "CWNodeRoleConfigurationTypeMappingsSort"),
  }),
  nodeRoleConfigurationTypeMappingModalVisibility: createNamedWrapperReducer(
    visibility,
    "CWNodeRoleConfigurationTypeMappingModal",
    false,
  ),
  nodeRoleConfigurationTypeMappingModalEditorTitle,
  nodeRoleConfigurationTypeMappingModalEditorMode,
  currentNodeRoleConfigurationTypeMapping,
})
