import { combineReducers } from "redux"
import events from "./events"
import eventScope from "./eventScope"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import visibility from "js/state/reducers/common/visibility"

export default combineReducers({
  events,
  eventScope,
  eventPopoverVisibility: createNamedWrapperReducer(visibility, "eventPopoverVisibility", false),
})
