const initialState = "last_day"

export default function eventScope(state = initialState, action) {
  switch (action.type) {
    case "SET_EVENT_SCOPE":
      return action.scope
    case "RESET_EVENT_SCOPE":
      return initialState
    default:
      return state
  }
}
