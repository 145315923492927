const showConfirmUninstallSoftwareModal = (state = false, action) => {
  switch (action.type) {
    case "SET_CONFIRM_UNINSTALL_SOFTWARE_MODAL":
      return action.show
    default:
      return state
  }
}

export default showConfirmUninstallSoftwareModal
