import { useCallback, useRef, useState } from "react"
import PropTypes from "prop-types"
import { adjust, append, assoc, compose, dissoc, filter } from "ramda"
import { localized } from "js/includes/common/utils"
import BackupFileFolderBrowser from "js/includes/components/Backup/BackupFileFolderBrowser"
import { getPlansHandler } from "./plansHandler"
import { getVolumesHandler } from "./volumesHandler"
import { getFileFoldersHandler } from "./fileFoldersHandler"
import { getFolderNavigationHandler } from "./folderNavigationHandler"
import { getRevisionsHandler } from "./revisionsHandler"
import { getMountedContentHandler } from "./mountedContentHandler"
import { getMountedContentNavigationHandler } from "./mountedContentNavigationHandler"
import { getFileRevisionsHandler } from "./fileRevisionsHandler"
import BackupManagerSearch from "./BackupManagerSearch"

const navigationHandlerList = {
  image: getVolumesHandler,
  arrowImage: getVolumesHandler,
  volume: getRevisionsHandler,
  fileFolder: getFileFoldersHandler,
  folder: getFolderNavigationHandler,
  revisions: getMountedContentHandler,
  mountedFolder: getMountedContentNavigationHandler,
  mountedFile: getMountedContentNavigationHandler,
  file: getFileRevisionsHandler,
  plans: getPlansHandler,
}

const navigationHandlerResolver = handlerProps => {
  const { currentDir: { type = "plans" } = {} } = handlerProps
  const handler = navigationHandlerList[type] ?? navigationHandlerList["plans"]
  return { type, ...handler(handlerProps) }
}

const removePreviousSearches = history =>
  history.flatMap(historyNode => {
    const { isSearchInProgress, isSearchResults } = historyNode

    if (isSearchInProgress) {
      return dissoc("isSearchInProgress", historyNode)
    }

    return !isSearchResults ? historyNode : []
  })

const setCustomHistory = ({ history, targetDir, searchValue }) => {
  if (targetDir.type === "revisions") {
    return history.concat({
      ...targetDir,
      name: `${targetDir.name} ${targetDir.volumeLetter}`,
    })
  }

  const filteredHistory = removePreviousSearches(history)

  if (searchValue) {
    return compose(
      append({
        ...targetDir,
        name: localized("Search Results"),
        isSearchResults: true,
      }),
      adjust(-1, assoc("isSearchInProgress", true)),
    )(filteredHistory)
  }

  if (targetDir.searchPath) {
    return filteredHistory.concat({
      ...targetDir,
      name: targetDir.searchPath,
    })
  }

  const currentDir = history.at(-1)
  if (currentDir?.isSearchResults) {
    return filteredHistory
  }

  return filteredHistory.concat(targetDir)
}

const BackupManager = ({ node, filterAction = () => true, filterColumn = () => true, isFileFolderBackupAllowed }) => {
  const [handler, setHandler] = useState(() => navigationHandlerResolver({ node, isFileFolderBackupAllowed }))
  const searchRef = useRef()

  const isSearchable = !!handler.searchable

  const onFetchData = useCallback(
    async ({ currentDir, fetchingParams, separator, isFromBreadcrumb, searchValue }) => {
      const { fetchData, onError } = navigationHandlerResolver({
        currentDir,
        node,
        isFileFolderBackupAllowed,
        separator,
      })
      try {
        return await fetchData({
          currentDir,
          fetchingParams: { ...fetchingParams, globalFilter: searchValue },
          separator,
          node,
          isFileFolderBackupAllowed,
          isFromBreadcrumb,
        })
      } catch (error) {
        onError?.(error, currentDir)
      }
    },
    [isFileFolderBackupAllowed, node],
  )
  return (
    <BackupFileFolderBrowser
      {...{
        tableId: `backup-manage-${handler.type}`,
        limit: 250,
        node,
        columns: filter(filterColumn, handler.columns ?? []),
        primaryActions: filter(filterAction, handler.actions ?? []),
        secondaryGlobalActions: filter(filterAction, handler.secondaryGlobalActions ?? []),
        getBrowseRowAction: handler.getBrowseRowAction,
        onFetchData,
        onSuccessfulBrowse: ({ targetDir, setHistory, separator, searchValue }) => {
          setHandler(
            navigationHandlerResolver({
              currentDir: targetDir,
              node,
              isFileFolderBackupAllowed,
              separator,
              fetchingParams: { globalFilter: searchValue },
            }),
          )
          !searchValue && searchRef.current?.clearSearch()
        },

        searchable: isSearchable,
        ...(isSearchable && {
          SearchBar: searchProps => <BackupManagerSearch ref={searchRef} {...searchProps} />,
        }),
        setCustomHistory,
        hideCheckboxes: handler.type === "volume",
      }}
    />
  )
}

BackupManager.propTypes = {
  node: PropTypes.object.isRequired,
  filterAction: PropTypes.func,
  filterColumn: PropTypes.func,
  isFileFolderBackupAllowed: PropTypes.bool,
}

BackupManager.defaultProps = {
  filterAction: () => true,
  filterColumn: () => true,
}

export default BackupManager
