import qs from "qs"
import { fetchJson, localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"
import showMountImageModal from "js/includes/components/Backup/Modals/MountImageModal"
import { getMountedContentActions } from "./actions"
import { getMountedContentColumns } from "./columns"

const onBeforeFetchData = async folder => {
  const { planId: imagePlanId, id: revisionId } = folder
  if (!imagePlanId || !revisionId) return false

  return await showMountImageModal(folder)
}

const fetchData = async ({
  currentDir,
  fetchingParams: { pageIndex, pageSize },
  separator,
  node,
  isFromBreadcrumb,
}) => {
  if (!isFromBreadcrumb) {
    const shouldFetch = await onBeforeFetchData(currentDir)
    if (!shouldFetch) return
  }

  const {
    planName,
    planId: imagePlanId,
    planUuid,
    id: revisionId,
    volumeLabel,
    path: parentPath,
    filteredVolumeName,
    planDeleted,
  } = currentDir

  const { results, totalElements, totalPages } = await fetchJson(
    `/backup/lockhart/image/v2/nodes/${node.id}/plans/${imagePlanId}/revisions/${revisionId}/content?${qs.stringify({
      folder: "/",
      start: pageIndex * pageSize,
      count: pageSize,
    })}`,
  )

  const content = results.map(({ name, type, size, modified }) => {
    const isFolder = type === "FOLDER"

    return {
      node,
      imagePlanId,
      planUuid,
      revisionId,
      planName,
      parentPath,
      folder: isFolder,
      actualSize: size,
      type: isFolder ? "mountedFolder" : "mountedFile",
      name,
      modified,
      size,
      path: `/${name}`,
      downloadPath: volumeLabel + separator + name,
      fullPath: filteredVolumeName + separator + name,
      fullParentPath: filteredVolumeName,
      planDeleted,
    }
  })

  return {
    data: content,
    pageCount: totalPages,
    totalCount: totalElements,
  }
}

const onError = (error, { name }) =>
  reportErrorAndShowMessage(error, localizationKey("There was an error fetching content for revision {{folderName}}"), {
    folderName: name,
  })

export const getMountedContentHandler = () => ({
  columns: getMountedContentColumns(),
  actions: getMountedContentActions(),
  fetchData,
  onError,
})
