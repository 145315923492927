import { map } from "ramda"
import { CopyIconLightSvg } from "@ninjaone/icons"
import { HoverDropdown, HoverDropdownItem } from "@ninjaone/components"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"
import Tooltip from "../../Tooltip"
import { clipboardWriteText } from "@ninjaone/webapp/src/js/includes/common/utils"

const CopyableDropdown = ({ copyableDropdownOptions }) => {
  return (
    <HoverDropdown
      buttonRenderer={() => (
        <button className="data-table-copy-to-clipboard" data-visible-on-row-hover onClick={e => e.stopPropagation()}>
          <CopyIconLightSvg />
        </button>
      )}
      dropdownRenderer={() =>
        map(option => (
          <HoverDropdownItem
            key={option.token}
            token={option.token}
            onClick={async () => {
              const value = await option.getAsyncValue?.()
              clipboardWriteText(value)
            }}
          />
        ))(copyableDropdownOptions)
      }
      dropdownMargin
      alignRight
    />
  )
}

const CopyToClipboard = ({ value, copyableDropdownOptions }) => {
  return copyableDropdownOptions?.length ? (
    <CopyableDropdown copyableDropdownOptions={copyableDropdownOptions} />
  ) : (
    <Tooltip label={localized("general.copyToClipboard")}>
      <button
        className="data-table-copy-to-clipboard"
        data-visible-on-row-hover
        data-clipboard-text={value}
        onClick={() => clipboardWriteText(value)}
      >
        <CopyIconLightSvg />
      </button>
    </Tooltip>
  )
}

export default CopyToClipboard
