export const defaultTimerSettings = {
  enabled: false,
  mode: null,
}

const initialState = {
  enabled: false,
  fromEmailDomain: null,
  useFromEmailDomain: false,
  timerSettings: defaultTimerSettings,
  attachmentSettings: {
    canAttachFiles: false,
    requireAuthToView: false,
  },
  emailNotificationSettings: {
    onAssigned: {
      technician: true,
    },
    onRemoved: {
      technician: true,
    },
    onCCAdded: {
      technician: true,
      endUserOrContact: true,
    },
    onTicketCreated: {
      onEditor: {
        technician: true,
        endUserOrContact: true,
      },
      onEmail: {
        technician: true,
        endUserOrContact: true,
      },
    },
    onTicketUpdated: {
      toRequester: {
        technician: true,
        endUserOrContact: true,
      },
      toCC: {
        technician: true,
        endUserOrContact: true,
      },
      toAssignee: {
        technician: true,
      },
    },
    onPrivateComment: {
      toRequester: {
        technician: true,
      },
      toCC: {
        technician: true,
      },
      toAssignee: {
        technician: true,
      },
    },
    onPublicComment: {
      toRequester: {
        technician: true,
        endUserOrContact: false,
      },
      toCC: {
        technician: true,
        endUserOrContact: true,
      },
      toAssignee: {
        technician: true,
      },
    },
  },
  businessHours: {
    twentyFourSeven: true,
    days: [],
  },
}

export default function configurations(state = initialState, action) {
  switch (action.type) {
    case "TICKETING_SET_CONFIGURATIONS":
      return { ...state, ...action.payload }
    default:
      return state
  }
}
