const initialState = window.localStorage.getItem("ninja:dashboard.sortOrgItemsBy") || "status"

export default function sortOrgItemsBy(state = initialState, action) {
  switch (action.type) {
    case "SET_SORT_ORG_ITEMS_BY":
      return action.sortItemsBy
    default:
      return state
  }
}
