import React, { memo } from "react"
import { FormControl } from "react-bootstrap"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import moment from "moment"
import { compose, defaultTo, last, map, omit, pluck } from "ramda"

import { getDateTimeLocale, getSeconds, isEKey } from "js/includes/common/utils"
import { StyledDateTime } from "js/includes/components/Styled/Form"

import { flattenOptions } from "../utils"

const omitLabel = omit(["label"])
const omitLabelOnLastElement = compose(omitLabel, last, defaultTo([]))
const omitLabelsOnList = compose(map(omitLabel), defaultTo([]))

const ValueEditor = memo(({ operator, value, handleOnChange, type, inputType, values, metaData, ariaLabel }) => {
  if (["present", "not_present", "changed"].includes(operator)) return null

  if (typeof type === "function") return type(value, values, handleOnChange, metaData)

  switch (type) {
    case "select": {
      const flattenedOptions = flattenOptions(values)

      return (
        <Select
          aria-label={ariaLabel}
          menuPlacement="auto"
          className="flex-full"
          options={values}
          onChange={selected => handleOnChange(selected.value)}
          value={flattenedOptions.find(v => v.value === value) || null}
          menuPortalTarget={document.body}
          getOptionLabel={({ label, labelText }) => label || labelText}
          styles={{
            menuPortal: styles => ({
              ...styles,
              zIndex: 9999,
            }),
          }}
        />
      )
    }
    case "multi-select": {
      const flattenedOptions = flattenOptions(values)

      return (
        <Select
          aria-label={ariaLabel}
          menuPlacement="auto"
          className="flex-full"
          isMulti
          options={values}
          value={flattenedOptions?.filter(v => value && value.split(",").includes(v.value.toString())) ?? []}
          onChange={selected => handleOnChange(selected?.length ? pluck("value", selected).join(",") : "")}
          menuPortalTarget={document.body}
          getOptionLabel={({ label, labelText }) => label || labelText}
          styles={{
            menuPortal: styles => ({
              ...styles,
              zIndex: 9999,
            }),
          }}
        />
      )
    }
    case "multi-creatable-select": {
      const flattenedOptions = flattenOptions(values)

      function handleChange(upcomingChange, { action }) {
        switch (action) {
          case "select-option":
          case "create-option":
            handleOnChange([...value, omitLabelOnLastElement(upcomingChange)])
            break
          case "remove-value":
            handleOnChange(omitLabelsOnList(upcomingChange))
            break
          case "clear":
            handleOnChange([])
            break
          default:
            break
        }
      }

      return (
        <CreatableSelect
          aria-label={ariaLabel}
          menuPlacement="auto"
          className="flex-full"
          isMulti
          options={values}
          value={value.map(v => ({
            value: v.value,
            label: v.__isNew__ ? v.value : flattenedOptions.find(o => o.value === v.value)?.label,
            __isNew__: v.__isNew__,
          }))}
          onChange={handleChange}
          menuPortalTarget={document.body}
          getOptionLabel={({ label, labelText }) => label || labelText}
          styles={{
            menuPortal: styles => ({
              ...styles,
              zIndex: 9999,
            }),
          }}
        />
      )
    }
    case "radio":
      return (
        <span aria-label={ariaLabel}>
          {values.map(({ name, label }) => (
            <label key={name}>
              <input
                type="radio"
                value={name}
                checked={value === name}
                onChange={e => handleOnChange(e.target.value)}
              />
              {label}
            </label>
          ))}
        </span>
      )

    case "date":
      return (
        <StyledDateTime
          closeOnSelect
          timeFormat={false}
          value={value ? moment.unix(value) : null}
          {...(ariaLabel && { inputProps: { "aria-label": ariaLabel } })}
          onChange={datetime => {
            const date = getDateTimeLocale(datetime)
            if (moment.isMoment(datetime) && date.isValid()) {
              handleOnChange(getSeconds(datetime.valueOf()))
            }
          }}
        />
      )

    default:
      return (
        <FormControl
          aria-label={ariaLabel}
          type={inputType || "text"}
          value={value}
          onChange={e => handleOnChange(e.target.value)}
          onKeyDown={event => {
            if (inputType === "number" && isEKey(event)) {
              event.preventDefault()
            }
          }}
        />
      )
  }
})

export default ValueEditor
