import { memo, useCallback } from "react"
import { connect } from "react-redux"

import { Select, Stepper } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { Box, Flex } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils"
import { getTimeOptions } from "js/includes/ticketing/common/TicketEditorFields/options"

import { mapStatusOptions } from "./TicketCreate"

export const MAX_TIME_ENTRY = {
  DAYS: 41,
  HOURS: 999,
  MINUTES: 59999,
}

const ConditionReset = memo(({ readonly, values, onChange, validation, statusList }) => {
  const { resetStatus, resetTimeUnit, resetTimeValue } = values
  const statusOptions = mapStatusOptions(statusList)

  const handleResetTimeUnitChange = useCallback(
    value => {
      if (resetTimeValue > MAX_TIME_ENTRY[value]) {
        onChange({ resetTimeUnit: value, resetTimeValue: MAX_TIME_ENTRY[value] })
      } else {
        onChange({ resetTimeUnit: value })
      }
    },
    [onChange, resetTimeValue],
  )

  return (
    <Flex flexDirection="column" gap={tokens.spacing[2]} margin={`0 ${tokens.spacing[1]}`}>
      <Select
        labelId="condition-reset-reset-status"
        labelText={localized("Status")}
        value={resetStatus}
        onChange={value => onChange("resetStatus", value)}
        options={statusOptions}
        errorMessage={validation.message.resetStatus}
        required
        disabled={readonly}
      />

      <Flex gap={tokens.spacing[2]} justifyContent="space-between" alignItems="end">
        <Box minWidth={180}>
          <Stepper
            {...{
              labelText: localized("Add time entry"),
              disabled: readonly,
              min: 0,
              max: MAX_TIME_ENTRY[resetTimeUnit],
              onChange: value => onChange("resetTimeValue", value),
              required: false,
              useInlineLabel: false,
              value: resetTimeValue,
              error: validation.message.resetTimeValue,
            }}
          />
        </Box>
        <Box flex="1 0">
          <Select
            id="select-condition-reset-time"
            triggerAriaLabel={localized("Add time entry")}
            className="flex-full"
            value={resetTimeUnit}
            onChange={handleResetTimeUnitChange}
            options={getTimeOptions()}
            disabled={readonly}
          />
        </Box>
      </Flex>
    </Flex>
  )
})

export default connect(({ ticketing }) => ({ statusList: ticketing.status.list }))(ConditionReset)
