import { combineReducers } from "redux"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import visibility from "js/state/reducers/common/visibility"
import sortBy from "js/state/reducers/common/tableSorting/sortBy"
import sortDirection from "js/state/reducers/common/tableSorting/sortDirection"
import agreementTypeList from "./agreementTypeList"
import productList from "./productList"
import productMappings from "./productMappings"
import currentProductMapping from "./productMapping"
import productModalEditorMode from "./productModalEditorMode"
import nodeRoleProductMappingModalEditorTitle from "./nodeRoleProductMappingModalEditorTitle"
import nodeRoleProductMappingModalEditorMode from "./nodeRoleProductMappingModalEditorMode"
import currentNodeRoleProductMapping from "./nodeRoleProductMapping"
import productAgreementSync from "./sync"

export default combineReducers({
  agreementTypeList,
  productList,
  productMappings,
  productMappingSort: combineReducers({
    sortBy: createNamedWrapperReducer(sortBy, "CWProductMappingSort", "name"),
    sortDirection: createNamedWrapperReducer(sortDirection, "CWProductMappingSort"),
  }),
  productMappingModalVisibility: createNamedWrapperReducer(visibility, "CWProductMappingsModal", false),
  productModalEditorMode,
  currentProductMapping,
  nodeRoleProductMappingModalVisibility: createNamedWrapperReducer(visibility, "CWNodeRoleProductMappingModal", false),
  nodeRoleProductMappingModalEditorTitle,
  nodeRoleProductMappingModalEditorMode,
  currentNodeRoleProductMapping,
  productAgreementSync,
})
