import { compose, mergeRight, pluck, prop, reject } from "ramda"
import {
  requiredSyncFields,
  configurationQuestions,
} from "js/includes/configuration/psa/ConnectWise/settings/ConnectWiseConfigurationTypes/DeviceDataSyncAttributes/defaultAttributes"

const getDefaultSelectedValue = () =>
  compose(pluck("name"), reject(prop("required")))([...requiredSyncFields(), ...configurationQuestions()])

export const initialState = {
  deviceDataSyncAttributes: getDefaultSelectedValue(),
}

export default function settings(state = initialState, action) {
  switch (action.type) {
    case "SET_CW_SETTINGS":
      return action.payload
    case "SET_CW_DEVICE_DATA_SYNC_ATTRIBUTES":
      return action.attributes ? mergeRight(state, { deviceDataSyncAttributes: action.attributes }) : state
    default:
      return state
  }
}
