const mapping = (state = [], action) => {
  switch (action.type) {
    case "SET_MAPPING_DATA":
      return action.data
    default:
      return state
  }
}

export default mapping
