import { memo, useEffect } from "react"
import { connect } from "react-redux"
import { useQuery } from "urql"
import RuleEditor, { filterOperators } from "js/includes/components/RuleEditor"
import { localized, localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"
import {
  getTypeOptions,
  getSourceOptions,
  getSeverityOptions,
  getPriorityOptions,
  getCommentTypeOptions,
  getUpdatedByOptions,
} from "js/includes/ticketing/common/TicketEditorFields/options"
import { getStatusOptions } from "js/includes/ticketing/shared/utils/options"
import { getTags } from "js/includes/ticketing/graphql"
import { getTagValues } from "js/includes/configuration/integrations/ticketing/utils"
import { useSorted } from "js/includes/common/hooks"
import { isTicketStatus } from "js/includes/configuration/integrations/ticketing/ticketCustomStatus/common"
import { TimeSinceEditor, UserEditor, OrganizationEditor, StatusEditor, FormEditor, LocationEditor } from "./components"

const findAttribute = (attributes, field) => attributes.find(({ value }) => value === field)

const isMulti = operator => ["contains_any", "contains_none"].includes(operator)

function customTimeSinceEditorRenderer(value, _, handleOnChange) {
  return <TimeSinceEditor {...{ value, handleOnChange }} />
}

function customUserEditorRenderer({ valueSelectorKey, ...options }) {
  return function(value, _, handleOnChange, metaData) {
    return <UserEditor {...{ value, handleOnChange, metaData, valueSelectorKey, ...options }} />
  }
}

function customOrganizationEditorRenderer(value, _, handleOnChange, metaData) {
  return <OrganizationEditor {...{ value, handleOnChange, metaData }} />
}

function customLocationEditorRenderer(value, _, handleOnChange, metaData) {
  return <LocationEditor {...{ value, handleOnChange, metaData }} />
}

function getCustomFormEditorRenderer({ formOptions, operator }) {
  const isMultiValue = isMulti(operator)
  return function(value, _, handleOnChange, metaData) {
    // key is needed because SearchableDropDown breaks when isMulti changes (and there's an existing value set)
    return (
      <FormEditor
        {...{ key: `${isMultiValue}`, value, handleOnChange, metaData, formOptions, isMulti: isMultiValue }}
      />
    )
  }
}

function customStatusEditorRenderer(operator) {
  return function(value, _, handleOnChange) {
    return (
      <StatusEditor
        {...{ value, handleOnChange, parentOnly: ["parent_equals", "parent_not_equals"].includes(operator) }}
      />
    )
  }
}

const getActionOptions = () => [
  {
    value: "INSERT",
    label: localized("Ticket Created"),
  },
  {
    value: "UPDATE",
    label: localized("Ticket Updated"),
  },
]

export const getCustomOperators = type => {
  switch (type) {
    case "MULTI_SELECT":
      return filterOperators(["present", "not_present", "contains_any", "contains_none"])
    case "DROPDOWN":
    case "CHECKBOX":
      return filterOperators(["present", "not_present", "equals", "not_equals"])
    case "TEXT":
    case "TEXT_MULTILINE":
      return filterOperators([
        "present",
        "not_present",
        "contains",
        "does_not_contain",
        "contains_any",
        "contains_none",
      ])
    case "NUMERIC":
    case "DECIMAL":
    case "DATE":
      return filterOperators([
        "present",
        "not_present",
        "greater_than",
        "less_than",
        "greater_or_equal_than",
        "less_or_equal_than",
      ])
    default:
      return null
  }
}

const getCustomValueEditorTypes = type => {
  switch (type) {
    case "MULTI_SELECT":
      return "multi-select"
    case "DROPDOWN":
    case "CHECKBOX":
      return "select"
    case "TEXT":
    case "TEXT_MULTILINE":
      return "text"
    case "NUMERIC":
    case "DECIMAL":
      return "number"
    case "DATE":
      return "date"
    default:
      return "text"
  }
}

const getCustomInputType = type => {
  switch (type) {
    case "NUMERIC":
    case "DECIMAL":
      return "number"
    case "DATE":
      return "date"
    default:
      return "text"
  }
}

const getCustomValues = customAttribute => {
  const { type, content } = customAttribute
  switch (type) {
    case "MULTI_SELECT":
    case "DROPDOWN":
      return content?.values?.map(({ id, name }) => ({ value: id, label: name }))
    case "CHECKBOX":
      return [
        { value: "true", label: localized("general.true") },
        { value: "false", label: localized("general.false") },
      ]
    default:
      return ""
  }
}

const Conditions = memo(
  ({ query, combinator, onQueryChange, type, statusList, attributes, fields, getValidations, formOptions }) => {
    const [{ error: tagsError, data: tagsData }] = useQuery({
      query: getTags,
    })

    useEffect(() => {
      if (tagsError) {
        reportErrorAndShowMessage(tagsError, localizationKey("Error fetching tags"))
      }
    }, [tagsError])

    const tags = useSorted(tagsData?.tags)

    const getValues = (field, operator) => {
      switch (field) {
        case "action":
          return getActionOptions()
        case "tags":
          return getTagValues(tags)
        case "form":
          return formOptions
        case "type":
          return getTypeOptions()
        case "status":
          return getStatusOptions(statusList)
        case "source":
          return getSourceOptions()
        case "severity":
          return getSeverityOptions()
        case "priority":
          return getPriorityOptions()
        case "business_hours":
          return null
        case "commentType":
          return getCommentTypeOptions()
        case "updated_by":
          return getUpdatedByOptions()
        default:
          const customAttribute = findAttribute(attributes, field)
          return !!customAttribute ? getCustomValues(customAttribute) : ""
      }
    }

    const getOperators = field => {
      if (isTicketStatus(field))
        return filterOperators([
          "hours_since:less_or_equal_than",
          "hours_since:greater_or_equal_than",
          "hours_since:is",
        ])

      switch (field) {
        case "status":
          return filterOperators([
            "equals",
            "not_equals",
            "parent_equals",
            "parent_not_equals",
            ...(type === "EVENT_BASED" ? ["changed", "changed_to"] : []),
          ])
        case "source":
        case "type":
        case "severity":
        case "priority":
          return filterOperators(["equals", "not_equals", ...(type === "EVENT_BASED" ? ["changed", "changed_to"] : [])])
        case "summary":
        case "description":
          return filterOperators(["contains", "does_not_contain", "contains_any", "contains_none"])
        case "tags":
        case "organization":
        case "location":
          return filterOperators(["contains_any", "contains_none", "present", "not_present"])
        case "form":
          return filterOperators(["equals", "not_equals", "contains_any", "contains_none"])
        case "requester":
        case "assignee":
          return filterOperators(["present", "not_present", "equals", "not_equals", "contains_any", "contains_none"])
        case "business_hours":
          return filterOperators(["in", "not_in"])
        case "action":
          return filterOperators(["equals", "not_equals"])
        case "commentType":
          return filterOperators(["equals"])
        case "updated_by":
          return filterOperators(["contains_any"])
        case "ticket_created":
        case "ticket_changed":
        case "assigned":
        case "customer":
        case "technician":
          return filterOperators([
            "hours_since:less_or_equal_than",
            "hours_since:greater_or_equal_than",
            "hours_since:is",
          ])
        default:
          const customAttribute = findAttribute(attributes, field)
          return !!customAttribute ? getCustomOperators(customAttribute.type) : null
      }
    }

    const getValueEditorType = (field, operator) => {
      if (isTicketStatus(field)) return customTimeSinceEditorRenderer

      switch (field) {
        case "assignee":
          return customUserEditorRenderer({
            valueSelectorKey: "id",
            userType: "TECHNICIAN",
            showCurrentUserOption: type !== "TIME_BASED",
            isMulti: isMulti(operator),
          })
        case "requester":
          return customUserEditorRenderer({
            valueSelectorKey: "uid",
            showCurrentUserOption: type !== "TIME_BASED",
            isMulti: isMulti(operator),
          })
        case "status":
          return customStatusEditorRenderer(operator)
        case "source":
        case "type":
        case "severity":
        case "priority":
        case "action":
        case "commentType":
          return "select"
        case "form":
          return getCustomFormEditorRenderer({ formOptions, operator })
        case "tags":
        case "updated_by":
          return "multi-select"
        case "organization":
          return customOrganizationEditorRenderer
        case "location":
          return customLocationEditorRenderer
        case "business_hours":
          return () => null
        case "ticket_created":
        case "ticket_changed":
        case "assigned":
        case "customer":
        case "technician":
          return customTimeSinceEditorRenderer
        default:
          const customAttribute = findAttribute(attributes, field)
          return !!customAttribute ? getCustomValueEditorTypes(customAttribute.type) : "text"
      }
    }

    const getInputType = field => {
      const customAttribute = findAttribute(attributes, field)
      return !!customAttribute ? getCustomInputType(customAttribute.type) : "text"
    }

    return (
      <RuleEditor
        {...{
          query,
          fields,
          getOperators,
          getValueEditorType,
          getValues,
          combinator,
          onQueryChange,
          getInputType,
          getValidations,
          resetOnFieldChange: true,
        }}
      />
    )
  },
)

export default connect(({ ticketing }) => ({ statusList: ticketing.status.list }))(Conditions)
