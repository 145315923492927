export default function alertMessages(state = [], action) {
  switch (action.type) {
    case "ADD_ALERT":
      return state.concat(action.alert)
    case "REMOVE_ALERT":
      return state.filter(alert => alert.id !== action.alertId)
    case "REMOVE_ALL_ALERTS":
      return []
    default:
      return state
  }
}
