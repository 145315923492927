import styled from "@emotion/styled"
import { sizer } from "@ninjaone/utils"
import { StyledButton } from "./Styled"

const StyledIconButton = styled(StyledButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${sizer(8)};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ active, theme }) =>
    active
      ? `        
        background-color: ${theme.color.primary["005"]};
        color: ${theme.color.action};
     `
      : `color: ${theme.color.text};`}
`

export const IconButton = ({ children, onClick, className = "", active = false }) => {
  return <StyledIconButton {...{ active, className, onClick }}>{children}</StyledIconButton>
}
