import { map, when, propEq, mergeDeepLeft, reject } from "ramda"

const tabs = (state = [], action) => {
  switch (action.type) {
    case "SET_BOARD_TABS":
      return action.tabs
    case "ADD_BOARD_TAB":
      return [...state, action.tab]
    case "UPDATE_BOARD_TAB":
      return map(when(propEq("id", action.tab.id), mergeDeepLeft(action.tab)), state)
    case "DELETE_BOARD_TAB":
      return reject(propEq("id", action.tab.id), state)
    default:
      return state
  }
}

export default tabs
