import { append, remove, insert } from "ramda"

export const initialState = []

export default function locations(state = initialState, action) {
  switch (action.type) {
    case "SET_LOCATIONS":
      return action.payload
    case "ADD_LOCATION":
      return append(action.newLocation, state)
    case "REMOVE_LOCATION":
      const deleteIndex = state.findIndex(loc => loc.id === action.id)
      return remove(deleteIndex, 1, state)
    case "EDIT_LOCATION":
      const editIndex = state.findIndex(loc => loc.id === action.location.id)
      const tempLocations = remove(editIndex, 1, state)
      return insert(editIndex, action.location, tempLocations)
    default:
      return state
  }
}
