import { combineReducers } from "redux"

import checklists from "js/state/reducers/documentation/checklists"
import knowledgeBase from "js/state/reducers/documentation/knowledgeBase"
import delegate from "./documentationDelegateNodes"
import appsAndServices from "./appsAndServices"

export default combineReducers({
  checklists,
  delegate,
  knowledgeBase,
  appsAndServices,
})
