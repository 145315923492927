const runningSearch = (state = null, action) => {
  switch (action.type) {
    case "SET_PERFORMING_DEVICE_ACTION":
      return action.performingDeviceAction
    default:
      return state
  }
}

export default runningSearch
