import { pluck } from "ramda"

const selectedListItems = (state = [], action) => {
  switch (action.type) {
    case "SELECT_ITEM":
      return [...state, action.item.id]
    case "CLEAR_ITEM":
      return state.filter(d => d !== action.item.id)
    case "SELECT_ALL_ITEMS":
      return pluck("id", action.items)
    case "CLEAR_ALL_ITEMS":
      return []
    case "SELECT_THIS_ITEM_ONLY":
      const itemId = action.item.id
      return state.length === 1 && state.includes(itemId) ? [] : [itemId]
    default:
      return state
  }
}

export default selectedListItems
