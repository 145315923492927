import { buildNonNativeScripts } from "../scripts"

export default function templateLibraryScripts(state = [], action) {
  switch (action.type) {
    case "SET_TEMPLATE_LIBRARY_SCRIPTS":
      const { scripts } = action
      return [...buildNonNativeScripts(scripts)]
    default:
      return state
  }
}
