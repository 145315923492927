import { combineReducers } from "redux"
import configurations from "./configurations"
import dataTables from "./dataTables"
import quickBooks from "./quickBooks"

export default combineReducers({
  configurations,
  dataTables,
  quickBooks,
})
