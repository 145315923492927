import { map } from "ramda"

const tabs = (state = [], action) => {
  switch (action.type) {
    case "SET_FAVORITE_TABS":
      return action.tabs
    case "UPDATE_FAVORITE_TAB": {
      const { id, type, payload } = action.tab
      return map(tab => {
        if (tab.id === id && tab.type === type) {
          return { ...tab, ...payload }
        }
        return tab
      }, state)
    }
    default:
      return state
  }
}

export default tabs
