import { assoc } from "ramda"

export const initialState = {
  id: 0,
  nodeRoleId: 0,
  configurationTypeId: 0,
  configurationTypeName: "",
  onCreateStatusId: 0,
  onCreateStatusName: "",
  onDeleteStatusId: 0,
  onDeleteStatusName: "",
}

export default function nodeRoleConfigurationTypeMapping(state = initialState, action) {
  switch (action.type) {
    case "RESET_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING":
      return initialState
    case "SET_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING":
      return action.mapping
    case "SET_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING_ID":
      return assoc("id", action.id, state)
    case "SET_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING_NODE_ROLE":
      return assoc("nodeRoleId", action.nodeRole.id, state)
    case "SET_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING_CONFIGURATION_TYPE":
      state = assoc("configurationTypeId", action.configurationType.id, state)
      return assoc("configurationTypeName", action.configurationType.name, state)
    case "SET_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING_ON_CREATE_STATUS":
      state = assoc("onCreateStatusId", action.status.id, state)
      return assoc("onCreateStatusName", action.status.name, state)
    case "SET_CW_NODE_ROLE_CONFIGURATION_TYPE_MAPPING_ON_DELETE_STATUS":
      state = assoc("onDeleteStatusId", action.status.id, state)
      return assoc("onDeleteStatusName", action.status.name, state)
    default:
      return state
  }
}
