import { assocPath } from "ramda"
import { initialState } from "./initialState"
import { setBackupProductCode } from "./backupAgent"
import {
  onChangeBackupMode,
  onSaveBackupConsolePassword,
  onSelectBackupNode,
  onEnableBackup,
  onDisableBackup,
  onSaveBackupBandwidthThrottleKb,
  onSaveBackupBandwidthThrottle,
  onSaveBackupNetworkLocation,
  onChangeCloudStorageLocation,
  onChangeDeployTo,
} from "./backupSettings"

export default function backups(state = initialState(), action) {
  switch (action.type) {
    case "CUSTOMER_EDITOR_SET_BACKUP_PRODUCT_CODE":
      return setBackupProductCode(state, action)
    case "CUSTOMER_EDITOR_CHANGE_BACKUP_MODE":
      return onChangeBackupMode(state, action)
    case "CUSTOMER_EDITOR_SAVE_BACKUP_CONSOLE_PASSWORD":
      return onSaveBackupConsolePassword(state, action)
    case "CUSTOMER_EDITOR_SELECT_BACKUP_NODE":
      return onSelectBackupNode(state, action)
    case "CUSTOMER_EDITOR_ENABLE_BACKUP":
      return onEnableBackup(state, action)
    case "CUSTOMER_EDITOR_DISABLE_BACKUP":
      return onDisableBackup(state, action)
    case "CUSTOMER_EDITOR_SAVE_BACKUP_BANDWIDTH_THROTTLE":
      return onSaveBackupBandwidthThrottle(state, action)
    case "CUSTOMER_EDITOR_SAVE_BACKUP_BANDWIDTH_THROTTLE_KB":
      return onSaveBackupBandwidthThrottleKb(state, action)
    case "CUSTOMER_EDITOR_SAVE_BACKUP_NETWORK_LOCATION":
      return onSaveBackupNetworkLocation(state, action)
    case "CUSTOMER_EDITOR_INITIALIZE_BACKUPS":
      return initialState(action.backups)
    case "SET_DELETES_SCHEDULED":
      return assocPath(["lockhart", "deletesScheduled"], action.deletesScheduled, state)
    case "CUSTOMER_EDITOR_CHANGE_BACKUP_CLOUD_STORAGE_LOCATION":
      return onChangeCloudStorageLocation(state, action)
    case "CUSTOMER_EDITOR_CHANGE_DEPLOY_TO":
      return onChangeDeployTo(state, action)
    default:
      return state
  }
}
