import { Col, ControlLabel, FormGroup } from "react-bootstrap"

import { createRevisionLabel, getRestoreFileJob, submitJob } from "js/includes/common/backup"
import showModal from "js/includes/common/services/showModal"
import {
  getPathSeparator,
  isFeatureEnabled,
  isNilOrEmpty,
  localized,
  showSuccessMessage,
} from "js/includes/common/utils"
import { replaceSeparatorAndRemoveTrailingSlash } from "js/includes/components/Browsers"
import LockhartJobDetailsModal from "js/includes/components/LockhartJobDetailsModal"
import RestoreModal, { getOriginalLocationPath } from "./RestoreModal"

export const RestoreFileRevisionModal = ({ unmount: close, nodes, sourceNode }) => {
  const [selectedRevision] = nodes
  const sourceNodeName = sourceNode.friendlyName || sourceNode.name || sourceNode.systemName

  const restore = async ({
    destinationNode,
    collisionHandling,
    restorePermissions,
    destinationPath,
    isOriginalLocation,
  }) => {
    const {
      nodes: [node],
    } = await window.deviceList.fetchById(isOriginalLocation ? sourceNode.id : destinationNode.id)
    const separator = getPathSeparator(sourceNode)
    const sourcePath = replaceSeparatorAndRemoveTrailingSlash(selectedRevision.file.path, separator)
    const restoreDestinationPath = isOriginalLocation ? getOriginalLocationPath(sourcePath, separator) : destinationPath

    const restoreJob = getRestoreFileJob({
      batch: nodes,
      nodeId: node.id,
      nodeName: node.name || node.systemName,
      sourcePath,
      sourceNodeName,
      destinationPath: restoreDestinationPath,
      revisionId: selectedRevision.id,
      sourceNodeId: sourceNode.id,
      sourcePlanId: selectedRevision.file.planId,
      sourcePlanName: selectedRevision.file.planName,
      collisionHandling: collisionHandling.value,
      originalLocation: isOriginalLocation,
      ...(isFeatureEnabled("backup_ntfs_permissions") && { restorePermissions }),
    })

    const response = await submitJob([restoreJob])

    if (response.resultCode !== "SUCCESS") throw new Error("Error creating restore job")

    showSuccessMessage(localized("Restore job created successfully"))
    close()
    showModal(<LockhartJobDetailsModal {...{ job: restoreJob.jobs[0], node }} />)
  }

  return (
    <RestoreModal
      {...{
        RevisionSelector: (
          <FormGroup className="text-left" controlId="backup-restore-manager-revision">
            <Col componentClass={ControlLabel} xs={12}>
              {localized("Revision")}
            </Col>
            <Col xs={12}>
              <div>{createRevisionLabel(selectedRevision)}</div>
            </Col>
          </FormGroup>
        ),
        restoreJob: restore,
        modalTitle: localized("Restore File"),
        disableRestore: isNilOrEmpty(selectedRevision),
        sourceNode,
        nodes: nodes.map(node => ({ ...node, ...node.file })),
        restorePath: selectedRevision.path,
        close,
      }}
    />
  )
}
