import { remove } from "ramda"

export default function scheduledTasks(state = [], action) {
  switch (action.type) {
    case "INIT_SCHEDULED_TASKS":
      return action.scheduledTasks
    case "DELETE_SCHEDULED_TASK":
      const deleteIndex = state.findIndex(task => task.id === action.id)
      return remove(deleteIndex, 1, state)
    default:
      return state
  }
}
