import { combineReducers } from "redux"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import value from "js/state/reducers/common/value"
import language from "./language"
import locale from "./locale"
import isBranded from "./isBranded"
import theme from "./theme"
import darkMode from "./darkMode"
import timezone from "./timezone"
import announcement from "./announcement"
import serverCallPrefix from "./serverCallPrefix"

export default combineReducers({
  language,
  locale,
  environment: createNamedWrapperReducer(value, "application.environment", null),
  region: createNamedWrapperReducer(value, "application.region", null),
  isBranded,
  theme,
  darkMode,
  timezone,
  ticketingDomain: createNamedWrapperReducer(value, "application.ticketingDomain", null),
  announcement,
  serverCallPrefix,
  recaptchaPublicKey: createNamedWrapperReducer(value, "application.recaptchaPublicKey", null),
  defaultDivisionConfig: createNamedWrapperReducer(value, "application.defaultDivisionConfig", {}),
  security: createNamedWrapperReducer(value, "application.security", null),
  origin: createNamedWrapperReducer(value, "application.origin", null),
})
