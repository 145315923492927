import { initialState } from "./initialState"
import {
  initializeNodeApprovals,
  onChangeMode,
  onApproveNodes,
  onRejectNodes,
  onClearNodes,
  refreshNodeApprovalsTabs,
} from "./approvalStatus"
import { onChangeTab, onChangeSelected, onChangeFilterText } from "./tabsHandler"

export default function nodeApprovalContent(state = initialState(), action) {
  switch (action.type) {
    case "NODE_APPROVALS_CHANGE_MODE":
      return onChangeMode(state, action)
    case "NODE_APPROVALS_CHANGE_TAB":
      return onChangeTab(state, action)
    case "NODE_APPROVALS_APPROVE_NODES":
      return onApproveNodes(state, action)
    case "NODE_APPROVALS_REJECT_NODES":
      return onRejectNodes(state, action)
    case "NODE_APPROVALS_CLEAR_NODES":
      return onClearNodes(state, action)
    case "NODE_APPROVALS_CHANGE_SELECTED":
      return onChangeSelected(state, action)
    case "NODE_APPROVALS_CHANGE_FILTER_TEXT":
      return onChangeFilterText(state, action)
    case "NODE_APPROVALS_INITIALIZE":
      return initializeNodeApprovals(state, action)
    case "NODE_APPROVALS_REFRESH":
      return refreshNodeApprovalsTabs(state, action)
    default:
      return state
  }
}
