import { combineReducers } from "redux"
import tasks from "./scheduledTasks"
import currentTask from "./scheduledTask"
import preselectedTargets from "./preselectedTargets"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import sortBy from "js/state/reducers/common/tableSorting/sortBy"
import sortDirection from "js/state/reducers/common/tableSorting/sortDirection"
import loading from "js/state/reducers/common/loading"
import visibility from "js/state/reducers/common/visibility"
import initialEditorState from "./initialEditorState"

const scheduledTasksReducer = combineReducers({
  tasks,
  currentTask,
  scheduledTaskListSort: combineReducers({
    sortBy: createNamedWrapperReducer(sortBy, "ScheduledTaskListSort", "name"),
    sortDirection: createNamedWrapperReducer(sortDirection, "ScheduledTaskListSort"),
  }),
  editorLoading: createNamedWrapperReducer(loading, "ScheduledTaskEditor", false),
  editorSaving: createNamedWrapperReducer(loading, "savingTask", false),
  addTargetsModalVisibility: createNamedWrapperReducer(visibility, "ScheduledTaskAddTargetsModal", false),
  targetTypeSort: combineReducers({
    sortBy: createNamedWrapperReducer(sortBy, "TargetTypeSort", "name"),
    sortDirection: createNamedWrapperReducer(sortDirection, "TargetTypeSort", "DESC"),
  }),
  initialEditorState,
  preselectedTargets,
})

export default function rootReducer(state, action) {
  return scheduledTasksReducer(state, action)
}
