import { omit, clone, filter } from "ramda"

export default function patchOverrides(state = {}, action) {
  switch (action.type) {
    case "SET_PATCH_OVERRIDES":
      return action.patchOverrides
    case "SET_PATCH_OVERRIDE": {
      const {
        patchOverridePair: [id, patchOverride],
      } = action
      const _patchOverride = clone(patchOverride)
      const {
        inheritance: { inherited },
      } = _patchOverride

      if (inherited) {
        _patchOverride.inheritance.overridden = true
      }

      return {
        ...state,
        ...{ [id]: _patchOverride },
      }
    }
    case "REVERT_PATCH_OVERRIDE": {
      const [id, inheritable] = action.patchOverridePair
      const {
        inheritance: { parentPolicySettings },
      } = inheritable

      const revertedInheritable = {
        ...inheritable,
        ...parentPolicySettings,
      }

      revertedInheritable.inheritance.overridden = false

      return {
        ...state,
        ...{ [id]: revertedInheritable },
      }
    }
    case "REMOVE_PATCH_OVERRIDE":
      return omit([action.id], state)
    case "CLEAR_PATCH_OVERRIDES":
      return filter(({ inheritance: { inherited } }) => !!inherited)(state)
    default:
      return state
  }
}
