const initialState = {
  enabled: false,
}

export default function connection(state = initialState, action) {
  switch (action.type) {
    case "SET_ZENDESK_NOW_CONNECTION":
      return { ...state, ...action.payload }
    case "RESET_ZENDESK_CONNECTION":
      return initialState
    default:
      return state
  }
}
