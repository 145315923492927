export const softwareInventorySortPropertyDashboard = (state = "publisher", action) => {
  switch (action.type) {
    case "SET_SOFTWARE_INVENTORY_SORT_PROPERTY_DASHBOARD":
      return action.selection ? action.selection.dataKey : state
    default:
      return state
  }
}

export const softwareInventorySortPropertyDevice = (state = "publisher", action) => {
  switch (action.type) {
    case "SET_SOFTWARE_INVENTORY_SORT_PROPERTY_DEVICE":
      return action.selection ? action.selection.dataKey : state
    default:
      return state
  }
}

export const softwareInventorySortDirectionProperty = (state = "ASC", action) => {
  switch (action.type) {
    case "SET_SOFTWARE_INVENTORY_SORT_DIRECTION_PROPERTY":
      return action.value ? action.value : state
    default:
      return state
  }
}

export const softwareInventorySoftwareFilterPropertyDashboardtoExport = (state = "", action) => {
  switch (action.type) {
    case "SET_SOFTWARE_INVENTORY_SOFTWARE_FILTER_PROPERTY_DASHBOARD_EXPORT":
      return action.queryFilter?.softwareFilter || ""
    default:
      return state
  }
}
