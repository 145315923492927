export default function activeSearch(state = "ALL", action) {
  switch (action.type) {
    case "SET_ACTIVE_SEARCH":
      return action.activeSearch
    case "DELETE_GROUP":
    case "CLEAR_ACTIVE_SEARCH":
      return "ALL"
    default:
      return state
  }
}
