import { useCallback, useRef } from "react"
import { last } from "ramda"
import { useTheme } from "@emotion/react"
import tokens from "@ninjaone/tokens"
import {
  isNotNilOrEmpty,
  localizationKey,
  localized,
} from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"
import { Flex, StyledSpan } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { getPathSeparator, reportErrorAndShowMessage } from "@ninjaone/webapp/src/js/includes/common/utils"
import { useMountedState } from "@ninjaone/webapp/src/js/includes/common/hooks"
import { Breadcrumb } from "@ninjaone/components"
import { DataTable } from "@ninjaone/components"

const defaultBreadcrumbSeparator = "▸"

export const getBreadcrumbSeparator = ({
  isSearchInProgress,
  isSearchResults,
  type,
  node: { nodeClass },
  common,
  name,
}) => {
  switch (true) {
    case isSearchInProgress:
    case isSearchResults:
      return defaultBreadcrumbSeparator
    case type === "file" || type === "mountedFile":
      return ""
    case type === "folder" && !common:
    case ["mountedFolder", "revisions"].includes(type):
      return getPathSeparator({ nodeClass })
    default:
      return defaultBreadcrumbSeparator
  }
}

const onRefresh = ({ resetData }) => resetData()

const BackupFileFolderBrowser = ({
  tableId,
  limit,
  node,
  columns,
  primaryActions,
  secondaryGlobalActions,
  getBrowseRowAction,
  onFetchData,
  onSuccessfulBrowse,
  searchable,
  SearchBar,
  searchPlaceholderText,
  setCustomHistory,
  hideCheckboxes,
}) => {
  const theme = useTheme()
  const [history, setHistory] = useMountedState([])
  const [loading, setLoading] = useMountedState(false)
  const [searchValue, setSearchValue] = useMountedState()
  const preFetchedResult = useRef([])
  const currentDir = last(history)

  const separator = getPathSeparator(node)

  const onBrowse = async ({ isRoot, node: _targetDir, historyIndex, searchValue }) => {
    if (!isRoot) {
      const { folder, restricted } = _targetDir
      if (!folder || restricted) return
    }
    const isFromBreadcrumb = historyIndex !== undefined
    const targetDir = isFromBreadcrumb ? history[historyIndex] : _targetDir
    const isSearch = isNotNilOrEmpty(searchValue) || currentDir?.isSearchResults

    // if we are navigating to the same path
    if (targetDir?.path === currentDir?.path && !isSearch) return

    let response
    setLoading(true)
    try {
      response = await onFetchData({
        currentDir: targetDir,
        fetchingParams: {
          filters: [],
          pageIndex: 0,
          pageSize: limit,
          sortBy: [],
        },
        separator,
        isFromBreadcrumb,
        searchValue,
      })
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("There was an error fetching content"))
    } finally {
      setLoading(false)
    }

    if (!response) return

    preFetchedResult.current.push(response)
    if (isRoot) {
      setHistory([])
    } else if (isFromBreadcrumb) {
      setHistory(h => h.slice(0, historyIndex + 1))
    } else if (setCustomHistory) {
      setHistory(history => setCustomHistory({ history, targetDir, searchValue }))
    } else {
      setHistory(h => h.concat(targetDir))
    }
    onSuccessfulBrowse?.({ targetDir, setHistory, separator, searchValue })
  }

  const onFetchDataFromDataTable = useCallback(
    async fetchingParams => {
      if (preFetchedResult.current.length) return preFetchedResult.current.pop()
      let response
      try {
        response = await onFetchData({
          currentDir,
          fetchingParams,
          separator,
          searchValue,
        })
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("There was an error fetching content"))
      }

      return response ?? { data: [] }
    },
    [onFetchData, currentDir, separator, searchValue],
  )

  return (
    <Flex flex={1} flexDirection="column">
      <Breadcrumb
        {...{
          separator: getBreadcrumbSeparator,
          history,
          handleClick: onBrowse,
          rootRenderer: () => (
            <a
              href={"#!"}
              className="clickable-link"
              onClick={e => {
                e.preventDefault()
                onBrowse({ isRoot: true })
              }}
            >
              {localized("All plans")}
              {history.length ? (
                <StyledSpan margin={[0, tokens.spacing[1]]}>{defaultBreadcrumbSeparator}</StyledSpan>
              ) : null}
            </a>
          ),
          customRenderer: (content, separator = "/") => {
            switch (true) {
              case content.endsWith(separator):
                return content
              case content.endsWith("\\") || content.endsWith("/"):
                return content.replace(/[/|\\]+$/, separator)
              case separator === defaultBreadcrumbSeparator:
                return (
                  <>
                    {content}
                    <StyledSpan margin={[0, tokens.spacing[1]]}>{defaultBreadcrumbSeparator}</StyledSpan>
                  </>
                )
              default:
                return content + separator
            }
          },
          colors: {
            link: theme.color.ninjaBlueSaturated,
          },
        }}
      />
      {SearchBar ? (
        <SearchBar
          {...{
            searchValue,
            onSearchValueChange: newValue => {
              setSearchValue(newValue)
              onBrowse({ node: currentDir, searchValue: newValue })
            },
          }}
        />
      ) : null}
      <DataTable
        {...{
          pageSizeLimit: limit,
          loading,
          tableId,
          fetchData: onFetchDataFromDataTable,
          noRowsToken: localizationKey("Empty Folder"),
          onRefresh,
          showSearchBar: false,
          columns,
          actions: {
            primary: [...(getBrowseRowAction ? [getBrowseRowAction(onBrowse)] : []), ...primaryActions],
            row: {
              action: rowData => onBrowse({ node: rowData }),
              disable: ({ folder, restricted }) => !folder || restricted,
            },
          },
          secondaryGlobalActionsButtons: secondaryGlobalActions,
          resetPageOnFetchChange: true,
          searchPlaceholderText,
          //TODO: remove this when settings problems due to handler changes are fixed
          hideSettingsButton: true,
          hideCheckboxes,
        }}
      />
    </Flex>
  )
}

export default BackupFileFolderBrowser
