import { combineReducers } from "redux"
import hosts from "./hosts"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import loading from "js/state/reducers/common/loading"
import vmHostDashboard from "./vmHostDashboard"
import vmGuestDashboard from "./vmGuestDashboard"
import vmGuests from "./vmGuests"

export default combineReducers({
  hosts,
  loadingHosts: createNamedWrapperReducer(loading, "loadingVirtualizationHosts"),
  vmHostDashboard,
  loadingVmDashboardData: createNamedWrapperReducer(loading, "loadingVmDashboardData"),
  vmGuestDashboard,
  vmGuests,
  loadingVMGuests: createNamedWrapperReducer(loading, "FETCH_VM_GUESTS", false),
  runningVMGuestAction: createNamedWrapperReducer(loading, "VM_RUNNING_ACTION", false),
})
