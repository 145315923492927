import styled from "@emotion/styled"
import { sizer } from "@ninjaone/utils"

export const StyledRoot = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  border-bottom: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  background-color: ${({ theme }) => theme.colorBackground};
`

export const StyledTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sizer(2)};
  padding: ${sizer(4, 4, 4, 0)};
  border-right: 1px solid ${({ theme }) => theme.colorBorderWeakest};
`

export const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sizer(1)};
  flex: 1;
`

export const StyledListItem = styled.button`
  display: flex;
  align-items: center;
  width: 220px;
  height: 38px;
  padding: ${sizer(0, 3)};
  border: 0;
  border-radius: 4px;
  font-family: "Inter";
  text-align: left;
  background-color: ${({ theme }) => theme.colorBackground};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colorForegroundHover};
  }

  &[data-state="active"] {
    background: ${({ theme }) => theme.colorForegroundSelected};

    span,
    svg {
      color: ${({ theme }) => theme.colorTextActionStrong};
    }
  }

  &[data-error="true"] span,
  &[data-error="true"] svg {
    color: ${({ theme }) => theme.colorAlertError};
  }

  &[data-animate="true"][data-error="true"] {
    animation: Highlight 5s;
  }

  &:focus-visible {
    outline: 2px auto ${({ theme }) => theme.colorForegroundFocus};
    outline-offset: 0px;

    @supports (-moz-appearance: none) {
      outline-offset: -2px;
    }
  }

  @keyframes Highlight {
    20% {
      background: ${({ theme }) => theme.colorAlertErrorWeak};
    }
    80% {
      background: ${({ theme }) => theme.colorAlertErrorWeak};
    }
  }
`

export const StyledError = styled.span`
  margin-left: ${sizer(2)};
  color: ${({ theme }) => theme.colorAlertError};
`

export const StyledContent = styled.div`
  flex-grow: 1;
  margin: ${sizer(6, 0, 6, 6)};
  overflow: scroll;
  background-color: ${({ theme }) => theme.colorBackground};

  &:focus-visible {
    outline: 2px auto ${({ theme }) => theme.colorForegroundFocus};
    outline-offset: 0px;

    @supports (-moz-appearance: none) {
      outline-offset: -2px;
    }
  }

  &::-webkit-scrollbar {
    width: 0;
  }
`
