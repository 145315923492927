const subTab = (state = "", action) => {
  switch (action.type) {
    case "SELECT_SUB_TAB":
      return action.subTab
    default:
      return state
  }
}

export default subTab
