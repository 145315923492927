const boolean = (state = false, action) => {
  switch (action.type) {
    case "SET_TO_TRUE":
      return true
    case "SET_TO_FALSE":
      return false
    default:
      return state
  }
}

export default boolean
