import React, { memo, useEffect, useState } from "react"
import { Col, ControlLabel, FormGroup, Modal } from "react-bootstrap"
import { always } from "ramda"

import { Checkbox } from "@ninjaone/components"
import { createJobOption, defaultOption } from "js/includes/common/backup"
import { getPathSeparator, getPrefixedUrl, localized } from "js/includes/common/utils"
import { getJobsByFolderEndpoint } from "js/includes/components/Backup/BackupManager/utils"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

import { DownloadWarningMessage } from "./DownloadWarningMessage"
import { replaceSeparatorAndRemoveTrailingSlash } from "js/includes/components/Browsers"

export default memo(function DownloadFolderModal({ unmount: close, nodes, sourceNode }) {
  const [folder] = nodes
  const [enabled, setEnabled] = useState(false)
  const [job, setJob] = useState(defaultOption)
  const [includeLatestRevisions, setIncludeLatestRevisions] = useState(false)
  const { planId, planName, name, path, id: folderId } = folder

  useEffect(() => {
    if (job.value) {
      setEnabled(true)
    } else {
      setEnabled(false)
    }
  }, [job])

  const downloadHref = getPrefixedUrl(
    `/backup/lockhart/nodes/${sourceNode.id}/plans/${planId}/folders/${folderId}/jobs/${job.value}/data?restoreDeleted=${includeLatestRevisions}`,
  )

  return (
    <Modal.Dialog bsClass="inmodal modal" dialogClassName={"restore-manager-download-modal folder"}>
      <Modal.Header>
        <Modal.Title>{localized("Download Folder")}</Modal.Title>
      </Modal.Header>
      <Modal.Body bsClass={"modal-body overflow-visible-important display-flex flex-direction-column"}>
        <FormGroup className="text-left" controlId="backup-restore-manager-download-info">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Device")}
          </Col>
          <Col xs={12}>
            <div>{sourceNode.displayName}</div>
          </Col>
        </FormGroup>
        <FormGroup className="text-left" controlId="backup-restore-manager-download-info">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Plan Name")}
          </Col>
          <Col xs={12}>
            <div>{planName}</div>
          </Col>
        </FormGroup>
        <FormGroup className="text-left" controlId="backup-restore-manager-download-info">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Path")}
          </Col>
          <Col xs={12}>
            <div className="break-all">
              {replaceSeparatorAndRemoveTrailingSlash(path, getPathSeparator(sourceNode))}
            </div>
          </Col>
        </FormGroup>
        <FormGroup className="text-left" controlId="backup-restore-manager-download-info">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Deleted Files")}
          </Col>
          <Col xs={12}>
            <Checkbox
              fontWeight={400}
              onChange={({ isChecked }) => setIncludeLatestRevisions(isChecked)}
              label={localized("Include Latest Revision of Deleted Files")}
            />
          </Col>
        </FormGroup>
        <FormGroup className="text-left" controlId="backup-restore-manager-download-select">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Date")}
          </Col>
          <Col xs={12}>
            <SearchableDropDown
              {...{
                openOnFocus: true,
                pageSize: 100,
                endpoint: getJobsByFolderEndpoint(sourceNode, planId),
                width: "100%",
                rowHeight: 40,
                noRowsRendererToken: "dashboard.backup.manageBackups.downloadNotAvailable",
                onSelect: setJob,
                dataFormatter: obj => ({ ...createJobOption(obj), completed: obj.completed }),
                lastItemKey: "completed",
                isSearchable: false,
                searchParams: always({ upsyncedData: true }),
              }}
            />
          </Col>
        </FormGroup>
        <DownloadWarningMessage />
      </Modal.Body>
      <Modal.Footer>
        <div className="display-flex justify-content-between">
          <div className="display-flex flex-full justify-content-end">
            {enabled && (
              <a
                className="btn btn-success"
                onClick={() => setEnabled(false)}
                href={downloadHref}
                download={name + ".zip"}
              >
                {localized("Download")}
              </a>
            )}

            <button type="button" className="btn btn-white" onClick={close}>
              {localized("Close")}
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal.Dialog>
  )
})
