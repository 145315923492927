import styled from "@emotion/styled"

import { sizer } from "@ninjaone/utils"

import { getReadableBytes } from "js/includes/common/_conversions"
import showModal from "js/includes/common/services/showModal"
import { localizationKey, localized } from "js/includes/common/utils"
import Modal from "js/includes/components/Modal"
import { Box, Flex } from "js/includes/components/Styled"
import { StyledFormGroup } from "js/includes/components/Styled/Form"

const StyledLabel = styled.div`
  font-weight: 700;
  margin-bottom: ${sizer(1)};
`

const MountImageModal = ({ name: revisionDate, size, volumeLabel, resolve, unmount }) => (
  <Modal
    {...{
      close: () => {
        resolve(false)
        unmount()
      },
      closeText: localizationKey("Cancel"),
      showCloseButton: false,
      saveText: localizationKey("Mount"),
      save: () => {
        resolve(true)
        unmount()
      },
      title: localizationKey("Mount Image"),
      overflow: true,
    }}
  >
    <Flex fontSize={14} flexDirection="column" textAlign="left">
      <Box marginBottom={sizer(6)}>
        {localized(
          "You are about to mount this volume in the cloud. This may take some time depending on the size of the volume. Are you sure you want to continue?",
        )}
      </Box>
      <StyledFormGroup marginBottom={sizer(4)}>
        <StyledLabel>{localized("Revision Date")}</StyledLabel>
        <div>{revisionDate}</div>
      </StyledFormGroup>
      <StyledFormGroup marginBottom={sizer(4)}>
        <StyledLabel>{localized("Volume Name")}</StyledLabel>
        <div>{volumeLabel}</div>
      </StyledFormGroup>
      <StyledFormGroup marginBottom={sizer(4)}>
        <StyledLabel>{localized("Volume Size")}</StyledLabel>
        <div>{getReadableBytes(size) || "-"}</div>
      </StyledFormGroup>
    </Flex>
  </Modal>
)

const showMountImageModal = props => {
  return new Promise(resolve => {
    showModal(<MountImageModal {...{ resolve, ...props }} />)
  })
}

export default showMountImageModal
