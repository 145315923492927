import React from "react"
import Select from "react-select"
import { whereEq } from "ramda"
import { Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { localizationKey, localizedF } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"

const volumeOptions = [
  {
    value: "INCLUDE_ALL_VOLUMES",
    label: localizedF("All Volumes"),
    checkList: "INCLUDE",
    volumeName: "ALL_VOLUMES",
  },
  {
    value: "INCLUDE_BOOT_VOLUME",
    label: localizedF("Boot Volume"),
    checkList: "INCLUDE",
    volumeName: "BOOT_VOLUME",
  },
  {
    value: "EXCLUDE_BOOT_VOLUME",
    label: localizedF("Excluding Boot Volume"),
    checkList: "EXCLUDE",
    volumeName: "BOOT_VOLUME",
  },
  {
    value: "EXCLUDE_BOOT_AND_RECOVERY_VOLUMES",
    label: localizedF("Excluding Boot & Recovery Volumes"),
    checkList: "EXCLUDE",
    volumeName: "BOOT_AND_RECOVERY_VOLUMES",
  },
]

export default function DefragOption({ onChange, values: { volumeName, checkList }, validation }) {
  return (
    <>
      <Box margin={sizer(2, 0, 1)}>
        <Text bold size="sm" token={localizationKey("Parameters")} />
      </Box>
      <Select
        getOptionLabel={({ label }) => label()}
        options={volumeOptions}
        id="defragOptions"
        value={volumeOptions.find(whereEq({ volumeName, checkList }))}
        onChange={selection => {
          onChange({ volumeName: selection.volumeName, checkList: selection.checkList })
        }}
      />
    </>
  )
}
