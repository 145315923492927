import { reject, propEq, map } from "ramda"

export const addHost = (state, action) => {
  return {
    ...state,
    list: [...state.list, action.host],
  }
}

export const removeHost = (state, action) => {
  const { host } = action
  return {
    ...state,
    list: reject(propEq("id", host.id), state.list),
  }
}

export const updateHost = (state, action) => {
  const { host } = action
  return {
    ...state,
    list: map(h => (h.id === host.id ? { ...h, ...host } : h), state.list),
  }
}
