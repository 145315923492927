const boardsConfiguration = (state = [], action) => {
  switch (action.type) {
    case "SET_BOARDS_CONFIGURATION":
      return action.boardsConfiguration
    default:
      return state
  }
}

export default boardsConfiguration
