import { combineReducers } from "redux"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import confirmUninstallSoftwareInputValue from "./confirmUninstallSoftwareInputValue"
import {
  softwareInventorySortPropertyDashboard,
  softwareInventorySortPropertyDevice,
  softwareInventorySortDirectionProperty,
  softwareInventorySoftwareFilterPropertyDashboardtoExport,
} from "./softwareInventory"
import loading from "js/state/reducers/common/loading"
import selectedSoftware from "./selectedSoftware"
import uninstallingSoftware from "./uninstallingSoftware"
import softwareInventoryFilter from "./softwareInventoryFilter"
import showConfirmUninstallSoftwareModal from "./showConfirmUninstallSoftwareModal"
import softwarePlatformFilter from "./softwarePlatformFilter"
import subTab from "js/state/reducers/common/subTab"
import softwarePatches from "./softwarePatches"
import softwarePatchesFilter from "./softwarePatchesFilter"
import selectedListItems from "js/state/reducers/common/selectedListItems"
import processingPolicy from "./processingPolicy"
import confirmModalOptions from "./confirmModalOptions"

export default combineReducers({
  softwareInventorySortPropertyDashboard,
  softwareInventorySortPropertyDevice,
  softwareInventorySortDirectionProperty,
  softwareInventorySoftwareFilterPropertyDashboardtoExport,
  loadingSoftwareInventory: createNamedWrapperReducer(loading, "loadingSoftwareInventory"),
  selectedSoftware,
  uninstallingSoftware,
  softwareInventoryFilter,
  showConfirmUninstallSoftwareModal,
  confirmUninstallSoftwareInputValue,
  softwarePlatformFilter,
  activeSubTab: createNamedWrapperReducer(subTab, "softwareTab", "inventory"),
  softwarePatches,
  loadingSoftwarePatches: createNamedWrapperReducer(loading, "loadingSoftwarePatches"),
  softwarePatchesFilter,
  selectedSoftwarePatches: createNamedWrapperReducer(selectedListItems, "softwarePatches"),
  processingDevices: createNamedWrapperReducer(loading, "softwareTab"),
  processingPolicy,
  confirmModalOptions,
})
