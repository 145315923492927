import { pluck, without } from "ramda"

const getProgramIds = pluck("id")

const selectedProducts = (state = [], action) => {
  switch (action.type) {
    case "SELECT_ADD_PRODUCT":
      return [...state, action.product.id]
    case "CLEAR_ADD_PRODUCT":
      return state.filter(d => d !== action.product.id)
    case "SELECT_ADD_ALL_PRODUCTS":
      return pluck("id", action.products)
    case "CLEAR_ADD_ALL_PRODUCTS":
      return []
    case "SELECT_ADD_THIS_PRODUCT_ONLY":
      return [action.product.id]
    case "SELECT_ADD_VENDOR":
      return [...state, ...getProgramIds(action.programs)]
    case "CLEAR_ADD_VENDOR":
      return without(getProgramIds(action.programs), state)
    case "TOGGLE_SELECT_ADD_PRODUCT": {
      const { product } = action
      return state.some(p => p === product.id) ? state.filter(p => p !== product.id) : [...state, action.product.id]
    }
    default:
      return state
  }
}

export default selectedProducts
