const initialState = {
  enabled: false,
  scim: {
    enabled: false,
  },
  skipLoginMFA: {
    enabled: false,
  },
}

export default function identityProvider(state = initialState, action) {
  switch (action.type) {
    case "SET_SAML_CONFIGURATION":
      return { ...state, ...action.payload }
    case "SET_SCIM_STATUS":
      return {
        ...state,
        scim: {
          ...state.scim,
          enabled: action.enabled,
        },
      }
    default:
      return state
  }
}
