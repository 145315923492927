import { combineReducers } from "redux"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import credentialsList from "./credentialsList"
import sortBy from "js/state/reducers/common/tableSorting/sortBy"
import sortDirection from "js/state/reducers/common/tableSorting/sortDirection"

export default combineReducers({
  credentialsList,
  credentialsListSort: combineReducers({
    sortBy: createNamedWrapperReducer(sortBy, "credentialsListSort", "defaults"),
    sortDirection: createNamedWrapperReducer(sortDirection, "credentialsListSort"),
  }),
})
