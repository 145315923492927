import { fetchJson, reportErrorAndShowMessage, showSuccessMessage } from "js/includes/common/utils"
import { localizationKey, localized } from "js/includes/common/utils/ssrAndWebUtils"
import BackupConfirmationModal from "js/includes/components/Backup/Modals/BackupConfirmationModal"

const nameColumn = {
  Header: localized("Name"),
  id: "name",
  accessor: "name",
}

const pathColumn = {
  Header: localized("Path"),
  id: "path",
  accessor: "path",
}

const ConfirmDeleteRevisionsModal = ({ nodeId, planId, planName, directories: _dir, resetData, unmount }) => {
  let directories = []
  _dir.forEach(d => (directories = directories.concat(d.common ? d.content : [d])))
  const hasRootFolders = directories.some(d => d.root)

  const handleConfirm = async ({ unmount }) => {
    const fileIds = []
    const folderIds = []

    directories.forEach(dir => {
      if (dir.type === "file") {
        fileIds.push(dir.id)
      }

      if (dir.type === "folder") {
        folderIds.push(dir.id)
      }

      if (dir.type === "volume") {
        fileIds.push(dir.fileId)
      }
    })

    try {
      await fetchJson(`/backup/lockhart/nodes/${nodeId}/plans/${planId}/delete-revisions`, {
        options: {
          method: "POST",
          body: JSON.stringify({
            fileIds,
            folderIds,
            planName,
          }),
        },
      })
      showSuccessMessage(localized("Data deletion scheduled successfully"))
    } catch (error) {
      if (!error.isHandledMfaError) {
        reportErrorAndShowMessage(error, localizationKey("Error deleting plan files"))
      }
    }
    resetData()
    unmount()
  }

  return (
    <BackupConfirmationModal
      {...{
        titleToken: localizationKey("Confirm delete data"),
        descriptionText: localized(
          "This action will permanently delete all backup data the following {{quantity}} item(s). Data will be deleted and removed from your calculated usage in 72 hours.",
          {
            quantity: directories.length,
          },
        ),
        actionToken: localizationKey("Delete"),
        onConfirm: handleConfirm,
        onCancel: ({ unmount }) => {
          unmount()
        },
        unmount,
        data: directories,
        columns: [...(!hasRootFolders ? [nameColumn] : []), pathColumn],
      }}
    />
  )
}

export default ConfirmDeleteRevisionsModal
