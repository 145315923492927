import { memo, useCallback, useEffect, useMemo, useRef } from "react"
import { ControlLabel, Tab, Tabs } from "react-bootstrap"
import { connect } from "react-redux"
import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import fastDeepEquals from "fast-deep-equal"
import {
  __,
  always,
  compose,
  defaultTo,
  evolve,
  filter,
  find,
  flatten,
  has,
  ifElse,
  includes,
  isNil,
  keys,
  lensProp,
  map,
  pluck,
  prop,
  propEq,
  reduce,
  set,
  values,
  where,
} from "ramda"
import { useQuery } from "urql"

import { Switch } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"

import { useForm, useMountedState } from "js/includes/common/hooks"
import {
  applyMultipleValidations,
  fetchJson,
  localizationKey,
  localized,
  ninjaReportError,
  reportErrorAndShowMessage,
  showErrorMessage,
  showSuccessMessage,
  sortByFieldNameCaseInsensitive,
  validations,
} from "js/includes/common/utils"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import {
  parseServerActionRules,
  parseServerRules,
  sanitizeRules,
  setServerActionRules,
} from "js/includes/components/RuleEditor"
import {
  StyledCol,
  StyledForm,
  StyledFormControl,
  StyledFormGroup,
  StyledModal,
} from "js/includes/components/Styled/Form"
import { getTitleToken } from "js/includes/ticketing/commonMethods"
import { fetchTriggerBoards as _fetchTriggerBoards } from "js/state/actions/general/boardTabs"
import { getTicketingForms } from "js/includes/common/client"
import Loading from "js/includes/components/Loading"

import { Actions } from "./Actions"
import { Columns } from "./Columns"
import Conditions from "./Conditions"
import {
  getActiveAttributes,
  getColumnOptions,
  getFields,
  buildGetValidations,
  triggerContainsHoursSinceCondition,
  setMissingValidations,
} from "./utils"

const query = `
  query GetAttributeList {
    ticketAttributes { 
      id 
      attributeType
      name 
      active
      content { 
        values { 
          id 
          name 
        } 
      } 
    }
  }
`

const getDefaultColumns = compose(
  pluck(["value"]),
  filter(
    where({
      value: includes(__, [
        "status",
        "id",
        "summary",
        "organization",
        "requester",
        "assignee",
        "type",
        "source",
        "createTime",
        "totalTimeTracked",
      ]),
    }),
  ),
)

const defaultValues = {
  enabled: true,
  name: "",
  description: "",
  conditions: {
    all: [],
    any: [],
  },
  actions: [],
}

const setTouched = map(set(lensProp("touched"), true))

const updateConditionsTouched = evolve({
  all: setTouched,
  any: setTouched,
})

const isValid = compose(
  isNil,
  find(compose(ifElse(has("success"), propEq("success", false), always(undefined)), prop("validation"))),
  defaultTo([]),
)

const flattenValues = compose(flatten, values)

const automationErrorCodeTokens = {
  automation_ticket_assignee_do_not_exists: localizationKey("Ticket actions cannot contain deleted users"),
  ticket_changes_action_user_or_contact_not_found: localizationKey("Ticket actions cannot contain deleted users"),
  email_action_user_or_contact_not_found: localizationKey("Email action cannot contain deleted users"),
}

const TriggerEditorModal = memo(
  ({ id, type = "EVENT_BASED", copy = false, system, fetchTriggerBoards, onUpdateSuccess, unmount, statusList }) => {
    const shouldFetchForms = !system && ["EVENT_BASED", "TIME_BASED", "BOARD"].includes(type)
    const [formOptions, setFormOptions] = useMountedState([])
    const [fetchingFormOptions, setFetchingFormOptions] = useMountedState(shouldFetchForms)

    const isBoard = type === "BOARD"
    const isResponseTemplate = type === "RESPONSE_TEMPLATE"
    const isTimeBased = type === "TIME_BASED"

    const initialValuesRef = useRef(null)
    const [organizations, setOrganizations] = useMountedState([])
    const [isPersisting, setIsPersisting] = useMountedState(false)
    const [fetchingTrigger, setFetchingTrigger] = useMountedState(!!id)

    const defaultBoardValues = useMemo(
      () => ({
        ...defaultValues,
        columns: getDefaultColumns(getColumnOptions()),
        sortBy: [
          {
            field: "id",
            direction: "DESC",
          },
        ],
      }),
      [],
    )

    const [{ error, data }] = useQuery({
      query,
      requestPolicy: "network-only",
    })

    if (error) {
      showErrorMessage(localized("Error"))
      ninjaReportError(error)
    }

    const { attributes, getValidations } = useMemo(() => {
      const attributes = getActiveAttributes(data?.ticketAttributes || [])
      return {
        attributes,
        getValidations: buildGetValidations(attributes),
      }
    }, [data?.ticketAttributes])

    const fields = useMemo(() => {
      const fieldsOptions = { label: localized("Fields"), options: attributes }
      return {
        all: [{ label: localized("System"), options: getFields(type, "all", statusList || []) }, fieldsOptions],
        any: [{ label: localized("System"), options: getFields(type, "any", statusList || []) }, fieldsOptions],
      }
    }, [attributes, statusList, type])

    const { values, onChange, validateForm, validation } = useForm({
      fields: isBoard ? defaultBoardValues : defaultValues,
      validate: {
        name: applyMultipleValidations([validations.required, validations.maxLength(50)]),
        description: validations.maxLength(250),
        ...(!isResponseTemplate && { conditions: value => validations.required([...value.any, ...value.all]) }),
        ...(!isBoard && { actions: validations.required }),
      },
    })

    const { enabled, name, description, conditions, actions, columns, sortBy } = values

    useEffect(() => {
      async function prepareResources() {
        try {
          const response = await fetchJson("/client/list")
          setOrganizations(response)
        } catch (error) {
          ninjaReportError(error)
        }
      }
      prepareResources()
    }, [setOrganizations])

    const fetchForms = useCallback(async () => {
      setFetchingFormOptions(true)
      try {
        const forms = await getTicketingForms()
        const activeFormOptions = reduce(
          (acc, form) => {
            const { id, name, active } = form
            if (active) {
              acc.push({ value: id, label: name })
            }
            return acc
          },
          [],
          forms,
        )
        setFormOptions(sortByFieldNameCaseInsensitive("label", "ASC", activeFormOptions))
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Error fetching forms"))
      } finally {
        setFetchingFormOptions(false)
      }
    }, [setFormOptions, setFetchingFormOptions])

    useEffect(() => {
      if (shouldFetchForms) {
        fetchForms()
      }
    }, [fetchForms, shouldFetchForms])

    useEffect(() => {
      async function fetchTrigger() {
        try {
          setFetchingTrigger(true)
          const response = await fetchJson(`/ticketing/trigger/${id}`)
          const { conditions: _conditions, actions: _actions } = response
          const serverValues = {
            ...response,
            ...(copy && {
              name: `${response.name ?? ""} (${localized("Copy")})`,
            }),
            conditions: {
              all: _conditions.all ? parseServerRules(_conditions.all) : null,
              any: _conditions.any ? parseServerRules(_conditions.any) : null,
            },
            actions: _actions ? parseServerActionRules(_actions) : null,
          }
          onChange(serverValues)
          initialValuesRef.current = serverValues
        } catch (error) {
          showErrorMessage(localized("Failed"))
          ninjaReportError(error)
        } finally {
          setFetchingTrigger(false)
        }
      }

      id && !initialValuesRef.current && fetchTrigger()
    }, [id, onChange, copy, getValidations, setFetchingTrigger])

    function removeDeletedCondition(conditions) {
      if (conditions?.length > 1 && copy && system) {
        return conditions.filter(condition => condition.field !== "deleted")
      }
      return conditions
    }

    function addMissingValidationsToConditions(conditions) {
      return keys(conditions).reduce((acc, key) => {
        acc[key] = setMissingValidations({ list: conditions[key], getValidations })
        return acc
      }, {})
    }

    function addMissingValidationsToActions(actions) {
      return setMissingValidations({ list: actions, getValidations })
    }

    /**
     * Validates conditions and actions.
     *
     * Before validating, it sets missing validations in actions and conditions that do not have the validation field.
     * Elements will have missing validations when an automation is updated without touching elements.
     *
     * @returns true if actions and conditions are valid
     */
    function validateActionsAndConditions() {
      const updatedActions = addMissingValidationsToActions(setTouched(actions))
      const actionsAreValid = isValid(updatedActions)
      onChange("actions", updatedActions)

      let conditionsAreValid = true
      if (!isResponseTemplate) {
        const updatedConditions = addMissingValidationsToConditions(updateConditionsTouched(conditions))
        conditionsAreValid = isValid(flattenValues(updatedConditions))
        onChange("conditions", updatedConditions)
      }

      return actionsAreValid && conditionsAreValid
    }

    const returnPlaceholderErrorMessage = error => {
      switch (error.resultCode) {
        case "email_action_comment_placeholder_invalid":
          return localizationKey("Invalid placeholder found at email body")
        case "email_action_num_placeholder_comments_invalid":
          return localizationKey("No more than one placeholder comment is allowed")
        case "action_comment_placeholder_not_allowed":
          return localizationKey("Placeholder used in wrong field")
        default:
          const defaultErrorMessage = isBoard
            ? localizationKey("Error saving ticket board")
            : localizationKey("Error saving automation")

          return automationErrorCodeTokens[error?.resultCode] || defaultErrorMessage
      }
    }

    async function handleSave(e) {
      e.preventDefault()

      if (isPersisting) return

      try {
        if (isBoard && !columns?.length) {
          return showErrorMessage(localized("Please add at least one column"))
        }

        if (!system || copy) {
          const actionsAndConditionsAreValid = validateActionsAndConditions()

          if (!validateForm() || !actionsAndConditionsAreValid) return

          if (isTimeBased && !triggerContainsHoursSinceCondition(conditions)) {
            return showErrorMessage(
              localized("Time based automations require at least one hour since condition to be set"),
            )
          }
        }

        setIsPersisting(true)

        const creating = !id || copy

        await fetchJson(`/ticketing/trigger/${creating ? "" : id}`, {
          options: {
            method: creating ? "POST" : "PUT",
            body: JSON.stringify({
              enabled,
              name,
              description,
              type,
              conditions: {
                all: conditions.all ? removeDeletedCondition(sanitizeRules(conditions.all)) : null,
                any: conditions.any ? sanitizeRules(conditions.any) : null,
              },
              actions: actions ? sanitizeRules(setServerActionRules(actions)) : null,
              ...(isBoard && { columns, sortBy }),
            }),
          },
        })

        if (isBoard) {
          //NOTE: Comment for now until we implement new endpoint that will bring ticket count
          //id ? updateTriggerBoardTab(response) : addTriggerBoardTab(response)
          fetchTriggerBoards()
        }

        showSuccessMessage(localized("Success"))
        onUpdateSuccess?.()
        unmount()
      } catch (error) {
        reportErrorAndShowMessage(error, returnPlaceholderErrorMessage(error))
      } finally {
        setIsPersisting(false)
      }
    }

    async function handleClose() {
      const initialValues = initialValuesRef.current ?? (isBoard ? defaultBoardValues : defaultValues)

      if (!fastDeepEquals(initialValues, values)) {
        const response = await ShowMessageDialog({
          icon: { icon: faEdit, type: "critical" },
          title: localizationKey("You have unsaved changes"),
          message: localizationKey("Are you sure you wish to proceed?"),
          buttons: [
            { id: true, label: localizationKey("Continue"), type: "critical" },
            { id: false, label: localizationKey("Cancel") },
          ],
        })

        return response && unmount()
      }

      unmount()
    }

    function onQueryChange(condition, combinator) {
      onChange("conditions", { ...conditions, [combinator]: condition })
    }

    const isConditionsTabValid = isValid([...(!isResponseTemplate ? flattenValues(conditions) : [])])
    const isActionsTabValid = isValid(actions)

    const disableField = system && !copy

    return (
      <StyledForm horizontal marginTop="0">
        <StyledModal
          tallModal
          dialogClassName="wide-modal"
          title={getTitleToken(type)}
          save={handleSave}
          close={handleClose}
          maxHeight="600px !important"
          overflowX="hidden !important"
          disabledSaveBtn={isPersisting}
        >
          {fetchingFormOptions || fetchingTrigger ? (
            <Loading />
          ) : (
            <>
              {!isBoard && (
                <StyledFormGroup id="form-group-ruleset-enabled-container" marginBottom={2}>
                  <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
                    {localized("Enabled")}
                  </StyledCol>
                  <StyledCol xs={12} flexDirection="column" marginTop={2}>
                    <Switch checked={enabled} onChange={checked => onChange("enabled", checked)} />
                  </StyledCol>
                </StyledFormGroup>
              )}
              <StyledFormGroup
                id="form-group-trigger-name-container"
                validationState={validation.success.name === false ? "error" : null}
              >
                <StyledCol
                  xs={12}
                  display="inline-block"
                  flex="none"
                  componentClass={ControlLabel}
                  htmlFor="automation-name"
                >
                  {localized("Name")} *
                </StyledCol>
                <StyledCol xs={12} flexDirection="column" marginTop={2}>
                  <StyledFormControl
                    id="automation-name"
                    value={name}
                    onChange={e => onChange("name", e.target.value)}
                    onBlur={() => validateForm(["name"])}
                    disabled={disableField}
                    maxLength={50}
                  />
                  {validation.message.name && <em className="invalid">{validation.message.name}</em>}
                </StyledCol>
              </StyledFormGroup>

              <StyledFormGroup id="form-group-trigger-description-container" marginBottom={2}>
                <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
                  {localized("Description")}
                </StyledCol>
                <StyledCol xs={12} flexDirection="column" marginTop={2}>
                  <StyledFormControl
                    componentClass="textarea"
                    value={description || ""}
                    onChange={e => onChange("description", e.target.value)}
                    disabled={disableField}
                    maxLength={250}
                    resize="none"
                    height={`${sizer(25)} !important`}
                  />
                  {validation.message.description && <em className="invalid">{validation.message.description}</em>}
                </StyledCol>
              </StyledFormGroup>

              <Tabs
                id="ticketing-trigger-tabs"
                defaultActiveKey={system && !copy ? "columns" : isResponseTemplate ? "actions" : "conditions"}
                className="custom-nav-tabs text-align-left"
                mountOnEnter
              >
                {(!system || copy) && !isResponseTemplate && (
                  <Tab
                    eventKey="conditions"
                    title={`${localized("Conditions")} *`}
                    tabClassName={validation.success.conditions === false || !isConditionsTabValid ? "tab-error" : ""}
                  >
                    <Conditions
                      {...{
                        query: conditions["all"],
                        combinator: "all",
                        onQueryChange: condition => onQueryChange(condition, "all"),
                        attributes,
                        fields: fields.all,
                        organizations,
                        type,
                        getValidations,
                        formOptions,
                      }}
                    />
                    <Conditions
                      {...{
                        query: conditions["any"],
                        combinator: "any",
                        onQueryChange: condition => onQueryChange(condition, "any"),
                        attributes,
                        fields: fields.any,
                        organizations,
                        type,
                        getValidations,
                        formOptions,
                      }}
                    />
                  </Tab>
                )}
                {isBoard ? (
                  <Tab eventKey="columns" title={localized("Columns and Sorting")}>
                    <Columns {...{ columns, ticketAttributes: data?.ticketAttributes, sortBy, onChange }} />
                  </Tab>
                ) : (
                  <Tab
                    eventKey="actions"
                    title={`${localized("Actions")} *`}
                    tabClassName={validation.success.actions === false || !isActionsTabValid ? "tab-error" : ""}
                  >
                    <Actions
                      query={actions}
                      onQueryChange={action => onChange("actions", action)}
                      type={type}
                      getValidations={getValidations}
                    />
                  </Tab>
                )}
              </Tabs>
            </>
          )}
        </StyledModal>
      </StyledForm>
    )
  },
)

export default connect(({ ticketing }) => ({ statusList: ticketing.status.list }), {
  fetchTriggerBoards: _fetchTriggerBoards,
})(TriggerEditorModal)
