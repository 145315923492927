import { memo } from "react"
import { pluck } from "ramda"

import { Text } from "@ninjaone/components"

import {
  fetchJson,
  localizationKey,
  localized,
  localizedWith,
  reportErrorAndShowMessage,
  showSuccessMessage,
} from "js/includes/common/utils"
import { ConfirmationModal } from "js/includes/components/ConfirmationModal"
import { Flex } from "js/includes/components/Styled"

export default memo(function ConfirmDeletePlanRevisionsModal({ name, nodeId, directories, unmount }) {
  const handleConfirm = async ({ unmount }) => {
    try {
      await fetchJson(`/backup/lockhart/nodes/${nodeId}/delete-plan-revisions?planName=${name}`, {
        options: {
          method: "POST",
          body: JSON.stringify(pluck("planId", directories)),
        },
      })
      showSuccessMessage(localized("Data deletion scheduled successfully"))
    } catch (error) {
      if (!error.isHandledMfaError) {
        reportErrorAndShowMessage(error, localizationKey("Error deleting plan files"))
      }
    }
    unmount()
  }
  return (
    <ConfirmationModal
      {...{
        type: "danger",
        titleToken: localizationKey("Confirm plan data delete"),
        actionToken: localizationKey("Delete"),
        focusTrapDisabled: true,
        onConfirm: handleConfirm,
        onCancel: ({ unmount }) => {
          unmount()
        },
        unmount,
      }}
    >
      <Flex flexDirection="column">
        <Text textWrap size="sm">
          {localizedWith(
            "This action will permanently delete all backup data for plan <%dynamicBoldProp>name<%>. Data will be deleted and removed from your calculated usage in 72 hours.",
            {
              dynamicBoldProp: () => <b>{name}</b>,
            },
          )}
        </Text>
      </Flex>
    </ConfirmationModal>
  )
})
