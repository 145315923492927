import { applySpec, compose, concat, filter, gt, gte, lte, map, prop, reduce, toString } from "ramda"

import { MAX_INTEGER_VALUE } from "js/includes/common/_constants"
import {
  contains,
  isPositiveInteger,
  localized,
  parseNumber,
  rejectInactive,
  sortByFieldNameCaseInsensitive,
  validations,
  isNinjaPSAEnabledFromSettings,
  isUserAllowedToUseNinjaPSAAdministrativeActions,
  arrayToMapWithKey,
  isNilOrEmptyOrBlank,
  isNilOrEmpty,
} from "js/includes/common/utils"
import { isTicketStatus } from "js/includes/configuration/integrations/ticketing/ticketCustomStatus/common"

export const getColumnOptions = () =>
  sortByFieldNameCaseInsensitive(
    "label",
    "ASC",
  )([
    {
      value: "id",
      label: "ID", // localization removed
    },
    {
      value: "priority",
      label: localized("Priority"),
    },
    {
      value: "severity",
      label: localized("Severity"),
    },
    {
      value: "description",
      label: localized("Description"),
    },
    {
      value: "status",
      label: localized("Status"),
    },
    {
      value: "requester",
      label: localized("Requester"),
    },
    {
      value: "summary",
      label: localized("Subject"),
    },
    {
      value: "organization",
      label: localized("Organization"),
    },
    {
      value: "location",
      label: localized("Location"),
    },
    {
      value: "type",
      label: localized("Type"),
    },
    {
      value: "source",
      label: localized("Source"),
    },
    {
      value: "createTime",
      label: localized("Create Time"),
    },
    {
      value: "totalTimeTracked",
      label: localized("Total Time Tracked"),
    },
    {
      value: "totalTimeTrackedDecimal",
      label: localized("Total Time Tracked Decimal"),
    },
    {
      value: "assignedAppUser",
      label: localized("Assignee"),
    },
    {
      value: "solvedTime",
      label: localized("Solved Time"),
    },
    {
      value: "ticketForm",
      label: localized("Ticket Form"),
    },
    {
      value: "lastUpdatedTimeByAssignee",
      label: localized("Last Updated Time by Assignee"),
    },
    {
      value: "lastUpdatedTimeByRequester",
      label: localized("Last Updated Time by Requester"),
    },
    {
      value: "device",
      label: localized("Device"),
    },
    {
      value: "lastUpdatedBy",
      label: localized("Last Updated By"),
    },
    {
      value: "lastUpdated",
      label: localized("Last Updated"),
    },
    ...(isNinjaPSAEnabledFromSettings() && isUserAllowedToUseNinjaPSAAdministrativeActions()
      ? [
          {
            value: "agreement",
            label: localized("Agreement"),
          },
        ]
      : []),
  ])

export const triggerContainsHoursSinceCondition = conditions => {
  const { all } = conditions
  return all.some(({ operator }) =>
    contains(["hours_since:greater_or_equal_than", "hours_since:less_or_equal_than", "hours_since:is"], operator),
  )
}

export const getActiveAttributes = compose(
  map(
    applySpec({
      value: compose(concat("attribute:"), toString, prop("id")),
      label: prop("name"),
      type: prop("attributeType"),
      content: prop("content"),
    }),
  ),
  rejectInactive,
)

export const getFields = (type, combinator, statusList) => [
  ...(["BOARD", "TIME_BASED"].includes(type)
    ? combinator === "all"
      ? [
          { value: "ticket_created", label: localized("Time Since Ticket Created") },
          { value: "ticket_changed", label: localized("Time Since Ticket Changed") },
          { value: "assigned", label: localized("Time Since Assigned") },
          { value: "customer", label: localized("Time Since Customer Responded") },
          { value: "technician", label: localized("Time Since Technician Responded") },
          ...statusList?.map(status => ({
            value: status.id?.toString(),
            label: localized("Time Since {{status}}", { status: status.displayName }),
          })),
        ]
      : []
    : [
        {
          value: "action",
          label: localized("Action"),
        },
      ]),
  ...(combinator === "all" && type !== "BOARD"
    ? [{ value: "business_hours", label: localized("Business Hours") }]
    : []),
  { value: "type", label: localized("Type") },
  { value: "status", label: localized("Status") },
  { value: "source", label: localized("Source") },
  { value: "severity", label: localized("Severity") },
  { value: "priority", label: localized("Priority") },
  { value: "summary", label: localized("Subject") },
  { value: "description", label: localized("Description") },
  { value: "organization", label: localized("Organization") },
  { value: "requester", label: localized("Requester") },
  { value: "assignee", label: localized("Assignee") },
  { value: "tags", label: localized("Tags") },
  ...(["EVENT_BASED", "TIME_BASED", "BOARD"].includes(type)
    ? [
        { value: "form", label: localized("Form") },
        { value: "location", label: localized("Location") },
      ]
    : []),
  ...(type === "EVENT_BASED"
    ? [
        { value: "commentType", label: localized("Comment Type") },
        { value: "updated_by", label: localized("Updated By") },
      ]
    : []),
]

const overrideValidation = () => ({ success: true })

function validateTimeSince(value, values) {
  if (!value) return overrideValidation()
  const [hour, minute] = value.split(":")

  const parsedHour = parseNumber(hour)
  const parsedMinute = parseNumber(minute)

  if (gte(parsedHour, 0) && gt(parsedMinute, 0)) return overrideValidation()

  const required = validations.required(parsedHour)

  const isNegativeInteger = !isPositiveInteger(parsedHour) && localized("Value must be a positive integer")

  return {
    success: required.success && !isNegativeInteger,
    message: required.message || isNegativeInteger,
  }
}

const findAttribute = (attributes, field) => attributes.find(({ value }) => value === field)

function validatePositiveInteger(value) {
  const parsedValue = parseNumber(value)
  const required = validations.required(parsedValue)

  const isNegativeInteger = !isPositiveInteger(parsedValue) && localized("Value must be a positive integer")

  const isNotLessThanOrEqualToMaxInt =
    !lte(parsedValue, MAX_INTEGER_VALUE) && `${localized("Value must be less than or equal to")} ${MAX_INTEGER_VALUE}`

  return {
    success: required.success && !isNegativeInteger && !isNotLessThanOrEqualToMaxInt,
    message: required.message || isNegativeInteger || isNotLessThanOrEqualToMaxInt,
  }
}

const getCustomValidations = (type, operator) => {
  if (["present", "not_present"].includes(operator)) return overrideValidation

  switch (type) {
    case "NUMERIC":
      return validatePositiveInteger
    default:
      return validations.required
  }
}

export const getAsArray = value => {
  if (isNilOrEmpty(value)) {
    return []
  }
  return Array.isArray(value) ? value : [value]
}

export const getSelectedValue = ({ value, isMulti, valuesMap, idKey = "id", labelKey = "name" }) => {
  if (isNilOrEmptyOrBlank(value)) {
    return isMulti ? [] : null
  }

  const values = String(value).split(",")

  const selectedValues = map(id => {
    const metadataValue = valuesMap[id]
    return metadataValue ? metadataValue : { [idKey]: id, [labelKey]: localized("N/A") }
  }, values)

  return isMulti ? selectedValues : selectedValues[0]
}

const convertFormValueToArray = ({ value, valueToArrayConverter }) => {
  if (valueToArrayConverter) {
    return valueToArrayConverter(value)
  }
  const parsedValue = isNilOrEmptyOrBlank(value) ? null : `${value}`
  return filter(id => !!id, parsedValue?.split(",") || [])
}

const validateRequiredOrWrongValues = ({
  metaData,
  errorMessage,
  metadataIdKey = "id",
  checkWrongValue,
  valueToArrayConverter,
}) => {
  const metaDataArray = getAsArray(metaData)
  const metadataMap = arrayToMapWithKey(metadataIdKey, metaDataArray)

  return value => {
    const values = convertFormValueToArray({ value, valueToArrayConverter })
    if (!values.length) {
      return validations.required("")
    }
    if (values.some(id => checkWrongValue(metadataMap[id]))) {
      return { success: false, message: errorMessage }
    }
    return overrideValidation()
  }
}

export const getValuesMap = ({ options = [], metaData, metaDataMapper, idKey = "id" }) => {
  const metaDataArray = metaDataMapper ? getAsArray(metaData).map(metaDataMapper) : getAsArray(metaData)
  return arrayToMapWithKey(idKey, [...options, ...metaDataArray])
}

export const getUsersDeletedValidation = ({ metaData, metadataIdKey, valueToArrayConverter }) => {
  return validateRequiredOrWrongValues({
    metaData,
    metadataIdKey,
    checkWrongValue: data => data?.deleted === true,
    errorMessage: localized("Must not contain deleted users"),
    valueToArrayConverter,
  })
}

export const getOrganizationsDeletedValidation = ({ metaData }) => {
  return validateRequiredOrWrongValues({
    metaData,
    checkWrongValue: data => data?.deleted === true,
    errorMessage: localized("Must not contain deleted organizations"),
  })
}

export const mapExistingUidValues = objectList =>
  reduce(
    (acc, { uid }) => {
      if (uid) {
        acc.push(uid)
      }
      return acc
    },
    [],
    objectList || [],
  )

export const buildGetValidations = attributes => (field, operator, metaData) => {
  if (isTicketStatus(field)) return validateTimeSince

  switch (field) {
    case "ticket_created":
    case "ticket_changed":
    case "assigned":
    case "customer":
    case "technician":
      return validateTimeSince
    case "organization":
    case "requester":
    case "assignee":
    case "tags":
    case "form":
    case "location":
      if (["present", "not_present"].includes(operator)) {
        return overrideValidation
      }
      if (["requester", "assignee"].includes(field)) {
        return getUsersDeletedValidation({ metaData, metadataIdKey: field === "assignee" ? "id" : "uid" })
      }
      if ("form" === field) {
        return validateRequiredOrWrongValues({
          metaData,
          checkWrongValue: data => data?.active === false,
          errorMessage: localized("Must not contain inactive forms"),
        })
      }
      if ("location" === field) {
        return validateRequiredOrWrongValues({
          metaData,
          checkWrongValue: data => data?.deleted === true,
          errorMessage: localized("Must not contain deleted locations"),
        })
      }
      if ("organization" === field) {
        return getOrganizationsDeletedValidation({ metaData })
      }

      return validations.required
    case "business_hours":
      return overrideValidation
    case "type":
    case "status":
    case "source":
    case "severity":
    case "priority":
      return operator === "changed" ? overrideValidation : validations.required

    // Validations for actions
    case "ticket":
      if (["requesterUid", "assignedAppUserId"].includes(operator)) {
        return getUsersDeletedValidation({ metaData, metadataIdKey: operator === "assignedAppUserId" ? "id" : "uid" })
      }
      if ("organization" === operator) {
        return getOrganizationsDeletedValidation({ metaData })
      }
      return validations.required
    case "SEND_EMAIL":
      return getUsersDeletedValidation({
        metadataIdKey: "uid",
        metaData: metaData?.toUsers,
        valueToArrayConverter: value => {
          if (isNilOrEmpty(value)) {
            return []
          }
          const { uids = [], keys = [], emails = [] } = value
          return mapExistingUidValues([...uids, ...keys, ...emails])
        },
      })
    case "add_cc_list":
    case "set_cc_list":
      return getUsersDeletedValidation({
        metaData: metaData?.users,
        metadataIdKey: "uid",
        valueToArrayConverter: value => mapExistingUidValues(value),
      })
    default:
      const customAttribute = findAttribute(attributes, field)
      return !!customAttribute ? getCustomValidations(customAttribute.type, operator) : validations.required
  }
}

export const defineValidations = ({ getValidations, field, operator, metaData }) => {
  if (getValidations) {
    const val = getValidations(field, operator, metaData)
    if (val) return val
  }
  return validations.required
}

/**
 * Sets a validation field in every element in the list that does not have that field.
 *
 * @param {Object} data An object with the following keys
 * - list, an array of parsed conditions or actions (not the structure the server returns)
 * - getValidations, function that returns validations for specific actions or conditions
 * @returns a new list.
 */
export const setMissingValidations = ({ list, getValidations }) => {
  return (list || []).map(condition => {
    if (condition.validation) {
      return condition
    }

    const { field, operator, metaData, value } = condition
    return {
      ...condition,
      touched: true,
      validation: defineValidations({ getValidations, field, operator, metaData })(value),
    }
  })
}
