import {
  getDailyTimeDescription,
  getWeeklyTimeDescription,
  getMonthlyTimeDescription,
  getWeekOfMonthDescription,
  getIntervalTimeDescription,
} from "js/includes/common/utils"

export const getScheduleBackupDescription = backupPlan => {
  const { schedule } = backupPlan
  const { dayOfMonth, weekDays, weekOfMonth } = schedule

  const hour = schedule.hour || schedule.scheduleHour
  const minute = schedule.minute || schedule.scheduleMinute
  const type = schedule.type || schedule.scheduleType

  switch (type) {
    case "DAILY":
      return getDailyTimeDescription(hour, minute)
    case "WEEKLY":
      return getWeeklyTimeDescription(hour, minute, weekDays)
    case "MONTHLY":
      return getMonthlyTimeDescription(hour, minute, dayOfMonth)
    case "WEEK_OF_MONTH":
      return getWeekOfMonthDescription(hour, minute, weekOfMonth, weekDays)
    case "INTERVAL":
      return getIntervalTimeDescription(hour, minute)
    default:
      return ""
  }
}
