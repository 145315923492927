import { memo, useState, useEffect } from "react"
import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { Text } from "@ninjaone/components"
import { getTimeDiffInMinutes, getLastUpdatedLabel } from "../utils"

const INTERVAL_TIME = 60000

export default memo(function LastUpdatedLabel({ lastUpdated, onlyShowAfter5Minutes }) {
  const [timeDiffInMinutes, setTimeDiffInMinutes] = useState(null)
  const label = getLastUpdatedLabel(timeDiffInMinutes)
  const hidden = onlyShowAfter5Minutes && timeDiffInMinutes <= 5

  useEffect(() => {
    setTimeDiffInMinutes(getTimeDiffInMinutes(lastUpdated))

    const interval = setInterval(() => {
      setTimeDiffInMinutes(getTimeDiffInMinutes(lastUpdated))
    }, INTERVAL_TIME)

    return () => clearInterval(interval)
  }, [lastUpdated])

  return (
    !hidden && (
      <Box>
        <Text size="sm" color="colorTextWeakest">
          {label}
        </Text>
      </Box>
    )
  )
})
