const initialState = {}

export default function smtpHealth(state = initialState, action) {
  switch (action.type) {
    case "TICKETING_SET_SMTP_HEALTH":
      return action.payload
    case "TICKETING_UPDATE_SMTP_HEALTH_STATUS":
      return { ...state, health: action.status }
    default:
      return state
  }
}
