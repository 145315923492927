import { useEffect, useState } from "react"
import { INSERT_TABLE_COMMAND } from "@lexical/table"

import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"

import Input from "../../Input"
import Modal from "../../Modal"

const MAX_ROWS = 50
const MAX_COLUMNS = 10

export const InsertTableDialog = ({ activeEditor, backgroundClassName, unmount }) => {
  const [rows, setRows] = useState("5")
  const [columns, setColumns] = useState("5")
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    const row = Number(rows)
    const column = Number(columns)
    if (row && row > 0 && row <= MAX_ROWS && column && column > 0 && column <= MAX_COLUMNS) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [rows, columns])

  const onClick = () => {
    // TODO: Maybe read this from the config?
    const includeHeaders = false

    activeEditor.dispatchCommand(INSERT_TABLE_COMMAND, {
      columns,
      rows,
      includeHeaders,
    })

    unmount()
  }

  return (
    <Modal
      backgroundClassName={backgroundClassName}
      heading={localized("Insert table")}
      withCloseX
      buttons={[
        {
          onClick,
          disabled,
          labelToken: localized("Insert"),
          variant: "primary",
        },
      ]}
      unmount={unmount}
    >
      <Input
        required
        type="number"
        value={rows}
        onChange={e => setRows(e.target.value)}
        labelText={localized("Rows")}
        placeholder={localized("5")}
        min={1}
        max={MAX_ROWS}
      />
      <Input
        required
        type="number"
        value={columns}
        onChange={e => setColumns(e.target.value)}
        labelText={localized("Columns")}
        placeholder={localized("5")}
        min={1}
        max={MAX_COLUMNS}
      />
    </Modal>
  )
}
