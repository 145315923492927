import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { createPortal } from "react-dom"
import styled from "@emotion/styled"

import tokens from "@ninjaone/tokens"
import { ChevronDownLightIcon } from "@ninjaone/icons"

import Text from "../../Text"
import Tooltip from "../../Tooltip"

export const DROPDOWN_ICON_COLOR = "colorTextWeak"
export const DROPDOWN_TEXT_COLOR = "colorTextStrong"
export const DROPDOWN_TEXT_DISABLED_COLOR = "colorTextDisabled"
export const DROPDOWN_ICON_DISABLED_COLOR = "colorTextDisabled"

export const StyledDropdown = styled.div`
  z-index: 9999;
  display: block;
  position: fixed;
  border: 1px solid ${({ theme }) => theme.colorBorderWeak};
  border-radius: ${tokens.borderRadius[1]};
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  box-shadow: ${({ theme }) => theme.elevationWeak};
  ${({ withMaxHeight }) =>
    withMaxHeight &&
    `
  max-height: 250px;
  overflow-y: auto;
  `}
`

export const StyledDropdownItem = styled.button`
  cursor: pointer;
  padding: 0 ${tokens.spacing[2]};
  height: 28px;
  gap: ${tokens.spacing[2]};
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  border: 0;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }

  &:first-child {
    margin-top: ${tokens.spacing[1]};
  }

  &:last-child {
    margin-bottom: ${tokens.spacing[1]};
  }
`

const DropdownContext = createContext(null)

const dropdownPadding = 4

export const DropdownItem = ({ children, className, onClick, onMouseDown, title }) => {
  const ref = useRef(null)

  const dropdownContext = useContext(DropdownContext)
  if (dropdownContext === null) throw new Error("DropdownItem must be a child of Droppown")

  const { registerItem } = dropdownContext

  useEffect(() => {
    if (ref && ref.current) {
      registerItem(ref)
    }
  }, [registerItem])

  return (
    <StyledDropdownItem
      {...{
        ref,
        className,
        onClick,
        onMouseDown,
        title,
        type: "button",
      }}
    >
      {children}
    </StyledDropdownItem>
  )
}

export const DropdownItems = ({ children, dropdownClassName, dropdownRef, onClose, withMaxHeight }) => {
  const [items, setItems] = useState()
  const [highlightedItem, setHighlightedItem] = useState()

  const registerItem = useCallback(
    itemRef => {
      setItems(prev => (prev ? [...prev, itemRef] : [itemRef]))
    },
    [setItems],
  )

  const handleKeyDown = event => {
    if (!items) return

    const key = event.key

    if (["Escape", "ArrowUp", "ArrowDown", "Tab"].includes(key)) {
      event.preventDefault()
    }

    if (key === "Escape" || key === "Tab") {
      onClose()
    } else if (key === "ArrowUp") {
      setHighlightedItem(prev => {
        if (!prev) return items[0]
        const index = items.indexOf(prev) - 1
        return items[index === -1 ? items.length - 1 : index]
      })
    } else if (key === "ArrowDown") {
      setHighlightedItem(prev => {
        if (!prev) return items[0]
        return items[items.indexOf(prev) + 1]
      })
    }
  }

  const contextValue = useMemo(
    () => ({
      registerItem,
    }),
    [registerItem],
  )

  useEffect(() => {
    if (items && !highlightedItem) {
      setHighlightedItem(items[0])
    }

    if (highlightedItem && highlightedItem.current) {
      highlightedItem.current.focus()
    }
  }, [items, highlightedItem])

  return (
    <DropdownContext.Provider value={contextValue}>
      <StyledDropdown
        className={dropdownClassName}
        ref={dropdownRef}
        onKeyDown={handleKeyDown}
        withMaxHeight={withMaxHeight}
      >
        {children}
      </StyledDropdown>
    </DropdownContext.Provider>
  )
}

export const Dropdown = ({
  dropdownClassName,
  disabled = false,
  tooltipLabel,
  buttonLabel,
  buttonAriaLabel,
  buttonCss,
  ButtonIcon,
  ButtonComponent,
  buttonIconClassName,
  children,
  stopCloseOnClickSelf,
  withMaxHeight = true,
}) => {
  const dropdownRef = useRef(null)
  const buttonRef = useRef(null)
  const [showDropDown, setShowDropDown] = useState(false)

  const handleClose = () => {
    setShowDropDown(false)
    if (buttonRef && buttonRef.current) {
      buttonRef.current.focus()
    }
  }

  useEffect(() => {
    const button = buttonRef.current
    const dropDown = dropdownRef.current

    if (showDropDown && button !== null && dropDown !== null) {
      const { top, left } = button.getBoundingClientRect()
      dropDown.style.top = `${top + button.offsetHeight + dropdownPadding}px`
      dropDown.style.left = `${Math.min(left, window.innerWidth - dropDown.offsetWidth - 20)}px`
    }
  }, [showDropDown])

  useEffect(() => {
    const button = buttonRef.current

    if (button !== null && showDropDown) {
      const handle = event => {
        const target = event.target
        if (stopCloseOnClickSelf) {
          if (dropdownRef.current && dropdownRef.current.contains(target)) return
        }
        if (!button.contains(target)) {
          setShowDropDown(false)
        }
      }
      document.addEventListener("click", handle)

      return () => {
        document.removeEventListener("click", handle)
      }
    }
  }, [showDropDown, stopCloseOnClickSelf])

  useEffect(() => {
    const handleButtonPositionUpdate = () => {
      if (showDropDown) {
        const button = buttonRef.current
        const dropDown = dropdownRef.current
        if (button !== null && dropDown !== null) {
          const { top } = button.getBoundingClientRect()
          const newPosition = top + button.offsetHeight + dropdownPadding
          if (newPosition !== dropDown.getBoundingClientRect().top) {
            dropDown.style.top = `${newPosition}px`
          }
        }
      }
    }

    document.addEventListener("scroll", handleButtonPositionUpdate)

    return () => {
      document.removeEventListener("scroll", handleButtonPositionUpdate)
    }
  }, [buttonRef, dropdownRef, showDropDown])

  const buttonComponent = (
    <ButtonComponent
      type="button"
      disabled={disabled}
      aria-label={buttonAriaLabel || buttonLabel}
      onClick={() => setShowDropDown(prevState => !prevState)}
      ref={buttonRef}
    >
      {ButtonIcon && <ButtonIcon color={disabled ? DROPDOWN_ICON_DISABLED_COLOR : DROPDOWN_ICON_COLOR} />}
      {buttonLabel && (
        <Text size="sm" color={disabled ? DROPDOWN_TEXT_DISABLED_COLOR : DROPDOWN_TEXT_COLOR}>
          {buttonLabel}
        </Text>
      )}
      <ChevronDownLightIcon color={disabled ? DROPDOWN_ICON_DISABLED_COLOR : "colorTextWeakest"} size="sm" />
    </ButtonComponent>
  )

  return (
    <>
      {tooltipLabel ? <Tooltip label={tooltipLabel}>{buttonComponent}</Tooltip> : buttonComponent}
      {showDropDown &&
        createPortal(
          <DropdownItems
            dropdownClassName={dropdownClassName}
            dropdownRef={dropdownRef}
            onClose={handleClose}
            withMaxHeight={withMaxHeight}
          >
            {children}
          </DropdownItems>,
          document.body,
        )}
    </>
  )
}
