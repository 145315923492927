import React from "react"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { faSpinner } from "@fortawesome/pro-solid-svg-icons"
import { StyledFontAwesomeIcon, StyledSpan } from "./Styled"

export default function Loading({
  className,
  loadingText,
  css,
  spinnerColor,
  withExtraSpace,
  hideLoadingText,
  ...style
}) {
  return (
    <StyledSpan {...{ className, css, ...style }}>
      <StyledFontAwesomeIcon
        {...{
          icon: faSpinner,
          spin: true,
          ...(spinnerColor && { color: spinnerColor }),
        }}
      />
      &nbsp;{withExtraSpace && <>&nbsp;</>}
      {!hideLoadingText && localized(loadingText || localizationKey("Loading"))}
    </StyledSpan>
  )
}
