import { assocPath, dissocPath } from "ramda"
import { getCompletePathToItem } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/helpers"

const updatePolicyItem = (policy, { pathToItem, parentPolicy, newItem }) => {
  const completePathToItem = getCompletePathToItem(pathToItem)
  return assocPath(completePathToItem, newItem, policy)
}

const deletePolicyItem = (policy, { pathToItem }) => {
  const completePathToItem = getCompletePathToItem(pathToItem)
  return dissocPath(completePathToItem, policy)
}

export default function policy(state = null, action) {
  switch (action.type) {
    case "CLEAR_POLICY":
      return null
    case "SET_POLICY":
      return action.policy
    case "UPDATE_POLICY_ITEM":
      return updatePolicyItem(state, action)
    case "DELETE_POLICY_ITEM":
      return deletePolicyItem(state, action)
    case "UPDATE_POLICY_NAME":
      return { ...state, name: action.name, description: action.description, enabled: action.enabled }
    case "SHOW_NOT_FOUND_ERROR":
      return { showNotFoundError: true }
    default:
      return state
  }
}
