export default function parentPolicy(state = null, action) {
  switch (action.type) {
    case "CLEAR_PARENT_POLICY":
      return null
    case "SET_PARENT_POLICY":
      return action.parentPolicy
    default:
      return state
  }
}
