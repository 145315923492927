const sideBar = (state = !!localStorage.getItem("sideNavBar.collapsed") || false, action) => {
  switch (action.type) {
    case "SET_IS_SIDE_BAR_COLLAPSED":
      return action.isSideBarCollapsed
    default:
      return state
  }
}

export default sideBar
