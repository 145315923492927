const initialState = {
  agreements: {
    active: {
      globalFilter: "",
    },
    inactive: {
      globalFilter: "",
    },
    archived: {
      globalFilter: "",
    },
  },
}

export default function dataTables(state = initialState, action) {
  switch (action.type) {
    case "NINJA_PSA_SET_DATA_TABLES_DATA":
      return { ...state, ...action.payload }
    case "NINJA_PSA_RESET_DATA_TABLES_DATA":
      const sectionKey = action.payload
      if (sectionKey in initialState) {
        return {
          ...state,
          [sectionKey]: initialState[sectionKey],
        }
      }
      return initialState
    default:
      return state
  }
}
