const usersFilter = (state = "", action) => {
  switch (action.type) {
    case "SET_USERS_FILTER":
      return action.usersFilter
    default:
      return state
  }
}

export default usersFilter
