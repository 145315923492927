const confirmUninstallSoftwareInputValue = (state = "", action) => {
  switch (action.type) {
    case "SET_CONFIRM_UNINSTALL_INPUT_VALUE":
      return action.value
    default:
      return state
  }
}

export default confirmUninstallSoftwareInputValue
