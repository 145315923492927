import { append, remove, insert } from "ramda"

export default function productMappings(state = [], action) {
  switch (action.type) {
    case "SET_CW_PRODUCT_MAPPINGS":
      return action.payload
    case "ADD_CW_PRODUCT_MAPPING":
      return append(action.newProductMapping, state)
    case "REMOVE_CW_PRODUCT_MAPPING":
      const deleteIndex = state.findIndex(ctm => ctm.id === action.id)
      return remove(deleteIndex, 1, state)
    case "EDIT_CW_PRODUCT_MAPPING":
      const editIndex = state.findIndex(ctm => ctm.id === action.productMapping.id)
      const tempProductMappings = remove(editIndex, 1, state)
      return insert(editIndex, action.productMapping, tempProductMappings)
    default:
      return state
  }
}
