import { SortDirection } from "react-virtualized"
import { combineReducers } from "redux"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import sortBy from "js/state/reducers/common/tableSorting/sortBy"
import sortDirection from "js/state/reducers/common/tableSorting/sortDirection"
import endUsers from "./endUsers"

export default combineReducers({
  endUsers,
  endUsersListSort: combineReducers({
    sortBy: createNamedWrapperReducer(sortBy, "endUsersListSort", "name"),
    sortDirection: createNamedWrapperReducer(sortDirection, "endUsersListSort", SortDirection.ASC),
  }),
})
