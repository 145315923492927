import { mergeRight } from "ramda"

const initialState = {
  types: {},
  statuses: {},
}

export default function companyFilterMap(state = initialState, action) {
  switch (action.type) {
    case "SET_CW_COMPANY_FILTER_TYPES":
      return mergeRight(state, {
        types: action.types,
      })
    case "SET_CW_COMPANY_FILTER_STATUSES":
      return mergeRight(state, {
        statuses: action.statuses,
      })
    case "SET_CW_COMPANY_FILTER_MAP":
      return action.payload
    default:
      return state
  }
}
