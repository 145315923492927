import { useEffect, useRef } from "react"
import styled from "@emotion/styled"

import { CloseIcon, SearchIcon } from "@ninjaone/icons"
import { getLineHeight, getTextSize, sizer } from "@ninjaone/utils"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

const StyledSearchBar = styled.div`
  display: flex;
  height: ${sizer(10)};
  width: ${({ width }) => width ?? sizer(150)};
  border: 1px solid ${({ theme, borderColor }) => borderColor || theme.colorBorderWeak};
  box-sizing: border-box;
  border-radius: ${sizer(1)};
  background-color: ${({ theme }) => theme.colorBackgroundWidget};

  &:has(input:disabled) {
    background-color: ${({ theme }) => theme.colorBackgroundInputDisabled};
  }

  & input {
    height: 100%;
  }

  &:not(:has(input:disabled)):focus-within {
    border: 1px solid ${({ theme, borderColor }) => borderColor || theme.colorBorderWeak};
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
`
const StyledPlaceholder = styled.span`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: ${sizer(3)};
  pointer-events: none;
`

const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  width: 100%;
  outline: none;
  border-radius: ${sizer(1)};
  border: none;
  padding: ${sizer(2)};
  padding-right: ${({ value }) => sizer(!!value ? 1 : 2)};
  font-size: ${getTextSize("sm")};
  font-weight: 400;
  user-select: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: ${getLineHeight("sm")};
  letter-spacing: normal;
  color: ${({ color, theme }) => (color ? theme.color[color] : theme.colorTextStrong)};
  margin: 0;

  &:disabled {
    color: ${({ theme }) => theme.colorTextDisabled};
    background-color: ${({ theme }) => theme.colorBackgroundInputDisabled};
  }

  &:disabled::placeholder {
    color: ${({ theme }) => theme.colorTextDisabled};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colorTextWeakest};
  }

  ::-ms-clear,
  ::-ms-reveal {
    display: none;
  }

  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }
`

const StyledCloseButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.colorTextStrong};
  outline: none;
  border-radius: ${sizer(0, 1, 1, 0)};

  &:hover {
    background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest};
  }

  &:focus-visible {
    background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest};
  }

  &:disabled {
    color: ${({ theme }) => theme.colorTextDisabled};
    cursor: initial;
  }
`

export default function SearchBar({
  placeholderText,
  width,
  value,
  clearInput,
  autofocus,
  borderColor,
  buttonId,
  rightIcon,
  disabled,
  ...rest
}) {
  const searchBarRef = useRef()

  useEffect(() => {
    if (searchBarRef?.current && autofocus) {
      searchBarRef.current.focus()
    }
  }, [searchBarRef, autofocus])

  return (
    <StyledSearchBar {...{ width, borderColor }}>
      <StyledPlaceholder>
        <SearchIcon color="colorTextWeakest" />
      </StyledPlaceholder>
      <StyledInput
        type="search"
        placeholder={placeholderText}
        value={value}
        disabled={disabled}
        {...rest}
        ref={searchBarRef}
      />
      {!!value && clearInput && (
        <StyledCloseButton
          type="button"
          onClick={clearInput}
          aria-label={localized("Clear")}
          id={buttonId ?? null}
          disabled={disabled}
        >
          <CloseIcon />
        </StyledCloseButton>
      )}
      {rightIcon}
    </StyledSearchBar>
  )
}
