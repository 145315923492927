import { DOJO_ARTICLES_PREFIX, DOJO_SECTION_PREFIX, ninjaReportError } from "js/includes/common/utils"
import { dojoArticles } from "./dojoArticles"

// Articles are matched by their "route" property using the react-router matching algorithm.
// - An "exact" flag can be added for exact matches.
// - Routes can contain wilcards "*" to represent dynamic path segments that can match any characters including slashes "/"
// - Routes can also contain params (":id", ":page", etc..) to represent dynamic path segments that match specific values in the URL path
//
// Examples:
//
// {
//  route: "/systemDashboard",
// }
// matches on "/systemDashboard", "/systemDashboard/overview", and "/systemDashboard/threats/active"
//
// {
//  route: "/systemDashboard",
//  exact: true
// }
// matches on "/systemDashboard"
//
// {
//  route: "/systemDashboard/*",
// }
// matches on "/systemDashboard", "/systemDashboard/overview", and "/systemDashboard/threats/active"
//
// {
//  route: "/systemDashboard/*",
//  exact: true
// }
// matches on "/systemDashboard/overview" and "/systemDashboard/threats/active"
//
// {
//  route: "/systemDashboard/:page",
//  exact: true
// }
// matches on "/systemDashboard/overview" and "/systemDashboard/pendingScripts"
//
// {
//  route: "/editor/user/:id/general",
//  exact: true
// }
// matches on "/editor/user/1/general" and "/editor/user/33/general"
//
// {
//  route: "/editor/user/:id/*",
//  exact: true
// }
// matches on "/editor/user/1/general" and "/editor/user/1/roles"

const enrichDojoArticles = articles => {
  const copy = Object.assign({}, articles)
  const keys = Object.keys(copy)

  keys.forEach(id => {
    const article = articles[id]

    copy[id] = {
      ...article,
      href: article.isSection ? `${DOJO_SECTION_PREFIX}/${id}` : `${DOJO_ARTICLES_PREFIX}/${id}`,
    }
  })

  return copy
}

const suggestedArticles = enrichDojoArticles(dojoArticles)

export const getSuggestedArticles = articleIds => {
  if (!Array.isArray(articleIds) || !articleIds.length) return []

  return articleIds
    .filter(articleId => {
      const article = suggestedArticles[articleId]

      if (!article) {
        const errorMessage = `Unable to find suggested article with the following id: ${articleId}`
        console.error(errorMessage)
        ninjaReportError(errorMessage)
        return false
      }

      return true
    })
    .map(articleId => suggestedArticles[articleId])
}

export const suggestedArticlesByRoute = [
  {
    route: "/getStarted",
    exact: true,
    articles: getSuggestedArticles(["16050515738765"]),
  },
  {
    route: "/systemDashboard/overview",
    exact: true,
    articles: getSuggestedArticles(["207436993", "22291610271757", "16050515738765"]),
  },
  {
    route: "/systemDashboard/maintenance",
    exact: true,
    articles: getSuggestedArticles(["207436993", "207436993#h_01ENKFJVN8N4Y7HDN9SY8H5SMH", "18159591896717"]),
  },
  {
    route: "/systemDashboard/pendingScripts",
    exact: true,
    articles: getSuggestedArticles(["207436993", "207436993#h_01ENKFJVN8N4Y7HDN9SY8H5SMH"]),
  },
  {
    route: "/systemDashboard/alerts",
    exact: true,
    articles: getSuggestedArticles(["207436993", "207436993#h_01ENKFJVN8N4Y7HDN9SY8H5SMH", "18159591896717"]),
  },
  {
    route: "/systemDashboard/threats/:page",
    exact: true,
    articles: getSuggestedArticles(["207436993", "207436993#h_01ENKFJVN8N4Y7HDN9SY8H5SMH", "18159591896717"]),
  },
  {
    route: "/systemDashboard/approvals/:page",
    exact: true,
    articles: getSuggestedArticles(["207436993", "207436993#h_01ENKFJVN8N4Y7HDN9SY8H5SMH", "18159591896717"]),
  },
  {
    route: "/systemDashboard/software/:page",
    exact: true,
    articles: getSuggestedArticles(["207436993", "360004879451", "10496973792397"]),
  },
  {
    route: "/systemDashboard/osPatches/:page",
    exact: true,
    articles: getSuggestedArticles(["207436993", "10496973792397#h_01GH0WXKVVWWS7PGCN7NT61BQE"]),
  },
  {
    route: "/systemDashboard/backup/:page",
    exact: true,
    articles: getSuggestedArticles(["207436993", "360046961572"]),
  },
  {
    route: "/systemDashboard/activities/:page",
    exact: true,
    articles: getSuggestedArticles(["207436993", "207436993#h_01ENKFMJ9P937W0HNZZPNHYCXA", "207436983"]),
  },
  {
    route: "/systemDashboard/activities/remoteControl",
    exact: true,
    articles: getSuggestedArticles(["207436993", "207436993#h_01ENKFMJ9P937W0HNZZPNHYCXA", "22291470699661"]),
  },
  {
    route: "/systemDashboard/activities/remoteTools",
    exact: true,
    articles: getSuggestedArticles(["207436993", "207436993#h_01ENKFMJ9P937W0HNZZPNHYCXA", "22291470699661"]),
  },
  {
    route: "/systemDashboard/knowledgeBase",
    articles: getSuggestedArticles(["207436993", "6921324974861"]),
  },
  {
    route: "/customerDashboard/:id/overview",
    exact: true,
    articles: getSuggestedArticles(["207436993#01H26M1DX19T64GKZ0A0MQ0DJK"]),
  },
  {
    route: "/customerDashboard/:id/location/:location",
    exact: true,
    articles: getSuggestedArticles(["207436993", "360016311651"]),
  },
  {
    route: "/customerDashboard/:id/maintenance",
    exact: true,
    articles: getSuggestedArticles(["207436993", "207436993#h_01ENKFJVN8N4Y7HDN9SY8H5SMH", "15287965524109"]),
  },
  {
    route: "/customerDashboard/:id/pendingScripts",
    exact: true,
    articles: getSuggestedArticles(["207436993", "207436993#h_01ENKFJVN8N4Y7HDN9SY8H5SMH", "15287965524109"]),
  },
  {
    route: "/customerDashboard/:id/alerts",
    exact: true,
    articles: getSuggestedArticles(["207436993", "207436993#h_01ENKFJVN8N4Y7HDN9SY8H5SMH", "15287965524109"]),
  },
  {
    route: "/customerDashboard/:id/threats/:page",
    exact: true,
    articles: getSuggestedArticles(["207436993", "207436993#h_01ENKFJVN8N4Y7HDN9SY8H5SMH", "15287965524109"]),
  },
  {
    route: "/customerDashboard/:id/approvals/:page",
    exact: true,
    articles: getSuggestedArticles(["207436993", "207436993#h_01ENKFJVN8N4Y7HDN9SY8H5SMH", "15287965524109"]),
  },
  {
    route: "/customerDashboard/:id/software/:page",
    exact: true,
    articles: getSuggestedArticles(["207436993", "360004879451"]),
  },
  {
    route: "/customerDashboard/:id/osPatches/:page",
    exact: true,
    articles: getSuggestedArticles(["207436993", "10496973792397#h_01GH0WXKVVWWS7PGCN7NT61BQE"]),
  },
  {
    route: "/customerDashboard/:id/backup/:page",
    exact: true,
    articles: getSuggestedArticles(["207436993", "360046961572"]),
  },
  {
    route: "/customerDashboard/:id/activities/:page",
    exact: true,
    articles: getSuggestedArticles(["207436993", "207436993#01H26RF5NCTCYPMDE5KSPMGJZV"]),
  },
  {
    route: "/customerDashboard/:id/documentation/*",
    exact: true,
    articles: getSuggestedArticles(["207436993", "6921324974861"]),
  },
  {
    route: "/customerDashboard/:id/customFields",
    exact: true,
    articles: getSuggestedArticles(["207436993"]),
  },
  {
    route: "/customerDashboard/:id/ticketing",
    exact: true,
    articles: getSuggestedArticles(["207436993"]),
  },
  {
    route: "/deviceDashboard/:id/overview",
    exact: true,
    articles: getSuggestedArticles(["207436993", "15287965524109", "6344435230477"]),
  },
  {
    route: "/deviceDashboard/:id/details",
    exact: true,
    articles: getSuggestedArticles(["207436993", "15287965524109", "360051670071"]),
  },
  {
    route: "/deviceDashboard/:id/settings",
    exact: true,
    articles: getSuggestedArticles(["207436993", "15287965524109", "360051670071"]),
  },
  {
    route: "/deviceDashboard/:id/software/:page",
    exact: true,
    articles: getSuggestedArticles(["207436993", "15287965524109", "360051670071", "360004879451"]),
  },
  {
    route: "/deviceDashboard/:id/osPatches/:page",
    exact: true,
    articles: getSuggestedArticles(["207436993", "10496973792397#h_01GH0WXKVVWWS7PGCN7NT61BQE"]),
  },
  {
    route: "/deviceDashboard/:id/tools/:page",
    exact: true,
    articles: getSuggestedArticles(["207436993", "360030662192"]),
  },
  {
    route: "/deviceDashboard/:id/backup/:page",
    exact: true,
    articles: getSuggestedArticles(["207436993", "360046961572"]),
  },
  {
    route: "/deviceDashboard/:id/activities/:page",
    exact: true,
    articles: getSuggestedArticles(["207436993", "207436993#01H26RHS59YY6FTY12V2WNYH04", "207436983"]),
  },
  {
    route: "/deviceDashboard/:id/customFields",
    exact: true,
    articles: getSuggestedArticles(["207436993", "18601842971789", "4402354243981"]),
  },
  {
    route: "/deviceDashboard/:id/ticketing",
    exact: true,
    articles: getSuggestedArticles(["207436993", "360056423691", "4439200196237"]),
  },
  {
    route: "/administration/general/settings",
    exact: true,
    articles: getSuggestedArticles(["360004175091", "213529286"]),
  },
  {
    route: "/administration/general/branding/:page",
    exact: true,
    articles: getSuggestedArticles(["360021397911", "360021149472", "360004175091"]),
  },
  {
    route: "/administration/general/activities",
    exact: true,
    articles: getSuggestedArticles(["207436983", "18209364145293", "360004175091"]),
  },
  {
    route: "/administration/apps/ninjaPatching",
    exact: true,
    articles: getSuggestedArticles(["360060061732", "17610755368845", "115003718266", "360004175091"]),
  },
  {
    route: "/administration/users/technicians",
    exact: true,
    articles: getSuggestedArticles(["4409384586637", "360003242791"]),
  },
  {
    route: "/administration/users/technicianRoles",
    exact: true,
    articles: getSuggestedArticles(["4409384586637", "360003242791", "4424709904781"]),
  },
  {
    route: "/administration/users/singleSignOn",
    exact: true,
    articles: getSuggestedArticles(["4409384586637", "15650871262605", "360059862912"]),
  },
  {
    route: "/administration/users/technicianSecuritySettings",
    exact: true,
    articles: getSuggestedArticles(["4409384586637", "360003242791", "4424787875213"]),
  },
  {
    route: "/administration/users/endUsers",
    exact: true,
    articles: getSuggestedArticles(["4409384586637", "360059027052", "360035549512"]),
  },
  {
    route: "/administration/users/endUserRoles",
    exact: true,
    articles: getSuggestedArticles(["4409384586637", "360035549512"]),
  },
  {
    route: "/administration/users/contacts",
    exact: true,
    articles: getSuggestedArticles(["4409384586637"]),
  },
  {
    route: "/administration/apps",
    exact: true,
    articles: getSuggestedArticles(["207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889", "360004175031"]),
  },
  {
    route: "/administration/apps/api",
    exact: true,
    articles: getSuggestedArticles(["4403617211277", "214971106", "207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889"]),
  },
  {
    route: "/administration/apps/ticketing",
    exact: true,
    articles: getSuggestedArticles([
      "207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889",
      "4567434594573",
      "360056423691",
    ]),
  },
  {
    route: "/administration/apps/backup",
    exact: true,
    articles: getSuggestedArticles([
      "207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889",
      "360009961552",
      "360045232051",
    ]),
  },
  {
    route: "/administration/apps/documentation",
    exact: true,
    articles: getSuggestedArticles([
      "207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889",
      "17687466337677",
      "18030982761357",
    ]),
  },
  {
    route: "/administration/apps/remoteSupport",
    exact: true,
    articles: getSuggestedArticles([
      "207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889",
      "360056410972",
      "360030662192",
    ]),
  },
  {
    route: "/administration/apps/mdm",
    exact: true,
    articles: getSuggestedArticles(["207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889", "17900463685389"]),
  },
  {
    route: "/administration/apps/ninjaRemote",
    exact: true,
    articles: getSuggestedArticles(["207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889"]),
  },
  {
    route: "/administration/apps/teamviewer",
    exact: true,
    articles: getSuggestedArticles(["207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889", "360004175031"]),
  },
  {
    route: "/administration/apps/serviceNow",
    exact: true,
    articles: getSuggestedArticles(["207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889", "360004175031"]),
  },
  {
    route: "/administration/apps/connectWiseManage",
    exact: true,
    articles: getSuggestedArticles(["207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889", "360004175031"]),
  },
  {
    route: "/administration/apps/webroot",
    exact: true,
    articles: getSuggestedArticles(["207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889", "360004175031"]),
  },
  {
    route: "/administration/apps/cloudberry",
    exact: true,
    articles: getSuggestedArticles(["207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889", "360004175031"]),
  },
  {
    route: "/administration/apps/gravityZone",
    exact: true,
    articles: getSuggestedArticles(["207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889", "360004175031"]),
  },
  {
    route: "/administration/apps/sentinelOne",
    exact: true,
    articles: getSuggestedArticles(["207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889", "360004175031"]),
  },
  {
    route: "/administration/apps/crowdstrike",
    exact: true,
    articles: getSuggestedArticles(["207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889", "360004175031"]),
  },
  {
    route: "/administration/apps/autotask",
    exact: true,
    articles: getSuggestedArticles(["207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889", "360004175031"]),
  },
  {
    route: "/administration/apps/winget",
    exact: true,
    articles: getSuggestedArticles(["207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889", "360004175031"]),
  },
  {
    route: "/administration/apps/connectWiseControl",
    exact: true,
    articles: getSuggestedArticles(["207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889", "360004175031"]),
  },
  {
    route: "/administration/apps/notificationChannels",
    exact: true,
    articles: getSuggestedArticles(["4406766167821", "207273133#h_e1e19d04-2b64-4b43-baa1-c2e455090889"]),
  },
  {
    route: "/administration/devices/groups",
    exact: true,
    articles: getSuggestedArticles(["360035953551", "360016094512"]),
  },
  {
    route: "/administration/devices/roles",
    exact: true,
    articles: getSuggestedArticles(["360035953551"]),
  },
  {
    route: "/administration/devices/roleCustomFields",
    exact: true,
    articles: getSuggestedArticles(["360035953551", "18601842971789"]),
  },
  {
    route: "/administration/devices/globalCustomFields",
    exact: true,
    articles: getSuggestedArticles(["360035953551", "18601842971789"]),
  },
  {
    route: "/administration/devices/healthStatus",
    exact: true,
    articles: getSuggestedArticles(["360035953551", "360050975492"]),
  },
  {
    route: "/administration/devices/cache",
    exact: true,
    articles: getSuggestedArticles(["30624563717133"]),
  },
  {
    route: "/administration/library/downloads",
    exact: true,
    articles: getSuggestedArticles(["207273133#h_01HDKN10W1G7WQ9CVQVAX9KCED", "360056410972", "4409813444749"]),
  },
  {
    route: "/administration/library/snmpLibrary",
    exact: true,
    articles: getSuggestedArticles(["207273133#h_01HDKN10W1G7WQ9CVQVAX9KCED", "4426831669261", "7982786688525"]),
  },
  {
    route: "/administration/library/scripting/:page",
    exact: true,
    articles: getSuggestedArticles(["360016094492", "360004171232", "207273133#h_01HDKN10W1G7WQ9CVQVAX9KCED"]),
  },
  {
    route: "/administration/customers/list",
    exact: true,
    articles: getSuggestedArticles(["360051694271", "360016311651"]),
  },
  {
    route: "/administration/policies/agent",
    exact: true,
    articles: getSuggestedArticles(["360003478432", "360046348752", "115004434683", "206864826"]),
  },
  {
    route: "/administration/policies/nms",
    exact: true,
    articles: getSuggestedArticles(["360003478432", "360046348752", "115004434683", "206864826"]),
  },
  {
    route: "/administration/policies/virtualization",
    exact: true,
    articles: getSuggestedArticles(["360003478432", "360046348752", "115004434683", "206864826"]),
  },
  {
    route: "/administration/policies/mdm",
    exact: true,
    articles: getSuggestedArticles(["360003478432", "360046348752", "115004434683"]),
  },
  {
    route: "/administration/tasks",
    exact: true,
    articles: getSuggestedArticles(["360016094572", "360019817832"]),
  },
  {
    route: "/deviceSearch",
    exact: true,
    articles: getSuggestedArticles(["15287965524109", "18159591896717", "6344435230477"]),
  },
  {
    route: "/group/:id",
    exact: true,
    articles: getSuggestedArticles(["360016094512", "15287965524109", "18159591896717", "6344435230477"]),
  },
  {
    route: "/remoteSupport",
    exact: true,
    articles: getSuggestedArticles(["360030662192", "360056410972"]),
  },
  {
    route: "/quickConnect/:page",
    exact: true,
    articles: getSuggestedArticles(["9529843681549", "16908567015565"]),
  },
  {
    route: "/reporting/*",
    exact: true,
    articles: getSuggestedArticles(["9782317575821", "360025675292", "360045092571"]),
  },
  {
    route: "/editor/report/:id?",
    exact: true,
    articles: getSuggestedArticles(["9782317575821", "360025675292", "360045092571"]),
  },
  {
    route: "/ticketing/*",
    exact: true,
    articles: getSuggestedArticles(["360056423691", "4436263017357", "10097536945805"]),
  },
  {
    route: "/editor/customer/*",
    exact: true,
    articles: getSuggestedArticles(["360046348752", "360051694271#01H1YHMDSMF05FRM0ZCZDKFGHE"]),
  },
  {
    route: "/editor/customer/:id/backup/*",
    exact: true,
    articles: getSuggestedArticles([
      "360046961412",
      "360061732472",
      "360046348752",
      "360051694271#01H1YHMDSMF05FRM0ZCZDKFGHE",
    ]),
  },
  {
    route: "/editor/customer/:id/credentials",
    exact: true,
    articles: getSuggestedArticles(["360016094532", "360046348752", "360051694271#01H1YHMDSMF05FRM0ZCZDKFGHE"]),
  },
  {
    route: "/editor/customer/:id/backup/network-storage",
    exact: true,
    articles: getSuggestedArticles(["4409813341453", "360046348752", "360051694271#01H1YHMDSMF05FRM0ZCZDKFGHE"]),
  },
  {
    route: "/editor/customer/:id/patching",
    exact: true,
    articles: getSuggestedArticles(["360056174332", "360046348752", "360051694271#01H1YHMDSMF05FRM0ZCZDKFGHE"]),
  },
  {
    route: "/editor/customer/:id/security-node-approvals",
    exact: true,
    articles: getSuggestedArticles([
      "4405173636237",
      "360026622892",
      "360046348752",
      "360051694271#01H1YHMDSMF05FRM0ZCZDKFGHE",
    ]),
  },
  {
    route: "/editor/customer/:id/virtualization",
    exact: true,
    articles: getSuggestedArticles([
      "360057099852",
      "360031007591",
      "360046348752",
      "360051694271#01H1YHMDSMF05FRM0ZCZDKFGHE",
    ]),
  },
  {
    route: "/editor/policy/*",
    exact: true,
    articles: getSuggestedArticles(["360003478432#h_01HD4SRW29HT6CPC5SEZNB8SNR", "206864826", "209612533"]),
  },
  {
    route: "/editor/vmpolicy/*",
    exact: true,
    articles: getSuggestedArticles([
      "360003478432#h_01HD4SRW29HT6CPC5SEZNB8SNR",
      "206864826",
      "209612533",
      "15628524601485",
    ]),
  },
  {
    route: "/editor/androidpolicy/*",
    exact: true,
    articles: getSuggestedArticles(["19075396746893", "17916102408205", "20070381996301"]),
  },
  {
    route: "/editor/iospolicy/*",
    exact: true,
    articles: getSuggestedArticles(["19675912129165", "20028476165389", "17899554040845", "20070760920077"]),
  },
  {
    route: "/editor/scheduledTask/*",
    exact: true,
    articles: getSuggestedArticles(["360016094572"]),
  },
  {
    route: "/editor/user/:id/*",
    exact: true,
    articles: getSuggestedArticles(["360003242791", "5568986625165", "4436245047437", "4416950739597"]),
  },
  {
    route: "/editor/role/:id/*?roleType=technician",
    exact: true,
    articles: getSuggestedArticles(["360003242791", "5568986625165", "4436245047437", "4416950739597"]),
  },
  {
    route: "/editor/endUser/:id/*",
    exact: true,
    articles: getSuggestedArticles(["4409384586637", "360035894551", "360035549512"]),
  },
  {
    route: "/editor/role/:id/*?roleType=endUser",
    exact: true,
    articles: getSuggestedArticles(["4409384586637", "360035894551", "360035549512"]),
  },
  {
    route: "/editor/websiteBranding",
    exact: true,
    articles: getSuggestedArticles(["360021397911", "360021149472", "360018921951"]),
  },
  {
    route: "/editor/systray/:id",
    exact: true,
    articles: getSuggestedArticles(["115001717823"]),
  },
  {
    route: "/editor/client-app/:id?",
    exact: true,
    articles: getSuggestedArticles(["4403617211277", "20708139639565"]),
  },
  {
    route: "/editor/cloudMonitor/:id?",
    exact: true,
    articles: getSuggestedArticles(["207027356"]),
  },
  {
    route: "/editor/script/:id",
    exact: true,
    articles: getSuggestedArticles(["207991486", "19027076144269", "22328250934669", "17765447097357"]),
  },
  {
    route: "/editor/templateScript/:id",
    exact: true,
    articles: getSuggestedArticles(["207991486", "19027076144269", "22328250934669", "17765447097357"]),
  },
  {
    route: "/cloudMonitorDashboard/*",
    exact: true,
    articles: getSuggestedArticles(["207027356"]),
  },
  {
    route: "/mobileDashboard/*",
    exact: true,
    articles: getSuggestedArticles(["360031007611", "11451699609101"]),
  },
  {
    route: "/vmDashboard/*",
    exact: true,
    articles: getSuggestedArticles(["360031007591", "360030662132"]),
  },
  {
    route: "/vmGuestDashboard/*",
    exact: true,
    articles: getSuggestedArticles(["360031007591", "360030662132"]),
  },
  {
    route: "/nmsDashboard/*",
    exact: true,
    articles: getSuggestedArticles(["207021723", "360029544711", "360004175071"]),
  },
  {
    route: "/editor/shadowProtect/*",
    exact: true,
    articles: getSuggestedArticles(["207437163"]),
  },
  {
    route: "/editor/imageManager/*",
    exact: true,
    articles: getSuggestedArticles(["360045232051", "16363919566477", "4409813444749"]),
  },
  {
    route: "administration/apps/vulnerabilityManagement",
    exact: true,
    articles: getSuggestedArticles(["26331558954509"]),
  },
  {
    route: "administration/apps/rapid7",
    exact: true,
    articles: getSuggestedArticles(["26331594477197"]),
  },
  {
    route: "administration/apps/qualys",
    exact: true,
    articles: getSuggestedArticles(["26331660240653"]),
  },
  {
    route: "administration/apps/tenable",
    exact: true,
    articles: getSuggestedArticles(["26331671031053"]),
  },
  {
    route: "administration/apps/crowdstrikeSpotlight",
    exact: true,
    articles: getSuggestedArticles(["27491852723725"]),
  },
]
