import { useCallback, useEffect, useMemo, useState } from "react"
import { createPortal } from "react-dom"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  useBasicTypeaheadTriggerMatch,
} from "@lexical/react/LexicalTypeaheadMenuPlugin"

import { debounce } from "@ninjaone/webapp/src/js/includes/common/utils"

import Text from "../../../Text"

import { $createMentionNode } from "./Node"
import { StyledTypeheadPopover, StyledTypeheadPopoverUl, StyledTypeheadPopoverLi } from "./styled"

const PUNCTUATION = "\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;"
const NAME = "\\b[A-Z][^\\s" + PUNCTUATION + "]"

const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION,
}

const PUNC = DocumentMentionsRegex.PUNCTUATION

const TRIGGERS = ["@"].join("")

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = "[^" + TRIGGERS + PUNC + "\\s]"

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  "(?:" +
  "\\.[ |$]|" + // E.g. "r. " in "Mr. Smith"
  " |" + // E.g. " " in "Josh Duck"
  "[" +
  PUNC +
  "]|" + // E.g. "-' in "Salier-Hellendag"
  ")"

const LENGTH_LIMIT = 75

const AtSignMentionsRegex = new RegExp(
  "(^|\\s|\\()([" + TRIGGERS + "]((?:" + VALID_CHARS + VALID_JOINS + "){0," + LENGTH_LIMIT + "}))$",
)

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
  "(^|\\s|\\()([" + TRIGGERS + "]((?:" + VALID_CHARS + "){0," + ALIAS_LENGTH_LIMIT + "}))$",
)

// At most, 5 suggestions are shown in the popup.
const SUGGESTION_LIST_LENGTH_LIMIT = 5

const mentionsCache = new Map()

function useMentionSearch(mentionString, mentionFetch) {
  const [results, setResults] = useState([])

  const debouncedFetch = useCallback(
    queryString =>
      debounce(async queryString => {
        mentionsCache.set(queryString, null)
        const response = await mentionFetch(queryString)
        mentionsCache.set(queryString, response)
        setResults(response)
      }, 500)(queryString),
    [mentionFetch],
  )

  useEffect(() => {
    const cachedResults = mentionsCache.get(mentionString)

    if (mentionString == null) {
      setResults([])
      return
    }

    if (cachedResults === null) {
      return
    } else if (cachedResults !== undefined) {
      setResults(cachedResults)
      return
    }

    debouncedFetch(mentionString)
  }, [debouncedFetch, mentionString])

  return results
}

function checkForAtSignMentions(text, minMatchLength) {
  let match = AtSignMentionsRegex.exec(text)

  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text)
  }

  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1]

    const matchingString = match[3]
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      }
    }
  }

  return null
}

function getPossibleQueryMatch(text) {
  return checkForAtSignMentions(text, 1)
}

class MentionTypeaheadOption extends MenuOption {
  id
  label
  metadata

  constructor(id, label, metadata) {
    super(id)
    this.id = id
    this.label = label
    this.metadata = metadata
  }
}

const MentionsTypeaheadMenuItem = ({ index, isSelected, onClick, onMouseEnter, option }) => {
  let className = "item"

  if (isSelected) {
    className += " selected"
  }

  return (
    <StyledTypeheadPopoverLi
      key={option.key}
      id={"typeahead-item-" + index}
      className={className}
      ref={option.setRefElement}
      aria-selected={isSelected}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      tabIndex={-1}
    >
      <Text size="sm" color="colorTextWeak">
        {option.label}
      </Text>
    </StyledTypeheadPopoverLi>
  )
}

export const MentionPlugin = ({
  idKey,
  labelKey,
  mentionFetch,
  onAddMention,
  MentionsTypeaheadMenuItemComponent = MentionsTypeaheadMenuItem,
}) => {
  const [editor] = useLexicalComposerContext()

  const [queryString, setQueryString] = useState(null)

  const results = useMentionSearch(queryString, mentionFetch)

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch("/", {
    minLength: 0,
  })

  const options = useMemo(
    () =>
      results
        ?.map(result => {
          return new MentionTypeaheadOption(result[idKey], result[labelKey], result)
        })
        .slice(0, SUGGESTION_LIST_LENGTH_LIMIT),
    [idKey, labelKey, results],
  )

  const onSelectOption = useCallback(
    (selectedOption, nodeToReplace, closeMenu) => {
      editor.update(() => {
        const mentionNode = $createMentionNode(selectedOption.id, selectedOption.label, selectedOption.metadata)
        onAddMention?.(selectedOption)
        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode)
        }
        closeMenu()
      })
    },
    [editor, onAddMention],
  )

  const checkForMentionMatch = useCallback(
    text => {
      const slashMatch = checkForSlashTriggerMatch(text, editor)
      if (slashMatch !== null) return null

      return getPossibleQueryMatch(text)
    },
    [checkForSlashTriggerMatch, editor],
  )

  return (
    <LexicalTypeaheadMenuPlugin
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      options={options}
      menuRenderFn={(anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) =>
        anchorElementRef.current && results?.length
          ? createPortal(
              <StyledTypeheadPopover>
                <StyledTypeheadPopoverUl>
                  {options.map((option, i) => (
                    <MentionsTypeaheadMenuItemComponent
                      key={option.key}
                      index={i}
                      isSelected={selectedIndex === i}
                      onClick={() => {
                        setHighlightedIndex(i)
                        selectOptionAndCleanUp(option)
                      }}
                      onMouseEnter={() => {
                        setHighlightedIndex(i)
                      }}
                      option={option}
                    />
                  ))}
                </StyledTypeheadPopoverUl>
              </StyledTypeheadPopover>,
              anchorElementRef.current,
            )
          : null
      }
    />
  )
}
