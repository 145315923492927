import React, { memo } from "react"

import { FileFolderBrowserTree, getLockhartFileFolderBrowsingMethods } from "js/includes/components/Browsers"
import { replaceSeparatorAndRemoveTrailingSlash } from "js/includes/components/Browsers"
import { isWindowsDevice } from "js/includes/common/utils"

const PlanFileFolderExplorer = memo(({ destinationNode, height, setDestinationPath }) => {
  const { id: deviceId, nodeClass, nodeRole } = destinationNode
  const separator = isWindowsDevice(nodeClass) ? "\\" : "/"

  return (
    <FileFolderBrowserTree
      {...{
        height,
        deviceId,
        nodeClass,
        selectable: true,
        canCreateNewFolder: true,

        onSelect: keys => {
          const [path] = keys
          const destinationPath = path ? replaceSeparatorAndRemoveTrailingSlash(path, separator) : ""
          setDestinationPath(destinationPath)
        },

        ...getLockhartFileFolderBrowsingMethods({
          deviceId,
          showFiles: false,
          nodeRole,
          disableExclusionListValidation: true,
          nodeClass,
        }),
      }}
    />
  )
})

export default PlanFileFolderExplorer
