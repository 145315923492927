import { useCallback, useRef } from "react"
import { css } from "@emotion/css"
import { faImage, faPaperclip, faTrash } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Editor, Element as SlateElement, Range, Transforms } from "slate"
import { ReactEditor, useSelected, useSlateStatic } from "slate-react"

import { isValidFileSize, isValidFileType } from "js/includes/ticketing/editor/shared/utils"

import { AWSResourceImage } from "../AWSResourceImage"
import { Button } from "../components"
import { StyledHiddenInput } from "../styled"
import { ATTACHMENT_FILE_EXTENSIONS, IMAGE_TYPES, INLINE_FILE_EXTENSIONS } from "js/includes/common/utils"

const insertImage = (editor, { uri, url, width, height, attachment }) => {
  const text = { text: "" }
  const image = {
    type: "image",
    uri,
    url,
    width,
    height,
    attachment,
    children: [text],
  }

  Transforms.insertNodes(editor, image, { mode: "highest" })
}

export const withImages = ({ addInlineImage }) => editor => {
  const { isVoid, insertData } = editor

  editor.isVoid = element => {
    return element.type === "image" ? true : isVoid(element)
  }

  editor.insertData = async data => {
    const { files } = data

    if (files?.length > 0) {
      for (const file of files) {
        if (!IMAGE_TYPES.includes(file?.type)) return

        const [mime] = file.type.split("/")

        if (mime === "image") {
          addInlineImage?.(editor, file, (resource = {}) => {
            const { attachment, uri } = resource

            if (attachment.resourceId && uri) {
              insertImage(editor, resource)
            }
          })
        }
      }
    }

    insertData(data)
  }

  return editor
}

export const InsertAttachmentButton = ({ disabled, addAttachment }) => {
  const inputRef = useRef(null)

  async function handleFileChange(event) {
    const [file] = event.target.files

    inputRef.current.value = ""

    if (!isValidFileType(file, ATTACHMENT_FILE_EXTENSIONS) || !isValidFileSize(file)) return

    addAttachment(file)
  }

  return (
    <>
      <StyledHiddenInput type="file" ref={inputRef} onChange={handleFileChange} accept={ATTACHMENT_FILE_EXTENSIONS} />
      <Button
        disabled={disabled}
        onMouseDown={event => {
          event.preventDefault()
          inputRef.current.click()
        }}
      >
        <FontAwesomeIcon icon={faPaperclip} />
      </Button>
    </>
  )
}

export const InsertImageButton = ({ disabled, addInlineImage }) => {
  const inputRef = useRef(null)
  const editor = useSlateStatic()

  async function handleFileChange(event) {
    const [file] = event.target.files

    inputRef.current.value = ""

    if (!isValidFileType(file, INLINE_FILE_EXTENSIONS) || !isValidFileSize(file)) return

    addInlineImage(editor, file, (resource = {}) => {
      const { attachment, uri } = resource

      if (attachment.resourceId && uri) {
        insertImage(editor, resource)
      }
    })
  }

  return (
    <>
      <StyledHiddenInput type="file" ref={inputRef} onChange={handleFileChange} accept={INLINE_FILE_EXTENSIONS} />
      <Button
        disabled={disabled}
        onMouseDown={event => {
          event.preventDefault()
          inputRef.current.click()
        }}
      >
        <FontAwesomeIcon icon={faImage} />
      </Button>
    </>
  )
}

export const Image = ({ readOnly, attachments, attributes, children, element }) => {
  const editor = useSlateStatic()
  const { uri, width, height } = element
  const path = ReactEditor.findPath(editor, element)
  const selected = useSelected()
  const attachment =
    attachments?.find(({ resourceId }) => resourceId === element.attachment.resourceId) ?? element.attachment

  const updateImageData = useCallback(
    data => {
      const { selection } = editor

      if (!!selection && Range.isCollapsed(selection)) {
        const [image] = Editor.nodes(editor, {
          match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "image",
          mode: "highest",
        })

        if (image) {
          const [block, path] = image

          Transforms.setNodes(
            editor,
            { ...block, ...data },
            {
              at: path,
              match: node => node.type === "image",
              mode: "highest",
            },
          )
        }
      }
    },
    [editor],
  )

  return (
    <div {...attributes}>
      {children}
      <div
        contentEditable={false}
        className={css`
          position: relative;
        `}
      >
        <AWSResourceImage
          {...{
            editor,
            selected,
            uri,
            width,
            height,
            readOnly,
            attachment,
            updateImageData,
          }}
        />

        {!readOnly && (
          <Button
            active
            onClick={() => Transforms.removeNodes(editor, { at: path })}
            className={css`
              display: ${selected ? "inline" : "none"};
              position: absolute;
              top: 0.5em;
              left: 0.5em;
              background-color: white;
            `}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        )}
      </div>
    </div>
  )
}
