import React, { memo, useEffect, useState } from "react"
import * as ninjaIcons from "@ninjaone/icons"
import { useMounted } from "@ninjaone/webapp/src/js/includes/common/hooks"
import { ninjaReportError } from "@ninjaone/webapp/src/js/includes/common/utils"
import { getSignedUrlFromAttachmentId } from "@ninjaone/webapp/src/js/includes/common/client"
import Skeleton from "../../Skeleton/Skeleton"

const SIGNED_URL_EXPIRATION_MILLIS = 55000

function getSignedUrlCache(attachmentId) {
  const _cachedItem = window.localStorage.getItem(`ninja:signed-url:${attachmentId}`)
  if (!_cachedItem) return null

  const [ts, cachedSignedUrl] = _cachedItem.split(" ")
  if (Date.now() - parseInt(ts) > SIGNED_URL_EXPIRATION_MILLIS) {
    return null
  }

  return { signedUrl: cachedSignedUrl }
}

function setSignedUrlCache(attachmentId, signedUrl) {
  window.localStorage.setItem(`ninja:signed-url:${attachmentId}`, `${Date.now()} ${signedUrl}`)
}

export const ImageResource = memo(({ attachmentId, size, defaultIconName }) => {
  const [signedUrl, setSignedUrl] = useState(null)
  const [isResourceBroken, setIsResourceBroken] = useState(false)
  const mounted = useMounted()

  useEffect(() => {
    if (!attachmentId) {
      return
    }
    const getUrl = async () => {
      try {
        const cachedSignedUrl = getSignedUrlCache(attachmentId)

        const { signedUrl } = cachedSignedUrl || (await getSignedUrlFromAttachmentId(attachmentId))

        if (signedUrl !== cachedSignedUrl?.signedUrl) {
          setSignedUrlCache(attachmentId, signedUrl)
        }

        if (mounted.current) {
          if (signedUrl) {
            setSignedUrl(signedUrl)
            setIsResourceBroken(false)
          } else {
            setIsResourceBroken(true)
          }
        }
      } catch (error) {
        setIsResourceBroken(true)
        ninjaReportError(error)
      }
    }
    getUrl()
  }, [attachmentId, mounted])

  if (isResourceBroken) {
    const DefaultIcon = defaultIconName ? ninjaIcons[defaultIconName] : null
    return DefaultIcon && <DefaultIcon />
  }

  return signedUrl ? (
    <img height={size ?? "20"} width={size ?? "20"} src={signedUrl} alt="" />
  ) : (
    <Skeleton height="32px" width="32px" />
  )
})
