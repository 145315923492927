const confirmModalOptions = (state = null, action) => {
  switch (action.type) {
    case "SET_CONFIRM_MODAL_OPTIONS":
      return action.options
    case "SET_CONFIRM_MODAL_LOADING": {
      const { loading } = action
      return { ...state, loading }
    }
    case "CLEAR_CONFIRM_MODAL_OPTIONS":
      return null
    default:
      return state
  }
}

export default confirmModalOptions
