const products = (state = {}, action) => {
  switch (action.type) {
    case "SET_SOFTWARE_PRODUCTS":
      return action.products
    default:
      return state
  }
}

export default products
