import { assoc, assocPath, insertAll, remove, mergeRight, any } from "ramda"
import { defaultSchedule } from "js/includes/components/Schedule/serverBased/scheduleDataDefaults"
import { defaultAgentSchedule } from "js/includes/common/schedule"

function getInitialState() {
  return {
    id: 0,
    name: "",
    description: "",
    enabled: true,
    runsOnNodeScheduledTask: false,
    scripts: {
      actionList: [],
    },
    schedule: defaultSchedule,
    targets: [],
  }
}

export default function scheduledTask(state = getInitialState(), action) {
  switch (action.type) {
    case "INIT_SCHEDULED_TASK":
      return action.scheduledTask
    case "SET_SCHEDULED_TASK_ENABLED":
      return assoc("enabled", action.enabled, state)
    case "SET_SCHEDULED_TASK_NAME":
      return assoc("name", action.name, state)
    case "SET_SCHEDULED_TASK_DESCRIPTION":
      return assoc("description", action.description, state)
    case "SET_SCHEDULED_TASK_RUNS_ON_NODE":
      const targetsHasGroups = any(({ targetType }) => targetType === "GROUP")(state.targets)
      const resetTargets = targetsHasGroups && action.runsOnNodeScheduledTask
      return mergeRight(state, {
        schedule: action.runsOnNodeScheduledTask ? defaultAgentSchedule : defaultSchedule,
        runsOnNodeScheduledTask: action.runsOnNodeScheduledTask,
        ...(resetTargets && { targets: [] }),
      })
    case "SET_SCHEDULED_TASK_SCRIPTS_DATA":
      if (!state.scripts) {
        return assoc("scripts", { actionList: action.data }, state)
      }
      return assocPath(["scripts", "actionList"], action.data, state)
    case "SET_SCHEDULED_TASK_SCHEDULE":
      return assoc("schedule", action.schedule, state)
    case "SET_SCHEDULED_TASK_REPEATS":
      return assocPath(["schedule", "repeats"], action.repeats, state)
    case "ADD_SCHEDULED_TASK_TARGETS":
      const targets = insertAll(state.targets.length, action.targets, state.targets)
      return assoc("targets", targets, state)
    case "DELETE_SCHEDULED_TASK_TARGET":
      const { scheduledTaskId, targetType, targetId } = action.target
      const deleteIndex = state.targets.findIndex(
        target =>
          target.scheduledTaskId === scheduledTaskId &&
          target.targetType === targetType &&
          target.targetId === targetId,
      )
      const targetsAfterDelete = remove(deleteIndex, 1, state.targets)
      return assoc("targets", targetsAfterDelete, state)
    case "DELETE_SCHEDULED_TASK_ALL_TARGETS":
      return assoc("targets", [], state)
    case "RESET_SCHEDULED_TASK":
      return getInitialState()
    default:
      return state
  }
}
