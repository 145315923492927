import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { v4 as uuidv4 } from "uuid"

import { useMountedState } from "@ninjaone/webapp/src/js/includes/common/hooks"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/localization"
import { PlusIcon, MinusIcon } from "@ninjaone/icons"
import { isRequiredIf } from "@ninjaone/utils"

import { Label } from "./Form/Label"
import { ErrorIconTooltip } from "./IconTooltips"

const StyledStepper = styled.div`
  position: relative;
  width: 100%;
  min-width: 132px;
  max-width: ${({ compact }) => (compact ? "132px" : "264px")};
`

const StyledInput = styled.input`
  width: 100%;
  height: 38px;
  flex-grow: 1;
  border: 1px solid ${({ theme }) => theme.colorBorderWeak};
  border-radius: 4px;
  text-align: center;
  color: ${({ theme }) => theme.colorTextStrong};
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  -moz-appearance: textfield;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.colorBorderDecorativeStrong};
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    background-color: ${({ theme }) => theme.colorForegroundSelected};
  }

  &:disabled {
    color: ${({ theme }) => theme.colorTextDisabled};
    background-color: ${({ theme }) => theme.colorBackgroundInputDisabled};
    cursor: not-allowed;
  }

  &[aria-invalid="true"] {
    border: 1px solid ${({ theme }) => theme.colorBorderError};
  }
`

const StyledButton = styled.button`
  position: absolute;
  top: 1px;
  width: 33px;
  height: 36px;
  border: 0;
  border-radius: 0;
  color: ${({ theme }) => theme.colorTextAction};
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 1px;
    display: block;
    height: 34px;
    width: 1px;
    background-color: ${({ theme }) => theme.colorBackgroundAccentNeutral};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }

  &:disabled {
    color: ${({ theme }) => theme.colorTextDisabled};
    background-color: ${({ theme }) => theme.colorBackgroundInputDisabled};
    cursor: not-allowed;
  }
`

const StyledMinusButton = styled(StyledButton)`
  left: 1px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;

  &:after {
    right: -1px;
  }
`

const StyledPlusButton = styled(StyledButton)`
  right: 1px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;

  &:after {
    left: -1px;
  }
`

const StyledIconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
`
const roundToPrecision = ({ value, precision }) => {
  const factor = Math.pow(10, precision)
  return Math.round(value * factor) / factor
}

const getUpdatedValue = ({ allowFloat, max, min, precision, step, type, value }) => {
  const newValue = type === "increment" ? value + step : value - step
  if (value === "" || newValue < min) return min
  if (max && newValue > max) return max
  if (allowFloat) return roundToPrecision({ value: newValue, precision })
  return newValue
}

const Stepper = ({
  allowFloat = false,
  allowNegative = false,
  ariaLabel,
  compact,
  error: parentError,
  disabled,
  labelText,
  min = 1,
  max,
  onChange,
  precision,
  required,
  step = 1,
  tooltipAlignment = "end",
  tooltipPosition = "bottom",
  tooltipText,
  validateFromHook,
  value: parentValue,
}) => {
  const initialValue =
    (allowFloat && parentValue ? roundToPrecision({ precision, value: parentValue }) : parentValue) ?? min
  const [value, setValue] = useMountedState(initialValue)
  const [error, setError] = useState("")
  const ariaId = useMemo(() => uuidv4(), [])
  const timeoutId = useRef(null)

  const handleBlur = e => {
    // Get the current target when the blur event occurs
    const currentTarget = e.currentTarget

    // Give the browser time to register the active element on the next render
    // This function ensures that the default value will only populate the
    // input's value when the user has clicked outside of the component, and
    // will not update the input's value before the inc/dec buttons are clicked.
    timeoutId.current = setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        // If the consuming team would prefer to handle validation and value updates
        // when the component is blurred allow them to do so.
        if (validateFromHook) {
          onChange(value)
          return
        }

        let newValue

        if (value < min) {
          newValue = min
        }
        if (max && value > max) {
          newValue = max
        }

        if (value === "") {
          newValue = initialValue
        }

        if (newValue) {
          setValue(newValue)
          onChange(newValue)
          setError("")
        }
      }
    }, 0)
  }

  const handleChange = value => {
    // Allow user to clear the input
    if (value === "") {
      setValue(value)
      return
    }

    // We use Number here as parseFloat() does not support 0.0 while user is typing
    const numericValue = allowFloat ? Number(value) : parseInt(value, 10)

    // Prevent negative numbers unless allowNegative is true
    if (!allowNegative && numericValue < 0) {
      return
    }

    // Prevent users from typing a decimal greater than the precision
    if (allowFloat) {
      const [, decimal] = value.split(".")
      if (decimal?.length > precision) return
    }

    setValue(numericValue)

    // Allow user to finish typing negative numbers or floats if allowed
    if ((allowNegative && value === "-") || (allowFloat && value === ".")) {
      return
    }

    validateFromHook ? onChange(numericValue) : handleValidation(numericValue)
  }

  const handleErrors = useCallback(
    value => {
      if (value < min) return setError(localized("{{min}} is the minimum", { min }))
      if (value > max) return setError(localized("{{max}} is the maximum", { max }))
      setError("")
    },
    [max, min, setError],
  )

  const handleValidation = value => {
    if (max) {
      value >= min && value <= max && onChange(value)
    } else {
      value >= min && onChange(value)
    }

    handleErrors(value)
  }

  const handleValueUpdate = ({ type }) => {
    const _value = getUpdatedValue({ allowFloat, max, min, precision, step, type, value })
    setValue(_value)
    onChange(_value)
    setError("")
  }

  useEffect(() => {
    if (typeof parentValue === "number") {
      // Ensure that the value passed to the component is at the same precision
      // as the precision prop when allowFloat is true.
      setValue(allowFloat ? roundToPrecision({ value: parentValue, precision }) : parentValue)
    }
  }, [allowFloat, parentValue, precision, setValue])

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
    }
  }, [])

  return (
    <>
      {labelText && (
        <Label
          labelFor={ariaId}
          {...{
            disabled,
            labelText,
            required,
            tooltipText,
          }}
        />
      )}

      <StyledStepper data-ninja-stepper="" onBlur={e => handleBlur(e)} {...{ compact }}>
        <StyledMinusButton
          aria-label={localized("Decrement")}
          data-ninja-stepper-decrement=""
          data-testid="stepper-decrement"
          disabled={disabled || value <= min}
          onClick={() => handleValueUpdate({ type: "decrement" })}
          tabIndex={-1}
          type="button"
        >
          <MinusIcon size="md" />
        </StyledMinusButton>

        <StyledInput
          aria-invalid={!!parentError || !!error}
          aria-errormessage={(parentError || error) && `${ariaId}-error`}
          aria-label={!labelText ? ariaLabel : undefined}
          id={ariaId}
          onChange={e => handleChange(e.target.value)}
          type="number"
          {...{
            disabled,
            min,
            max,
            required,
            step,
            value,
          }}
        />

        {(parentError || error) && (
          <StyledIconContainer>
            <ErrorIconTooltip {...{ ariaId, error: parentError || error, tooltipAlignment, tooltipPosition }} />
          </StyledIconContainer>
        )}

        <StyledPlusButton
          aria-label={localized("Increment")}
          data-ninja-stepper-increment=""
          data-testid="stepper-increment"
          disabled={disabled || value >= max}
          onClick={() => handleValueUpdate({ type: "increment" })}
          tabIndex={-1}
          type="button"
        >
          <PlusIcon size="md" />
        </StyledPlusButton>
      </StyledStepper>
    </>
  )
}

const validateStep = ({ props, propName, componentName }) => {
  const { allowFloat, precision, step } = props

  if (!allowFloat && step !== undefined && !Number.isInteger(step)) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. ` +
        `The \`step\` prop (${step}) must be an integer unless \`allowFloat\` is true`,
    )
  }

  if (step !== undefined && precision !== undefined) {
    const stepDecimalPlaces = (step.toString().split(".")[1] || "").length
    if (stepDecimalPlaces > precision) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. ` +
          `The \`step\` prop (${step}) has more decimal places than allowed by \`precision\` (${precision}).`,
      )
    }
  }
}

const customPropTypes = {
  allowFloat: PropTypes.bool,
  precision: PropTypes.number,
  step: PropTypes.number,
}

Stepper.propTypes = {
  /**
   * Enables floating point values for the component's input.
   */
  allowFloat: PropTypes.bool,
  /**
   * Enables negative numbers for the component's input.
   */
  allowNegative: PropTypes.bool,
  /**
   * Accessible text for assistive technology. Required when `labelText` is not provided.
   */
  ariaLabel: isRequiredIf(
    PropTypes.string,
    props => !props.hasOwnProperty("labelText"),
    "`ariaLabel` is required if not passing a value for `labelText",
  ),
  /**
   * Controls the maximum width of the component
   */
  compact: PropTypes.bool,
  /**
   * Disables the input and removes asterisk from label if required
   */
  disabled: PropTypes.bool,
  /**
   * Error message from parent component.
   */
  error: PropTypes.string,
  /**
   * The label for the component
   */
  labelText: PropTypes.string,
  /**
   * The minimum value for the input
   */
  min: PropTypes.number,
  /**
   * The maxium value for the input
   */
  max: PropTypes.number,
  /**
   * The function that runs when the min and max have not been exceeded.
   * Uses the default value as the argument when the input is empty
   * or the user has entered an invalid value and loses focus on the
   * component.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * The number of decimal places to round to when `allowFloat` is
   * true. Also determines the maximum allowed decimal places for the `step`
   * prop.
   */
  precision: isRequiredIf(
    PropTypes.number,
    props => props.hasOwnProperty("allowFloat"),
    "`precision` is required when allowFloat is `true`",
  ),
  /**
   * Toggles the required attribute of the input and adds an asterisk to the
   * label
   */
  required: PropTypes.bool,
  /**
   * The amount to increment or decrement the value by when using the buttons
   * or arrow keys. The step should be a whole number.
   */
  step: (props, propName, componentName) => {
    PropTypes.checkPropTypes(customPropTypes, props, propName, componentName)
    return validateStep({ props, propName, componentName })
  },
  /**
   * The horizontal alignment of the error tooltip against the error icon.
   */
  tooltipAlignment: PropTypes.oneOf(["start", "center", "end"]),
  /**
   * The position of the error tooltip against the error icon.
   */
  tooltipPosition: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  /**
   * The text for the more information tooltip in the component's label.
   */
  tooltipText: PropTypes.string,
  /**
   * The default value for the component. When undefined internally the
   * component will use the `min` as the default value.
   */
  value: PropTypes.number,
}

export default Stepper
