/*
    {
      id: 168
      name: "mac group"
      createdOn: 1481929840213
      updatedOn: 1481929840213
    }
*/

export default function filters(state = [], action) {
  switch (action.type) {
    case "SET_GROUPS":
      return action.searches
    case "CREATE_GROUP":
      return [...state, action.filter]
    case "UPDATE_GROUP":
      return state.map(f => (f.id === action.filter.id ? action.filter : f))
    case "UPDATE_NEW_GROUP":
      return state.map(f => (f.id === -1 ? action.filter : f))
    case "DELETE_GROUP":
      return state.filter(f => f.id !== action.filter.id)
    default:
      return state
  }
}
