import React, { memo, useEffect } from "react"
import { connect } from "react-redux"
import { applySpec, compose, curry, filter, map, pluck, prop, propEq } from "ramda"
import { useQuery } from "urql"

import { useMountedState, useSorted } from "js/includes/common/hooks"
import {
  fetchJson,
  localizationKey,
  localized,
  reportErrorAndShowMessage,
  sortByFieldNameCaseInsensitive,
} from "js/includes/common/utils"
import RuleEditor from "js/includes/components/RuleEditor"
import { getTagValues } from "js/includes/configuration/integrations/ticketing/utils"
import TagsInput from "js/includes/ticketing/common/TagsInput"
import { getPriorityOptions, getSeverityOptions } from "js/includes/ticketing/common/TicketEditorFields/options"
import { getTags } from "js/includes/ticketing/graphql"
import { getStatusOptions } from "js/includes/ticketing/shared/utils/options"

import {
  CommentEditor,
  EmailEditor,
  OrganizationEditor,
  StatusEditor,
  TicketSubjectEditor,
  UserEditor,
  UserEmailEditor,
} from "./components"

function getOperators(field) {
  switch (field) {
    case "add_tags":
    case "remove_tags":
    case "set_tags":
    case "add_cc_list":
    case "set_cc_list":
    case "ADD_TICKET_COMMENT":
    case "SEND_EMAIL":
      return []
    case "SEND_NOTIFICATION":
      return [
        { value: "EMAIL", labelToken: localizationKey("Email") },
        { value: "SMS", labelToken: "SMS" },
        { value: "SLACK", labelToken: "Slack" },
      ]
    case "ticket":
      return [
        { value: "organization", labelToken: localizationKey("Assign Organization") },
        { value: "assignedAppUserId", labelToken: localizationKey("Assign to") },
        { value: "requesterUid", labelToken: localizationKey("Requester") },
        { value: "status", labelToken: localizationKey("Status") },
        { value: "severity", labelToken: localizationKey("Severity") },
        { value: "priority", labelToken: localizationKey("Priority") },
        { value: "summary", labelToken: localizationKey("Subject") },
      ]
    case "TICKET_LOG_ENTRY_TIME_TRACKER":
      return [
        { value: "MINUTES", labelToken: localizationKey("Minutes") },
        { value: "HOURS", labelToken: localizationKey("Hours") },
        { value: "DAYS", labelToken: localizationKey("Days") },
      ]
    default:
      return null
  }
}

function customTagRenderer(value, values, handleOnChange) {
  return <TagsInput tags={values} selected={pluck("value", value || [])} onChange={handleOnChange} />
}

function customCommentEditorRenderer(type) {
  return function(value, values, handleOnChange) {
    return <CommentEditor {...{ value, values, handleOnChange, withCurrentUserPlaceholders: type !== "TIME_BASED" }} />
  }
}

function customEmailEditorRenderer(type) {
  return function(value, values, handleOnChange, metaData) {
    return (
      <EmailEditor {...{ value, values, handleOnChange, metaData, showCurrentUserOption: type !== "TIME_BASED" }} />
    )
  }
}

function customTicketSubjectEditorRenderer(value, _, handleOnChange) {
  return <TicketSubjectEditor {...{ value, handleOnChange }} />
}

function customUserEditorRenderer({ valueSelectorKey, ...options }) {
  return function(value, _, handleOnChange, metaData) {
    return <UserEditor {...{ value, handleOnChange, metaData, valueSelectorKey, ...options }} />
  }
}

function customOrganizationEditorRenderer(value, _, handleOnChange, metaData) {
  return <OrganizationEditor {...{ value, handleOnChange, metaData, isMulti: false }} />
}

function customStatusEditorRenderer(value, _, handleOnChange) {
  return <StatusEditor {...{ value, handleOnChange, hideClose: true }} />
}

function customUserEmailEditorRenderer(value, _, handleOnChange, metaData) {
  return <UserEmailEditor {...{ value, handleOnChange, metaData }} />
}

const getInputType = field => {
  switch (field) {
    case "TICKET_LOG_ENTRY_TIME_TRACKER":
      return "number"
    default:
      return "text"
  }
}

function getTicketValues(operator, statusList) {
  switch (operator) {
    case "status":
      return getStatusOptions(statusList, { hideClose: true })
    case "priority":
      return getPriorityOptions()
    case "severity":
      return getSeverityOptions()
    case "summary":
      return ""
    default:
      return []
  }
}

const getChannelValues = curry((operator, list) =>
  compose(
    map(
      applySpec({
        value: prop("id"),
        label: prop("name"),
      }),
    ),
    filter(propEq("type", operator)),
  )(list),
)

export const Actions = memo(({ query = [], onQueryChange, type, statusList, getValidations }) => {
  const [channels, setChannels] = useMountedState([])

  const [{ error: tagsError, data: tagsData }] = useQuery({
    query: getTags,
  })

  if (tagsError) {
    reportErrorAndShowMessage(tagsError, localizationKey("Error fetching tags"))
  }

  const tags = useSorted(tagsData?.tags)

  useEffect(() => {
    async function fetchChannels() {
      try {
        const response = await fetchJson("/channel/list/brief")
        setChannels(sortByFieldNameCaseInsensitive("name", "ASC", response))
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Error fetching notification channels"))
      }
    }
    fetchChannels()
  }, [setChannels])

  const fields = [
    { value: "add_tags", label: localized("Add tags") },
    { value: "set_tags", label: localized("Set tags") },
    { value: "remove_tags", label: localized("Remove tags") },
    { value: "ticket", label: localized("Update Ticket") },
    { value: "add_cc_list", label: localized("Add CC") },
    { value: "set_cc_list", label: localized("Set CC") },
    { value: "ADD_TICKET_COMMENT", label: localized("Add comment") },
    { value: "TICKET_LOG_ENTRY_TIME_TRACKER", label: localized("Add Time") },
    ...(type === "RESPONSE_TEMPLATE"
      ? []
      : [
          { value: "SEND_NOTIFICATION", label: localized("Send Notification") },
          { value: "SEND_EMAIL", label: localized("Email") },
        ]),
  ]
  const oneTimeFields = ["EVENT_BASED", "RESPONSE_TEMPLATE", "TIME_BASED"].includes(type)
    ? ["add_cc_list", "set_cc_list", "ADD_TICKET_COMMENT", "TICKET_LOG_ENTRY_TIME_TRACKER"]
    : []

  function getValues(field, operator) {
    switch (field) {
      case "add_tags":
      case "remove_tags":
      case "set_tags":
        return getTagValues(tags)
      case "ticket":
        return getTicketValues(operator, statusList)
      case "SEND_NOTIFICATION":
        return getChannelValues(operator, channels)
      case "add_cc_list":
      case "set_cc_list":
        return []
      default:
        return ""
    }
  }

  function getValueEditorType(field, operator) {
    switch (field) {
      case "add_tags":
      case "set_tags":
      case "remove_tags":
        return customTagRenderer
      case "SEND_EMAIL":
        return customEmailEditorRenderer(type)
      case "ADD_TICKET_COMMENT":
        return customCommentEditorRenderer(type)
      case "add_cc_list":
      case "set_cc_list":
        return customUserEmailEditorRenderer
      case "ticket":
        switch (operator) {
          case "organization":
            return customOrganizationEditorRenderer
          case "summary":
            return customTicketSubjectEditorRenderer
          case "assignedAppUserId":
            return customUserEditorRenderer({ valueSelectorKey: "id", userType: "TECHNICIAN" })
          case "requesterUid":
            return customUserEditorRenderer({ valueSelectorKey: "uid" })
          case "status":
            return customStatusEditorRenderer
          default:
            return "select"
        }
      case "SEND_NOTIFICATION":
        return "select"
      case "TICKET_LOG_ENTRY_TIME_TRACKER":
        return "number"
      default:
        return "text"
    }
  }

  return (
    <RuleEditor
      {...{
        query,
        fields,
        oneTimeFields,
        getOperators,
        getValues,
        getValueEditorType,
        getInputType,
        onQueryChange,
        combinator: "actions",
        getValidations,
      }}
    />
  )
})

export default connect(({ ticketing }) => ({ statusList: ticketing.status.list }))(Actions)
