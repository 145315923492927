import { pick } from "ramda"

export default function announcement(state = null, action) {
  switch (action.type) {
    case "SET_APP_ANNOUNCEMENT":
      return pick(["id", "content"], action.payload)
    case "HIDE_APP_ANNOUNCEMENT":
      return null
    default:
      return state
  }
}
