import ReactDOM from "react-dom"
import { Ref, forwardRef, PropsWithChildren } from "react"
import { cx, css } from "@emotion/css"
import { StyledButton, StyledEditorContainer, StyledMenu, StyledPre, StyledSection } from "./styled"

interface BaseProps {
  className: string;
  [key: string]: unknown;
}
type OrNull<T> = T | null

export const EditorContainer = forwardRef((props: PropsWithChildren<BaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
  <StyledEditorContainer {...props} ref={ref} />
))

export const Button = forwardRef(
  (
    props: PropsWithChildren<
      {
        active: boolean,
      } & BaseProps,
    >,
    ref: Ref<OrNull<HTMLButtonElement>>,
  ) => <StyledButton {...props} ref={ref} type="button" />,
)

export const Portal = ({ children }) => {
  return typeof document === "object" ? ReactDOM.createPortal(children, document.body) : null
}

export const Menu = forwardRef((props: PropsWithChildren<BaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
  <StyledMenu {...props} ref={ref} />
))

export const Toolbar = forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
    <Menu
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          position: relative;
        `,
      )}
    />
  ),
)

export const Section = forwardRef((props: PropsWithChildren<BaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
  <StyledSection {...props} ref={ref} />
))

export const Code = forwardRef((props: PropsWithChildren<BaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
  <StyledPre
    {...props}
    ref={ref}
    padding={1}
    borderWidth={1}
    backgroundColor="ninjaWhite"
    borderColor="ninjaLight"
    borderStyle="solid"
    fontFamily="monospace"
    borderRadius={5}
  />
))
