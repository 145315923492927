export default function deleteDevice(state = {}, action) {
  switch (action.type) {
    case "OPEN_DELETE_DEVICE_MODAL":
      return { devices: action.devices }
    case "CLOSE_DELETE_DEVICE_MODAL":
      return {}
    default:
      return state
  }
}
