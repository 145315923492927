import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"

const CloseButton = ({ id, type = "submit", onClick }) => (
  <button className="close" {...{ type, onClick, id }}>
    <FontAwesomeIcon icon={faTimes} />
  </button>
)

export default CloseButton

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["button", "reset", "submit"]),
}
