import { useState } from "react"
import { connect } from "react-redux"
import { sizer } from "@ninjaone/utils"
import { Modal, Text, Input } from "@ninjaone/components"
import {
  capitalizeFirstLetterOfEveryWord,
  fetch,
  fullName,
  localizationKey,
  localized,
  ninjaReportError,
  validations,
} from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import { useForm } from "js/includes/common/hooks"
import { BackupPromoCodes } from "js/includes/components/MarketingPromoPopup/promotions/helpers"

function RequestModal({ unmount, userName, userEmail }) {
  const [requestSend, setRequestSend] = useState(false)
  const { values, onChange, validation, validateForm } = useForm({
    validateOnChange: true,
    fields: {
      name: capitalizeFirstLetterOfEveryWord(fullName(userName)),
      email: userEmail,
    },
    validate: {
      name: validations.required,
      email: validations.email,
    },
  })
  const { name, email } = values

  const submit = async () => {
    if (validateForm()) {
      setRequestSend(true)
      try {
        await fetch(`/division/promotion/${BackupPromoCodes.demoRequest}`, {
          options: {
            method: "POST",
            body: JSON.stringify({ userName: name, userEmail: email }),
          },
        })
      } catch (error) {
        ninjaReportError(error)
      }
    }
  }

  if (requestSend) {
    return (
      <Modal
        titleGroup={{
          titleToken: localizationKey("Demo request sent"),
          DescriptionComponent: () => (
            <Text
              size="sm"
              token={localizationKey("Thank you! Your Product Specialist will reach out to you shortly via email.")}
              textWrap
            />
          ),
        }}
        unmount={unmount}
        cancelable={false}
      />
    )
  }

  return (
    <Modal
      titleGroup={{
        titleToken: localizationKey("Request Demo"),
        DescriptionComponent: () => (
          <Text
            size="sm"
            token={localizationKey("Please confirm the following information before submitting your request.")}
            textWrap
          />
        ),
      }}
      unmount={unmount}
      buttons={[{ type: "save", labelToken: localizationKey("Submit"), onClick: submit }]}
      cancelable
    >
      <Box width="100%">
        <Text>{localized("Name")} *</Text>
        <Input name="name" type="text" value={name} onChange={onChange} errorMessage={validation.message.name} />

        <Box marginTop={sizer(3)}>
          <Text>{localized("Email")} *</Text>
          <Input name="email" type="email" value={email} onChange={onChange} errorMessage={validation.message.email} />
        </Box>
      </Box>
    </Modal>
  )
}

export default connect(({ session }) => ({
  userName: session.user,
  userEmail: session.user.email,
}))(RequestModal)
