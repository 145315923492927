export default function nodeOverride(state = null, action) {
  switch (action.type) {
    case "CLEAR_NODE":
      return null
    case "SET_NODE_FOR_OVERRIDE":
      return action.node
    case "UPDATE_NODE_FOR_OVERRIDE":
      return { ...state, ...action.node }
    default:
      return state
  }
}
