import policy from "./policy"
import policyOriginal from "./policyOriginal"
import parentPolicy from "./parentPolicy"
import nodeOverride from "./nodeOverride"
import loading from "js/state/reducers/common/loading"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"

const editor = {
  policy,
  policyOriginal,
  parentPolicy,
  nodeOverride,
  savingPolicy: createNamedWrapperReducer(loading, "SavingPolicy", false),
}

export default editor
