import React, { memo } from "react"
import { localized } from "js/includes/common/utils"

export default memo(
  ({ id, action, checked, history, content, token, nodeId, nodeClass, LinkComponent, onAction, dispatchCallback }) => {
    return (
      <div
        className="btn-link m-b-sm"
        onClick={async event =>
          action &&
          (await onAction(
            { action, id },
            {
              event,
              checked,
              history,
              content,
              nodeId,
              nodeClass,
              dispatchCallback,
            },
          ))
        }
      >
        {LinkComponent ? <LinkComponent {...{ checked, token }} /> : localized(token)}
      </div>
    )
  },
)
