import { PureComponent } from "react"
import { Box, StyledImage, StyledFontAwesomeIcon, StyledSpan } from "js/includes/components/Styled"
import { faFileImage } from "@fortawesome/pro-solid-svg-icons"
import { localized, downloadFile, reportErrorAndShowMessage, localizationKey } from "js/includes/common/utils"
import { getSignedUrlFromAttachmentId } from "js/includes/common/client"
import { ResizableBox } from "js/includes/components/Resizable"
import { Attachment } from "js/includes/components/Attachment"
import showModal from "js/includes/common/services/showModal"
import { ImagePreviewModal } from "js/includes/components/ImagePreviewModal"

function isAttachmentUploaded(attachment) {
  return attachment?.resourceId || attachment?.id
}

export class AWSResourceImage extends PureComponent {
  state = {
    loading: true,
    isResourceBroken: false,
    resource: {
      width: this.props.width,
      height: this.props.height,
      src: this.props.uri,
    },
  }

  loadResource = src => {
    const { width, height } = this.props
    const image = new Image()

    image.onload = () => {
      this._isMounted &&
        this.setState({
          resource: {
            width: width || image.width,
            height: height || image.height,
            src,
          },
          loading: false,
        })
    }

    image.src = src
  }

  getSignedSrc = async () => {
    const { attachment } = this.props
    const uploadStatus = attachment.uploadStatus || "SUCCESS"

    switch (uploadStatus) {
      case "FAILURE":
      case "SUSPICIOUS":
        this.setState({
          isResourceBroken: true,
        })
        break
      case "SUCCESS": {
        try {
          this.setState({
            loading: true,
          })

          const { signedUrl } = await getSignedUrlFromAttachmentId(attachment.id)

          if (signedUrl) {
            this.loadResource(signedUrl)
          } else {
            this._isMounted &&
              this.setState({
                isResourceBroken: true,
              })

            throw new Error("Broken Resource")
          }
        } catch (error) {
          // We dont't want to throw an error but we want to log it in the console.
          console.error(error)

          this._isMounted &&
            this.setState({
              loading: false,
            })
        }
        break
      }
      default:
        break
    }
  }

  componentDidMount() {
    this._isMounted = true
    const { attachment, uri } = this.props

    if (isAttachmentUploaded(attachment) && !uri) {
      this.getSignedSrc()
    } else {
      this.setState({ loading: false })
    }
  }

  componentDidUpdate(prevProps) {
    const { attachment, uri } = this.props
    if (attachment !== prevProps.attachment && isAttachmentUploaded(attachment) && !uri) {
      this.getSignedSrc()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onDownload = async () => {
    try {
      const { attachment } = this.props
      const { signedUrl } = await getSignedUrlFromAttachmentId(attachment?.id)
      downloadFile(signedUrl, attachment?.metadata?.name)
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Error while downloading image."))
    }
  }

  handleClick = () => {
    const {
      onDownload,
      props: { attachment },
    } = this

    showModal(
      <ImagePreviewModal
        {...{
          onDownload,
          attachmentId: attachment?.id,
          titleText: attachment?.metadata?.name,
        }}
      />,
    )
  }

  render() {
    const {
      state: { loading, isResourceBroken, resource },
      props: { selected, updateImageData, readOnly, name, width, height, attachment },
    } = this

    const uploadStatus = attachment.uploadStatus

    if (isResourceBroken) {
      return (
        <Box flex={1} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <StyledFontAwesomeIcon icon={faFileImage} color="ninjaRed" fontSize={6} />
          <StyledSpan fontSize={0} marginTop={2} fontWeight="bold" color="ninjaRed">
            {localized("Resource Not Found")}
          </StyledSpan>
        </Box>
      )
    }

    if (uploadStatus === "PROCESSING") return <Attachment attachment={attachment} />

    return loading ? null : readOnly ? (
      <StyledImage
        key={attachment.resourceId}
        draggable={false}
        onClick={this.handleClick}
        {...{
          alt: name,
          width: resource.width ? resource.width + "px" : "100%",
          display: "block",
          src: resource.src,
          maxWidth: "100%",
          boxShadow: selected ? "0 0 0 3px #B4D5FF" : "none",
        }}
      />
    ) : (
      <ResizableBox
        width={width ?? resource?.width}
        height={height ?? resource?.height}
        onResizeStop={resized => {
          updateImageData(resized)
        }}
        ChildrenRenderer={({ width, isContainerWidthOrGreater }) => {
          return (
            <StyledImage
              {...{
                alt: name,
                width: isContainerWidthOrGreater ? "100%" : width + "px",
                display: "block",
                src: resource.src,
                maxWidth: "100%",
                boxShadow: selected ? "0 0 0 3px #B4D5FF" : "none",
              }}
            />
          )
        }}
      />
    )
  }
}
