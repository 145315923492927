import styled from "@emotion/styled"
import { StyledTypeheadPopoverLi } from "@ninjaone/components/src/WYSIWYG/Plugins/MentionsPlugin/styled"
import { Text } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { Flex } from "js/includes/components/Styled"

const TicketingStyledTypeheadPopoverLi = styled(StyledTypeheadPopoverLi)`
  height: auto;
`

export const TicketingMentionsTypeaheadMenuItem = ({ index, isSelected, onClick, onMouseEnter, option }) => {
  let className = "item"

  if (isSelected) {
    className += " selected"
  }

  return (
    <TicketingStyledTypeheadPopoverLi
      key={option.key}
      id={"typeahead-item-" + index}
      className={className}
      ref={option.setRefElement}
      aria-selected={isSelected}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      tabIndex={-1}
      role="option"
    >
      <Flex flexDirection="column" padding={[tokens.spacing[1], 0]} alignItems="flex-start">
        <Text size="sm" color="colorTextStrong" fontWeight={500}>
          {option.label}
        </Text>
        <Text size="sm" color="colorTextWeak">
          {option.metadata.email}
        </Text>
      </Flex>
    </TicketingStyledTypeheadPopoverLi>
  )
}
