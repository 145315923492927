const initialState = []

export default function events(state = initialState, action) {
  switch (action.type) {
    case "SET_SYSTEM_EVENTS":
      return action.data
    case "RESET_SYSTEM_EVENTS":
      return initialState
    default:
      return state
  }
}
