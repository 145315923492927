import { combineReducers } from "redux"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import value from "js/state/reducers/common/value"
import boolean from "js/state/reducers/common/boolean"
import selectedPolicyScripts from "./selectedPolicyScripts"
import selectedScriptsResultConditionScript from "./selectedScriptsResultConditionScript"
import visibility from "../../common/visibility"

export default combineReducers({
  showScriptSelectorVisibility: createNamedWrapperReducer(boolean, "showScriptSelectorVisibility"),
  scriptsRequiredMessageVisibility: createNamedWrapperReducer(visibility, "scriptsRequiredMessageVisibility"),
  selectedPolicyScripts,
  selectedScriptsResultConditionScript,
  parameterModal: createNamedWrapperReducer(value, "parameterModal", null),
  conditionScriptSelector: createNamedWrapperReducer(boolean, "conditionScriptSelector"),
  showScriptResultConditionError: createNamedWrapperReducer(boolean, "showScriptResultConditionError"),
})
