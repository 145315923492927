import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { getStatusColor, sizer } from "@ninjaone/utils"
import { Circle, ExclamationCircle, MinusCircle, QuestionCircle, createIcon } from "@ninjaone/icons"
import Tooltip from "./Tooltip"

const getStatusIcon = status => {
  switch (status) {
    case "HEALTHY":
      return Circle
    case "UNHEALTHY":
      return MinusCircle
    case "NEEDS_ATTENTION":
      return ExclamationCircle
    case "UNKNOWN":
    default:
      return QuestionCircle
  }
}

const createStatusIcon = status => styled(getStatusIcon(status))`
  width: ${sizer(3)} !important;
  font-size: ${sizer(3)};
  color: ${({ theme }) => (status === "UNKNOWN" ? theme.color.black["065"] : getStatusColor(status, theme))} !important;
`

const StyledDeviceIconContainer = styled.div`
  position: relative;
  width: 25px;
  height: ${sizer(5)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.black["075"]};
`

const StyledStatusContainer = styled.div`
  position: absolute;
  top: -6px;
  right: -6px;
  width: ${sizer(3)};
  height: ${sizer(3)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.white["100"]};
  z-index: 1;
`

export default function DeviceIcon({ status, icon, tooltip }) {
  const StyledStatusIcon = createStatusIcon(status)
  const StyledDeviceIcon = createIcon(icon)
  const content = (
    <StyledDeviceIconContainer>
      {status && (
        <StyledStatusContainer>
          <StyledStatusIcon />
        </StyledStatusContainer>
      )}
      <StyledDeviceIcon fontSize={sizer(5)} />
    </StyledDeviceIconContainer>
  )

  return tooltip ? <Tooltip label={tooltip}>{content}</Tooltip> : content
}

DeviceIcon.propTypes = {
  status: PropTypes.string,
  icon: PropTypes.object.isRequired,
  tooltip: PropTypes.string,
}
