import { useMemo, useState } from "react"
import { any, anyPass, append, compose, filter, groupBy, pluck, prop, propSatisfies, reject, without } from "ramda"
import fastDeepEqual from "fast-deep-equal"
import styled from "@emotion/styled/macro"

import {
  contains,
  isNotNilOrEmpty,
  localizationKey,
} from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"
import tokens from "@ninjaone/tokens"
import { sizer } from "@ninjaone/utils"
import { filterOptions } from "../utils"

import Button from "../../Button"
import Dropdown from "../../Dropdown"
import Text from "../../Text"

const StyledFilterButton = styled(Button)`
  padding: 10px ${sizer(3)};

  &[data-disabled="true"] {
    cursor: initial; // lets match what we have for other filters in the DataTable
  }
`

const hasAppliedFilters = any(({ value, defaultValue }) => {
  if (isNotNilOrEmpty(defaultValue) && isNotNilOrEmpty(value)) {
    return !fastDeepEqual(value, defaultValue)
  }

  return isNotNilOrEmpty(value)
})

const hasLooseAppliedFilters = any(
  ({ value, initialValue, defaultValue }) =>
    isNotNilOrEmpty(value) || isNotNilOrEmpty(initialValue) || isNotNilOrEmpty(defaultValue),
)

const filterByNameList = nameList => propSatisfies(contains(nameList), "name")
const getFilterNamesWithInitialValue = compose(pluck("name"), filter(propSatisfies(isNotNilOrEmpty, "initialValue")))

export default function CustomFilters({ customFilters, updateFilter, clearFilters, clearFiltersOnReset, disabled }) {
  const { primary: primaryFilters = [], secondary: secondaryFilters = [] } = useMemo(
    () => groupBy(prop("category"), customFilters),
    [customFilters],
  )

  const [initialSecondaryFilterNames, setInitialSecondaryFilterNames] = useState(
    getFilterNamesWithInitialValue(secondaryFilters),
  )
  const [visibleSecondaryFilterNames, setVisibleSecondaryFilterNames] = useState(
    getFilterNamesWithInitialValue(secondaryFilters),
  )

  const visibleSecondaryFilters = filter(filterByNameList(visibleSecondaryFilterNames), secondaryFilters)
  const additionalSecondaryFilters = reject(
    anyPass([prop("hidden"), filterByNameList(visibleSecondaryFilterNames)]),
    secondaryFilters,
  )

  const showClearButton =
    visibleSecondaryFilterNames.length > 0 ||
    (clearFiltersOnReset && hasLooseAppliedFilters(customFilters)) ||
    hasAppliedFilters(customFilters)

  const onRemove = name => {
    setInitialSecondaryFilterNames(without(name))
    setVisibleSecondaryFilterNames(without(name))
  }

  return (
    <>
      {primaryFilters.map(filter => {
        if (filter.hidden) return null

        const PrimaryFilter = filterOptions[filter.type]?.Component
        return (
          <PrimaryFilter
            key={filter.name}
            filter={filter}
            updateFilter={updateFilter}
            openOnMount={filter?.openOnMount}
            disabled={disabled}
          />
        )
      })}

      {visibleSecondaryFilters.map(filter => {
        if (filter.hidden) return null

        const SecondaryFilter = filterOptions[filter.type]?.Component
        return (
          <SecondaryFilter
            {...{
              key: filter.name,
              filter,
              updateFilter,
              alwaysShowRemove: true,
              openOnMount: !initialSecondaryFilterNames.includes(filter.name),
              onRemove,
              onDropdownClose: () => {
                if (isNotNilOrEmpty(filter.value)) return
                onRemove(filter.name)
              },
              disabled,
            }}
          />
        )
      })}

      {additionalSecondaryFilters.length > 0 && (
        <Dropdown
          modal={false}
          options={additionalSecondaryFilters}
          onSelect={({ name }) => setVisibleSecondaryFilterNames(append(name))}
          variant="compact"
          disabled={disabled}
        >
          <StyledFilterButton
            renderAsDiv
            type="secondary"
            variant="accessory"
            labelToken="general.moreFilters"
            disabled={disabled}
          />
        </Dropdown>
      )}

      {showClearButton && (
        <Button
          type="tertiary"
          transparent
          onClick={() => {
            setInitialSecondaryFilterNames(getFilterNamesWithInitialValue(secondaryFilters))
            setVisibleSecondaryFilterNames(getFilterNamesWithInitialValue(secondaryFilters))
            clearFilters()
          }}
          disabled={disabled}
        >
          <Text
            type="body"
            lineHeight={tokens.typography.lineHeight}
            token={localizationKey("Reset filters")}
            color={disabled ? "colorTextDisabled" : "colorTextWeak"}
            fontWeight={tokens.typography.fontWeight.medium}
          />
        </Button>
      )}
    </>
  )
}
