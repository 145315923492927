import { useTheme } from "@emotion/react"

import { sizer } from "@ninjaone/utils"
import { Badge, ToggleButton } from "@ninjaone/components"
import { VARIANTS } from "@ninjaone/components/src/ToggleButton"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

const ShowSelectedButton = ({ showSelected, toggleShowSelected, showSelectedCount, gotoPage }) => {
  const theme = useTheme()

  return (
    <ToggleButton
      compact
      variant={VARIANTS.ACCESSORY}
      labelText={localized("Show selected")}
      pressed={showSelected}
      onPressedChange={() => {
        toggleShowSelected(showSelected => {
          if (showSelected) {
            gotoPage(0)
          }
        })
      }}
      rightRenderer={() => (
        <Badge
          data-testid="show-selected-count-badge"
          count={showSelectedCount}
          backgroundColor={showSelected ? theme.colorForegroundSelectedStrong : theme.colorBackgroundAccentNeutral}
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding={[0, sizer(2)]}
          width="initial"
          height="14px"
          bold
        />
      )}
    />
  )
}

export default ShowSelectedButton
