export const updateOwner = (state, action) => {
  return {
    ...state,
    node: {
      ...state.node,
      ownerUid: action.owner.ownerUid,
      ownerName: action.owner.ownerName,
    },
  }
}
