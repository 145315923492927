import qs from "qs"
import { fetchJson, localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"
import { getMountedContentActions } from "./actions"
import { getMountedContentColumns } from "./columns"

const fetchData = async ({
  currentDir: {
    name,
    planName,
    planId,
    imagePlanId,
    planUuid,
    revisionId,
    path: parentPath,
    downloadPath: downloadParentPath,
    fullPath: fullParentPath,
    planDeleted,
  },
  fetchingParams: { pageIndex, pageSize },
  separator,
  node,
}) => {
  if (!imagePlanId || !revisionId)
    return {
      data: [],
    }
  const { results, totalElements, totalPages } = await fetchJson(
    `/backup/lockhart/image/v2/nodes/${node.id}/plans/${imagePlanId}/revisions/${revisionId}/content?${qs.stringify({
      folder: parentPath,
      start: pageIndex * pageSize,
      count: pageSize,
    })}`,
  )

  const content = results.map(({ name, type, size, modified }) => {
    const isFolder = type === "FOLDER"

    return {
      node,
      planId,
      imagePlanId,
      planUuid,
      revisionId,
      planName,
      parentPath,
      folder: isFolder,
      actualSize: size,
      type: isFolder ? "mountedFolder" : "mountedFile",
      name,
      modified,
      size,
      path: `${parentPath}/${name}`,
      downloadPath: downloadParentPath + separator + name,
      fullPath: fullParentPath + separator + name,
      fullParentPath,
      planDeleted,
    }
  })

  return {
    data: content,
    pageCount: totalPages,
    totalCount: totalElements,
  }
}

const onError = (error, { name }) =>
  reportErrorAndShowMessage(error, localizationKey("There was an error fetching content for folder {{folderName}}"), {
    folderName: name,
  })

export const getMountedContentNavigationHandler = () => ({
  columns: getMountedContentColumns(),
  actions: getMountedContentActions(),
  fetchData,
  onError,
})
