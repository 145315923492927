import { reject, propEq, map } from "ramda"

const contacts = (state = { list: [], textFilter: "" }, action) => {
  switch (action.type) {
    case "SET_CONTACT_LIST":
      return { ...state, list: action.list }
    case "SET_CONTACT_FILTER":
      return { ...state, textFilter: action.textFilter }
    case "DELETE_CONTACT":
      return { ...state, list: reject(propEq("id", action.contactId), state.list) }
    case "ADD_CONTACT":
      return { ...state, list: [...state.list, action.contact] }
    case "EDIT_CONTACT":
      return {
        ...state,
        list: map(c => (c.id === action.contact.id ? action.contact : c), state.list),
      }
    default:
      return state
  }
}

export default contacts
