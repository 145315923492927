import { TableNode } from "@lexical/table"

export const tableExport = [
  TableNode,
  (editor, node) => {
    const element = document.createElement("table")
    const className = editor._config.theme.table
    if (className) {
      element.className = className
    }
    element.style = "border-collapse: collapse; border-spacing: 0;"
    return { element }
  },
]

export const htmlExport = new Map([tableExport])
