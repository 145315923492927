import { combineReducers } from "redux"
import products from "./products"
import quickMenus from "./quickMenus"
import guideTooltips from "./guideTooltips"
import ivantiProducts from "./ivantiProducts"
import recentTabs from "./recentTabs"
import sideBar from "./sideBar"
import deviceRoles from "./deviceRoles"
import favoriteTabs from "./favoriteTabs"
import boardsConfiguration from "./boardsConfiguration"
import boardTabs from "./boardTabs"
import customHealthStatus from "./healthStatus"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import value from "js/state/reducers/common/value"

export default combineReducers({
  products,
  quickMenus,
  guideTooltips,
  ivantiProducts,
  recentTabs,
  isSideBarCollapsed: sideBar,
  deviceRoles,
  favoriteTabs,
  boardsConfiguration,
  boardTabs,
  customHealthStatus,
  downloadableProducts: createNamedWrapperReducer(value, "downloadableProducts", {}),
})
