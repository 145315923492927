import { memo, useState } from "react"

import { Tooltip, Checkbox } from "@ninjaone/components"
import { GripLinesIconSvg, ErrorIconSvg } from "@ninjaone/icons"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"
import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"

import Cell from "./Cell"
import ActionCell from "./ActionCell"
import { useShiftPressed } from "../hooks"

const rowDataType = "text/plain"

const isTargetOrParentsClickable = node => {
  const isTableRow = node.tagName.toLowerCase() === "tr"
  const isBody = node.tagName.toLowerCase() === "body" // element clicked was in portal
  const isClickable = !!node.onclick || !!node.href || node.tagName.toLowerCase() === "input"
  const isTooltip = node.getAttribute("data-ninja-tooltip-trigger") !== null // radix tooltip has a noop function implemented.

  if (isTableRow || isBody || isTooltip) return false
  if (isClickable) return true

  return isTargetOrParentsClickable(node.parentNode)
}

const getRowIcon = props => {
  const { error, errorTooltipLabel } = props
  if (!error) return null

  if (error) {
    if (errorTooltipLabel) {
      return (
        <Box marginTop="1px">
          <Tooltip label={errorTooltipLabel} variant="danger">
            <ErrorIconSvg />
          </Tooltip>
        </Box>
      )
    }
    return <ErrorIconSvg />
  }
}

const Row = ({
  fetchData,
  row,
  id,
  selectMode,
  enableLazyLoadRows,
  handleToggleRow,
  checked,
  title,
  actions,
  actionButtons,
  handleKeyDown,
  handleOnClick,
  multipleRowsSelected,
  hideCheckboxes,
  rowLabelColumn,
  isLoading,
  hasMultipleVisibleColumns,
  className,
  resetData,
  dragAndDrop,
  scrollableArea,
  getCustomRowProps,
  lastCellFocused,
  showSelectedToggleRowSelected,
  tableContainerWidth,
}) => {
  const { onDrop, accessorKey } = dragAndDrop || {}
  const isShiftPressed = useShiftPressed()

  const [mouseEnteredRow, setMouseEnteredRow] = useState(false)
  const [actionDropdownOpen, setActionsDropdownOpen] = useState(false)

  const { action: rowAction, disable: disableAction } = actions?.row || {}

  const rowProps = row.getRowProps()
  const { hideActions, ...customProps } = getCustomRowProps?.(row.original) || {}

  const enableRowAction = rowAction && !disableAction?.(row.original) && !hideActions

  const onSelectRow = ({ event, row }) => {
    if (enableLazyLoadRows || fetchData) {
      handleToggleRow(row.id, !checked)
    }

    row.toggleRowSelected()
    showSelectedToggleRowSelected(row.id, !checked)
    handleOnClick({ event, row })
  }

  return (
    <tr
      {...rowProps}
      id={id}
      className={`${className} ${enableRowAction && !selectMode ? "cursor-pointer" : ""} ${
        customProps?.greyedOut ? " greyed-out" : ""
      } ${actionDropdownOpen ? " force-hover" : ""} ${onDrop ? " dnd-row" : ""}`}
      tabIndex={0}
      data-selected={checked}
      data-disabled={customProps?.disabled}
      data-testid={row.id}
      onKeyDown={event => handleKeyDown({ event, row, rowAction, enableRowAction, fetchData })}
      data-test-element-key={row?.original?.id}
      // Do not change this attr. This is used internally for keyboard navigation.
      data-table-row-id={row.id}
      onMouseEnter={() => {
        onDrop && document.getElementById(id).removeAttribute("draggable")

        if (!mouseEnteredRow) {
          setMouseEnteredRow(true)
        }
      }}
      onMouseLeave={() => {
        if (mouseEnteredRow && !actionDropdownOpen) {
          setMouseEnteredRow(false)
        }
      }}
      {...((enableRowAction || selectMode) && {
        ...{
          onClick: event => {
            if (!isTargetOrParentsClickable(event.target)) {
              if (selectMode && !customProps?.disabled) {
                onSelectRow({ event, row })
              } else if (enableRowAction) {
                rowAction(row.original, event, resetData)
              }
            }
          },
        },
      })}
      {...(onDrop && {
        onDragStart: e => {
          // This defines the icon the browser will show when dragging
          e.dataTransfer.effectAllowed = "move"
          e.dataTransfer.clearData(rowDataType)
          // Set current row data to be retrieved when dropping the row
          e.dataTransfer.setData(
            rowDataType,
            JSON.stringify({
              fromRow: row.original,
              index: row.index,
            }),
          )
        },
        onDragEnd: e => {
          // Remove draggable attribute so that the row cannot be dragged clicking on any place inside it.
          e.currentTarget.removeAttribute("draggable")
        },
        onDragEnter: e => e.preventDefault(),
        onDragOver: e => {
          e.preventDefault()
          if (!e.currentTarget.classList.contains("dnd-hover")) {
            e.currentTarget.classList.add("dnd-hover")
          }
        },
        // Remove dnd class from element that was dragged
        onDragLeave: e => e.currentTarget.classList.remove("dnd-hover"),
        onDrop: e => {
          const data = JSON.parse(e.dataTransfer.getData(rowDataType))
          onDrop({ fromIndex: data.index, toIndex: row.index, fromRow: data.fromRow, toRow: row.original })
          // Remove dnd class from the element the row was dropped on
          document.getElementById(id).classList.remove("dnd-hover")
        },
      })}
      {...(isShiftPressed && { style: { userSelect: "none" } })}
    >
      {row.cells.map((cell, index) => {
        const key = `${id}-${index}`

        if (cell.column.id === "selection") {
          if (hideCheckboxes) return null

          const { disabled, disabledTooltipLabel = localized("This item cannot be actioned") } = customProps || {}

          return (
            <td
              key={key}
              tabIndex={0}
              {...cell.getCellProps()}
              className="data-table-cell-selection"
              data-test-column-id={cell.column.id}
              onClick={event => !disabled && onSelectRow({ event, row })}
              onKeyDown={event => {
                if (event.key === "Enter" && !disabled) {
                  onSelectRow({ event, row })
                  event.stopPropagation()
                }
              }}
            >
              {disabled ? (
                <Tooltip label={disabledTooltipLabel}>
                  <Checkbox
                    {...{
                      onChange: ({ event }) => onSelectRow({ event, row }),
                      ariaLabel: row.values[rowLabelColumn] ?? disabledTooltipLabel,
                      disabled,
                    }}
                  />
                </Tooltip>
              ) : (
                <Checkbox
                  {...{
                    title,
                    checked,
                    onChange: ({ event }) => onSelectRow({ event, row }),
                    ariaLabel: row.values[rowLabelColumn] ?? "",
                  }}
                />
              )}
            </td>
          )
        }

        if (cell.column.id === "drag-and-drop") {
          return (
            <td {...cell.getCellProps()} className="dnd-cell">
              <div className="content">
                {accessorKey && <div className="value">{row.original[accessorKey]}</div>}
                <div
                  data-visible-on-row-hover
                  className="hover-cursor-grab"
                  {...(onDrop && {
                    // Add and remove draggable attribute so that the row can be dragged only after clicking on this icon container.
                    onMouseDown: () => document.getElementById(id).setAttribute("draggable", true),
                    onMouseUp: () => document.getElementById(id).removeAttribute("draggable"),
                  })}
                >
                  {/* For some reason Tooltip affects performance so it is shown after mouse entered row. */}
                  {mouseEnteredRow ? (
                    <Tooltip label={localized("Drag to re-order row")}>
                      <GripLinesIconSvg />
                    </Tooltip>
                  ) : (
                    <GripLinesIconSvg />
                  )}
                </div>
              </div>
            </td>
          )
        }

        if (cell.column.id === "status-indicator") {
          const { className: originalClassName = "", ...cellProps } = cell.getCellProps()
          const additionalClassName = "status-indicator-column"
          const { fallbackColor, getStatusIndicatorColor, getStatusIndicatorBorderColor } = cell.column

          if (isLoading) return null

          return (
            <td
              tabIndex={0}
              {...cellProps}
              className={`${originalClassName} ${additionalClassName}`}
              data-test-column-id={cell.column.id}
            >
              <div className="status-indicator-cell">
                <div
                  className="status-indicator"
                  style={{
                    backgroundColor: getStatusIndicatorColor(row.original) || fallbackColor,
                    ...(!!getStatusIndicatorBorderColor && {
                      border: `1px solid ${getStatusIndicatorBorderColor(row.original)}`,
                    }),
                  }}
                />
              </div>
            </td>
          )
        }

        if (cell.column.id === "row-state-icon") {
          return (
            <td tabIndex={0} {...cell.getCellProps()} data-test-column-id={cell.column.id} style={{ paddingLeft: 0 }}>
              {getRowIcon(customProps)}
            </td>
          )
        }

        if (cell.column.id === "actions") {
          return (
            <ActionCell
              {...{
                key,
                cell,
                cellId: key,
                row,
                actions,
                actionButtons,
                lastCellFocused,
                isLoading,
                resetData,
                mouseEnteredRow,
                scrollableArea,
                hideActions,
                isShiftPressed,
                onOpenChange: setActionsDropdownOpen,
              }}
            />
          )
        }

        return (
          <Cell
            {...{
              key,
              mouseEnteredRow,
              hasMultipleVisibleColumns,
              value: cell.value,
              column: cell.column,
              originalRow: row.original,
              tableContainerWidth,
            }}
          />
        )
      })}
    </tr>
  )
}

export default memo(Row)
