const uninstallingSoftware = (state = false, action) => {
  switch (action.type) {
    case "SET_UNINSTALLING_SOFTWARE":
      return action.value
    default:
      return state
  }
}

export default uninstallingSoftware
