import styled from "@emotion/styled"
import { SearchIcon } from "@ninjaone/icons"
import { sizer, getTextSize, getLineHeight } from "@ninjaone/utils"

const StyledSearchInput = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  > * {
    width: inherit;
  }
`
const StyledPlaceholder = styled.span`
  height: inherit;
  display: flex;
  justify-content: flex-end;
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection}`};
  align-items: center;
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  padding-right: ${sizer(3)};
  pointer-events: none;
`

export const StyledInput = styled.input`
  width: 100%;
  height: ${({ height }) => height};
  border: none;
  ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom}`};
  outline: none;
  background-color: ${({ theme }) => theme.color.background};
  border-radius: ${sizer(1)};
  padding: ${({ padding }) => (padding ? padding : sizer(4, 5))};
  ${({ flexDirection, hasIcon }) =>
    flexDirection === "row-reverse"
      ? `padding-left: ${sizer(6)};`
      : `padding-right: ${hasIcon ? sizer(10) : sizer(3)};`}
  font-size: ${({ size }) => getTextSize(size || "sm")};
  font-weight: 400;
  user-select: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: ${({ size }) => getLineHeight(size || "sm")};
  letter-spacing: normal;
  color: ${({ color, theme }) =>
    color ? theme.color[color] || theme.color?.[color.code]?.[color.shade] || color : ""};

  ::placeholder {
    color: ${({ theme }) => theme.color.gray};
  }
`

export default function SearchInput({ testId, placeholderText, iconColor, height, ...rest }) {
  return (
    <StyledSearchInput>
      <StyledInput
        hasIcon
        type="search"
        height={height ?? "100%"}
        border="none"
        data-testid={testId}
        placeholder={placeholderText}
        {...rest}
      />
      <StyledPlaceholder {...rest}>
        <SearchIcon color={iconColor ?? "gray"} />
      </StyledPlaceholder>
    </StyledSearchInput>
  )
}
