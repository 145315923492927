const initialState = {
  urls: null,
}

export default function connect(state = initialState, action) {
  switch (action.type) {
    case "QUICK_CONNECT_SET_URLS":
      return { ...state, urls: action.urls }
    default:
      return state
  }
}
