import { LinkPlugin as LexicalLinkPlugin } from "@lexical/react/LexicalLinkPlugin"

function defaultValidateUrl(url) {
  return (
    url === "https://" ||
    // Taken from https://mathiasbynens.be/demo/url-regex
    new RegExp(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i).test(url)
  )
}

export const LinkPlugin = ({ validateUrl = defaultValidateUrl }) => {
  return <LexicalLinkPlugin validateUrl={validateUrl} />
}
