import { setNodeRolePolicyMap, updateNodeRolePolicyMap } from "./actionHandlers"

const initialState = {
  nodeRolePolicyMap: [],
}

export default function policies(state = initialState, action) {
  switch (action.type) {
    case "SET_NODE_ROLE_POLICY_MAP":
      return setNodeRolePolicyMap(state, action)
    case "UPDATE_NODE_ROLE_POLICY_MAP":
      return updateNodeRolePolicyMap(state, action)
    case "CLEAR_NODE_ROLE_POLICY_MAP":
      return initialState
    default:
      return state
  }
}
