import { useCallback, useEffect, useState } from "react"
import { is } from "ramda"
import styled from "@emotion/styled"
import { ImageComponent } from "@ninjaone/components/src/WYSIWYG"
import { Skeleton } from "@ninjaone/components"
import { getSignedUrlFromAttachmentId } from "js/includes/common/client"
import { useMountedState } from "js/includes/common/hooks"
import { reportErrorAndShowMessage, localizationKey, PubSub, downloadFile } from "js/includes/common/utils"
import { Attachment } from "js/includes/components/Attachment"
import { ImagePreviewModal } from "js/includes/components/ImagePreviewModal"
import showModal from "js/includes/common/services/showModal"

const StyledImageWrapper = styled.span`
  img {
    &:hover {
      cursor: pointer !important;
    }
  }
`

function getDimensions({ width, height }) {
  return {
    width: is(Number, width) ? `${width}px` : "200px",
    height: is(Number, height) ? `${height}px` : "100px",
  }
}

export const TicketingImageComponent = props => {
  const { attachment, src: _src } = props
  const [uploadStatus, setUploadStatus] = useMountedState(props?.attachment?.uploadStatus)
  const [src, setSrc] = useMountedState(_src)
  const [loadingSignedUrl, setLoadingSignedURL] = useState(!!attachment)

  const loadSignedUrl = useCallback(async () => {
    if (attachment?.id) {
      try {
        const { signedUrl } = await getSignedUrlFromAttachmentId(attachment.id)
        setSrc(signedUrl)
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Failed to load inline image"))
      } finally {
        setLoadingSignedURL(false)
      }
    }
  }, [attachment, setSrc, setLoadingSignedURL])

  useEffect(() => {
    if (uploadStatus === "PROCESSING") return
    if (attachment && loadingSignedUrl) loadSignedUrl()
  }, [attachment, loadingSignedUrl, loadSignedUrl, uploadStatus])

  useEffect(() => {
    const subscription =
      attachment &&
      PubSub.subscribe(`ATTACHMENT_${attachment.id}`, (_, data) => {
        const { uploadStatus } = data
        setUploadStatus(uploadStatus)
      })
    return () => subscription && PubSub.unsubscribe(subscription)
  }, [attachment, setUploadStatus])

  const onDownload = useCallback(async () => {
    try {
      const { signedUrl } = await getSignedUrlFromAttachmentId(attachment?.id)
      downloadFile(signedUrl, attachment?.metadata?.name)
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Error while downloading image."))
    }
  }, [attachment?.metadata?.name, attachment?.id])

  const handlePreview = useCallback(() => {
    showModal(
      <ImagePreviewModal
        {...{
          onDownload,
          attachmentId: attachment?.id,
          titleText: attachment?.metadata?.name,
        }}
      />,
    )
  }, [onDownload, attachment?.id, attachment?.metadata?.name])

  if (uploadStatus === "PROCESSING") return <Attachment attachment={attachment} />

  return loadingSignedUrl ? (
    <Skeleton {...getDimensions(props)} />
  ) : (
    <StyledImageWrapper onClick={handlePreview}>
      <ImageComponent {...props} src={src} />
    </StyledImageWrapper>
  )
}
