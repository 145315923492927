export default function accountAutoMapPreview(state = {}, action) {
  switch (action.type) {
    case "SET_CW_ACCOUNT_AUTO_MAP":
      return action.data
    case "CLEAR_CW_ACCOUNT_AUTO_MAP":
      return {}
    default:
      return state
  }
}
