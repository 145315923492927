import { map } from "ramda"

const initialState = {
  devices: [],
}

export default function tables(state = initialState, action) {
  switch (action.type) {
    case "QUICK_CONNECT_SET_DEVICES":
      return { ...state, devices: action.devices }
    case "QUICK_CONNECT_SYNC_DEVICES_ORIGINAL_STATUS":
      return { ...state, devices: map(device => ({ ...device, status: device.currentStatus }), state.devices) }
    case "QUICK_CONNECT_UPDATE_DEVICE":
      return {
        ...state,
        devices: map(device => (device.deviceId === action.device.deviceId ? action.device : device), state.devices),
      }
    default:
      return state
  }
}
