const setSelectedSoftware = (state = [], action) => {
  switch (action.type) {
    case "SET_SELECTED_SOFTWARE":
      return action.software
    default:
      return state
  }
}

export default setSelectedSoftware
