import qs from "qs"
import {
  EnglishTextToken,
  dateTime,
  fetchJson,
  localizationKey,
  reportErrorAndShowMessage,
  user,
} from "js/includes/common/utils"
import { getDownloadAction, getRestoreAction } from "./actions"
import { getRevisionsColumns } from "./columns"
import { planTypesMap } from "js/includes/common/backup"
import { DestinationType, planType } from "js/includes/editors/Policy/Sections/Backups/common/planTypes"
import { LOCAL } from "js/includes/editors/Policy/Sections/Backups/common/destination"

type currentDirType = {
  name: string
  planName: string
  planId: number
  planDeleted: boolean
  id: number
  path: string
  disabled: boolean
  backupPermissions: boolean
  planType: planType
  destination: DestinationType
}

type fetchDataProps = {
  currentDir: currentDirType
  fetchingParams: {
    pageIndex: number
    pageSize: number
  }
  node: { id: number }
}

type RevisionType = {
  id: number
  size: number
  backupSize: number
  bldType: string
  jobCreated: number
  jobCompleted: number
  onS3: boolean
}

const fetchData = async ({
  currentDir: {
    name: parentName,
    planName,
    planId,
    planDeleted,
    id: fileId,
    path: filePath,
    disabled,
    backupPermissions,
    planType,
    destination,
  },
  fetchingParams: { pageIndex, pageSize },
  node,
}: fetchDataProps) => {
  const { results, totalElements, totalPages } = await fetchJson(
    `/backup/lockhart/v2/nodes/${node.id}/plans/${planId}/files/${fileId}/revisions?${qs.stringify({
      page: pageIndex,
      size: pageSize,
    })}`,
  )

  const content = results.map(({ id, size, backupSize, bldType, jobCreated, jobCompleted }: RevisionType) => {
    return {
      node,
      file: {
        id: fileId,
        path: filePath,
        planName,
        planId,
      },
      id,
      name: dateTime(jobCreated),
      isPointInTime: true,
      volumeLabel: parentName,
      folder: false,
      planDeleted,
      actualSize: size,
      backupSize,
      type: "revisions",
      path: id.toString(),
      size,
      bldType,
      created: jobCreated,
      jobCompleted,
      disabled,
      backupPermissions,
      planType,
      destination,
    }
  })

  return {
    data: content,
    pageCount: totalPages,
    totalCount: totalElements,
  }
}

const onError = (error: Error, { name }: { name: string }) =>
  reportErrorAndShowMessage(
    error,
    // TODO: remove 'as EnglishTextToken' when localization type issue is fixed
    localizationKey("There was an error fetching revisions for {{volumeName}}" as EnglishTextToken),
    {
      volumeName: name,
    },
  )

const getIsDownloadDisabled = ({ planType, destination }: currentDirType) => {
  return planType === planTypesMap.FILE_FOLDER && destination === LOCAL
}

const getFileRevisionActions = (isFileFolderBackupAllowed = false) => {
  if (!user("canViewAndRestoreBackupData") && !isFileFolderBackupAllowed) return []

  return [
    getRestoreAction({
      hideMultiAction: (selected: currentDirType[]) => selected.length > 1,
    }),
    getDownloadAction({
      hideRowAction: getIsDownloadDisabled,
      hideMultiAction: (selected: currentDirType[]) => selected.length > 1 || getIsDownloadDisabled(selected[0] ?? {}),
    }),
  ]
}

export const getFileRevisionsHandler = ({ isFileFolderBackupAllowed }: { isFileFolderBackupAllowed: boolean }) => ({
  columns: getRevisionsColumns(),
  actions: getFileRevisionActions(isFileFolderBackupAllowed),
  fetchData,
  onError,
})
