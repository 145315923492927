import ReactMarkdown from "react-markdown"
import styled from "@emotion/styled"
import { StyledEmptyCard } from "@ninjaone/components/src/Card"
import { getTextSize, sizer } from "@ninjaone/utils"
import { Skeleton } from "@ninjaone/components"
import { Flex } from "js/includes/components/Styled"

export const allowedElements = ["p", "em", "strong", "a"]

export const ArticleCard = styled(StyledEmptyCard)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${sizer(4)};
  gap: ${sizer(2)};
  border-color: ${({ theme }) => theme.colorBorderWeak};

  &&& a {
    color: ${({ theme }) => theme.colorTextAction};

    &:focus-visible {
      outline-color: ${({ theme }) => theme.colorForegroundFocus};
    }
  }
`

export const Markdown = styled(ReactMarkdown)`
  p {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: ${({ theme }) => theme.colorTextWeakest};
    display: -webkit-box;
    font-size: ${getTextSize("sm")};
    overflow: hidden;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const HelpDropdownSkeletons = () => {
  return (
    <Flex flexDirection="column" gap={sizer(2)}>
      <ArticleCard marginBottom={sizer(2)}>
        <Skeleton display="block" width="50%" height="16px" />
        <Skeleton display="block" width="100%" height="16px" />
        <Skeleton display="block" width="100%" height="16px" />
      </ArticleCard>
      <ArticleCard>
        <Skeleton display="block" width="50%" height="16px" />
        <Skeleton display="block" width="100%" height="16px" />
        <Skeleton display="block" width="100%" height="16px" />
      </ArticleCard>
      <ArticleCard>
        <Skeleton display="block" width="50%" height="16px" />
        <Skeleton display="block" width="100%" height="16px" />
        <Skeleton display="block" width="100%" height="16px" />
      </ArticleCard>
    </Flex>
  )
}
