import { useCallback, useEffect, useMemo, useRef } from "react"
import { connect, useDispatch } from "react-redux"
import { last } from "ramda"
import styled from "@emotion/styled"
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons"

import { AlertMessage, ButtonGroup, GuideTooltip, IconButton, SearchBar } from "@ninjaone/components"
import { CloseIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"

import { useMountedState } from "js/includes/common/hooks"
import {
  getNinjaBranding,
  isEscapeKey,
  isRmmServiceLocation,
  localizationKey,
  localized,
  ninjaReportError,
  reportErrorAndShowMessage,
} from "js/includes/common/utils"
import { hideHelpGuideTooltip } from "js/includes/common/client/guideTooltips"

import { Box, Flex } from "js/includes/components/Styled"

import { DROPDOWN_VIEWS, DROPDOWN_VIEWS_KEY, fetchHelpArticles } from "./helpDropdownData"
import { closeGuideTooltip } from "js/state/actions/general/guideTooltips"
import Portal from "js/includes/components/Portal"

const zIndexAboveEditors = 2060
const zIndexBelowTopNavBarDropdowns = 997

const StyledDropdownContainer = styled.div`
  top: ${({ isEditor }) => (isEditor ? "52px" : "50px")};
  height: ${({ isEditor }) => `calc(100vh - ${isEditor ? "52px" : "89px"})`};
  position: absolute;
  right: 0;
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  outline: none;
  padding: ${sizer(6)};
  border: 1px solid ${({ theme }) => theme.colorBorderWeak};
  border-top: 0px;
  border-bottom: 0px;
  background-color: ${({ theme }) => theme.colorBackground};
  z-index: ${({ isEditor }) => (isEditor ? zIndexAboveEditors : zIndexBelowTopNavBarDropdowns)};
  width: 420px;
  user-select: none;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  clip-path: inset(0px 0px 0px -20px);
`

const StyledIndicator = styled.div`
  position: absolute;
  top: 10px;
  right: 6px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  outline: 2px solid ${({ theme }) => theme.color.background};
  background-color: ${({ theme }) => theme.color.indicatorColor};
`

const StyledButton = styled.button`
  border: none;
  background: none;
  padding: 0;

  &:focus-visible {
    outline: 2px auto ${({ theme }) => theme.color.primary["075"]};
    @supports (-moz-appearance: none) {
      outline-offset: -2px;
    }
  }
`

const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  justify-content: end;
  gap: ${sizer(2)};
  padding-top: ${sizer(2)};
`

const StyledIconButtonWrapper = styled.div`
  button {
    ${({ theme, isEditor }) =>
      isEditor &&
      `
        background-color: ${theme.colorBackground};
        color: ${theme.colorTextAction};
    `}
    ${({ usesSmallTrigger }) => usesSmallTrigger && `height: 34px;`}
  }
`

const StyledTitle = styled.div`
  font-size: 18px;
  line-height: 27px;
  color: ${({ theme }) => theme.colorTextStrong};
  font-weight: 500;
`

const allowOutlineOverflowProps = {
  paddingLeft: "2px",
  paddingRight: "2px",
  paddingBottom: "2px",
  paddingTop: "2px",
  marginLeft: "-2px",
  marginRight: "-2px",
  marginBottom: "-2px",
  marginTop: "-2px",
}

const shouldShow = (isBranded, featureEnabled) => (isBranded ? featureEnabled : true)

const HeaderDropdown = ({ handleClose, currentView, searchBarProps, showHelpNinjaArticles }) => {
  const showSearchBar =
    showHelpNinjaArticles && [DROPDOWN_VIEWS_KEY.default, DROPDOWN_VIEWS_KEY.articleList].includes(currentView)
  const titleToken = DROPDOWN_VIEWS?.[currentView]?.titleToken || localizationKey("Help resources")
  const isOnSubmitHelpTicket = currentView === "submitHelpTicket"
  const titleMarginBottom = isOnSubmitHelpTicket ? sizer(1) : sizer(4)

  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between" marginBottom={titleMarginBottom}>
        <StyledTitle>{localized(titleToken)}</StyledTitle>
        <StyledButton onClick={handleClose} aria-label={localized("Close")}>
          <CloseIcon color="colorTextStrong" />
        </StyledButton>
      </Flex>

      {showSearchBar && (
        <Box marginBottom={sizer(6)}>
          <SearchBar placeholderText={localized("Search help articles")} autofocus width="100%" {...searchBarProps} />
        </Box>
      )}
    </Box>
  )
}

const FooterDropdown = ({ handleGoBack, handleClose, showAdditionalResources, currentView, handleChangeView }) => {
  const buttons =
    DROPDOWN_VIEWS?.[currentView].getFooterButtons?.({
      handleChangeView,
      visible: showAdditionalResources,
      handleGoBack,
    }) ?? []

  if (!buttons.length) return null

  return (
    <Box>
      <StyledButtonGroup buttons={buttons} />
    </Box>
  )
}

const helpDropdownTriggerId = "fs-help-menu"

const HelpDropdown = ({
  helpSettings: _helpSettings,
  helpNinjaArticlesEnabled,
  helpSubmitTicketEnabled,
  ninjaOneWorkrampAcademyEnabled,
  locale,
  isBranded,
  openHelpTooltip,
  activeRoute,
  isEditor,
  portalContainerId,
  usesSmallTrigger,
  showHelpMenuGuideTooltip,
}) => {
  const [showIndicator, setShowIndicator] = useMountedState(false)
  const [show, setShow] = useMountedState(false)
  const [alertProps, setAlertProps] = useMountedState(null)
  const dropdownRef = useRef(null)
  const dispatch = useDispatch()

  const [searchCriteria, setSearchCriteria] = useMountedState("")
  const [loading, setLoading] = useMountedState(false)
  const [articles, setArticles] = useMountedState([])
  const [error, setError] = useMountedState(false)
  const searchTimer = useRef(null)

  const defaultView = DROPDOWN_VIEWS_KEY.default
  const [viewHistory, setViewHistory] = useMountedState([defaultView])
  const currentView = last(viewHistory)
  const showAdditionalResources = !!searchCriteria.length && !loading
  const isArticleListView = currentView === DROPDOWN_VIEWS_KEY.articleList

  const showHelpNinjaArticles = shouldShow(isBranded, helpNinjaArticlesEnabled)
  const showHelpSubmitTicket = shouldShow(isBranded, helpSubmitTicketEnabled)
  const showNinjaOneWorkrampAcademy = shouldShow(isBranded, ninjaOneWorkrampAcademyEnabled)

  const resetSearch = useCallback(() => {
    clearTimeout(searchTimer.current)
    setLoading(false)
    setSearchCriteria("")
    setArticles([])
    setError(false)
  }, [setArticles, setLoading, setSearchCriteria, setError])

  const handleClose = useCallback(() => {
    setShow(false)
    resetSearch()
    setViewHistory([defaultView])
  }, [defaultView, resetSearch, setShow, setViewHistory])

  const handleKeyPress = useCallback(
    event => {
      if (!show) return

      const eventComesFromZendeskForm =
        event.target.hasAttribute("data-ninja-select-content") ||
        event.target.hasAttribute("data-ninja-select-item") ||
        event.target.closest("[data-ninja-searchable-dropdown]")

      if (eventComesFromZendeskForm) return

      if (isEscapeKey(event)) {
        handleClose()
      }
    },
    [handleClose, show],
  )

  const handleChangeView = nextView => {
    if (viewHistory.includes(nextView)) return
    setViewHistory(prevState => [...prevState, nextView])
  }

  const handleGoBack = () => {
    if (loading || isArticleListView) resetSearch()
    setViewHistory(prevState => prevState.slice(0, -1))
  }

  const handleSearchBarChange = e => {
    const { value } = e.target
    if (!value.length) return handleGoBack()

    setSearchCriteria(value)
    setError(false)
    setLoading(true)
    handleChangeView(DROPDOWN_VIEWS_KEY.articleList)

    clearTimeout(searchTimer.current)
    searchTimer.current = setTimeout(() => fetchArticles(value), 2000)
  }

  const fetchArticles = async query => {
    try {
      const { results } = await fetchHelpArticles({ query, locale })

      if (!Array.isArray(results)) {
        throw new Error("Invalid results response")
      }

      setArticles(results)
    } catch (error) {
      setArticles([])
      setError(true)
      ninjaReportError(error)
    } finally {
      setLoading(false)
    }
  }

  const helpSettings = useMemo(
    () => (isRmmServiceLocation() ? _helpSettings : getNinjaBranding().general.helpSettings),
    [_helpSettings],
  )

  const handleCloseHelpTooltip = useCallback(async () => {
    dispatch(closeGuideTooltip("helpMenu"))

    try {
      await hideHelpGuideTooltip()
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Unable to save help tooltip status"))
    }
  }, [dispatch])

  useEffect(() => {
    if (!show) return
    const dropdown = dropdownRef.current
    dropdown.focus()
    dropdown.addEventListener("keydown", handleKeyPress)
    return () => {
      dropdown.removeEventListener("keydown", handleKeyPress)
    }
  }, [handleKeyPress, show])

  useEffect(() => {
    const handleHashChange = event => {
      const enteringEditor = event.newURL.includes("/editor/") && !event.oldURL.includes("/editor/")

      if (enteringEditor) {
        handleClose()
      }
    }

    window.addEventListener("hashchange", handleHashChange)

    return () => {
      window.removeEventListener("hashchange", handleHashChange)
    }
  }, [handleClose])

  useEffect(() => {
    const handleHashChange = () => {
      if (showHelpMenuGuideTooltip) {
        handleCloseHelpTooltip()
      }
    }

    window.addEventListener("hashchange", handleHashChange, { once: true })

    return () => {
      window.removeEventListener("hashchange", handleHashChange)
    }
  }, [showHelpMenuGuideTooltip, handleCloseHelpTooltip])

  const tooltipTitle = localized("A smarter way to get help")
  const tooltipDescription = localized("View suggested help articles wherever you are in the app.")

  if (!helpSettings?.enabled) return null

  const triggerIconButton = (
    <StyledIconButtonWrapper {...{ isEditor, usesSmallTrigger }}>
      <IconButton
        {...{
          handleClick: () => {
            setShow(prevState => !prevState)
            setShowIndicator(false)
            showHelpMenuGuideTooltip && handleCloseHelpTooltip()
          },
          icon: faQuestionCircle,
          id: helpDropdownTriggerId,
          size: usesSmallTrigger ? "sm" : "lg",
          active: show,
          ...(showHelpMenuGuideTooltip && { "aria-label": localized("Help resources") }),
          ...(!showHelpMenuGuideTooltip && !isEditor && { tooltip: localized("Help resources") }),
          ...(showIndicator && { Indicator: StyledIndicator }),
        }}
      />
    </StyledIconButtonWrapper>
  )

  return (
    <Box position="relative">
      {isEditor ? (
        triggerIconButton
      ) : (
        <GuideTooltip
          {...{
            position: "bottom",
            align: "end",
            width: 400,
            title: tooltipTitle,
            iconName: "BulbOnIconLight",
            textRenderer: () => <p>{tooltipDescription}</p>,
            anchorRenderer: () => triggerIconButton,
            onClose: handleCloseHelpTooltip,
            usesPortal: false,
            alignOffset: -10,
            sideOffset: 10,
            open: showHelpMenuGuideTooltip,
          }}
        />
      )}

      {show && (
        <Portal containerId={portalContainerId}>
          <StyledDropdownContainer {...{ show, tabIndex: 0, ref: dropdownRef, isEditor }}>
            <HeaderDropdown
              {...{
                currentView,
                handleClose,
                searchBarProps: {
                  value: searchCriteria,
                  onChange: handleSearchBarChange,
                  clearInput: handleGoBack,
                },
                showHelpNinjaArticles,
              }}
            />
            <Flex flexDirection="column" flex="1" overflow="auto" {...allowOutlineOverflowProps}>
              {DROPDOWN_VIEWS[currentView].renderer({
                handleClose,
                handleChangeView,
                articles,
                error,
                setAlertProps,
                activeRoute,
                loading,
                showHelpSubmitTicket,
                showNinjaOneWorkrampAcademy,
                showHelpNinjaArticles,
              })}
            </Flex>
            {alertProps && (
              <Box paddingTop={sizer(1)}>
                <AlertMessage {...alertProps} />
              </Box>
            )}
            <FooterDropdown
              {...{
                currentView,
                handleChangeView,
                handleGoBack,
                showAdditionalResources,
              }}
            />
          </StyledDropdownContainer>
        </Portal>
      )}
    </Box>
  )
}

export default connect(({ websiteBranding, application: { locale, isBranded }, general: { guideTooltips } }) => ({
  helpSettings: websiteBranding.brandingNode.general.helpSettings,
  helpNinjaArticlesEnabled: websiteBranding.brandingNode.general.helpSettings.ninjaArticlesEnabled,
  helpSubmitTicketEnabled: websiteBranding.brandingNode.general.helpSettings.submitTicketEnabled,
  ninjaOneWorkrampAcademyEnabled: websiteBranding.brandingNode.general.helpSettings.ninjaOneWorkrampAcademyEnabled,
  locale,
  isBranded,
  showHelpMenuGuideTooltip: guideTooltips.helpMenu,
}))(HelpDropdown)
