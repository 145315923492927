import React, { memo, useEffect, useState } from "react"
import { Col, ControlLabel, FormGroup, Modal } from "react-bootstrap"
import { always } from "ramda"

import { createRevisionLabel, createRevisionOption } from "js/includes/common/backup"
import { getPathSeparator, getPrefixedUrl, isNotNilOrEmpty, localizationKey, localized } from "js/includes/common/utils"
import { getRevisionsByFileEndpoint } from "js/includes/components/Backup/BackupManager/utils"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

import { DownloadWarningMessage } from "./DownloadWarningMessage"
import { replaceSeparatorAndRemoveTrailingSlash } from "js/includes/components/Browsers"

export default memo(function DownloadFileModal({
  unmount: close,
  nodes,
  sourceNode,
  revision: [selectedRevision] = [],
}) {
  const file = nodes?.[0] ?? selectedRevision?.file
  const [enabled, setEnabled] = useState(false)
  const [revision, setRevision] = useState("")
  const { planId, planName, name, path } = file

  useEffect(() => {
    if (revision.value || isNotNilOrEmpty(selectedRevision)) {
      setEnabled(true)
    } else {
      setEnabled(false)
    }
  }, [revision, selectedRevision])

  const downloadHref = getPrefixedUrl(
    `/backup/lockhart/nodes/${sourceNode.id}/plans/${planId}/revisions/${
      isNotNilOrEmpty(selectedRevision) ? selectedRevision.id : revision.value
    }/data`,
  )

  return (
    <Modal.Dialog bsClass="inmodal modal" dialogClassName={"restore-manager-download-modal file"}>
      <Modal.Header>
        <Modal.Title>{localized("Download File")}</Modal.Title>
      </Modal.Header>
      <Modal.Body bsClass={"modal-body overflow-visible-important display-flex flex-direction-column"}>
        <FormGroup className="text-left" controlId="backup-restore-manager-download-info">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Device")}
          </Col>
          <Col xs={12}>
            <div>{sourceNode.displayName}</div>
          </Col>
        </FormGroup>
        <FormGroup className="text-left" controlId="backup-restore-manager-download-info">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Plan Name")}
          </Col>
          <Col xs={12}>
            <div>{planName}</div>
          </Col>
        </FormGroup>
        <FormGroup className="text-left" controlId="backup-restore-manager-download-info">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Path")}
          </Col>
          <Col xs={12}>
            <div className="break-all">
              {replaceSeparatorAndRemoveTrailingSlash(path, getPathSeparator(sourceNode))}
            </div>
          </Col>
        </FormGroup>

        <FormGroup className="text-left" controlId="backup-restore-manager-download-select">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Revision")}
          </Col>
          <Col xs={12}>
            {isNotNilOrEmpty(selectedRevision) ? (
              <div>{createRevisionLabel(selectedRevision)}</div>
            ) : (
              <SearchableDropDown
                {...{
                  openOnFocus: true,
                  pageSize: 100,
                  endpoint: getRevisionsByFileEndpoint(sourceNode, planId, file),
                  width: "100%",
                  rowHeight: 40,
                  noRowsRendererToken: localizationKey(
                    "Browser download is not available for the selected file(s). To recover the file(s), please use the restore option.",
                  ),
                  onSelect: setRevision,
                  dataFormatter: obj => ({ ...createRevisionOption(obj), id: obj.id }),
                  lastItemKey: "id",
                  isSearchable: false,
                  searchParams: always({ onCloud: true }),
                }}
              />
            )}
          </Col>
        </FormGroup>
        <DownloadWarningMessage />
      </Modal.Body>
      <Modal.Footer>
        <div className="display-flex justify-content-between">
          <div className="display-flex flex-full justify-content-end">
            {enabled && (
              <a className="btn btn-success" onClick={() => setEnabled(false)} href={downloadHref} download={name}>
                {localized("Download")}
              </a>
            )}

            <button type="button" className="btn btn-white" onClick={close}>
              {localized("Close")}
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal.Dialog>
  )
})
