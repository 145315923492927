import React from "react"
import { Col } from "react-bootstrap"
import Link from "js/includes/components/Link"

import { DOJO_ARTICLES_PREFIX, localized, userIsTechnician } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"

export const DownloadWarningMessage = () =>
  userIsTechnician() ? (
    <Col xs={12}>
      <Box textAlign="left" fontSize="12px">
        {localized(
          "When restoring large amounts of data, it is strongly recommended to restore the data from the Manage Backup console rather than downloading with a browser.",
        )}
      </Box>
      {
        <Box textAlign="left" fontSize="12px">
          <Link href={`${DOJO_ARTICLES_PREFIX}/360045232051-Ninja-Backup-Setup-Guide`}>
            <div className="btn-link">{localized("More Information")}</div>
          </Link>
        </Box>
      }
    </Col>
  ) : null
