import { ExternalLinkIcon } from "@ninjaone/icons"
import { getTextSize } from "@ninjaone/utils"
import tokens from "@ninjaone/tokens"
import Link from "js/includes/components/Link"
import styled from "@emotion/styled"
import { colors } from "js/includes/common/theme"

const StyledDiv = styled.div`
  font-size: ${getTextSize("sm")};
  line-height: ${tokens.typography.lineHeight};
  font-weight: ${({ bold }) => (bold ? tokens.typography.fontWeight.medium : tokens.typography.fontWeight.regular)};
  text-wrap: balance;
  color: ${colors.ninjaBlueSaturated};

  button:focus-visible & {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
  button:hover & {
    color: ${colors.ninjaBlueSaturated};
  }
`

const HelpDropdownAnchor = ({ href, bold, children }) => {
  return (
    <Link href={href}>
      <StyledDiv bold>
        {children} <ExternalLinkIcon size="xs" />
      </StyledDiv>
    </Link>
  )
}

export default HelpDropdownAnchor
