import React from "react"
import Select from "react-select"
import { flattenOptions } from "../utils"

export default function ValueSelector({ className, handleOnChange, options, value, ariaLabel, excludeOptions }) {
  const flattenedOptions = flattenOptions(options)

  return (
    <Select
      aria-label={ariaLabel}
      menuPlacement="auto"
      className="flex-full"
      options={options.filter(option => option.value === value || !excludeOptions?.includes(option.value))}
      value={flattenedOptions.find(o => o.value === value)}
      onChange={selected => handleOnChange(selected.value)}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: styles => ({
          ...styles,
          zIndex: 9999,
        }),
      }}
    />
  )
}
