import { COMMAND_PRIORITY_CRITICAL } from "lexical"

import { $createImageNode } from "@ninjaone/components/src/WYSIWYG"

import { isNilOrEmpty } from "js/includes/common/utils"
import { $createTicketingImageNode, $createTicketingMentionsNode } from "./nodes"

const getHeightAndWidth = image => {
  const width = image.getAttribute("width")
  const height = image.getAttribute("height")

  return {
    width: +width || width,
    height: +height || height,
  }
}

export const imageImportForReadOnly = ({ attachments }) => image => {
  const parsedCid = image.getAttribute("src")?.split(":")
  const cid = parsedCid?.[1]
  const attachment = attachments.find(attachment => attachment?.metadata?.contentId === cid)

  if (!attachment) return null

  const node = $createTicketingImageNode({ cid, attachment, ...getHeightAndWidth(image) })

  return {
    conversion: () => {
      return { node }
    },
    priority: COMMAND_PRIORITY_CRITICAL,
  }
}

export const imageImport = ({ attachments, importImagesFromMemory }) => image => {
  const srcAttribute = image.getAttribute("src")

  let node
  if (importImagesFromMemory) {
    // It enters here when restoring from memory, like when restoring an edited ticket from memory (Redux store)
    const dataCidAttribute = image.getAttribute("data-cid")
    node = $createImageNode({ cid: dataCidAttribute, src: srcAttribute, ...getHeightAndWidth(image) })
  } else {
    // It enters here when attachment.metadata.___src exists, like when splitting a ticket
    const parsedCid = srcAttribute?.split(":")
    const cid = parsedCid?.[1]
    const attachment = attachments.find(attachment => attachment?.metadata?.contentId === cid)

    if (!attachment) return null

    node = $createImageNode({ cid, src: attachment.metadata.__src, ...getHeightAndWidth(image) })
  }

  return {
    conversion: () => {
      return { node }
    },
    priority: COMMAND_PRIORITY_CRITICAL,
  }
}

export const spanImport = ({ initialMentionData }) => span => {
  if (span.hasAttribute("data-lexical-mention") || span.classList.contains("technician-tagged")) {
    if (isNilOrEmpty(initialMentionData)) return null

    const textContent = span.textContent
    const mentionId = textContent.split(":").pop()
    const mention = initialMentionData.find(data => Number(data.id) === Number(mentionId))

    if (mention) {
      const node = $createTicketingMentionsNode(mention.id, mention.displayName, mention)
      return {
        conversion: element => {
          return { node }
        },
        priority: COMMAND_PRIORITY_CRITICAL,
      }
    }
  }

  return null
}
