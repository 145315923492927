import { combineReducers } from "redux"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import value from "js/state/reducers/common/value"
import sortBy from "js/state/reducers/common/tableSorting/sortBy"
import sortDirection from "js/state/reducers/common/tableSorting/sortDirection"
import editingScript from "./editingScript"
import templateLibraryScripts from "../../scripting/templateLibrary"

export default combineReducers({
  editingScript,
  scriptsList: combineReducers({
    scriptNameFilter: createNamedWrapperReducer(value, "configuration-scripting-scriptsList-scriptNameFilter"),
    scriptCategoryFilter: createNamedWrapperReducer(value, "configuration-scripting-scriptsList-scriptCategoryFilter"),
    scriptLanguageFilter: createNamedWrapperReducer(value, "configuration-scripting-scriptsList-scriptLanguageFilter"),
    scriptOperatingSystemFilter: createNamedWrapperReducer(
      value,
      "configuration-scripting-scriptsList-scriptOperatingSystemFilter",
    ),
    sort: combineReducers({
      sortBy: createNamedWrapperReducer(sortBy, "configuration-scripting-scriptsList-sort", "name"),
      sortDirection: createNamedWrapperReducer(sortDirection, "configuration-scripting-scriptsList-sort", "ASC"),
    }),
  }),
  scriptCategoriesList: combineReducers({
    scriptCategoryNameFilter: createNamedWrapperReducer(
      value,
      "configuration-scripting-scriptCategoriesList-scriptCategoryNameFilter",
    ),
    sort: combineReducers({
      sortBy: createNamedWrapperReducer(sortBy, "configuration-scripting-scriptCategoriesList-sort", "name"),
      sortDirection: createNamedWrapperReducer(
        sortDirection,
        "configuration-scripting-scriptCategoriesList-sort",
        "ASC",
      ),
    }),
  }),
  templateLibrary: combineReducers({
    scripts: templateLibraryScripts,
    sourceFilter: createNamedWrapperReducer(value, "configuration-scripting-templateScripts-sourceFilter"),
    osFilter: createNamedWrapperReducer(value, "configuration-scripting-templateScripts-osFilter"),
    scriptFilter: createNamedWrapperReducer(value, "configuration-scripting-templateScripts-scriptFilter"),
    sort: combineReducers({
      sortBy: createNamedWrapperReducer(sortBy, "configuration-scripting-templateScripts-sort", "name"),
      sortDirection: createNamedWrapperReducer(sortDirection, "configuration-scripting-templateScripts-sort", "ASC"),
    }),
  }),
})
