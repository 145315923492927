const marketingPromo = (state = [], action) => {
  switch (action.type) {
    case "SHOW_MARKETING_PROMO":
      return [...state, ...action.marketingPromoInfo]
    case "HIDE_MARKETING_PROMO":
      return action?.promoId ? state.filter(({ promoId }) => promoId !== action.promoId) : []
    default:
      return state
  }
}

export default marketingPromo
