import { DecoratorNode, $applyNodeReplacement } from "lexical"

import { MentionComponent } from "./Component"

export class MentionNode extends DecoratorNode {
  __id
  __label
  __metadata

  static getType() {
    return "mention"
  }

  static clone(node) {
    return new MentionNode(node.__id, node.__label, node.__metadata, node.__key)
  }

  constructor(id, label, metadata, key) {
    super(key)
    this.__id = id
    this.__label = label
    this.__metadata = metadata
  }

  static importDOM() {
    return {
      span: node => {
        if (!node.hasAttribute("data-lexical-mention")) return null

        return {
          conversion: convertMentionElement,
          priority: 1,
        }
      },
    }
  }

  createDOM() {
    const element = document.createElement("span")
    element.setAttribute("data-id", this.getId())
    return element
  }

  updateDOM() {
    return false
  }

  exportDOM() {
    const id = this.getId()

    const element = document.createElement("span")
    element.setAttribute("data-id", id)
    element.setAttribute("data-lexical-mention", "true")
    element.textContent = "@user:" + id
    return { element }
  }

  static importJSON(serializedNode) {
    return $createMentionNode(serializedNode.id, serializedNode.label, serializedNode.metadata)
  }

  setId(id) {
    const self = this.getWritable()
    self.__id = id
  }

  getId() {
    const self = this.getLatest()
    return self.__id
  }

  setLabel(label) {
    const self = this.getWritable()
    self.__label = label
  }

  getLabel() {
    const self = this.getLatest()
    return self.__label
  }

  setMetadata(metadata) {
    const self = this.getWritable()
    self.__metadata = metadata
  }

  getMetadata() {
    const self = this.getLatest()
    return self.__metadata
  }

  exportJSON() {
    return {
      id: this.getId(),
      label: this.getLabel(),
      metadata: this.getMetadata(),
      type: "mention",
      version: 1,
    }
  }

  decorate() {
    return <MentionComponent label={this.getLabel()} />
  }
}

function convertMentionElement(node) {
  const textContent = node.textContent

  if (textContent !== null) {
    const node = $createMentionNode(null, textContent)
    return { node }
  }

  return null
}

export function $createMentionNode(id, label, metadata) {
  return $applyNodeReplacement(new MentionNode(id, label, metadata))
}

export function $isMentionNode(node) {
  return node instanceof MentionNode
}
