export default function groupsFilter(state = { name: "" }, action) {
  switch (action.type) {
    case "SET_GROUPS_FILTER_NAME":
      return { ...state, name: action.filter }
    case "SET_GROUPS_FILTER_TYPE":
      return { ...state, type: action.filter }
    default:
      return state
  }
}
