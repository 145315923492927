const initialState = {
  cacheKey: "",
  selectedNodeIds: undefined,
  unselectedNodeIds: undefined,
  fromDeviceSearch: false,
  selectedCount: 0,
}

function actionRunnerParams(state = initialState, action) {
  switch (action.type) {
    case "SET_DEVICES_CACHE_KEY":
      return { ...state, cacheKey: action.cacheKey }
    case "SET_FROM_DEVICE_SEARCH":
      return { ...state, fromDeviceSearch: action.fromDeviceSearch }
    case "SET_ACTION_RUNNER_PARAMS":
      return action.actionRunnerParams
    case "CLEAR_ACTION_RUNNER_PARAMS":
      return initialState
    default:
      return state
  }
}

export default actionRunnerParams
