export default function devicesCount(state = 0, action) {
  switch (action.type) {
    case "SET_DEVICES_COUNT":
      return action.count
    case "CLEAR_ACTIVE_SEARCH":
      return 0
    default:
      return state
  }
}
