import { useCallback, useEffect } from "react"

import { useMountedState } from "js/includes/common/hooks"
import { arrayToMapWithKey, fullNameOrEmail, localized } from "js/includes/common/utils"
import {
  AppUsersAndContactsDropdown,
  getCurrentUserOption,
  searchableKey,
  valueSelectorKey as usersDropdownSelectorKey,
} from "js/includes/ticketing/shared/components/AppUsersAndContactsDropdown"

const currentUserValue = "current_user"

const buildUserFromMetadata = ({ metadata, valueSelectorKey }) => ({
  id: metadata?.id,
  [valueSelectorKey]: metadata?.[valueSelectorKey],
  [usersDropdownSelectorKey]: metadata?.[usersDropdownSelectorKey],
  [searchableKey]: metadata?.deleted
    ? localized("{{fullName}} (Deleted)", { fullName: fullNameOrEmail(metadata) })
    : fullNameOrEmail(metadata),
  email: metadata?.email,
  userType: metadata?.entityType || metadata?.userType,
})

const getEmptyValue = ({ isMulti }) => (isMulti ? [] : null)

const buildInitialUser = ({ value, isMulti, metadata, valueSelectorKey }) => {
  if (!value) {
    return getEmptyValue({ isMulti })
  }

  if (!isMulti) {
    return value === currentUserValue
      ? getCurrentUserOption()
      : buildUserFromMetadata({ metadata, usersDropdownSelectorKey, valueSelectorKey })
  }

  const metadataMap = arrayToMapWithKey(valueSelectorKey, metadata)

  return value.split(",").map(id => {
    if (id === currentUserValue) {
      return getCurrentUserOption()
    }
    return buildUserFromMetadata({ metadata: metadataMap[id], usersDropdownSelectorKey, valueSelectorKey })
  })
}

export const UserEditor = ({
  value,
  handleOnChange,
  valueSelectorKey = usersDropdownSelectorKey,
  metaData,
  isMulti,
  ...rest
}) => {
  const [selectedValue, setSelectedValue] = useMountedState(() =>
    buildInitialUser({ value, isMulti, metadata: metaData, valueSelectorKey }),
  )
  const [selectionData, setSelectionData] = useMountedState({
    value: selectedValue,
    isMulti,
  })

  /*
   * This effect is in charge of setting isMulti and value that are props of AppUsersAndContactsDropdown.
   * It's important because isMulti and value should change at the same time. Passing, for example,
   * isMulti: true and value: {} breaks that component.
   */
  useEffect(() => {
    let newUser = selectedValue
    if (!value || (isMulti && !Array.isArray(newUser)) || (!isMulti && Array.isArray(newUser))) {
      newUser = getEmptyValue({ isMulti })
    }

    setSelectionData({
      isMulti,
      value: newUser,
    })
  }, [value, isMulti, selectedValue, setSelectionData])

  const handleOnSelect = useCallback(
    selected => {
      setSelectedValue(selected)
      if (Array.isArray(selected)) {
        handleOnChange(
          !selected.length
            ? null
            : selected.map(user => (user.isCurrentUser ? currentUserValue : user[valueSelectorKey])).join(","),
        )
      } else {
        handleOnChange(!selected ? null : selected.isCurrentUser ? currentUserValue : selected[valueSelectorKey])
      }
    },
    [setSelectedValue, valueSelectorKey, handleOnChange],
  )

  return (
    <AppUsersAndContactsDropdown
      // TODO remove key when dropdown does not crash when going from {isMulti: false, value: {}} to {isMulti: true, value: []}
      key={`${selectionData.isMulti}`}
      value={selectionData.value}
      onSelect={handleOnSelect}
      isMulti={selectionData.isMulti}
      {...rest}
    />
  )
}
