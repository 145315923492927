import {
  ImageIconLight,
  AudioFileIconLight,
  VideoFileIconLight,
  ZipFileIconLight,
  PdfFileIconLight,
  CsvFileIconLight,
  PowerPointFileIconLight,
  WordFileIconLight,
} from "@ninjaone/icons"

export const msWordExtendedExtension = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
export const msPowerpointExtendedExtension = "vnd.openxmlformats-officedocument.presentationml.presentation"

export const iconCommonProps = { size: "lg", color: "#337AB7" } /* new primary 100 */

export const iconMappings = {
  image: { component: ImageIconLight, label: "Image" }, // localizationKey("Image")
  audio: { component: AudioFileIconLight, label: "Audio" }, // localizationKey("Audio")
  video: { component: VideoFileIconLight, label: "Video" }, // localizationKey("Video")
  zip: { component: ZipFileIconLight, label: "ZIP" },
  pdf: { component: PdfFileIconLight, label: "PDF" },
  csv: { component: CsvFileIconLight, label: "CSV" },
  powerpoint: { component: PowerPointFileIconLight, label: "PowerPoint" },
  msword: { component: WordFileIconLight, label: "Word" },
  [msPowerpointExtendedExtension]: {
    component: PowerPointFileIconLight,
    label: "PowerPoint",
  },
  [msWordExtendedExtension]: {
    component: WordFileIconLight,
    label: "Word",
  },
}
