import { combineReducers } from "redux"
import brandingNode from "./brandingNode"
import hostname from "./hostname"
import initialEditorState from "./initialEditorState"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import loading from "js/state/reducers/common/loading"
import visibility from "js/state/reducers/common/visibility"

const brandingReducer = combineReducers({
  brandingNode,
  hostname,
  editorLoading: createNamedWrapperReducer(loading, "WBEditor", false),
  addTopicModalVisibility: createNamedWrapperReducer(visibility, "WBAddHelpTopicModal", false),
  initialEditorState,
})

export default function rootReducer(state, action) {
  return brandingReducer(state, action)
}
