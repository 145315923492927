import { localized } from "js/includes/common/utils"

export const requiredSyncFields = () => [
  {
    name: "deviceIdentifier",
    required: true,
    titleText: localized("Device Identifier"),
  },
  {
    name: "ipAddress",
    required: true,
    titleText: localized("IP Address"),
  },
  {
    name: "managementLink",
    required: true,
    titleText: localized("Management Link"),
  },
  {
    name: "name",
    required: true,
    titleText: localized("Name"),
  },
  {
    name: "osType",
    required: true,
    titleText: localized("OS Type"),
  },
  {
    name: "cpuSPeed",
    titleText: localized("CPU Speed"),
  },
  {
    name: "defaultGateway",
    titleText: localized("Default Gateway"),
  },
  {
    name: "lastLoginName",
    titleText: localized("Last Login Name"),
  },
  {
    name: "localHardDrives",
    titleText: localized("Local Hard Drives"),
  },
  {
    name: "macAddress",
    titleText: localized("MAC Address"),
  },
  {
    name: "model",
    titleText: localized("Model"),
  },
  {
    name: "osInfo",
    titleText: localized("OS Info"),
  },
  {
    name: "ram",
    titleText: localized("RAM"),
  },
  {
    name: "serialNumber",
    titleText: localized("Serial Number"),
  },
]

export const configurationQuestions = () => [
  {
    name: "nodeUrl",
    titleText: localized("NinjaOne Device URL"),
  },
  {
    name: "privateIpAddress",
    titleText: localized("Private IP Address"),
  },
  {
    name: "teamViewerUrl",
    titleText: localized("TeamViewer URL"),
  },
  {
    name: "productKey",
    titleText: localized("Windows Product Key"),
  },
]
