import { memo, useEffect, useState } from "react"
import { useSelected, useSlate, useSlateStatic } from "slate-react"
import { faBorderBottom, faBorderRight, faTable, faTrash } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Editor, Transforms, Range, Point, Element as SlateElement } from "slate"
import OutsideClickAlerter from "js/includes/components/OutsideClickAlerter"
import { StyledTableOptions, StyledTableUnit, StyledTable } from "../styled"
import { Box, StyledSpan } from "js/includes/components/Styled"
import { Button, Menu } from "../components"
import { isElementSelected } from "../utils"

export const withTables = editor => {
  const { deleteBackward, deleteForward, insertBreak } = editor

  editor.deleteBackward = unit => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "table-cell",
      })

      if (cell) {
        const [, cellPath] = cell
        const start = Editor.start(editor, cellPath)

        if (Point.equals(selection.anchor, start)) {
          return
        }
      }
    }

    deleteBackward(unit)
  }

  editor.deleteForward = unit => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "table-cell",
      })

      if (cell) {
        const [, cellPath] = cell
        const end = Editor.end(editor, cellPath)

        if (Point.equals(selection.anchor, end)) {
          return
        }
      }
    }

    deleteForward(unit)
  }

  editor.insertBreak = () => {
    const { selection } = editor

    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "table",
      })

      if (table) {
        return
      }
    }

    insertBreak()
  }

  return editor
}

const createTableCell = (text = "") => {
  return {
    type: "table-cell",
    children: [{ text }],
  }
}

const createRow = columns => {
  const newRow = Array.from(columns, cell => {
    return typeof cell === "string" ? createTableCell(cell) : cell
  })

  return {
    type: "table-row",
    children: newRow,
  }
}

const createTableNode = rows => {
  const tableChildren = Array.from(rows, columns => createRow(columns))
  let tableNode = {
    type: "table",
    children: [
      {
        type: "table-body",
        children: tableChildren,
      },
    ],
  }
  return tableNode
}

const insertCells = (editor, tableNode, path, action) => {
  let currentTable = Array.from(tableNode.children[0].children, rows => Array.from(rows.children))

  const columns = currentTable[0].length

  if (action === "row") {
    currentTable.push(Array(columns).fill(""))
  } else {
    currentTable = Array.from(currentTable, row => {
      row.push("")
      return row
    })
  }

  const newTable = createTableNode(currentTable)

  Transforms.insertNodes(editor, newTable, { at: path })
}

const removeTable = editor => {
  Transforms.removeNodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "table",
    mode: "highest",
  })
}

const insertRow = editor => {
  const { selection } = editor
  if (!!selection && Range.isCollapsed(selection)) {
    const [tableNode] = Editor.nodes(editor, {
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "table",
      mode: "highest",
    })

    if (tableNode) {
      const [oldTable, path] = tableNode
      removeTable(editor)
      insertCells(editor, oldTable, path, "row")
    }
  }
}

const insertColumn = editor => {
  const { selection } = editor
  if (!!selection && Range.isCollapsed(selection)) {
    const [tableNode] = Editor.nodes(editor, {
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "table",
      mode: "highest",
    })

    if (tableNode) {
      const [oldTable, path] = tableNode
      removeTable(editor)
      insertCells(editor, oldTable, path, "columns")
    }
  }
}

const insertTable = (editor, { rows, columns }) => {
  const [tableNode] = Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "table",
    mode: "highest",
  })

  if (tableNode || !rows || !columns) {
    return
  }
  const cellText = Array.from({ length: rows }, () => Array.from({ length: columns }, () => ""))

  const newTable = createTableNode(cellText)

  Transforms.insertNodes(editor, newTable)
}

export const InsertTableButton = memo(({ disabled }) => {
  const editor = useSlateStatic()
  const selection = useSelected()
  const [showOptions, setShowOptions] = useState(false)
  const [tableData, setTableData] = useState({
    rows: 0,
    columns: 0,
  })

  const [tableInput, setTableInput] = useState(
    Array.from({ length: 6 }, () =>
      Array.from({ length: 6 }, (v, i) => ({
        isSelected: false,
        column: i,
      })),
    ),
  )

  const handleInsert = () => {
    selection && Transforms.select(editor, selection)
    insertTable(editor, tableData)
    setTableData({ rows: 0, columns: 0 })
    setShowOptions(false)
  }

  useEffect(() => {
    const newTable = Array.from({ length: 6 }, (obj, row) =>
      Array.from({ length: 6 }, (v, column) => ({
        isSelected: row + 1 <= tableData.rows && column + 1 <= tableData.columns,
        column,
      })),
    )
    setTableInput(newTable)
  }, [tableData])

  return (
    <OutsideClickAlerter
      className="position-relative"
      handleClickOutside={() => {
        setShowOptions(false)
      }}
    >
      <>
        <Button
          height="100%"
          disabled={disabled}
          onClick={() => {
            setShowOptions(true)
          }}
        >
          <FontAwesomeIcon icon={faTable} />
        </Button>

        {showOptions && (
          <StyledTableOptions>
            <div className="table-input">
              {tableInput.map((grp, row) =>
                grp.map(({ column, isSelected }) => (
                  <StyledTableUnit
                    key={column}
                    className="table-unit"
                    isSelected={isSelected}
                    onClick={() => handleInsert()}
                    onMouseOver={() =>
                      setTableData({
                        rows: row + 1,
                        columns: column + 1,
                      })
                    }
                  />
                )),
              )}
            </div>
          </StyledTableOptions>
        )}
      </>
    </OutsideClickAlerter>
  )
})

export const InTableOptions = () => {
  const editor = useSlateStatic()

  const handleButtonClick = action => {
    switch (action) {
      case "row":
        insertRow(editor)
        break
      case "column":
        insertColumn(editor)
        break
      case "remove":
        removeTable(editor)
        break
      default:
        return
    }
  }
  return (
    <>
      <Button format="insert row" onClick={() => handleButtonClick("row")}>
        <FontAwesomeIcon icon={faBorderBottom} />
      </Button>

      <Button format="insert column" onClick={() => handleButtonClick("column")}>
        <FontAwesomeIcon icon={faBorderRight} />
      </Button>

      <Button format="remove table" onClick={() => handleButtonClick("remove")}>
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </>
  )
}

export const TableComponent = ({ attributes, children, element, readOnly }) => {
  const editor = useSlate()
  const isTableSelected = isElementSelected(editor, element)

  return (
    <Box display="flex" position="relative" justifyContent="center" {...attributes}>
      {!readOnly && isTableSelected && (
        <StyledSpan position="absolute" top="-40px">
          <Menu>
            <InTableOptions />
          </Menu>
        </StyledSpan>
      )}
      <StyledTable>{children}</StyledTable>
    </Box>
  )
}
