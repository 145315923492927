import { memo } from "react"
import { Col, ControlLabel, FormGroup, Modal } from "react-bootstrap"
import qs from "qs"

import { getPathSeparator, getPrefixedUrl, localized } from "js/includes/common/utils"
import { replaceSeparatorAndRemoveTrailingSlash } from "js/includes/components/Browsers"

// This produces the same as unescape(encodeURIComponent(str)), but unescape is being deprecated
// first we use encodeURIComponent to get percent-encoded Unicode,
// then we convert the percent encodings into raw bytes which can be fed into btoa.
const b64EncodeUnicode = str =>
  btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode("0x" + p1)))

export default memo(function DownloadMountedContentModal({
  unmount: close,
  nodes: [
    {
      planName,
      name,
      path,
      downloadPath,
      revisionId,
      imagePlanId,
      node: { id: nodeId },
      folder,
    },
  ],
  sourceNode,
}) {
  const downloadHref = getPrefixedUrl(
    `/backup/lockhart/image/nodes/${nodeId}/plans/${imagePlanId}/revisions/${revisionId}/download?${qs.stringify({
      fileBase64: b64EncodeUnicode(path),
      type: folder ? "FOLDER" : "FILE",
    })}`,
  )

  return (
    <Modal.Dialog bsClass="inmodal modal" dialogClassName={"restore-manager-download-modal file"}>
      <Modal.Header>
        <Modal.Title>{localized("Download File")}</Modal.Title>
      </Modal.Header>
      <Modal.Body bsClass={"modal-body overflow-visible-important display-flex flex-direction-column"}>
        <FormGroup className="text-left" controlId="backup-restore-manager-download-info">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Device")}
          </Col>
          <Col xs={12}>
            <div>{sourceNode.displayName}</div>
          </Col>
        </FormGroup>
        <FormGroup className="text-left" controlId="backup-restore-manager-download-info">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Plan Name")}
          </Col>
          <Col xs={12}>
            <div>{planName}</div>
          </Col>
        </FormGroup>
        <FormGroup className="text-left" controlId="backup-restore-manager-download-info">
          <Col componentClass={ControlLabel} xs={12}>
            {localized("Path")}
          </Col>
          <Col xs={12}>
            <div className="break-all">
              {replaceSeparatorAndRemoveTrailingSlash(downloadPath, getPathSeparator(sourceNode))}
            </div>
          </Col>
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <div className="display-flex justify-content-between">
          <div className="display-flex flex-full justify-content-end">
            <a className="btn btn-success" onClick={close} href={downloadHref} download={name}>
              {localized("Download")}
            </a>
            <button type="button" className="btn btn-white" onClick={close}>
              {localized("Close")}
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal.Dialog>
  )
})
