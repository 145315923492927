import { combineReducers } from "redux"
import locations from "./locations"
import modalEditorTitle from "./modalEditorTitle"
import modalEditorMode from "./modalEditorMode"
import selectedNodes from "./selectedNodes"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import visibility from "js/state/reducers/common/visibility"
import subTab from "js/state/reducers/common/subTab"

const locationsReducer = combineReducers({
  locations,
  modalEditorTitle,
  modalEditorMode,
  changeOrganizationLocationModalVisibility: createNamedWrapperReducer(
    visibility,
    "ChangeOrganizationLocationModal",
    false,
  ),
  customeDashboardLocationSubTab: createNamedWrapperReducer(subTab, "locationTab", "all"),
  selectedNodes,
})

export default function rootReducer(state, action) {
  return locationsReducer(state, action)
}
