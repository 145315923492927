const createListWithNameReducer = (listName = "") => {
  return (state = [], action) => {
    switch (action.type) {
      case `SET_${listName}_LIST`:
        return action.list || []
      case `ADD_${listName}_ITEM`:
        return [...state, action.payload]
      case `REMOVE_${listName}_ITEM`:
        return state.filter(item => item.id !== action.payload)
      case `UPDATE_${listName}_ITEM`:
        const { payload } = action
        return state.map(item => (item.id === payload.id ? payload : item))
      default:
        return state
    }
  }
}

export default createListWithNameReducer
