const initialState = {
  additionsData: [],
  nodesData: [],
  agreementSyncStarted: false,
}

export default function agreementSync(state = initialState, action) {
  switch (action.type) {
    case "SET_CW_AGREEMENT_SYNC_PREVIEW":
      const { additionsData, nodesData } = action
      return { ...state, additionsData, nodesData }
    case "SET_CW_AGREEMENT_RUNNING_SYNC":
      const { agreementSyncStarted } = action
      return { ...state, agreementSyncStarted }
    case "RESET_CW_AGREEMENT_SYNC":
      return initialState
    default:
      return state
  }
}
