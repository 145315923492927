const rowExpansion = (state = "collapsed", action) => {
  switch (action.type) {
    case "EXPAND":
      return "expanded"
    case "COLLAPSE":
      return "collapsed"
    default:
      return state
  }
}

export default rowExpansion
