import { nanoid } from "nanoid"

export default function selectedPolicyScripts(state = [], action) {
  switch (action.type) {
    case "ADD_CONDITION_SCRIPT":
      return [...state, action.script]
    case "REMOVE_CONDITION_SCRIPT":
      return state.filter(cs => cs.id !== action.script.id)
    case "EDIT_CONDITION_SCRIPT":
      const { script } = action
      return state.map(cs => (cs.id === script.id ? script : cs))
    case "SET_CONDITION_SCRIPTS":
      return action.scripts.map(s => ({ ...s, id: nanoid(10) }))
    case "REORDER_CONDITION_SCRIPTS":
      return [...action.scripts]
    default:
      return state
  }
}
