import { initialSeverityFilters } from "js/includes/systemDashboard/vulnerabilities/constants"

const getInitialState = () => ({
  filters: initialSeverityFilters,
})

const setVulnerabilityFilters = (state, { filters }) => ({
  filters,
})

export default function vulnerabilityFilters(state = getInitialState(), action) {
  switch (action.type) {
    case "SET_VULNERABILITY_FILTERS":
      return setVulnerabilityFilters(state, action)
    default:
      return state
  }
}
