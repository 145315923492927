import styled from "@emotion/styled"
import { ListboxInput, ListboxButton, ListboxPopover, ListboxList, ListboxOption } from "@reach/listbox"
import "@reach/listbox/styles.css"
import { positionDefault, positionRight } from "@reach/popover"
import { CaretDownIcon, CaretUpIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import { Text } from "@ninjaone/components"
import { localized } from "js/includes/common/utils"

const StyledListboxPopover = styled(ListboxPopover)`
  z-index: 999;
  &[data-reach-listbox-popover] {
    margin-top: ${sizer(1)};
    padding: 0;
    background-color: ${({ theme }) => theme.color.background};
    border: 1px solid ${({ theme }) => theme.color.border};
    border-radius: ${sizer(1)};
    box-shadow: none !important;
    outline: none !important;
    color: ${({ theme }) => theme.color.text};
    min-width: ${sizer(50)};
  }
`

export const optionPadding = sizer(2, 2)

const StyledListboxList = styled(ListboxList)`
  &[data-reach-listbox-list] {
    padding: ${sizer(1)};
    max-height: ${sizer(50)};
    overflow-y: auto;

    > [data-reach-listbox-option] {
      cursor: pointer;
      text-align: left;
      color: inherit;
      padding: ${optionPadding};
      border-radius: ${sizer(1)};

      &[aria-selected="true"] {
        background: ${({ theme }) => theme.color.listItemActiveBackground};
        color: inherit;
      }
    }
  }
`

export const StyledListboxButton = styled(ListboxButton)`
  &[data-reach-listbox-button] {
    max-height: 38px;
    outline: none;
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.background};
    border-radius: ${sizer(1)};
    padding: ${sizer(2, 3, 2, 3)};
  }
  &[data-reach-listbox-button][aria-disabled="true"] {
    background-color: ${({ theme }) => theme.colorBackgroundInputDisabled};
  }
`

/**
 * @deprecated Please use @ninjaone/Select component instead.
 */
export const ButtonSelect = ({
  labelId,
  labelToken,
  value,
  onChange,
  options,
  disabled,
  alignRight,
  optionRenderer,
  valueRenderer,
  listboxButtonRenderer,
  listboxHeaderRenderer,
  listboxFooterRenderer,
  fullWidth = false,
  optionWrapperClassName = "",
}) => {
  const fullWidthClassName = fullWidth ? "full-width" : ""

  return (
    <ListboxInput
      aria-labelledby={labelId ?? "select"}
      {...{ value, onChange, disabled, className: fullWidthClassName }}
    >
      {({ value, valueLabel, isExpanded }) => (
        <>
          {listboxButtonRenderer ? (
            listboxButtonRenderer({ value, valueLabel, isExpanded })
          ) : (
            <StyledListboxButton
              className={fullWidthClassName}
              arrow={
                <Text size="sm" color="darkGray">
                  {isExpanded ? <CaretUpIcon /> : <CaretDownIcon />}
                </Text>
              }
              onClick={e => e.stopPropagation()}
            >
              {valueRenderer ? (
                valueRenderer({ value, valueLabel })
              ) : (
                <span data-value={value}>
                  <Text size="sm">{valueLabel}</Text>
                </span>
              )}
            </StyledListboxButton>
          )}
          <StyledListboxPopover
            {...{
              position: alignRight ? positionRight : positionDefault,
              portal: !fullWidth,
              className: fullWidthClassName,
            }}
          >
            {listboxHeaderRenderer}
            <StyledListboxList>
              {options.map(({ value, labelToken, labelText, disabled }) => (
                <ListboxOption
                  {...{ value, key: value, disabled, className: optionWrapperClassName }}
                  onClick={e => e.stopPropagation()}
                >
                  {optionRenderer ? (
                    optionRenderer({ value, valueLabel: labelText || localized(labelToken) })
                  ) : (
                    <Text token={labelToken} children={labelText} size="sm" />
                  )}
                </ListboxOption>
              ))}
            </StyledListboxList>
            {listboxFooterRenderer}
          </StyledListboxPopover>
        </>
      )}
    </ListboxInput>
  )
}
