import { propEq } from "ramda"
import { useMemo } from "react"
import { connect } from "react-redux"

import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { statusRowRenderer } from "js/includes/ticketing/editor/tabHeaderActions/general"
import { getStatusOptions } from "js/includes/ticketing/shared/utils/options"
import { localized } from "js/includes/common/utils"

const _StatusEditor = ({ value, handleOnChange, statusList, parentOnly, hideClose }) => {
  const statusOptions = useMemo(() => getStatusOptions(statusList, { parentOnly, hideClose }), [
    statusList,
    parentOnly,
    hideClose,
  ])

  return (
    <SearchableDropDown
      ariaAttributes={{ "aria-label": localized("Status value") }}
      keepDropdownInView
      width="100%"
      options={statusOptions}
      value={statusOptions.find(propEq("value", parseInt(value, 10)))}
      onSelect={({ value }) => handleOnChange(value)}
      searchableKey="labelText"
      rowRenderer={statusRowRenderer}
    />
  )
}

export const StatusEditor = connect(({ ticketing }) => ({ statusList: ticketing.status.list }))(_StatusEditor)
