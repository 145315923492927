import styled from "@emotion/styled"

import { sizer } from "@ninjaone/utils"

export const StyledButton = styled.button`
  user-select: none;
  outline: none;
  height: ${sizer(8)};
  border-radius: ${sizer(1)};
  border: none;
  background-color: transparent;

  &:hover {
    background-color: ${({ theme }) => theme.color.buttonLightGrayBackground};
  }
`

export const StyledButtonDiv = StyledButton.withComponent("div")
