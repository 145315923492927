import { memo } from "react"
import { useTheme } from "@emotion/react"

import { DataTable } from "@ninjaone/components"
import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"

import { ConfirmationModal } from "js/includes/components/ConfirmationModal"

export default memo(function BackupConfirmationModal({
  type = "danger",
  titleToken,
  actionToken,
  descriptionToken,
  descriptionText,
  onConfirm,
  onCancel,
  unmount,
  disabled,
  size = "md",
  columns,
  data,
  height = 500,
}) {
  const theme = useTheme()
  return (
    <ConfirmationModal
      {...{
        type,
        titleToken,
        actionToken,
        descriptionToken,
        descriptionText,
        onConfirm,
        onCancel,
        unmount,
        disabled,
        size,
      }}
    >
      <Box color={theme.color.black["100"]} height={height}>
        <DataTable
          {...{ tableId: "trim-table", columns: columns, rows: data, showSearchBar: false, hideSettingsButton: true }}
        />
      </Box>
    </ConfirmationModal>
  )
})
