import tokens from "@ninjaone/tokens"
import styled from "@emotion/styled"
import { ContentEditable, StyledEditorShell } from "@ninjaone/components/src/WYSIWYG"

const shouldForwardProp = prop =>
  !["noBorder", "noPadding", "errorMessage", "fullWidth", "isPublicResponse"].includes(prop)

export const StyledEditorReadOnly = styled.div`
  position: relative;
  overflow-x: auto;

  code {
    white-space: unset;
  }
  .editor-table {
    min-width: 100%;
  }
`

export const StyledTicketingEditorShell = styled(StyledEditorShell, { shouldForwardProp })`
  border: ${({ theme, noBorder, errorMessage }) =>
    noBorder ? 0 : `1px solid ${errorMessage ? theme.colorBorderError : theme.colorBorderWeakest}`};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};

  #editor-input {
    max-height: 450px;
  }
  .editor-table {
    min-width: 100%;
  }
`

export const StyledTicketingContentEditable = styled(ContentEditable, { shouldForwardProp })`
  padding: ${({ noPadding }) => (noPadding ? 0 : `${tokens.spacing[2]} ${tokens.spacing[4]} ${tokens.spacing[9]}`)};
  background-color: ${({ isPublicResponse, theme }) =>
    isPublicResponse === false ? theme.colorAlertWarningWeakest : theme.colorBackground};
`
