/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import Tooltip from "react-bootstrap/lib/Tooltip"
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"

const tooltipProps = ["id", "placement", "positionTop", "positionLeft", "arrowOffsetTop", "arrowOffsetLeft", "bsClass"]

const StyledTooltip = styled(Tooltip, { shouldForwardProp: prop => isPropValid(prop) || tooltipProps.includes(prop) })`
  .tooltip-inner {
    max-width: ${({ tooltipMaxWidth }) => tooltipMaxWidth ?? "200px"};
    text-align: ${({ tooltipTextAlign }) => tooltipTextAlign ?? "center"};
  }

  &&& {
    ${({ hideTooltip }) => hideTooltip && "visibility: hidden;"}
  }
`

export default function _Tooltip({
  id,
  placement,
  children,
  token,
  className,
  iconClassName,
  output,
  transform,
  textCss,
  tooltipMaxWidth,
  tooltipTextAlign,
  hideTooltip,
  delay,
}) {
  return (
    <OverlayTrigger
      delay={delay}
      placement={placement || "top"}
      overlay={
        <StyledTooltip
          id={id || "tooltip-top"}
          tooltipMaxWidth={tooltipMaxWidth}
          tooltipTextAlign={tooltipTextAlign}
          hideTooltip={hideTooltip}
        >
          {token ? (
            <span css={css(textCss)} className={className}>
              {localized(token)}
            </span>
          ) : (
            <span css={css(textCss)} className={`break-word ${className}`}>
              {output}
            </span>
          )}
        </StyledTooltip>
      }
      {...(hideTooltip && { animation: false })}
    >
      {children || <FontAwesomeIcon icon={faInfoCircle} className={`m-l-sm ${iconClassName}`} transform={transform} />}
    </OverlayTrigger>
  )
}
