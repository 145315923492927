import { combineReducers } from "redux"
import { SortDirection } from "react-virtualized"
import companyFilterMap from "./companyFilterMap"
import accountOrganizationModal from "./accountOrganizationModal"
import accountAutoMapPreview from "./accountAutoMapPreview"
import companyCriteria from "./companyCriteria"
import companies from "./companies"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import loading from "js/state/reducers/common/loading"
import visibility from "js/state/reducers/common/visibility"
import sortBy from "js/state/reducers/common/tableSorting/sortBy"
import sortDirection from "js/state/reducers/common/tableSorting/sortDirection"
import previouslyUsedFilterMap from "./previouslyUsedFilterMap"
import accountOrganizationMappings from "./accountOrganizationMappings"
import selectedAccountMappings from "./selectedAccountMappings"

export default combineReducers({
  companyFilterMap,
  accountOrganizationMappingModalVisibility: createNamedWrapperReducer(
    visibility,
    "CWAccountOrganizationMappingModal",
    false,
  ),
  accountOrganizationMappingModalLoading: createNamedWrapperReducer(
    loading,
    "CWAccountOrganizationMappingModal",
    false,
  ),
  possibleLocationMappingsModalLoading: createNamedWrapperReducer(loading, "CWPossibleLocationMappingsModal", false),
  accountOrganizationMappingSort: combineReducers({
    sortBy: createNamedWrapperReducer(sortBy, "CWAccountOrganizationMappingSort", "clientName"),
    sortDirection: createNamedWrapperReducer(sortDirection, "CWAccountOrganizationMappingSort", SortDirection.ASC),
  }),
  accountOrganizationModal,
  locationSiteMappingModalVisibility: createNamedWrapperReducer(visibility, "CWLocationSiteMappingModal", false),
  locationSiteMappingSort: combineReducers({
    sortBy: createNamedWrapperReducer(sortBy, "CWLocationSiteMappingSort", "locationName"),
    sortDirection: createNamedWrapperReducer(sortDirection, "CWLocationSiteMappingSort"),
  }),
  locationSiteLoading: createNamedWrapperReducer(loading, "CWLocationSiteLoading", false),
  accountAutoMapModalVisibility: createNamedWrapperReducer(visibility, "CWAccountAutoMapModal", false),
  accountAutoMapPreview,
  companyCriteria,
  companies,
  accountMapModalLoading: createNamedWrapperReducer(loading, "CWAccountMapModal", false),
  previouslyUsedFilterMap,
  accountOrganizationMappings,
  selectedAccountMappings,
})
