export const initialState = (list = []) => {
  return {
    list,
  }
}

export const initialDashboardState = (
  node = null,
  cpu = null,
  memory = null,
  hostSystem = {},
  datastore = [],
  healthSensors = [],
  network = [],
  licenses = [],
  healhSensorTypeFilter = [],
  healhSensorStatusFilter = [],
  hostPolicyList: [],
  organization = null,
  location = null,
  triggeredConditions = [],
  activeJobs = [],
  maintenanceMode = null,
) => {
  return {
    node,
    cpu,
    memory,
    datastore,
    hostSystem,
    healthSensors,
    network,
    licenses,
    healhSensorTypeFilter,
    healhSensorStatusFilter,
    hostPolicyList,
    organization,
    location,
    triggeredConditions,
    activeJobs,
    maintenanceMode,
  }
}

export const initialGuestDashboardState = (
  node = null,
  cpu = null,
  memory = null,
  guestSystem = {},
  storage = [],
  network = [],
  organization = null,
  location = null,
  triggeredConditions = [],
  activeJobs = [],
  snapshot = [],
  maintenanceMode = null,
) => {
  return {
    node,
    cpu,
    memory,
    storage,
    guestSystem,
    network,
    triggeredConditions,
    activeJobs,
    snapshot,
    maintenanceMode,
    organization,
    location,
  }
}
