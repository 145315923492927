export default function selectedScriptsResultConditionScript(state = {}, action) {
  switch (action.type) {
    case "SET_SCRIPTS_RESULT_CONDITION_SCRIPT":
      return { ...action.script }
    case "REMOVE_SCRIPTS_RESULT_CONDITION_SCRIPT":
      return {}
    default:
      return state
  }
}
