import styled from "@emotion/styled"

import tokens from "@ninjaone/tokens"

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${tokens.spacing[3]};
`

const StyledLabel = styled.label`
  /* Reset Bootstrap */
  margin-bottom: 0;

  flex: 1;
  display: flex;
  color: ${({ theme }) => theme.colorTextStrong};
  font-size: ${tokens.typography.fontSize.body};
`

const StyledInput = styled.input`
  border: 1px solid ${({ theme }) => theme.colorBorderWeak};
  padding: ${tokens.spacing[2]} ${tokens.spacing[3]};
  font-size: ${tokens.typography.fontSize.headingS};
  border-radius: ${tokens.borderRadius[1]};
  display: flex;
  flex: 2;
  min-width: 0;
  color: ${({ theme }) => theme.colorTextStrong};
  background-color: ${({ theme }) => theme.colorBackground};
`

export const TextInput = ({ label, value, onChange, placeholder, "data-test-id": dataTestId, type = "text" }) => {
  return (
    <StyledWrapper>
      <StyledLabel>{label}</StyledLabel>
      <StyledInput type={type} placeholder={placeholder} value={value} onChange={onChange} data-test-id={dataTestId} />
    </StyledWrapper>
  )
}
