const PRETTIER_PARSER_MODULES = {
  html: () => import("prettier/parser-html"),
  xml: () => import("@prettier/plugin-xml"),
}

const PRETTIER_OPTIONS = {
  html: {
    parser: "html",
    printWidth: 120,
  },
  xml: {
    parser: "xml",
    xmlWhitespaceSensitivity: "ignore",
    tabWidth: 2,
  },
}

export async function loadPrettierParser(lang) {
  const dynamicImport = PRETTIER_PARSER_MODULES[lang]
  return dynamicImport()
}

export async function loadPrettierFormat() {
  const { format } = await import("prettier/standalone")
  return format
}

export function getPrettierOptions(lang) {
  const options = PRETTIER_OPTIONS[lang]
  if (!options) throw new Error(`ToolbarPlugin: Language ${lang} is not supported.`)

  return options
}
