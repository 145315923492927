import { combineReducers } from "redux"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"
import value from "js/state/reducers/common/value"
import patchOverrides from "./patchOverrides"

export default combineReducers({
  textFilter: createNamedWrapperReducer(value, "patchOverridesTextFilter", ""),
  approvalFilter: createNamedWrapperReducer(value, "patchOverridesApprovalFilter", ""),
  typeFilter: createNamedWrapperReducer(value, "patchOverridesTypeFilter", ""),
  patchOverrides,
})
