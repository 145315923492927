const initialState = {
  data: {},
}

export default function connection(state = initialState, action) {
  switch (action.type) {
    case "SET_GW_CONNECTION":
      return { ...state, data: action.payload }
    default:
      return state
  }
}
