import { sizer } from "@ninjaone/utils"

import { Box } from "js/includes/components/Styled"
import { AttachmentPreview } from "js/includes/components/AttachmentPreview"

export const Attachments = ({ attachments, deleteAttachment, uploadProgress }) => {
  if (!attachments?.length) return null

  return (
    <Box display="grid" gridGap={sizer(1)} gridTemplateColumns="repeat(4, 1fr)" marginTop={sizer(7)}>
      {attachments?.map(attachment => {
        if (attachment.metadata.inline) return null
        return (
          <AttachmentPreview
            {...{
              key: attachment.resourceId || attachment.tempId,
              attachment: {
                ...attachment,
                progress: uploadProgress?.[attachment.tempId],
              },
              onDelete: deleteAttachment,
            }}
          />
        )
      })}
    </Box>
  )
}
