export const onChangeBackupMode = (state, { mode }) => {
  const agent = state.productCode.toLowerCase()
  return {
    ...state,
    [agent]: {
      ...state[agent],
      mode,
      nodeIds: mode === "MANUAL" ? state[agent].nodeIds : [],
    },
  }
}

export const onSaveBackupConsolePassword = (state, { consolePassword }) => {
  const agent = state.productCode.toLowerCase()
  return {
    ...state,
    [agent]: {
      ...state[agent],
      consolePassword,
    },
  }
}

export const onSelectBackupNode = (state, { nodeIds }) => {
  const agent = state.productCode.toLowerCase()
  return {
    ...state,
    [agent]: {
      ...state[agent],
      nodeIds,
    },
  }
}

export const onEnableBackup = (state, { nodeIds }) => {
  const agent = state.productCode.toLowerCase()
  return {
    ...state,
    [agent]: {
      ...state[agent],
      // Merge new nodeIds with existing ones, removing duplicates
      nodeIds: [...new Set([...state[agent].nodeIds, ...nodeIds])],
      // Remove the enabled nodes from excludedNodeIds
      excludedNodeIds: state[agent].excludedNodeIds.filter(id => !nodeIds.includes(id)),
    },
  }
}

export const onDisableBackup = (state, { excludedNodeIds }) => {
  const agent = state.productCode.toLowerCase()
  return {
    ...state,
    [agent]: {
      ...state[agent],
      // Merge new excludedNodeIds with existing ones, removing duplicates
      excludedNodeIds: [...new Set([...state[agent].excludedNodeIds, ...excludedNodeIds])],
      // Remove the disabed nodes from nodeIds
      nodeIds: state[agent].nodeIds.filter(id => !excludedNodeIds.includes(id)),
    },
  }
}

export const onSaveBackupBandwidthThrottleKb = (state, { bandwidthThrottleKb }) => {
  const agent = state.productCode.toLowerCase()
  return {
    ...state,
    [agent]: {
      ...state[agent],
      bandwidthThrottleKb,
    },
  }
}

export const onSaveBackupBandwidthThrottle = (state, { bandwidthThrottle }) => {
  const agent = state.productCode.toLowerCase()
  return {
    ...state,
    [agent]: {
      ...state[agent],
      bandwidthThrottle,
    },
  }
}

export const onSaveBackupNetworkLocation = (state, { networkLocation }) => {
  const agent = state.productCode.toLowerCase()
  return {
    ...state,
    [agent]: {
      ...state[agent],
      networkLocation,
    },
  }
}

export const onChangeCloudStorageLocation = (state, { cloudStorageLocation }) => {
  const agent = state.productCode.toLowerCase()
  return {
    ...state,
    [agent]: {
      ...state[agent],
      overrides: {
        ...state[agent].overrides,
        bucket: cloudStorageLocation,
      },
    },
  }
}

export const onChangeDeployTo = (state, { deployTo }) => {
  const agent = state.productCode.toLowerCase()
  return {
    ...state,
    [agent]: {
      ...state[agent],
      deployTo,
    },
  }
}
