const initialState = {
  pendingEmailsCount: 0,
}

export default function boards(state = initialState, action) {
  switch (action.type) {
    case "TICKETING_SET_PENDING_EMAIL_COUNT":
      return {
        ...state,
        pendingEmailsCount: action.ticketCount,
      }
    default:
      return state
  }
}
