import { always } from "ramda"
import { Col, ControlLabel, FormGroup } from "react-bootstrap"

import { createRevisionOption, getRestoreFileJob, submitJob } from "js/includes/common/backup"
import { useMountedState } from "js/includes/common/hooks"
import showModal from "js/includes/common/services/showModal"
import {
  getPathSeparator,
  isFeatureEnabled,
  localizationKey,
  localized,
  showSuccessMessage,
} from "js/includes/common/utils"
import { getRevisionsByFileEndpoint } from "js/includes/components/Backup/BackupManager/utils"
import { replaceSeparatorAndRemoveTrailingSlash } from "js/includes/components/Browsers"
import LockhartJobDetailsModal from "js/includes/components/LockhartJobDetailsModal"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import RestoreModal, { getOriginalLocationPath } from "./RestoreModal"

export const RestoreFileModal = ({ unmount: close, nodes, sourceNode }) => {
  const [firstNode] = nodes
  const { planId } = firstNode
  const sourceNodeName = sourceNode.friendlyName || sourceNode.name || sourceNode.systemName
  const [revision, setRevision] = useMountedState(null)

  const restore = async ({
    destinationNode,
    collisionHandling,
    restorePermissions,
    destinationPath,
    isOriginalLocation,
  }) => {
    const {
      nodes: [node],
    } = await window.deviceList.fetchById(isOriginalLocation ? sourceNode.id : destinationNode.id)
    const nodeId = node.id
    const nodeName = node.name || node.systemName
    const separator = getPathSeparator(sourceNode)
    const sourcePath = replaceSeparatorAndRemoveTrailingSlash(firstNode.path, separator)

    const restoreDestinationPath = isOriginalLocation ? getOriginalLocationPath(sourcePath, separator) : destinationPath

    const restoreJob = getRestoreFileJob({
      batch: nodes,
      nodeId,
      nodeName,
      sourcePath,
      sourceNodeName,
      destinationPath: restoreDestinationPath,
      revisionId: revision.value,
      sourceNodeId: sourceNode.id,
      sourcePlanId: firstNode.planId,
      sourcePlanName: firstNode.planName,
      collisionHandling: collisionHandling.value,
      originalLocation: isOriginalLocation,
      ...(isFeatureEnabled("backup_ntfs_permissions") && { restorePermissions }),
    })

    const response = await submitJob([restoreJob])

    if (response.resultCode !== "SUCCESS") throw new Error("Error creating restore job")

    showSuccessMessage(localized("Restore job created successfully"))
    close()
    showModal(<LockhartJobDetailsModal {...{ job: restoreJob.jobs[0], node }} />)
  }

  return (
    <RestoreModal
      {...{
        RevisionSelector: (
          <FormGroup className="text-left" controlId="backup-restore-manager-revision">
            <Col componentClass={ControlLabel} xs={12}>
              {localized("Revision")}
            </Col>
            <Col xs={12}>
              <SearchableDropDown
                {...{
                  openOnFocus: true,
                  pageSize: 100,
                  endpoint: getRevisionsByFileEndpoint(sourceNode, planId, firstNode),
                  width: "100%",
                  rowHeight: 40,
                  noRowsRendererToken: localizationKey("No revisions available"),
                  onSelect: setRevision,
                  dataFormatter: obj => ({ ...createRevisionOption(obj), id: obj.id }),
                  lastItemKey: "id",
                  isSearchable: false,
                  searchParams: always({ onCloud: false }),
                }}
              />
            </Col>
          </FormGroup>
        ),
        restoreJob: restore,
        modalTitle: nodes.length > 1 ? localized("Restore Batch") : localized("Restore File"),
        disableRestore: revision === null,
        sourceNode,
        nodes,
        restorePath: nodes.length > 1 ? firstNode.parentPath : firstNode.path,
        close,
      }}
    />
  )
}
