import { useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Root, Anchor, Portal, Content, Arrow, Close } from "@radix-ui/react-popover"
import tokens from "@ninjaone/tokens"
import * as ninjaIcons from "@ninjaone/icons"
import { isRequiredIf } from "@ninjaone/utils"
import { CloseIcon } from "@ninjaone/icons"
import { resetButtonStyle } from "./Styled"

const StyledArrow = styled(Arrow)`
  fill: ${({ theme }) => theme.colorBackgroundAccentCtaStrongest};
`

const StyledClose = styled(Close)`
  ${resetButtonStyle}
  position: absolute;
  top: ${tokens.spacing[4]};
  right: ${tokens.spacing[4]};
`

const StyledContent = styled(Content)`
  width: ${({ width }) => width}px;
  position: relative;
  background-color: ${({ theme }) => theme.colorBackgroundAccentCtaStrongest};
  color: ${({ theme }) => theme.colorTextHighContrast};
  border-radius: ${tokens.borderRadius[1]};
  padding: ${tokens.spacing[5]};
  z-index: 200;

  &&& svg {
    color: ${({ theme }) => theme.colorTextHighContrast};
  }

  h4 {
    display: flex;
    align-items: center;
    font-size: ${tokens.typography.fontSize.headingS};
    font-weight: ${tokens.typography.fontWeight.medium};
    margin-bottom: ${tokens.spacing[3]};
    margin-top: 0;
    gap: ${tokens.spacing[2]};
  }

  p {
    font-size: ${tokens.typography.fontSize.body};
    margin-top: 0;
    margin-bottom: 0;

    a {
      text-decoration: underline;
      color: inherit;
    }
  }
`

const GuideTooltip = ({
  position,
  align,
  width = 400,
  alignOffset,
  sideOffset = 20,
  open,
  anchorRenderer,
  anchorPortalElement,
  title,
  iconName,
  textRenderer,
  onClose,
  usesPortal = true,
}) => {
  const contentWrapperRef = useRef(null)
  const Icon = ninjaIcons[iconName] ?? null
  const content = (
    <StyledContent
      {...{
        side: position,
        align,
        width,
        hideWhenDetached: true,
        alignOffset,
        sideOffset,
        onOpenAutoFocus: event => {
          event.preventDefault()
        },
        onInteractOutside: event => {
          event.preventDefault()
        },
        updatePositionStrategy: "always",
      }}
    >
      <div ref={contentWrapperRef}>
        <StyledClose aria-label="Close">
          <CloseIcon />
        </StyledClose>
        <h4>
          {Icon && <Icon />}
          {title}
        </h4>
        {textRenderer && <div>{textRenderer()}</div>}
        <StyledArrow width={20} height={10} />
      </div>
    </StyledContent>
  )

  useEffect(() => {
    const contentWrapper = contentWrapperRef.current

    if (contentWrapper) {
      const disableFocusTrapOnContent = event => {
        event.stopPropagation()
      }
      contentWrapper.addEventListener("keydown", disableFocusTrapOnContent)

      return () => {
        contentWrapper.removeEventListener("keydown", disableFocusTrapOnContent)
      }
    }
  }, [])

  return (
    <Root
      {...{
        open,
        defaultOpen: true,
        modal: false,
        onOpenChange: open => {
          if (!open) {
            onClose?.()
          }
        },
      }}
    >
      {anchorRenderer ? (
        <Anchor>{anchorRenderer()}</Anchor>
      ) : (
        anchorPortalElement && createPortal(<Anchor />, anchorPortalElement)
      )}
      {usesPortal ? <Portal>{content}</Portal> : content}
    </Root>
  )
}

export default GuideTooltip

GuideTooltip.propTypes = {
  position: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  align: PropTypes.oneOf(["start", "center", "end"]),
  width: PropTypes.number,
  alignOffset: PropTypes.number,
  sideOffset: PropTypes.number,
  open: PropTypes.bool,
  anchorRenderer: isRequiredIf(
    PropTypes.func,
    props => !props.hasOwnProperty("anchorPortalElement"),
    "`anchorRenderer` is required if not passing a value for `anchorPortalElement",
  ),
  anchorPortalElement: isRequiredIf(
    PropTypes.object,
    props => !props.hasOwnProperty("anchorRenderer"),
    "`anchorPortalElement` is required if not passing a value for `anchorRenderer",
  ),
  title: PropTypes.string,
  iconName: PropTypes.string,
  textRenderer: PropTypes.func,
  onClose: PropTypes.func,
  usesPortal: PropTypes.bool,
}
