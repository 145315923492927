import styled from "@emotion/styled"
import { color, layout, typography } from "js/includes/components/Styled/system"
import { Box, Flex } from "js/includes/components/Styled"
import { sizer, getTextSize, getLineHeight } from "@ninjaone/utils"

export const StyledButton = styled.button`
  border: 0;
  display: inline-block;
  padding: ${({ theme }) => theme.spacing(0, 2)};
  color: ${({ theme, active }) => (active ? theme.color.ninjaBlue : theme.color.darkGray)};
  background-color: ${({ theme }) => theme.color.lightGray};

  &:disabled {
    color: ${({ theme }) => theme.color.gray};
  }

  &:focus {
    outline: none;
  }

  ${color}
  ${layout}
`

export const StyledEditorContainer = styled(Box)`
  word-break: break-word;
  max-height: 450px;
  overflow-y: scroll;

  img {
    max-width: 100%;
    height: auto;
  }

  h1,
  h2 {
    margin-top: 10px;
  }

  blockquote {
    padding: 10px 20px;
    margin: 0 0 10px;
    border-left: 5px solid ${({ theme }) => theme.color.brightGray};
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  ol,
  ul {
    list-style-type: revert;
  }

  font-size: ${getTextSize("sm")};

  [data-slate-placeholder="true"] {
    color: ${({ theme }) => theme.color.black["065"]};
    opacity: 1 !important;
  }
`

export const StyledTable = styled.table`
  width: 100%;
  ${typography}

  th,
  td {
    padding: 5px;
    border: 1px solid ${({ theme }) => theme.color.gray};
  }
`

export const StyledMenu = styled(Box)`
  min-height: 40px;
  display: flex;
  padding: 5px;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.lightGray};

  & > * + * {
    margin-left: 0;
  }
`

export const StyledTableOptions = styled(Box)`
  z-index: 1;
  position: absolute;
  padding: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.color.white["100"]};
  border: ${({ theme }) => `1px solid ${theme.color.gray}`};

  .table-option {
    display: flex;
    margin: 5px 2px;
    white-space: nowrap;
    gap: 5px;
  }

  .table-input {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    gap: 3px;
  }
`

export const StyledTableUnit = styled(Box)`
  width: 15px;
  height: 15px;
  cursor: default;
  border: 1px solid ${({ theme, isSelected }) => (isSelected ? theme.color.ninjaBlue : theme.color.gray)};
`

export const StyledHiddenInput = styled.input`
  display: none !important;
`

export const StyledPre = styled.pre`
  padding: 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  font-family: monospace;
  border-color: ${({ theme }) => theme.color.ninjaLight};
  background-color: ${({ theme }) => theme.color.ninjaWhite};
`

export const StyledSection = styled(Flex)`
  border: ${({ readOnly, hasError, theme }) =>
    readOnly ? "none" : `1px solid ${hasError ? theme.color.error["100"] : theme.color.border}`};
`

export const StyledInput = styled.input`
  width: 100%;
  outline: none;
  background-color: ${({ theme }) => theme.color.background};
  border: 1px solid
    ${({ theme, errorMessage, invalid }) => (errorMessage || invalid ? theme.color.red : theme.color.border)};
  border-radius: ${sizer(1)};
  padding: ${sizer(1)};
  padding-right: ${({ hasIcon }) => (hasIcon ? sizer(10) : sizer(3))};
  font-size: ${({ size }) => getTextSize(size || "sm")};
  font-weight: 400;
  user-select: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: ${({ size }) => getLineHeight(size || "sm")};
  letter-spacing: normal;
  color: ${({ color, theme }) => (color ? theme.color[color] : "")};

  ::placeholder {
    color: ${({ theme }) => theme.color.gray};
  }
`

export const StyledEm = styled.em`
  display: inline !important;
`
