export default function deviceSettings(state = {}, action) {
  switch (action.type) {
    case "UPDATE_TEAMVIEWER_REACH_ENABLED":
      return { teamViewerReachEnabled: action.teamViewerReachEnabled }
    case "UPDATE_SPLASHTOP_ENABLED":
      return { splashtopEnabled: action.splashtopEnabled }
    case "UPDATE_NINJACONNECT_ENABLED":
      return { ninjaConnectEnabled: action.ninjaConnectEnabled }
    default:
      return state
  }
}
