import { getRestoreFileJob, submitJob } from "js/includes/common/backup"
import showModal from "js/includes/common/services/showModal"
import { getPathSeparator, isFeatureEnabled, localized, showSuccessMessage } from "js/includes/common/utils"
import LockhartJobDetailsModal from "js/includes/components/LockhartJobDetailsModal"
import RestoreModal, { getOriginalLocationPath } from "./RestoreModal"

export const RestoreMountedContentModal = ({ unmount: close, nodes, sourceNode }) => {
  const [firstNode] = nodes
  const { revisionId } = firstNode
  const isFile = !firstNode.folder
  const sourceNodeName = sourceNode.friendlyName || sourceNode.name || sourceNode.systemName
  const restoreImageContentPath = nodes.length > 1 ? firstNode.fullParentPath : firstNode.fullPath

  const restore = async ({
    destinationNode,
    collisionHandling,
    restorePermissions,
    destinationPath,
    isOriginalLocation,
  }) => {
    const {
      nodes: [node],
    } = await window.deviceList.fetchById(isOriginalLocation ? sourceNode.id : destinationNode.id)
    const separator = getPathSeparator(sourceNode)
    const sourcePath = isFile ? firstNode.fullPath : restoreImageContentPath

    const getRestoreDestinationPath = () => {
      if (!isOriginalLocation) return destinationPath
      if (nodes.length > 1) return sourcePath
      return getOriginalLocationPath(sourcePath, separator)
    }

    const restoreJob = getRestoreFileJob({
      batch: nodes,
      nodeId: node.id,
      nodeName: node.name || node.systemName,
      sourceNodeName,
      destinationPath: getRestoreDestinationPath(),
      sourceNodeId: sourceNode.id,
      sourcePlanName: firstNode.planName,
      collisionHandling: collisionHandling.value,
      sourcePath,
      sourcePlanId: firstNode.planUuid,
      revisionId,
      restoreType: "EXTRACT",
      filesAndFoldersKey: "path",
      ...(isFeatureEnabled("backup_ntfs_permissions") && { restorePermissions }),
    })

    const response = await submitJob([restoreJob])

    if (response.resultCode !== "SUCCESS") throw new Error("Error creating restore job")

    showSuccessMessage(localized("Restore job created successfully"))
    close()
    showModal(<LockhartJobDetailsModal {...{ job: restoreJob.jobs[0], node }} />)
  }

  return (
    <RestoreModal
      {...{
        RevisionSelector: null,
        restoreJob: restore,
        modalTitle: isFile ? localized("Restore File") : localized("Restore Folder"),
        disableRestore: !revisionId,
        sourceNode,
        nodes,
        restorePath: restoreImageContentPath,
        close,
      }}
    />
  )
}
