export default function vmGuests(
  state = {
    filters: {
      os: [],
      states: [],
    },
    data: [],
  },
  action,
) {
  switch (action.type) {
    case "FILTER_VM_GUESTS":
      return { ...state, filters: action.filters }
    case "FETCH_VM_GUESTS":
      return { ...state, data: action.data }
    default:
      return state
  }
}
