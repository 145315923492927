const activityStatuses = (state = [], action) => {
  switch (action.type) {
    case "SET_ACTIVITY_STATUSES":
      return action.activityStatuses
    default:
      return state
  }
}

export default activityStatuses
