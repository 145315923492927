import { assoc, mergeDeepLeft, compose, propOr } from "ramda"

export default function rootReducer(state = {}, action) {
  switch (action.type) {
    case "UPDATE_ACTIVE_DEVICE":
      const { id, payload } = action

      const updatedActiveDevice = compose(mergeDeepLeft(payload), propOr({}, id))(state)

      return assoc(id, updatedActiveDevice, state)
    default:
      return state
  }
}
