import { memo, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { slice } from "ramda"
import tokens from "@ninjaone/tokens"
import { CaretRightIcon } from "@ninjaone/icons"
import { getTextSize, sizer } from "@ninjaone/utils"
import { useMountedState } from "@ninjaone/webapp/src/js/includes/common/hooks"
import { debounce, isNilOrEmpty } from "@ninjaone/webapp/src/js/includes/common/utils"
import { Box, StyledSpan } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { isEnterKey, isSpaceKey } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/charsets"
import { UnstyledButton } from "./Styled"
import classNames from "classnames"

const StyledBreadcrumbContainer = styled.nav`
  font-size: ${getTextSize("sm")};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : tokens.spacing[4])};
  width: ${({ width }) => width || "100%"};
  display: flex;
  align-items: center;

  ol {
    list-style: none;
  }

  .disabled {
    cursor: default;
    color: ${({ theme }) => theme.colorTextDisabled};
  }

  .breadcrumb-history-root-icon {
    width: 38px;
    line-height: 1;
  }

  .breadcrumb-history-path-wrapper {
    flex: 1;
    min-width: 1px;

    .breadcrumb-history-path {
      text-overflow: ellipsis;
      padding: 2px 0;
      display: flex;
      flex-wrap: nowrap;
      margin: 0;

      .last-link {
        color: ${({ theme }) => theme.colorTextWeak};
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .active-link {
        color: ${({ theme }) => theme.colorTextWeak};
        font-weight: 500;
      }

      li {
        white-space: nowrap;
      }
    }

    .clickable-link {
      color: ${({ theme, colors }) => (colors ? colors.link : theme.colorTextWeakest)};
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    .breadcrumb-history-path-dropdown {
      position: absolute;
      background-color: ${({ theme }) => theme.colorBackgroundWidget};
      border: 1px solid ${({ theme }) => theme.colorBorderWeak};
      width: auto;
      z-index: 2000;
      padding-inline-start: 0;

      li:hover:not(.disabled) {
        background-color: ${({ theme }) => theme.colorForegroundHover};
        text-decoration: underline;
      }

      .breadcrumb-history-path-dropdown-path {
        width: 300px;
        display: flex;
        align-items: center;
        padding: ${tokens.spacing[2]};
        justify-content: space-between;
        overflow-wrap: anywhere;
      }
    }
  }
`

const StyledCaretRightIcon = styled(CaretRightIcon)`
  color: ${({ theme }) => theme.colorTextWeakest};
`

const Breadcrumb = memo(
  ({
    history,
    setHistory,
    getItemUrl,
    handleClick,
    separator,
    rootRenderer,
    customRenderer,
    disableLastLink,
    width,
    colors,
    marginBottom = sizer(4),
    disableActiveLastLink = false,
    disableCollapse = false,
  }) => {
    const [collapsedBreadcrumbs, setCollapsedBreadcrumbs] = useMountedState([])
    const browserHistoryRef = useRef(null)
    const historyPathContainerRef = useRef(null)
    const [showCollapsed, setShowCollapsed] = useMountedState(false)

    useEffect(() => {
      const handleOverflow = debounce(() => {
        if (!historyPathContainerRef.current?.childNodes) return

        setCollapsedBreadcrumbs([])

        const childNodes = historyPathContainerRef.current?.childNodes
        let length = childNodes.length
        let group = []

        if (!length) {
          setCollapsedBreadcrumbs(group)
          return
        }

        let width = childNodes[0].offsetWidth + 50

        for (let i = length - 1; i >= 0; i--) {
          const node = childNodes[i]
          node.style.display = "inline"
          const offsetWidth = node.offsetWidth
          width += offsetWidth

          if (!history[i] || i === 0 || i === length - 1 || disableCollapse) {
            continue
          }

          if (width >= browserHistoryRef.current.offsetWidth) {
            group = [...group, { node: history[i], historyIndex: i }]
            node.style.display = i > 1 ? "none" : "inline"
          }
        }

        setCollapsedBreadcrumbs(group.reverse())
      }, 160)

      handleOverflow()

      window.addEventListener("resize", handleOverflow)

      return () => {
        window.removeEventListener("resize", handleOverflow)
      }
    }, [history, setCollapsedBreadcrumbs, disableCollapse])

    const defaultSeparator = <StyledCaretRightIcon size="xs" />

    const getSeparator = node =>
      separator ? (typeof separator === "function" ? separator(node) : separator) : defaultSeparator

    const showCollapsedBreadcrumbs = () => setShowCollapsed(true)
    const hideCollapsedBreadcrumbs = () => debounce(setShowCollapsed(false), 1000)

    const _handleClick = ({ node, historyIndex }) => {
      if (handleClick) {
        handleClick({ node, historyIndex })
      } else {
        if (node.path === window.location.hash) return
        setHistory?.(historyIndex === 0 ? [] : slice(0, historyIndex + 1, history))
        window.location.hash = node.path
      }
    }

    const breadcrumbs = history.map((node, historyIndex) => {
      const { path, name, active, id } = node
      const nodeKey = `${id}-${name}`
      const isInCollapsedDropdown = collapsedBreadcrumbs.some(({ node }) => node.path === path)
      const _separator = history.length > 1 ? getSeparator(node) : ""
      const isLastLink = historyIndex === history.length - 1
      const showEllipses = !disableCollapse && isInCollapsedDropdown && historyIndex === 1

      if (disableLastLink && isLastLink) {
        if (disableActiveLastLink) {
          return (
            <li
              className={!disableCollapse ? "last-link" : active ? "active-link" : "disabled"}
              key={nodeKey}
              aria-label={name}
            >
              {name}
            </li>
          )
        } else {
          return (
            <li className="last-link" key={nodeKey} aria-label={name}>
              {name}
            </li>
          )
        }
      } else if (showEllipses) {
        return (
          <li key={nodeKey} className="clickable-link">
            <UnstyledButton
              onKeyDown={event => {
                if (isEnterKey(event) || isSpaceKey(event)) {
                  showCollapsed ? hideCollapsedBreadcrumbs() : showCollapsedBreadcrumbs()
                }
              }}
              onMouseEnter={showCollapsedBreadcrumbs}
            >
              ...{_separator}
            </UnstyledButton>
          </li>
        )
      } else {
        const disabled = isNilOrEmpty(path)

        const liClassNames = classNames({
          "disabled": isNilOrEmpty(path),
          "clickable-link": !isNilOrEmpty(path),
          "active-link": disableActiveLastLink && active,
        })

        return (
          <li key={nodeKey} className={disabled ? "disabled" : ""}>
            <a
              href={getItemUrl?.(node) || window.location.href}
              className={liClassNames}
              onClick={e => {
                e.preventDefault()
                !disabled && _handleClick({ node, historyIndex })
              }}
            >
              {customRenderer ? (
                customRenderer(name, _separator)
              ) : (
                <>
                  {name}
                  {_separator}
                </>
              )}
            </a>
          </li>
        )
      }
    })

    const dropdownNavigation = !!collapsedBreadcrumbs.length && (
      <ol className="breadcrumb-history-path-dropdown">
        {collapsedBreadcrumbs.map(({ node, historyIndex }) => {
          const { path, name, id } = node
          const nodeKey = `${id}-${name}`
          const _separator = getSeparator(node)
          const disabled = isNilOrEmpty(path)

          return (
            <li
              key={`dropdown_${nodeKey}`}
              className={`breadcrumb-history-path-dropdown-path${disabled ? " disabled" : ""}`}
            >
              <UnstyledButton
                onClick={e => {
                  e.preventDefault()
                  !disabled && _handleClick({ node, historyIndex })
                }}
              >
                <a href={path || "#!"} className={disabled ? "disabled" : "clickable-link"}>
                  {customRenderer ? customRenderer(name, _separator) : name}
                </a>
                <StyledSpan marginLeft={sizer(1)}>{_separator}</StyledSpan>
              </UnstyledButton>
            </li>
          )
        })}
      </ol>
    )

    const dropdownOffset = historyPathContainerRef.current?.childNodes[0]?.offsetWidth

    return (
      <StyledBreadcrumbContainer {...{ width, colors, marginBottom, onMouseLeave: hideCollapsedBreadcrumbs }}>
        {rootRenderer?.()}
        <div data-testid="breadcrumbs-wrapper" className="breadcrumb-history-path-wrapper" ref={browserHistoryRef}>
          <ol data-testid="breadcrumbs-history" className="breadcrumb-history-path" ref={historyPathContainerRef}>
            {breadcrumbs}
          </ol>
          <Box position="relative" left={dropdownOffset ?? "0"}>
            {showCollapsed && dropdownNavigation}
          </Box>
        </div>
      </StyledBreadcrumbContainer>
    )
  },
)

export default Breadcrumb

Breadcrumb.propTypes = {
  rootRenderer: PropTypes.func,
  history: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string.isRequired,
    }),
  ),
  setHistory: PropTypes.func,
  getItemUrl: PropTypes.func,
  handleClick: PropTypes.func,
  separator: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  customRenderer: PropTypes.func,
  disableLastLink: PropTypes.bool,
  width: PropTypes.string,
  marginBottom: PropTypes.string,
}
