import { Modal } from "@ninjaone/components"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { useForm, useKeydown } from "js/includes/common/hooks"
import { isEscapeKey, validations } from "js/includes/common/utils"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export const AssignIncidentModal = ({ unmount, previousType, previousParentTicketId = null, onChange, onSave }) => {
  const {
    values: { parentTicket },
    onChange: onLocalChange,
    validateForm,
    validation: { message },
  } = useForm({
    fields: { parentTicket: null },
    validate: {
      parentTicket: validations.required,
    },
  })

  useKeydown(isEscapeKey, () => onChange({ type: previousType, parentTicketId: previousParentTicketId }))

  function handleSelect(selected) {
    const { id } = selected || {}
    onChange({ type: "INCIDENT", parentTicketId: id })
    onLocalChange({ parentTicket: selected })
  }

  function handleUnmount() {
    onChange({ type: previousType, parentTicketId: previousParentTicketId })
    unmount()
  }

  async function handleSave() {
    if (validateForm()) {
      await onSave()
      unmount()
    }
  }

  return (
    <Modal
      unmount={handleUnmount}
      buttons={[
        { type: "save", labelToken: localizationKey("Save"), onClick: handleSave, disabled: parentTicket === null },
      ]}
      titleGroup={{
        titleToken: localizationKey("Assign Incident"),
        descriptionToken: localizationKey("Select the problem this incident belongs to"),
      }}
    >
      <SearchableDropDown
        width="100%"
        pageSize={50}
        endpoint="/ticketing/ticket/problem"
        valueSelectorKey="id"
        searchableKey="name"
        openOnFocus
        keepDropdownInView
        value={parentTicket}
        searchParams={({ query }) => ({ ...(query && { searchCriteria: query }) })}
        rowRenderer={({ id, name }) => `#${id} ${name}`}
        onSelect={handleSelect}
        errorMessage={message.parentTicket}
        validationState={message.parentTicket && "error"}
        useSelectStyling
      />
    </Modal>
  )
}
