import { combineReducers } from "redux"
import policies from "./policies"
import discoveryJobs from "./discoveryJobs/rootReducer"
import loading from "js/state/reducers/common/loading"
import createNamedWrapperReducer from "js/state/reducers/createNamedWrapperReducer"

export default combineReducers({
  policies,
  discoveryJobs,
  lodingNodeRoles: createNamedWrapperReducer(loading, "nodeRolesList", false),
})
