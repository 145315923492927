import { ControlLabel } from "react-bootstrap"
import { StyledCol, StyledFormGroup } from "js/includes/components/Styled/Form"
import { localizationKey, localized } from "js/includes/common/utils"
import Tooltip from "js/includes/components/Tooltip"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

const SelectFormGroup = ({
  validationSuccess,
  validationMessage,
  label,
  options,
  value,
  onChange,
  required,
  tooltip,
  tooltipClassName = "text-wrap white-space-pre-line",
  tooltipPlacement = "top",
  isMulti,
  isClearable,
  onBlur,
  searchableKey = "name",
  valueSelectorKey = "id",
  loading,
  noRowsRendererToken,
  openOnFocus = true,
  searchableDropDownAttributes,
  isHorizontal,
  useSelectStyling,
}) => (
  <StyledFormGroup
    flexDirection={isHorizontal ? "row" : "column"}
    margin={[0, 0, 4]}
    validationState={validationSuccess === false ? "error" : null}
  >
    <StyledCol
      flex={4}
      componentClass={ControlLabel}
      justifyContent="left"
      flexDirection="row"
      alignItems="center"
      textAlign="left"
    >
      {typeof label === "function" ? label() : localized(label)}
      {required && <sup>*</sup>}
      {tooltip && <Tooltip className={tooltipClassName} token={tooltip} placement={tooltipPlacement} />}
    </StyledCol>
    <StyledCol flex={8}>
      <SearchableDropDown
        {...{
          useSelectStyling,
          options,
          value,
          validationMessage,
          openOnFocus,
          onSelect: onChange,
          searchableKey,
          valueSelectorKey,
          width: "100%",
          searchPlaceholderToken: isMulti ? localizationKey("Select Multiple...") : localizationKey("Select..."),
          isMulti,
          isClearable,
          onBlur,
          loading,
          noRowsRendererToken,
          keepDropdownInView: true,
          ...(validationSuccess === false && { validationState: "error" }),
          ...searchableDropDownAttributes,
        }}
      />
    </StyledCol>
  </StyledFormGroup>
)

export default SelectFormGroup
