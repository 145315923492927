import { Suspense } from "react"
import { $applyNodeReplacement } from "lexical"
import { ImageNode } from "@ninjaone/components/src/WYSIWYG"
import { TicketingImageComponent } from "./TicketingImageComponent"

function convertImageElement(domNode) {
  const img = domNode
  if (img.src.startsWith("file:///")) return null

  const { src, width, height } = img
  const node = $createTicketingImageNode({ height, src, width })
  return { node }
}
export class TicketingImageNode extends ImageNode {
  __cid
  __src
  __width
  __height
  __maxWidth
  __attachment

  constructor(cid, src, maxWidth, width, height, attachment, key) {
    super(cid, src, maxWidth, width, height, key)
    this.__cid = cid
    this.__src = src
    this.__maxWidth = maxWidth
    this.__width = width || "inherit"
    this.__height = height || "inherit"
    this.__attachment = attachment
  }

  static getType() {
    return "ticketing-image"
  }

  static clone(node) {
    return new TicketingImageNode(
      node.__cid,
      node.__src,
      node.__maxWidth,
      node.__width,
      node.__height,
      node.__attachment,
      node.__key,
    )
  }
  static importJSON(serializedNode) {
    const { height, width, maxWidth, src, cid, attachment } = serializedNode
    const node = $createTicketingImageNode({
      cid,
      height,
      maxWidth,
      src,
      width,
      attachment,
    })
    return node
  }

  exportDOM() {
    const element = document.createElement("img")
    element.setAttribute("data-cid", this.__cid)
    // TODO: Figure out if we could remove "src" and have the server use "data-cid" instead.
    element.setAttribute("src", this.getSrc())
    element.setAttribute("width", this.getWidth().toString())
    element.setAttribute("height", this.getHeight().toString())
    return { element }
  }

  static importDOM() {
    return {
      img: node => ({
        conversion: convertImageElement,
        priority: 0,
      }),
    }
  }

  exportJSON() {
    return {
      src: this.getSrc(),
      cid: this.__cid,
      attachment: this.__attachment,
      maxWidth: this.__maxWidth,
      height: this.getHeight() === "inherit" ? 0 : this.getHeight(),
      width: this.getWidth() === "inherit" ? 0 : this.getWidth(),
      type: "ticketing-image",
      version: 1,
    }
  }

  setWidthAndHeight(width, height) {
    const self = this.getWritable()
    self.__width = width
    self.__height = height
  }

  getWidth() {
    const self = this.getLatest()
    return self.__width
  }

  getHeight() {
    const self = this.getLatest()
    return self.__height
  }

  // View
  createDOM(config) {
    const span = document.createElement("span")
    const className = config.theme.image
    if (className) {
      span.className = className
    }
    return span
  }

  updateDOM() {
    return false
  }

  getSrc() {
    return this.__src
  }

  getCid() {
    return this.__cid
  }

  decorate() {
    return (
      <Suspense fallback={null}>
        <TicketingImageComponent
          cid={this.__cid}
          maxWidth={this.__maxWidth}
          nodeKey={this.getKey()}
          src={this.getSrc()}
          width={this.getWidth()}
          // height=auto will preserve image aspect ratio for log entries
          height="auto"
          resizable={true}
          attachment={this.__attachment}
        />
      </Suspense>
    )
  }
}

export function $createTicketingImageNode({ key, cid, src, height, width, maxWidth = "100%", attachment }) {
  return $applyNodeReplacement(new TicketingImageNode(cid, src, maxWidth, width, height, attachment, key))
}

export function $isTicketingImageNode(node) {
  return node instanceof TicketingImageNode
}
