import { initialState } from "./initialStates"

import { addHost, removeHost, updateHost } from "./actionHandlers/hostList"

export default function hosts(state = initialState(), action) {
  switch (action.type) {
    case "SET_VIRTUALIZATION_HOST_LIST":
      return initialState(action.hosts)
    case "ADD_VIRTUALIZATION_HOST":
      return addHost(state, action)
    case "REMOVE_VIRTUALIZATION_HOST":
      return removeHost(state, action)
    case "UDPATE_VIRTUALIZATION_HOST":
      return updateHost(state, action)
    case "CLEAR_VIRTUALIZATION_HOSTS":
      return initialState()
    default:
      return state
  }
}
