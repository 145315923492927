import styled from "@emotion/styled"
import { ContentEditable as LexicalContentEditable } from "@lexical/react/LexicalContentEditable"

const shouldForwardProp = prop => prop !== "isPublicResponse"

const StyledLexicalContentEditableReadOnly = styled(LexicalContentEditable, { shouldForwardProp })`
  order: 0;
  display: block;
  position: relative;
  outline: 0;
  background-color: ${({ isPublicResponse, theme }) =>
    isPublicResponse === false ? theme.colorAlertWarningWeakest : theme.colorBackground};
`

export const ContentEditableReadOnly = props => {
  return <StyledLexicalContentEditableReadOnly {...props} />
}
