import moment from "moment"
import { time, ordinal, localized } from "js/includes/common/utils"
import { shortDaysTokensMapper } from "js/includes/common/utils/ssrAndWebUtils"

export const getDailyTimeDescription = (hour, minute) =>
  localized("Daily at {{time}}", {
    time: time(
      moment({
        hour,
        minute,
      }),
    ),
  })

export const getIntervalTimeDescription = (hour, minute) => {
  let time, unit
  if (hour) {
    time = hour
    unit = hour > 1 ? localized("Hours") : localized("Hour")
  } else {
    time = minute
    unit = minute > 1 ? localized("Minutes") : localized("Minute")
  }

  return localized("Every {{time}} {{unit}}", {
    time,
    unit,
  })
}

const getLocalizedWeekDay = day => {
  return localized(shortDaysTokensMapper[day])
}

export const getWeeklyTimeDescription = (hour, minute, weekDays) => {
  const days = weekDays.map(day => getLocalizedWeekDay(day)).join(", ")

  return localized("Weekly on {{days}} at {{time}}", {
    days,
    time: time(
      moment({
        hour,
        minute,
      }),
    ),
  })
}

export const getMonthlyTimeDescription = (hour, minute, day) =>
  day === 0
    ? localized("Monthly on last day of the month, at {{time}}", {
        time: time(
          moment({
            hour,
            minute,
          }),
        ),
      })
    : localized("Monthly on the {{day}} at {{time}}", {
        day: ordinal(day),
        time: time(
          moment({
            hour,
            minute,
          }),
        ),
      })

export const getWeekOfMonthDescription = (hour, minute, weekOfMonth, weekDays) =>
  weekOfMonth === 0
    ? localized("Monthly on the last {{day}} of the month at {{time}}", {
        day: getLocalizedWeekDay(weekDays[0]),
        time: time(
          moment({
            hour,
            minute,
          }),
        ),
      })
    : localized("Monthly on the {{week}} {{day}} of the month at {{time}}", {
        week: ordinal(parseInt(weekOfMonth, 10)),
        day: getLocalizedWeekDay(weekDays[0]),
        time: time(
          moment({
            hour,
            minute,
          }),
        ),
      })
