import { PaperPlaneIconLight } from "@ninjaone/icons"
import { isRmmServiceLocation, localized } from "js/includes/common/utils"
import RowItem from "./RowItem"
import { DROPDOWN_VIEWS_KEY } from "./helpDropdownData"

const SubmitTicketRowItem = ({ handleChangeView }) => {
  return (
    <RowItem
      {...{
        id: "submit-help-ticket",
        onClick: () => {
          handleChangeView(DROPDOWN_VIEWS_KEY.submitHelpTicket)
        },
        topic: localized("Submit help ticket"),
        icon: isRmmServiceLocation() ? null : <PaperPlaneIconLight />,
      }}
    />
  )
}

export default SubmitTicketRowItem
