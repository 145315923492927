//TODO: turn into high order reducer
const processingDevices = (state = null, action) => {
  switch (action.type) {
    case "SET_PROCESSING_POLICY":
      return action.policy
    case "CLEAR_CONFIRM_MODAL_OPTIONS":
    case "CLEAR_PROCESSING_POLICY":
      return null
    default:
      return state
  }
}

export default processingDevices
