const initialState = {
  enabled: false,
  username: "",
  password: "",
}

export default function connection(state = initialState, action) {
  switch (action.type) {
    case "SET_AT_CONNECTION":
      return { ...state, ...action.payload }
    default:
      return state
  }
}
