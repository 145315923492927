import React, { memo } from "react"
import { find, map, omit } from "ramda"
import { useMutation } from "urql"

import { reportErrorAndShowMessage, showErrorMessage } from "js/includes/common/utils"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { Box } from "js/includes/components/Styled"
import { getTagErrors } from "js/includes/ticketing/commonMethods"
import { createTag as _createTag } from "js/includes/ticketing/graphql"

const findNew = ({ isNew }) => isNew
const genList = map(name => ({ value: name, label: name }))
const removeNewKey = map(omit("isNew"))

const TagsInput = ({ selected, tags, onChange, disableEditor, onFocus, ariaAttributes, ariaInputAttributes }) => {
  const [, createTag] = useMutation(_createTag)

  const handleChange = async values => {
    const newTag = find(findNew, values || [])
    if (newTag) {
      const tagErrors = getTagErrors(newTag.value)

      if (!tagErrors) {
        const { error } = await createTag({
          tag: {
            name: newTag.value,
          },
        })

        if (error) {
          return reportErrorAndShowMessage(error, localizationKey("Error creating tag"))
        }
      } else {
        return tagErrors.forEach(errorToken => {
          showErrorMessage(errorToken)
        })
      }
    }
    onChange(newTag ? removeNewKey(values) : values)
  }

  const selectedTags = genList(selected)

  return (
    <SearchableDropDown
      {...{
        options: tags,
        value: selectedTags,
        width: "100%",
        isMulti: true,
        isCreatable: true,
        placeholderToken: localizationKey("Tags"),
        onSelect: handleChange,
        openOnFocus: true,
        onCreateNewOption: value => ({ label: value }),
        shouldCreate: tag => {
          const errors = getTagErrors(tag)

          errors &&
            errors.forEach(errorToken => {
              showErrorMessage(errorToken)
            })

          return !errors
        },
        keepDropdownInView: true,
        disabled: disableEditor,
        rowRenderer: row => (
          <Box overflow="hidden">
            <p className="no-margin text-ellipsis line-height-initial">{row.label}</p>
          </Box>
        ),
        ariaAttributes,
        ariaInputAttributes,
      }}
    />
  )
}

TagsInput.defaultProps = {
  tags: [],
  onChange: () => {},
}

export default memo(TagsInput)
