const addProductSearch = (state = "", action) => {
  switch (action.type) {
    case "SET_ADD_PRODUCT_SEARCH":
      return action.value
    case "CLEAR_ADD_PRODUCT_SEARCH":
      return ""
    default:
      return state
  }
}

export default addProductSearch
